import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';
import applicationActions from '../application/actions';

const { } = actions;

const saveOption = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.saveOption( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Option saved' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const deleteOption = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { currentAccount, user, groups, currentUser, resources, categories } = getState();
    dispatch( actions.deleteOption( { ...data, accountId: currentAccount.id }, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Option deleted' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const deleteFolder = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { options, currentAccount, user, groups, currentUser, resources, categories } = getState();

    const array = options.options.map( option => new Promise( ( resolve2, reject2 ) => {
        if ( option.accountId === currentAccount.id && option.folderName === data ) {
            dispatch( actions.deleteOption( { optionId: option.id, accountId: option.accountId }, { user } ) )
                .then( () => {
                    resolve2();
                } ).catch( () => { reject2(); } );
        } else {
            resolve();
        }
    } ) );
    Promise.all( array )
        .then( () => {
            dispatch( messageOperations.showSuccess( 'Folder deleted' ) );
            resolve();
        } )
        .catch( () => { messageOperations.showError( 'Could not delete folder' ); reject(); } );
} ) );

const renameFolder = ( folder, newName ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { options, currentAccount, user, groups, currentUser, resources, categories } = getState();
    const array = options.options.map( option => new Promise( ( resolve2, reject2 ) => {
        if ( option.accountId === currentAccount.id && option.folderName === folder ) {
            const newOption = { ...option, folderName: newName };
            dispatch( actions.saveOption( { accountId: currentAccount.id, optionId: option.id, option: newOption }, { user } ) )
                .then( () => {
                    resolve2();
                } ).catch( () => { reject2(); } );
        } else {
            resolve2();
        }
    } ) );
    Promise.all( array )
        .then(
            () => {
                dispatch( actions.recalculate() );
                dispatch( messageOperations.showSuccess( 'Rename completed' ) );
                resolve();
            }
        )
        .catch( () => { dispatch( messageOperations.showSuccess( 'Rename failed' ) ); reject(); } );
} ) );

export default {
    saveOption,
    deleteOption,
    renameFolder,
    deleteFolder
};
