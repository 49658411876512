import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import TableView from '../Table/Table';
import UsersPermissionsModal from '../UsersPermissionsList/UsersPermissionsModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';

function composeMembersTable( members, triggerDeleteModal, currentAccount, updating, canAccess, updateMember, openPermissionsModal ) {
    const accountId = currentAccount.id;
    return members.map( member => (
        {
            id: Math.random(),
            icon: <div className="avatar">
                <Gravatar email={ member.email } className="img-avatar" />
            </div>,
            email: member.email,
            actions: <div>
                { updating === member.userId
          && <span>updating ...</span>
                }
                {( ( ( updating !== member.userId || !updating ) ) && canAccess )
          && (
              <button
                  style={ { marginLeft: '10px' } }
                  type="button"
                  className="btn btn-outline-primary"
                  disabled={ member.deleted || typeof currentAccount.usersPermissions === 'undefined' ? false : !currentAccount.usersPermissions[member.userId] }
                  onClick={ () => {
                      openPermissionsModal( member.userId );
                  } }>
                    permissions
              </button>
          )}
                {( ( ( updating !== member.userId || !updating ) && member.access !== 'Owner' ) && canAccess )
          && (
              <React.Fragment>

                  <button
                      style={ { marginLeft: '10px' } }
                      type="button"
                      className="btn btn-outline-danger"
                      disabled={ member.deleted }
                      onClick={ () => {
                          triggerDeleteModal( { userId: member.userId, inviteId: member.inviteId, accountId } );
                      } }>
            delete
                  </button>
              </React.Fragment>
          )
                }
            </div>,


        }
    ) );
}

const columns = [
    { name: 'icon', title: ' ' },
    { name: 'email', title: 'Email' },
    { name: 'actions', title: 'Actions' }
];

class ListMembers extends Component {
    static log( form ) { }

    constructor() {
        super();
        this.state = { updating: null, openModal: false, userId: null };
        this.openModal = this.openModal.bind( this );
        this.deleteMember = this.deleteMember.bind( this );
        this.updateMember = this.updateMember.bind( this );
        this.triggerDeleteModal = this.triggerDeleteModal.bind( this );
        this.toggleConfirmation = this.toggleConfirmation.bind( this );
    }

    deleteMember( data ) {
        if ( this.state.updating ) {
            return;
        }
        this.setState( { updating: this.state.deleteData.userId } );
        this.props.deleteMember( this.state.deleteData )
            .then( () => { this.setState( { updating: null, deleteData: {}, showConfirmModal: false } ); } )
            .catch( () => { this.setState( { updating: null, deleteData: {}, showConfirmModal: false } ); } );
    }

    updateMember( data ) {
        this.setState( { updating: data.userId } );
        this.props.updateMember( data )
            .then( () => { this.setState( { updating: null } ); } )
            .catch( () => { this.setState( { updating: null } ); } );
    }

    openModal( userId ) {
        this.setState( { openModal: true, userId } );
    }

    triggerDeleteModal( data ) {
        this.setState( { deleteData: data, showConfirmModal: true } );
    }

    toggleConfirmation() {
        this.setState( { showConfirmModal: !this.state.showConfirmModal } );
    }

    render() {
        const { currentAccount, canAccess } = this.props;
        if ( !Object.keys( currentAccount ).hasItems() || !currentAccount.members ) {
            return ( <script /> );
        }
        const membersToDisplay = Object.keys( currentAccount.members )
            .map( key => ( currentAccount.members[key].deleted ? null : { ...currentAccount.members[key], userId: key } ) )
            .filter( x => x );
        if ( membersToDisplay.length === 0 ) {
            return ( <script /> );
        }
        const list = composeMembersTable( membersToDisplay, this.triggerDeleteModal, this.props.currentAccount, this.state.updating, canAccess, this.updateMember, this.openModal );

        return (
            <div className="customTable clickableRow white-box" style={ { marginBottom: 30 } }>
                <div className="card-header pt-0">
                    <i className="fa fa-align-justify" /> Members
                </div>
                <ConfirmationModal
                    toggle={ this.toggleConfirmation }
                    open={ this.state.showConfirmModal }
                    action={ this.deleteMember }
                    processing={ this.state.updating }
                    message="Are you sure you want to delete this member ?"
                />
                <span style={ { display: 'none' } }>
                    <UsersPermissionsModal
                        currentAccount={ currentAccount }
                        open={ this.state.openModal }
                        userId={ this.state.userId }
                        reset={ () => { this.setState( { openModal: false } ); } }
                    />
                </span>
                <TableView
                    rowsDOM={ list }
                    columns={ columns }
                    disableSortingFor={ [ 'actions', 'icon' ] }
                />
            </div>
        );
    }
}

const { object, func } = PropTypes;
ListMembers.propTypes = {
    currentAccount: object.isRequired,
    deleteMember: func.isRequired
};

export default ListMembers;
