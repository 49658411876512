import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import Button from 'reactstrap/lib/Button';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import OptionBuilder from './OptionBuilder';
import DefaultOptionManager from './DefaultOptionManager';
import OptionsList from "./OptionsList";

class DefaultOptions extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isOptionBuilderModalOpen: false,
            isOptionAssignModalOpen: false
        };
    }

    toggleOptionBuilderModal = () => {
        this.setState( { isOptionBuilderModalOpen: !this.state.isOptionBuilderModalOpen } );
    };

    toggleOptionAssignModal = () => {
        this.setState( { isOptionAssignModalOpen: !this.state.isOptionAssignModalOpen } );
    };

    render() {
        const { open, toggle } = this.props;
        const { isOptionAssignModalOpen, isOptionBuilderModalOpen } = this.state;
        return (
            <Modal isOpen={ open } toggle={ toggle } className="customWidthModal">
                <ModalHeader toggle={ toggle }>Document types manager</ModalHeader>
                <ModalBody>
                    <button
                        id="createEditField"
                        className="btn btn-outline-primary btn-block col-lg-3 pull-right mt-0"
                        onClick={ this.toggleOptionBuilderModal }>Create / Edit Fields
                    </button>
                    <button
                        id="assignOption"
                        className="btn btn-outline-primary btn-block col-lg-3 pull-right mt-0"
                        onClick={ this.toggleOptionAssignModal }>Assign Field
                    </button>
                    <br />
                    <br />
                    <br />
                    <DefaultOptionManager
                        isOptionAssignModalOpen={ isOptionAssignModalOpen }
                        toggle={ this.toggleOptionAssignModal }
                    />
                    { isOptionBuilderModalOpen
                    && <OptionsList toggle={ this.toggleOptionBuilderModal } open={ isOptionBuilderModalOpen } />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button id="saveBtn" className="" onClick={ toggle }>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const { } = PropTypes;
DefaultOptions.propTypes = {};

export default DefaultOptions;
