import React, { Component } from 'react';
import './repeatBody.scss';
import { DailyView, MonthlyView, WeeklyView, YearlyView } from './repeatViews';
import Option from './Option';

class RepeatBody extends Component {
    constructor() {
        super();
        this.state = {
            status: false,
            selected: 'DAILY',
            repeatOptions: null
        };
        this.select = this.select.bind( this );
    }

    componentWillMount() {
        if ( typeof this.props.data !== 'undefined' && Object.keys( this.props.data ).hasItems() ) {
            this.setState( { selected: this.props.data.frequency.toUpperCase() } );
        }
    }

    select( value ) {
        if ( this.props.disabled ) {
            return;
        }
        this.setState( { selected: value } );
        this.props.callback( { type: value, payload: {} } );
    }

    render() {
        let currentView = '';
        switch ( this.state.selected ) {
            case 'DAILY': currentView = <DailyView callback={ this.props.callback } data={ this.props.data } disabled={ this.props.disabled } />; break;
            case 'WEEKLY': currentView = <WeeklyView callback={ this.props.callback } data={ this.props.data } disabled={ this.props.disabled } />; break;
            case 'MONTHLY': currentView = <MonthlyView callback={ this.props.callback } data={ this.props.data } disabled={ this.props.disabled } />; break;
            case 'YEARLY': currentView = <YearlyView callback={ this.props.callback } data={ this.props.data } disabled={ this.props.disabled } />; break;
            default: currentView = <DailyView callback={ this.props.callback } data={ this.props.data } disabled={ this.props.disabled } />;
        }
        return (
            <React.Fragment>
                <hr />
                <div className="widget collection">
                    <Option callback={ this.select } type="DAILY" text="Daily" selected={ this.state.selected === 'DAILY' } disabled={ this.props.disabled } />
                    <Option callback={ this.select } type="WEEKLY" text="Weekly" selected={ this.state.selected === 'WEEKLY' } disabled={ this.props.disabled } />
                    <Option callback={ this.select } type="MONTHLY" text="Monthly" selected={ this.state.selected === 'MONTHLY' } disabled={ this.props.disabled } />
                    <Option callback={ this.select } type="YEARLY" text="Yearly" selected={ this.state.selected === 'YEARLY' } disabled={ this.props.disabled } />
                </div>
                <div style={ { margin: '10px 0' } }>
                    { currentView }
                </div>
            </React.Fragment>
        );
    }
}

export default RepeatBody;
