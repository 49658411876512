import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import PropTypes from 'prop-types';

class OfflineModal extends React.PureComponent {
    render() {
        return (
            <Modal isOpen={ this.props.showsOfflineMessage || false } >
                <div className="text-center" style={ { textAlign: 'center', marginTop: '50px' } }>
                    <img
                        src="/img/mycontracts-logo-3-long-v3.svg"
                        alt="mycontracts"
                        // className="logo"
                        style={ {
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '50px',
                            width: '50%'
                        } }
                    />
                    <h2 className="text-warning">You are currently offline !</h2>
                    <p>Please make sure you have an internet connection</p>
                </div>
            </Modal>
        );
    }
}

OfflineModal.propTypes = {
    showsOfflineMessage: PropTypes.bool
};

export default OfflineModal;
