import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Badge } from 'reactstrap';
import Select from 'react-select';
import Display from '../Display';


class GroupSelector extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            groups: props.data || []
        };
        this.buildSelectedGroupsDOM = this.buildSelectedGroupsDOM.bind( this );
        this.selectGroup = this.selectGroup.bind( this );
        this.removeSelectedGroup = this.removeSelectedGroup.bind( this );
    }

    buildSelectedGroupsDOM( ) {
        return this.state.groups.map( id => {
            const selectedGroup = this.props.groups.having( 'id', id )[0];
            if ( typeof selectedGroup !== 'undefined' ) {
                return (
                    <Badge
                        key={ Math.random() }
                        color={ selectedGroup.escalation ? 'info' : 'primary' }
                        className={ this.props.disabled ? selectedGroup.escalation ? 'selectedGroup' : 'selectedGroup disabled' : 'selectedGroup' }>{selectedGroup.name}
                        <Display when={ !this.props.disabled && !selectedGroup.escalation }>
                            <span onClick={ () => { this.removeSelectedGroup( id ); } }>x</span>
                        </Display>
                    </Badge>
                );
            }
            return '';
        } );
    }

    removeSelectedGroup( data ) {
        const index = this.state.groups.indexOf( data );
        if ( index > -1 ) {
            const newState = [ ...this.state.groups ];
            newState.splice( index, 1 );
            this.setState( { groups: newState } );
            this.props.removeGroup( newState );
        }
    }

    selectGroup( data ) {
        this.setState( { groups: Array.from( new Set( [ ...this.state.groups, data.value ] ) ) } );
        this.props.callback( data.value );
    }

    render() {
        const defaultMessage = 'As no Group has yet been allocated for this document type/category, this Event will be assigned to the Creator.';
        if ( this.props.groups.length === 0 && !this.props.disabled ) {
            return ( <Alert color="info">{defaultMessage}</Alert> );
        }
        return (
            <React.Fragment>
                <div>
                    { this.buildSelectedGroupsDOM( ) }
                </div>
                <Select
                    className="customReactSelect"
                    name="priority"
                    id="eventPriority"
                    disabled={ this.props.disabled }
                    options={ this.props.groups.map( group => ( { value: group.id, label: group.name } ) ) }
                    isSearchable
                    value=""
                    onChange={ this.selectGroup }
                />
            </React.Fragment>
        );
    }
}

GroupSelector.defaultProps = {
};

GroupSelector.propTypes = {
    groups: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
};

export default GroupSelector;
