import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import Card from 'reactstrap/lib/Card';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardBody from 'reactstrap/lib/CardBody';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { documentOperations } from '../../../redux/ducks/documents';
import { categoryOperations } from '../../../redux/ducks/categories';
import DocumentsList from '../../../components/Document/DocumentsList';
import DocumentSearch from '../../../components/Search/DocumentSearch';
import Display from '../../../components/Display';
import { generateCan } from '../../../utils/Can';
import canUserPerformAction from '../../../utils/acls/canUserPerformAction';
import VaultBreadcrumb from '../../../components/Breadcrumb/VaultBreadcrumb';

function findById( array, id, res ) {
    return array.map( child => {
        if ( child.id === id ) {
            res.push( child );
        } else if ( typeof child.children !== 'undefined' ) {
            findById( child.children, id, res );
        }
    } );
}

class CategoryView extends Component {
    constructor( props ) {
        super( props );
        this.history = props.history;
        this.state = {
            data: [],
            loading: true,
            level: 0,
            currentChild: '',
            position: [],
            search: false
        };
    }

    UNSAFE_componentWillMount() {
        const categoryNavigationDepth = this.props.currentAccount.categoryNavigationDepth || 1;
        if (

            Object.keys( this.props.currentCategory ).isEmpty()
          || ( Object.keys( this.props.categoriesBreadcrumbs ).isEmpty() && this.props.currentCategory.id !== 'unassigned' ) ) {
            this.props.history.replace( '/docvault' );
            return;
        }
        let categoryName = '';
        let documentsForCategory = [];
        if ( Object.keys( this.props.categoryTree ).hasItems() && Object.keys( this.props.currentCategory ).hasItems() && typeof this.props.currentCategory !== 'undefined' ) {
            if ( this.props.currentCategory.id === 'unassigned' ) {
                categoryName = 'Unassigned';
            } else {
                const cat = this.props.currentCategory;
                if ( typeof cat === 'undefined' ) {
                    categoryName = '';
                } else {
                    categoryName = cat.name;
                }
            }
            let catForACL;
            if ( categoryNavigationDepth > 1 ) {
                catForACL = this.props.categoriesBreadcrumbs.firstOrDefault();
            } else {
                catForACL = this.props.currentCategory;
            }
            const { Can, abilities } = generateCan( this.props.currentUser.acl );
            let canSee;
            // disable ACLS for Unassigned
            if ( categoryName === 'Unassigned' ) {
                canSee = true;
            } else {
                canSee = canUserPerformAction( 'read', abilities, this.props.currentAccount.id, catForACL.name );
            }
            if ( canSee && categoryName !== 'Unassigned' ) {
                if ( categoryNavigationDepth > 1 ) {
                    if ( this.props.categoriesBreadcrumbs.last().name !== this.props.currentCategory.name ) {
                    // we have a forward from browser button
                        const lastOne = this.props.categoriesBreadcrumbs.last();
                        if ( lastOne.children.filter( child => child.name === this.props.currentCategory.name ).length === 0 ) {
                            this.props.history.replace( '/docvault' );
                            return <script />;
                        }
                        categoryName = buildLocation( [ ...this.props.categoriesBreadcrumbs, this.props.currentCategory ], this.props.currentCategory.name, true );
                    } else {
                        categoryName = buildLocation( this.props.categoriesBreadcrumbs, this.props.currentCategory.name, true );
                    }
                    documentsForCategory = this.props.documents.filter( x => x.formData.location.indexOf( categoryName ) === 0 && !x.dirty );
                } else {
                    documentsForCategory = this.props.documents.filter( document => document.formData.location.split( '/' )[0] === categoryName && !document.dirty );
                }
            } else {
                documentsForCategory = this.props.documents.filter( document => document.dirty );
            }
            const array = [];
            findById( this.props.categoryTree.treeData, catForACL.id, array );
            if ( categoryName !== 'Unassigned' ) {
                this.setState( {
                    currentChild: catForACL,
                    selectedCategory: array[0],
                    position: [ array[0] ] } );
            } else {
                this.setState( {
                    currentChild: catForACL,
                    selectedCategory: 'Unassigned',
                    position: 0 } );
            }
        }
        this.props.clearDocument();
        this.setState( { categoryName, documentsForCategory } );
    }

    componentDidMount() {
    // need to listen for change on search input.
        const that = this;
        if ( document.getElementById( 'Searchbox-downshift-input' ) ) {
            document.getElementById( 'Searchbox-downshift-input' ).addEventListener( 'input', function ( evt ) {
                if ( this.value.trim().length > 0 ) {
                    if ( typeof document.getElementsByClassName( 'cancel-icon' )[0] !== 'undefined' ) {
                        document.getElementsByClassName( 'cancel-icon' )[0].addEventListener( 'click', ( evt ) => {
                            if ( that.state.search ) {
                                setTimeout( () => { that.setState( { search: false } ); }, 100 );
                            }
                        } );
                    }
                    if ( !that.state.search ) {
                        setTimeout( () => { that.setState( { search: true } ); }, 100 );
                    }
                } else if ( that.state.search ) {
                    setTimeout( () => { that.setState( { search: false } ); }, 100 );
                }
            } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( nextProps.currentCategory !== this.props.currentCategory || nextProps.documents !== this.props.documents && Object.keys( nextProps.currentCategory ).hasItems() && typeof this.props.currentCategory !== 'undefined' ) {
            const categoryNavigationDepth = nextProps.currentAccount.categoryNavigationDepth || 1;
            if ( Object.keys( nextProps.currentCategory ).isEmpty()
              || ( Object.keys( nextProps.categoriesBreadcrumbs ).isEmpty() && nextProps.currentCategory.id !== 'unassigned' )
            ) {
                this.props.history.replace( '/docvault' );
                return <script />;
            }
            let categoryName = '';
            if ( nextProps.currentCategory.name === 'unassigned' ) {
                categoryName = 'Unassigned';
            } else {
                const cat = nextProps.currentCategory;
                if ( typeof cat === 'undefined' ) {
                    categoryName = '';
                } else {
                    categoryName = cat.name;
                }
            }
            let catForACL = {};
            if ( categoryNavigationDepth > 1 ) {
                catForACL = nextProps.categoriesBreadcrumbs.firstOrDefault();
            } else {
                catForACL = nextProps.currentCategory;
            }
            let documentsForCategory = [];
            const { Can, abilities } = generateCan( nextProps.currentUser.acl );
            let canSee;
            // disable ACLS for Unassigned
            if ( categoryName === 'Unassigned' ) {
                canSee = true;
            } else {
                canSee = canUserPerformAction( 'read', abilities, nextProps.currentAccount.id, catForACL.name );
            }

            if ( canSee && categoryName !== 'Unassigned' ) {
                if ( categoryNavigationDepth > 1 ) {
                    if ( nextProps.categoriesBreadcrumbs.last().name !== nextProps.currentCategory.name ) {
                    // we have a forward from browser button
                        const lastOne = nextProps.categoriesBreadcrumbs.last();
                        if ( lastOne.children.filter( child => child.name === nextProps.currentCategory.name ).length === 0 ) {
                            this.props.history.replace( '/docvault' );
                            return <script />;
                        }
                        categoryName = buildLocation( [ ...nextProps.categoriesBreadcrumbs, nextProps.currentCategory ], nextProps.currentCategory.name, true );
                    } else {
                        categoryName = buildLocation( nextProps.categoriesBreadcrumbs, nextProps.currentCategory.name, true );
                    }
                    documentsForCategory = nextProps.documents.filter( x => x.formData.location.indexOf( categoryName ) === 0 && !x.dirty );
                } else {
                    documentsForCategory = nextProps.documents.filter( document => document.formData.location.split( '/' )[0] === categoryName && !document.dirty );
                }
            } else {
                documentsForCategory = this.props.documents.filter( document => document.dirty );
            }
            this.setState( { categoryName, documentsForCategory } );
        }
    }

    componentWillUnmount() {
        if ( document.getElementById( 'Searchbox-downshift-input' ) ) {
            document.getElementById( 'Searchbox-downshift-input' ).removeEventListener( 'input', () => {} );
        }

        if ( typeof document.getElementsByClassName( 'cancel-icon' )[0] !== 'undefined' ) {
            document.getElementsByClassName( 'cancel-icon' )[0].removeEventListener( 'click', () => {} );
        }
    }

  selectCategory = e => {
      const array = [];
      findById( this.props.categoryTree.treeData, e.target.value, array );
      this.setState( {
          currentChild: e.target.value,
          selectedCategory: array[0],
          position: [ ...this.state.position, array[0] ] } );
  };

  render() {
      const { categoryTree } = this.props;
      if ( Object.keys( categoryTree ).isEmpty() || Object.keys( this.props.currentCategory ).isEmpty() ) {
          window.location.href = '/docvault';
          return <script />;
      }
      return (
          <div>
              {/* <Button color="primary" size="lg" block onClick={ this.history.goBack }> &lt; back</Button> */}
              <Row>
                  <DocumentSearch
                      currentAccount={ this.props.currentAccount }
                      isSearch={ this.state.search }
                      history={ this.props.history }
                      auth={ this.props.auth }
                      selectDocument={ this.props.selectDocument } />
              </Row>
              {/* <br /> */}
              <Display when={ !this.state.search }>
                  <React.Fragment>
                      <Row className="flexLeft">
                          <Col>
                              <VaultBreadcrumb
                                  location={ buildLocation( this.props.categoriesBreadcrumbs, this.props.currentCategory.name ) }
                              />
                          </Col>
                      </Row>
                      <Card className="white-box mt-3">
                          <CardBody className="p-0">
                              <DocumentsList
                                  history={ this.props.history }
                                  documentsForCategory={ this.state.documentsForCategory || [] }
                                  position={ this.state.position }
                                  selectDocument={ this.props.selectDocument } />
                          </CardBody>
                      </Card>
                  </React.Fragment>
              </Display>
              <br />
              {/* <Button color="primary" size="lg" block onClick={ this.history.goBack }> &lt; back</Button> */}
          </div>
      );
  }
}

CategoryView.propTypes = {
    currentAccount: PropTypes.object.isRequired,
    categoryTree: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    currentCategory: PropTypes.object.isRequired,
    documents: PropTypes.array.isRequired,
    selectDocument: PropTypes.func.isRequired
};

const mapStateToProps = state => ( {
    categoryTree: state.categories.categoryTree,
    currentAccount: state.currentAccount,
    currentUser: state.currentUser,
    currentCategory: state.categories.currentCategory,
    categoriesBreadcrumbs: state.categoriesBreadcrumbs,
    documents: state.documents.list
} );

const mapDispatchToProps = dispatch => ( {
    addDocument: ( status ) => dispatch( documentOperations.addDocument( status ) ),
    selectDocument: ( data ) => dispatch( documentOperations.selectDocument( data ) ),
    clearDocument: ( data ) => dispatch( documentOperations.clearDocument( data ) ),
    selectCategory: ( data ) => dispatch( categoryOperations.selectCategory( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( CategoryView );


const buildLocation = ( breadcrumbs, catName, simple ) => {
    const array = [];
    breadcrumbs.map( x => { array.push( x.name ); } );
    // array.push( catName );
    if ( simple ) {
        return `${array.join( '/' )}`;
    }
    return `/docvault/${array.join( '/' )}`;
};
