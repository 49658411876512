import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import mime from 'mime';

import { connect } from 'react-redux';
import Display from '../Display';
import messageOperations from '../../redux/ducks/messages/operations';

function formatBytes( bytes, decimals = 2 ) {
    if ( bytes === 0 ) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

    const i = Math.floor( Math.log( bytes ) / Math.log( k ) );
    return {
        value: parseFloat( ( bytes / Math.pow( k, i ) ).toFixed( dm ) ),
        size: sizes[i]
    };
}

const maxSize = 26214400; // 25MB
class UploadComponent extends Component {
    constructor() {
        super();
        this.state = {
            filesMeta: [],
            files: []
        };
        this.onFilesDrop = this.onFilesDrop.bind( this );
        this.deleteFile = this.deleteFile.bind( this );
    }

    componentWillMount() {
        this.setState( { files: this.props.files || [] } );
    }

    onFilesDrop( files ) {
        let invalidType = false;
        let noType = false;
        let maxSizeExceded = false;
        files.map( file => {
            if ( file.size > maxSize - 250 ) {
                maxSizeExceded = true;
                return;
            }
            if ( this.props.permittedType !== '' ) {
                if ( file.name.split( '.' ).last() !== this.props.permittedType ) {
                    invalidType = true;
                    return false;
                }
            }
            if ( !mime.getType( file.name ) ) {
                noType = true;
                return false;
            }
            return { fileName: `${this.props.accountId}^${file.name}`, type: mime.getType( file.name ) };
        } );
        if ( invalidType ) {
            this.props.showNotificationWarning( `Only files with extension ${this.props.permittedType} are allowed` );
            return;
        }
        if ( noType ) {
            this.props.showNotificationWarning( 'File must have an extension' );
            return;
        }
        if ( maxSizeExceded ) {
            this.props.showNotificationWarning( 'Max size for files is 25MB' );
            return;
        }
        this.props.showNotificationWarning( 'Files added. Please save document to begin upload.' );
        this.setState( { files } );
        this.props.callback( files );
    }

    deleteFile( file ) {
        this.props.callback( this.state.files.except( 'name', file.name ) );
        this.setState( { files: this.state.files.except( 'name', file.name ) } );
    }

    render() {
        return (
            <div>
                <Dropzone onDrop={ this.onFilesDrop } className="dropZone-single">
                    <div className="uploadIcon" />
                    <div className="text">click or drop files
                    </div>
                </Dropzone>
                <Display when={ this.state.files.length > 0 }>
                    <div className="p-2">

                        <h4 className="text-left mt-2">Dropped files</h4>
                        <ul className="text-info" style={ { paddingLeft: '15px', marginLeft: 0 } }>
                            {this.state.files.map( f => (
                                <li className="mb-1">
                                    <span>{f.name}</span>
                                    <span
                                        className="icon-trash red ml-2"
                                        style={ { marginRight: '10px', color: 'primary' } }
                                        onClick={ () => ( this.deleteFile( f ) ) }
                                        role="presentation" />
                                </li>
                            ) )}
                        </ul>

                    </div>
                </Display>
            </div>
        );
    }
}

const { string, func } = PropTypes;

UploadComponent.defaultProps = {
    callback: () => {},
    showNotificationWarning: () => {},
    permittedType: ''
};

UploadComponent.propTypes = {
    callback: func,
    showNotificationWarning: func,
    permittedType: string
};

const mapDispatchToProps = dispatch => ( {
    showNotificationWarning: ( message ) => dispatch( messageOperations.showWarning( message ) )
} );

export default connect( null, mapDispatchToProps )( UploadComponent );
