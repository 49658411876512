import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Modal, Button,
    ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import Form from 'react-jsonschema-form';
import Geosuggest from 'react-geosuggest';

import formTemplate from '../../constants/forms/account/accountDetails';
import TimezoneSelector from '../TimezoneSelector/TimezoneSelector';

class Account extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            modal: false,
            isEdit: false,
            isCreate: false,
            isError: false,
            errorMessage: null,
            isDropdownOpen: false,
            formData: {}
        };
    }

    UNSAFE_componentWillMount() {
    // this is needed in case of refresh on route /account
        this.populateFields( this.props );
    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        this.populateFields( nextProps );
    }

    populateFields = props => {
        if ( props.accountInfo && !this.state.isEdit ) {
            this.setState( { formData: props.accountInfo } );
        }
    };

    render() {
        const {
            onSubmit, onDelete, log, uid, accountInfo
        } = this.props;

        const handleToggleEdit = () => ( this.setState( { isEdit: !this.state.isEdit } ) );
        if ( !accountInfo && !this.state.isEdit ) {
            return (
                <div className="col-lg-12 text-center">
                    Look like you are not a account member.
                    Please ask to be added to one.
                    {/*<p style={ { marginTop: 30 } }>*/}
                        {/*<Button type="button" className="btn btn-outline-info" onClick={ handleToggleEdit }>Create account</Button>*/}
                    {/*</p>*/}
                </div>
            );
        }
        const formDataOrig = this.state.formData;
        const { schema } = formTemplate;
        const uiSchema = {
            'ui:order': [
                'accountName',
                'accountTimezone',
                'accountType',
                '*',
                'street',
                'city',
                'postCode',
                'state',
                'country'
            ],
            accountName: {
                // 'ui:help': 'Your account name',
                'ui:readonly': !this.state.isEdit,
            },
          accountTimezone: {
                // 'ui:help': 'Your account name',
                'ui:readonly': !this.state.isEdit,
                'ui:field': "timezonePicker"
            },
            idCard: {
                'ui:readonly': !this.state.isEdit,
            },
            accountType: {
                'ui:readonly': !this.state.isEdit,
            },
            company: {
                'ui:readonly': !this.state.isEdit,
            },
            abnacn: {
                'ui:readonly': !this.state.isEdit,
                'ui:emptyValue': ''
            },
            subpremise: {
                'ui:readonly': !this.state.isEdit,
                'ui:emptyValue': ''
            },
            street: {
                'ui:readonly': !this.state.isEdit,
            },
            city: {
                'ui:readonly': !this.state.isEdit,
            },
            postCode: {
                'ui:readonly': !this.state.isEdit,
            },
            state: {
                'ui:readonly': !this.state.isEdit,
            },
            country: {
                'ui:readonly': !this.state.isEdit,
            },

        };
        const clearFormData = () => this.setState( {
            formData: accountInfo || {
                street: '', postCode: '', city: '', state: '', country: ''
            }
        } );
        const handleCancel = () => { this.setState( { isEdit: false, isCreate: false } ); clearFormData(); };
        const handleToggleCreate = () => ( this.setState( { isCreate: !this.state.isCreate, isEdit: !this.state.isEdit } ) );
        const handleToggleDropdown = () => ( this.setState( { isDropdownOpen: !this.state.isDropdownOpen } ) );
        const handleModalToggle = () => { this.setState( { modal: !this.state.modal } ); };
        const handleDelete = () => {
            if ( this.props.isOwner ) {
                onDelete( formDataOrig );
                clearFormData();
                handleModalToggle();
                handleCancel();
            }
        };
        const transformErrors = ( errors ) => errors.map( error => {
            if ( error.name === 'pattern' ) {
                error.message = 'Only digits are allowed';
            }
            return error;
        } );

        const mapSuggestionsToFormData = ( addressComponent, newFormData ) => {
            switch ( true ) {
                case addressComponent.types.includes( 'floor' ) || addressComponent.types.includes( 'subpremise' ):
                    Object.assign( newFormData, { subpremise: '' } ); // clear street
                    Object.assign( newFormData, { subpremise: addressComponent.long_name } );
                    break;
                case addressComponent.types.includes( 'street_number' ):
                    Object.assign( newFormData, { street: '' } ); // clear street
                    Object.assign( newFormData, { street: addressComponent.long_name } );
                    break;
                case addressComponent.types.includes( 'route' ):
                    Object.assign( newFormData, { street: `${newFormData.street} ${addressComponent.long_name}` } );
                    break;
                case addressComponent.types.includes( 'locality' ):
                    Object.assign( newFormData, { city: '' } ); // clear street
                    Object.assign( newFormData, { city: addressComponent.long_name } );
                    break;
                case addressComponent.types.includes( 'postal_code' ):
                    Object.assign( newFormData, { postCode: '' } ); // clear street
                    Object.assign( newFormData, { postCode: addressComponent.short_name } );
                    break;
                case addressComponent.types.includes( 'administrative_area_level_1' ):
                    Object.assign( newFormData, { state: '' } ); // clear street
                    Object.assign( newFormData, { state: addressComponent.long_name } );
                    break;
                case addressComponent.types.includes( 'country' ):
                    Object.assign( newFormData, { country: '' } ); // clear street
                    Object.assign( newFormData, { country: addressComponent.long_name } );
                    break;
                default:
                    // my code goes here
                    break;
            }
        };

        const onSuggestSelect = ( suggest ) => {
            if ( suggest === undefined ) return;
            const newFormData = { ...this.state.formData };
            suggest.gmaps.address_components.forEach( ( elem ) => mapSuggestionsToFormData( elem, newFormData ) );
            this.setState( { formData: newFormData } );
            this._geoSuggest.clear();
        };

        const EditMenu = ( () => (

            <Dropdown
                className="float-right"
                isOpen={ this.state.isDropdownOpen }
                toggle={ handleToggleDropdown }
                hidden={ this.state.isEdit }>
                <DropdownToggle caret>
                    <i className="fa fa-edit"></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={ handleToggleCreate } hidden={ formDataOrig.id }>
                        <i className="fa fa-plus"></i>
            create
                    </DropdownItem>
                    <DropdownItem onClick={ handleToggleEdit } hidden={ !formDataOrig.id }>
                        <i className="fa fa-edit"></i>
            edit
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )

        );

        if ( this.state.formData.country === 'United States' ) {
            const domEntity = document.querySelectorAll( '[for="root_abnacn"]' )[0];
            if ( typeof domEntity !== 'undefined' ) {
                domEntity.innerText = 'EIN';
            }
        } else {
            const domEntity = document.querySelectorAll( '[for="root_abnacn"]' )[0];
            if ( typeof domEntity !== 'undefined' ) {
                domEntity.innerText = 'ABN / ACN';
            }
        }
        const fields = {timezonePicker: TimezoneSelector};
        return (
            <div className="card white-box">
                {/* <ConfirmDialog message="You have a notification pending" /> */}
                <div className="card-header pt-0">
                    <i className="fa fa-align-justify" />
                    {' '}
Account Information

                    { ( !this.state.isEdit && !this.props.isReadOnly ) || ( !this.state.isEdit && Object.keys( accountInfo ).isEmpty() )
                        ? <EditMenu />
                        : <script />
                    }
                </div>
                <div className="card-block">

                    {this.state.isError
                        ? (
                            <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        )
                        : <div />
                    }
                    {this.state.isEdit
                        ? (
                            <div>
                                <Geosuggest
                                // radius={ 10000 }
                                // country="au"
                                // location={ new root.google.maps.LatLng( -27.4674011, 153.2093 ) }
                                    onSuggestSelect={ onSuggestSelect }
                                    ref={ el => this._geoSuggest = el }
                                    placeholder="search an address or name of company"
                                />
                            </div>
                        )
                        : <div />
                    }

                    <Form
                        transformErrors={ transformErrors }
                        formData={ formDataOrig }
                        schema={ schema }
                        uiSchema={ uiSchema }
                        fields={ fields }
                        liveValidate
                        showErrorList={ false }
                        onChange={ formData => { this.setState( { formData: formData.formData } ); } }
                        onSubmit={ formData => { onSubmit( formData, uid ); handleCancel(); } }
                        onError={ log( 'errors' ) }>
                        <div className="form-actions">
                            <button
                                hidden={ !this.state.isEdit }
                                type="submit"
                                className="btn btn-primary float-right">
save
                            </button>
                            <button
                                hidden={ !this.state.isEdit }
                                type="button"
                                className="btn btn-outline-info"
                                onClick={ handleCancel }>
cancel
                            </button>
                            <button
                                style={ { marginLeft: '10px' } }
                                hidden={ !this.state.isEdit || !this.state.formData.id }
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={ handleModalToggle }>
delete
                            </button>
                        </div>
                    </Form>
                    <Modal isOpen={ this.state.modal } toggle={ handleModalToggle }>
                        <ModalHeader toggle={ handleModalToggle }>Delete Account</ModalHeader>
                        <ModalBody>
              Are you sure you want to delete account:
                            {' '}
                            <b>{this.state.formData.accountName}</b>
?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={ handleDelete }>Delete</Button>
                            {' '}
                            <Button color="secondary" onClick={ handleModalToggle }>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                </div>
            </div>

        );
    }
}

Account.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    log: PropTypes.func.isRequired,
    accountInfo: PropTypes.object.isRequired,
    uid: PropTypes.string.isRequired
};

export default Account;
