import React from 'react';

import Select from 'react-select';
import { connect } from 'react-redux';

class SimpleSelector extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { ...props.formData, currentLocationValid: true, value: props.value };
        this.onChange = this.onChange.bind( this );
    }

    onChange( data ) {
        this.setState( {
            location: data.value,
            value: data
        } );
        this.props.callback(data.value);
    }

    render() {
        return (
            <Select
                options={ this.props.data }
                value={ this.state.value }
                className="customReactSelect"
                onChange={ this.onChange }
            />
        );
    }
}

const mapStateToProps = state => ( {
    breadcrumbs: state.categories.breadcrumbs
} );

const mapDispatchToProps = dispatch => ( {
} );
export default connect( mapStateToProps, mapDispatchToProps )( SimpleSelector );
