// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const UPDATE_INVITE = 'UPDATE_INVITE';
const UPDATE_INVITE_COMPLETED = 'UPDATE_INVITE_COMPLETED';
const UPDATE_INVITE_FAILED = 'UPDATE_INVITE_FAILED';

const DELETE_INVITE = 'DELETE_INVITE';
const DELETE_INVITE_COMPLETED = 'DELETE_INVITE_COMPLETED';
const DELETE_INVITE_FAILED = 'DELETE_INVITE_FAILED';

const REISSUE_INVITE = 'REISSUE_INVITE';
const REISSUE_INVITE_COMPLETED = 'REISSUE_INVITE_COMPLETED';
const REISSUE_INVITE_FAILED = 'REISSUE_INVITE_FAILED';

export default {
    REISSUE_INVITE,
    REISSUE_INVITE_COMPLETED,
    REISSUE_INVITE_FAILED,
    UPDATE_INVITE,
    UPDATE_INVITE_COMPLETED,
    UPDATE_INVITE_FAILED,
    DELETE_INVITE,
    DELETE_INVITE_COMPLETED,
    DELETE_INVITE_FAILED
};
