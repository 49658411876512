import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import CreatableSelect from 'react-select/lib/Creatable';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import messageOperations from '../../redux/ducks/messages/operations';
import { documentOperations } from '../../redux/ducks/documents';
import TableView from '../Table/Table';
import Display from '../Display';
import PartiesModal from '../../views/Parties/PartiesModal';
import { partiesOperations } from '../../redux/ducks/parties';
import TooltipWrapper from '../Tooltip/Tooltip';
import { sortArrayByProperty } from '../../utils/sortArrayByProperty';

const columns = [
    { name: 'name', title: 'Name' },
    { name: 'knownAs', title: 'known as' },
    { name: 'actions', title: 'Actions' }
];

class PartyAssignModal extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            open: false,
            preselectedValue: false,
            list: [],
            selected: null
        };
    }

    UNSAFE_componentWillMount() {
        this.setState( { list: this.props.list } );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.state.preselectedValue ) {
            const preselectedParty = nextProps.parties.having( 'partyName', this.state.preselectedValue )[0];
            if ( typeof preselectedParty !== 'undefined' ) {
                let company = '';
                if ( typeof preselectedParty.company !== 'undefined' ) {
                    company = ` (${preselectedParty.company})`;
                }
                const selectedValue = { value: preselectedParty.id, label: `${preselectedParty.partyName}${company} ` };
                this.setState( { selected: selectedValue } );
            }
        }
        this.setState( { list: nextProps.list } );
    }

    toggleModal = () => {
        this.setState( { open: !this.state.open } );
    };

    saveSelections = () => {
        this.setState( { open: !this.state.open } );
    };

    addToList = () => {
        const as = document.getElementById( 'as' ).value;
        if ( as.length === 0 || Object.keys( this.state.selected ).isEmpty() ) {
            return;
        }
        this.setState( { list: [ ...this.state.list, { ...this.state.selected, as } ], selected: {} } );
        document.getElementById( 'as' ).value = '';
        setTimeout( () => { this.props.save( this.state.list ); }, 200 );
    };

    updateValue = data => {
        this.setState( { selected: data } );
    };

    remove = id => {
        this.setState( { list: this.state.list.except( 'value', id ) } );
        setTimeout( () => { this.props.save( this.state.list ); }, 200 );
    };

    buildRow = data => {
        return data.map( party => (
            {
                id: party.id,
                name: <div>{ party.label }</div>,
                knownAs: <div>{party.as}</div>,
                actions: <div onClick={ () => { this.remove( party.value ); } }>remove</div>
            }
        ) );
    };

    handleCreate = inputValue => {
        this.props.selectParty( { partyName: inputValue } );
        this.setState( { preselectedValue: inputValue } );
        document.getElementById( 'create_new_party' ).click();
    };

    shouldDisplayCreateNewParty( inputValue, selectValue, selectOptions ) {
        // determine if parties exist that contain the typed char
        // return selectOptions.filter( elem => elem.label.indexOf( inputValue ) > -1 ).length > 0;
        return true;
    }

    render() {
        let iterableParties = this.props.parties;
        if ( this.state.list.hasItems() ) {
            this.state.list.map( entry => { iterableParties = iterableParties.except( 'id', entry.value ); } );
        }
        const options = iterableParties.sort( (a, b)=>sortArrayByProperty(a,b, 'partyName') ).map( party => {
            // ( { value: party.id, label: `${party.partyName} (${party.company}) ` } ) );
            let company = '';
            if ( typeof party.company !== 'undefined' ) {
                company = ` (${party.company})`;
            }
            return ( { value: party.id, label: `${party.partyName}${company} ` } );
        } );
        const list = this.buildRow( this.state.list );
        const buttonText = this.props.list.hasItems() ? 'Edit Parties' : 'Add Parties';
        return (
            <div>
                <TooltipWrapper refId="partyModalBtnTrigger" content={ buttonText } />
                <div
                    id="partyModalBtnTrigger"
                    className="btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5 float-right"
                    style={ { marginRight: '5px' } }
                    onClick={ () => { this.setState( { open: true } ); } }>
                    <div className="fa fa-user" style={ { paddingTop: '6px' } } />
                </div>
                <Modal isOpen={ this.state.open } toggle={ this.toggleModal } className="partiesAssignModal">
                    <ModalHeader toggle={ this.toggleModal }>Assign Parties</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-lg-5">
                                <CreatableSelect
                                    isClearable
                                    onCreateOption={ this.handleCreate }
                                    isValidNewOption={ this.shouldDisplayCreateNewParty }
                                    options={ options }
                                    value={ this.state.selected }
                                    onChange={ this.updateValue }
                                    id="partiesDropdown"
                                />
                            </div>
                            <div className="text-center" style={ { top: 6, position: 'relative' } }>define as</div>
                            <div className="col-lg-4">
                                <input id="as" className="form-control" style={ { width: '100%' } } />
                            </div>
                            <div className="col-lg-2 text-center pl-0">
                                <Button id="assign" className="btn btn-outline" onClick={ this.addToList }>Assign</Button>
                            </div>
                        </div>
                        <Display when={ list.hasItems() }>
                            <div className="mt-3 customTable clickableRow">
                                <TableView
                                    rowsDOM={ list }
                                    columns={ columns }
                                />
                            </div>
                        </Display>

                    </ModalBody>
                    <ModalFooter>
                        <PartiesModal hideBtn />
                        <Button id="close" color="secondary" onClick={ this.saveSelections }>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const { array, object, string, func } = PropTypes;

PartyAssignModal.defaultProps = {

};

PartyAssignModal.propTypes = {
    list: array.isRequired
};

const mapStateToProps = state => ( {
    parties: state.parties.list
} );

const mapDispatchToProps = dispatch => ( {
    addDocument: ( status ) => dispatch( documentOperations.addDocument( status ) ),
    showNotificationSuccess: ( message ) => dispatch( messageOperations.showSuccess( message ) ),
    showNotificationError: ( message ) => dispatch( messageOperations.showError( message ) ),
    selectDocument: ( data ) => dispatch( documentOperations.selectDocument( data ) ),
    createCalendarEvent: ( data ) => dispatch( calendarEventsOperations.createCalendarEvent( data ) ),
    clearSelectedEvent: ( ) => dispatch( calendarEventsOperations.clearSelectedEvent( ) ),
    selectParty: ( data ) => dispatch( partiesOperations.selectParty( data ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( PartyAssignModal );
