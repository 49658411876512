import types from './types';

const deleteResource = ( data, extraData ) => {
    const body = data;
    return {
        type: 'DELETE_RESOURCE',
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/resources',
            method: 'DELETE',
            body
        }
    };
};
const updateResource = ( data, extraData ) => {
    const body = data;
    return {
        type: 'UPDATE_RESOURCE',
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/resources',
            method: 'PUT',
            body
        }
    };
};

export default {
    deleteResource,
    updateResource
};
