// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const ADD_DOCUMENT = 'ADD_DOCUMENT';
const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
const ADD_DOCUMENT_COMPLETED = 'ADD_DOCUMENT_COMPLETED';
const ADD_DOCUMENT_FAILED = 'ADD_DOCUMENT_FAILED';

const SELECT_DOCUMENT = 'SELECT_DOCUMENT';

const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';

const CLEAR_TEMP_DOC = 'CLEAR_TEMP_DOC';

const ADD_TEMP_DOC_DATA = 'ADD_TEMP_DOC_DATA';

export default {
    ADD_DOCUMENT,
    DELETE_DOCUMENT,
    ADD_DOCUMENT_COMPLETED,
    ADD_DOCUMENT_FAILED,
    SELECT_DOCUMENT,
    CLEAR_DOCUMENT,
    CLEAR_TEMP_DOC,
    ADD_TEMP_DOC_DATA
};
