const checkResourceAcl = require( './checkResourceAcl' );

function buildResourceId( accountId, categoryName = null ) {
    // we need to build multiple resources.
    // - for account lvl
    // - for all category lvl
    // - for specific category lvl
    let category = categoryName;
    if ( categoryName !== null ) {
        if ( categoryName.indexOf( '/' ) > -1 ) {
            category = categoryName.split( '/' )[0];
        }
    }

    const accountLvl = `rn::account:${accountId}`;
    const allCat = `rn::account:${accountId}::category:all`;
    const catLvl = `rn::account:${accountId}::category:${category.toLocaleLowerCase()}`;
    if ( categoryName !== null ) {
        return { accountLvl, allCat, catLvl };
    }
    return { accountLvl, allCat, catLvl: null };
}

/**
 * determines if the user can perform the desired action
 * starts from deepest level upward.
 * check if resource defined at cat level
 * if resource is defined, take for granted the true/false returned.
 * if resource not defined, go up a lvl to allCat, and then to account.
 * @param action
 * @param abilities
 * @param {string} accountId
 * @param {?string} categoryName
 * @return {*|boolean}
 */
function canUserPerformAction( action, abilities, accountId, categoryName ) {
    const { accountLvl, allCat, catLvl } = buildResourceId( accountId, categoryName );

    if ( catLvl !== null ) {
        if ( checkResourceAcl( abilities, `${catLvl}::*` ) ) {
            return abilities.can( action, `${catLvl}::*` );
        }
    }

    if ( checkResourceAcl( abilities, `${allCat}::*` ) ) {
        return abilities.can( action, `${allCat}::*` );
    }

    if ( checkResourceAcl( abilities, `${accountLvl}::*` ) ) {
        return abilities.can( action, `${accountLvl}::*` );
    }
    return false;
}

module.exports = canUserPerformAction;
