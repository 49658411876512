import React, { Component } from 'react';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import 'react-sortable-tree/style.css';
import { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getFlatDataFromTree, getTreeFromFlatData } from 'react-sortable-tree';
import uuidv1 from 'uuid/v1';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import Dropzone from 'react-dropzone';
import { Prompt } from 'react-router-dom';
import YourExternalNodeComponent from './CategoryBuilder';
import DocumentTypes from '../Settings/DocumentsTypes';
import DefaultOptions from '../Options/DefaultOptions';
import Display from '../Display';
import { fullStringClean } from '../../utils/cleanString';
import OptionsList from '../Options/OptionsList';

function buildDocTypes( doc ) {
    return (
        <YourExternalNodeComponent
            key={ Math.random( ) }
            node={ {
                name: doc.name,
                type: 'docType'
            } }
        />
    );
}

class Tree extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            // treeData: [ { title: 'Chicken', children: [ { title: 'Egg' } ] } ],
            id: null,
            file: null,
            saving: false,
            changesMade: false,
            isDocTypesModalOpen: false,
            openFoldersList: false,
            isDefaultOptionsModalOpen: false,
            changes: {},
            treeData: [
                { name: 'Category 1', type: 'category', id: uuidv1() },
                { name: 'Category 2', type: 'category', id: uuidv1() }
            ],
            defaultTreeData: [],
            confirmation: ''
        };
        this.originalTreeData = null;
        this.originalTreeData2 = null;
        this.changedTreeData = null;
        this.canDrop = this.canDrop.bind( this );
        this.handleInputChange = this.handleInputChange.bind( this );
        this.save = this.save.bind( this );
        this.reset = this.reset.bind( this );
        this.default = this.default.bind( this );
        this.confirmedReset = this.confirmedReset.bind( this );
        this.toggleDocTypesModal = this.toggleDocTypesModal.bind( this );
        this.toggleDefaultOptionsModal = this.toggleDefaultOptionsModal.bind( this );
        this.download = this.download.bind( this );
        this.onFilesDrop = this.onFilesDrop.bind( this );
        this.saveCatName = this.saveCatName.bind( this );
        this.toggleOptionBuilderModal = this.toggleOptionBuilderModal.bind( this );
    }

    UNSAFE_componentWillMount() {
        if ( Object.keys( this.props.category.treeData ).hasItems() ) {
            const newState = Object.assign( {}, this.state, this.props.category );
            this.setState( newState );
        }
    }

    componentDidMount() {
    // setTimeout( () => { this.handleScroll(); }, 1000 );
    // window.addEventListener( 'scroll', this.handleScroll.bind( this ) );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        const newState = Object.assign( {}, this.state, nextProps.category );
        this.setState( newState );
    }

    componentWillUnmount() {
        window.removeEventListener( 'scroll', handleScroll );
    }

    canDrop( { node, nextParent, nextPath } ) {
    // return ( !nextParent || !nextParent.noChildren ) && nextPath.length > 1;
    // if ( !nextParent ) {
    //     return false;
    // }
    // return nextParent.noChildren;
        const treeDepth = this.props.currentAccount.categoryNavigationDepth || 3;
        // limit nesting to this.props.categoryTreeDepth lvls.
        if ( nextPath.length > treeDepth && node.type !== 'docType' ) {
            return false;
        }

        // don't allow document type on first level
        if ( !nextParent && node.type === 'docType' ) {
            return false;
        }

        // don't allow document type as parent
        if ( nextParent && nextParent.type === 'docType' ) {
            return false;
        }

        // don't allow category as sibling of document type
        if ( node.type !== 'docType' && nextParent && nextParent.children && nextParent.children.having( 'type', 'docType' ).length === 1 ) {
            return false;
        }

        // don't allow document type as sibling of category
        if ( node.type === 'docType' && nextParent && nextParent.children && nextParent.children.having( 'type', 'category' ).length > 0 ) {
            return false;
        }

        // don't allow multiple document types on same node
        // if ( nextParent && nextParent.children && nextParent.children.having( 'type', 'docType' ).length === 2 ) {
        //     return false;
        // }

        // don't allow same type document types on same node
        if ( node.type === 'docType' && nextParent && nextParent.children && nextParent.children.having( 'name', node.name ).length > 1 ) {
            return false;
        }

        return true;

    }

    getNodeKey( { treeIndex } ) {
        return treeIndex;
    }

    handleInputChange( event ) {
        if ( event.target.name === 'existingValue' ) {
            this.setState( { name: '' } );
        }
        if ( event.target.name === 'name' ) {
            this.setState( { existingValue: 'default' } );
        }
        const { target } = event;
        const value = target.type === 'level' ? target.checked : target.value;
        const { name } = target;
        this.setState( {
            [name]: value,
            reservedWords: false
        } );
    }

    save( defaultTree = null ) {
        if ( this.state.saving ) {
            return;
        }
        if ( this.state.hasReservedWords ) {
            this.setState( { saving: false, reservedWords: 'You cannot use reserved names "All" or special characters. Please rename your categories !' } );
            return;
        }
        this.setState( { saving: true } );
        let changes = [];
        let hasReservedWords = false;
        try {
            this.changedTreeData = this.changedTreeData.map( entry => {
                sanitaze( entry );
                if ( entry.node.name.toLowerCase().trim() === 'all' || entry.node.name.trim() === '*' || entry.node.name.indexOf( '/' ) > -1 ) {
                    hasReservedWords = true;
                }
                return entry;
            } );

            Object.keys( this.state.changes ).map( key => {
                const entry = this.state.changes[key];
                if ( entry.value === 'all' || entry.value === '*' ) {
                    hasReservedWords = true;
                }
            } );
            if ( hasReservedWords ) {
                this.setState( { saving: false, reservedWords: 'You cannot use reserved names "All" or special characters. Please rename your categories !' } );
                return;
            }
            changes = composePathForChangedItems( this.originalTreeData, this.changedTreeData, this.state.changes );
        } catch ( err ) {
            this.setState( { saving: false } );
            throw err;
        }
        if ( this.state.confirmationResetModal ) {
            changes = this.props.breadcrumbs.map( x => ( { newBreadcrumb: x.breadcrumb } ) );
        }
        let { treeData } = this.state;
        if ( defaultTree ) {
            treeData = defaultTree;
        }
        treeData = treeData.filter( x => x.name.toLowerCase().trim() !== 'all' && x.name.trim() !== '*' ).map( entry => { sanitaze( entry ); return entry; } );

        const payload = {
            accountId: this.props.currentAccount.id,
            treeData,
            docType: this.state.docType,
            categoryId: this.state.id,
            changes
        };
        this.props.addCategory( payload ).then( () => {
            this.setState( { saving: false, changesMade: false, confirmationResetModal: false, confirmation: '', changes: {}, reservedWords: null } );
            this.originalTreeData = null;
            this.changedTreeData = null;
        } )
            .catch( ( err ) => {
                this.setState( { saving: false, confirmationResetModal: false, confirmation: '' } );
            } );
    }

    reset() {
        this.setState( { confirmationResetModal: true } );
    }

    async confirmedReset() {
        if ( this.state.resetInProgress ) {
            return;
        }
        this.setState( { resetInProgress: true } );
        if ( this.state.file ) {
            const name = `${this.props.currentAccount.id}/defaultCategory/${new Date().getTime()}.json`;
            this.props.uploadCategory(
                {
                    catData: {
                        filename: name,
                        accountId: this.props.currentAccount.id,
                        uploadType: 'category',
                        file: this.state.file
                    },
                    currentAccount: this.props.currentAccount
                }
            )
                .then( () => {
                    this.setState( { resetInProgress: false, file: null, confirmationResetModal: false } );
                } )
                .catch( () => {
                    this.setState( { resetInProgress: false, file: null, confirmationResetModal: false } );
                } );
        }
        await this.props.resetDefaultCategoryData( { accountId: this.props.currentAccount.id } )
            .then( () => {
                window.location.href = '/dashboard';
            } )
            .catch( () => {
                this.setState( { resetInProgress: false, file: null, confirmationResetModal: false } );
            } );
    }

    addNode( state, path, node, treeIndex ) {
        return (
            addNodeUnderParent( {
                treeData: state.treeData,
                parentKey: path[path.length - 1],
                expandParent: true,
                addAsFirstChild: true,
                getNodeKey: this.getNodeKey,
                newNode: {
                    name: 'New category',
                    type: 'category',
                    id: uuidv1()
                },
            } ).treeData
        );
    }

    saveCatName( node, path, getNodeKey, name ) {
        let hasReservedWords = false;
        if ( name.trim().replace( /\s\s+/g, ' ' ).length !== fullStringClean( name ).length ) {
            hasReservedWords = true;
        }
        this.setState( state => ( {
            changes: { ...state.changes, [node.id]: { type: 'update', value: name } },
            hasReservedWords,
            reservedWords: false,
            changesMade: true,
            treeData: changeNodeAtPath( {
                treeData: state.treeData,
                path,
                getNodeKey,
                newNode: { ...node, name },
            } ),
        } ) );
    }

    toggleDocTypesModal() {
        this.setState( { isDocTypesModalOpen: !this.state.isDocTypesModalOpen } );
    }

    toggleDefaultOptionsModal() {
        this.setState( { isDefaultOptionsModalOpen: !this.state.isDefaultOptionsModalOpen } );
    }

    download( e ) {
        e.preventDefault();
        const payload = {
            treeData: this.state.treeData,
            options: this.props.options,
            docTypes: this.props.documentTypes,
            groups: this.props.groupsArray,
            resources: this.props.resourcesArray
        };
        downloadTextFile( JSON.stringify( payload ), `${this.props.currentAccount.id}/defaultCategory/${new Date().getTime()}.json` );
    }

    default( e ) {
        e.preventDefault();
        const name = `${this.props.currentAccount.id}/defaultCategory/${new Date().getTime()}.json`;
        const payload = {
            treeData: this.state.treeData,
            options: this.props.options,
            docTypes: this.props.documentTypes,
            groups: this.props.groupsArray,
            resources: this.props.resourcesArray
        };
        const file = new Blob( [ JSON.stringify( payload ) ], { type: 'application/json', name } );
        this.save();
        const currentAccount = Object.assign( {}, this.props.currentAccount );
        delete currentAccount.usersPermissions;
        this.props.uploadCategory(
            {
                catData: {
                    filename: name,
                    accountId: this.props.currentAccount.id,
                    uploadType: 'category',
                    file
                },
                currentAccount
            }
        );
    }

    onFilesDrop( selectorFiles ) {
        const file = selectorFiles[0];

        if ( file.type !== 'application/json' ) {
            this.setState( { error: 'File should be a JSON' } );
            setTimeout( () => {
                this.setState( { error: false } );
            }, 6000 );
            return;
        }
        this.setState( { file, confirmationResetModal: true } );
    }

    toggleOptionBuilderModal() {
        this.setState( prevState => ( { openFoldersList: !prevState.openFoldersList } ) );
    }

    render() {
        const { currentAccount } = this.props;
        const treeDepth = currentAccount.categoryNavigationDepth || 3;
        let treeHeight = '100';
        const tree = document.getElementsByClassName( 'ReactVirtualized__Grid__innerScrollContainer' )[0];
        if ( tree ) {
            treeHeight = 100 + Number( tree.style.height.split( 'px' )[0] );
        }

        const externalNodeType = 'yourNodeType';
        const getNodeKey = ( { treeIndex } ) => treeIndex;
        const getRandomName = () => ( 'New category' );

        // compose original tree data
        if ( !this.originalTreeData ) {
            this.originalTreeData = getFlatDataFromTree( {
                treeData: this.state.treeData,
                getNodeKey,
                ignoreCollapsed: false
            } );
        }

        // compose changed tree data
        this.changedTreeData = getFlatDataFromTree( {
            treeData: this.state.treeData,
            getNodeKey,
            ignoreCollapsed: false
        } );
        const docTypes = this.props.documentTypes.filter( doc => typeof doc.name !== 'undefined' ).sort( compareNames ).map( doc => buildDocTypes( doc ) );
        let dropzoneRef;

        const generateProps = ( { node, path, treeIndex } ) => {
            const className = node.type;
            const settings = {
                className,
                title: (
                    <input
                        style={ { fontSize: '1.1rem' } }
                        value={ node.name }
                        onChange={ event => {
                            if ( node.type === 'option' ) { return; }
                            const name = event.target.value;
                            this.saveCatName( node, path, getNodeKey, name );
                        } }

                    />
                ),
                buttons: [
                    <span
                        className="categoryRemove"
                        onClick={ () => this.setState( state => ( {
                            changes: { ...state.changes, [node.id]: { type: 'delete' } },
                            changesMade: true,
                            treeData: removeNodeAtPath( {
                                treeData: state.treeData,
                                path,
                                getNodeKey,
                            } ),
                        } ) )
                        } />,
                ],
            };
            if ( path.length < treeDepth ) {
                settings.buttons.unshift(
                    <span
                        className="categoryAdd"
                        onClick={ () => {
                            this.setState( state => ( {
                                changesMade: true,
                                treeData: this.addNode( state, path, node, treeIndex ),
                            } ) );
                        }
                        } />
                );
            }

            if ( path.length + 1 === this.props.categoryTreeDepth || node.type === 'docType' || ( node.children && node.children.having( 'type', 'docType' ).hasItems() ) ) {
                delete settings.buttons;
                settings.buttons = [
                    <span
                        className="categoryRemove"
                        onClick={ () => this.setState( state => ( {
                            changes: { ...state.changes, [node.id]: { type: 'delete' } },
                            changesMade: true,
                            treeData: removeNodeAtPath( {
                                treeData: state.treeData,
                                path,
                                getNodeKey,
                            } ),
                        } ) )
                        } />
                ];
            }
            return settings;
        };
        return (
            <div className="row">
                <Prompt
                    when={ this.state.changesMade }
                    message={ location => 'You have unsaved changes. Please save the changes before navigating away. Click Cancel to return to Configuration page or Ok to continue.' }
                />
                <Modal isOpen={ this.state.confirmationResetModal } toggle={ () => { this.setState( { confirmationResetModal: false } ); } } className="">
                    <ModalHeader toggle={ () => { this.setState( { confirmationResetModal: false } ); } }>Are you absolutely sure?</ModalHeader>
                    <ModalBody>
                        <p>
              This action
                            {' '}
                            <strong>cannot</strong>
                            {' '}
              be undone. It will
                            {' '}
                            {' '}
                            <strong>permanently</strong>
                            {' '}
              replace existing category structure.
                        </p>
                        <p>
              Type
                            {' '}
                            {' '}
                            <strong>YES</strong>
                            {' '}
              to confirm:
                        </p>
                        <input
                            style={ { width: '100%' } }
                            name="confirmation"
                            type="text"
                            onChange={ this.handleInputChange } />
                    </ModalBody>
                    <ModalFooter>
                        {!this.state.resetInProgress
            && (
                <Button
                    style={ { width: '100%' } }
                    color="warning"
                    onClick={ () => { this.confirmedReset(); } }
                    disabled={ this.state.confirmation !== 'YES' }>
                    <span>I understand the consequences, reset category tree</span>
                </Button>
            )
                        }
                        { this.state.resetInProgress
            && (
                <Button
                    style={ { width: '100%' } }
                    color="warning"
                    onClick={ () => { } }
                    disabled={ this.state.confirmation !== 'YES' }>
                    <span> ... restoring</span>
                </Button>
            )
                        }
                    </ModalFooter>
                </Modal>
                <div className="col-lg-8 p-0">
                    <button
                        id="addCategory"
                        className="btn btn-info pull-left"
                        style={ { marginBottom: 30 } }
                        onClick={ () => this.setState( state => ( {
                            changesMade: true,
                            treeData: [ {
                                name: getRandomName(),
                                type: 'category',
                                id: uuidv1()
                            }, ...state.treeData ],
                        } ) )
                        }
                    >
            Add new category
                    </button>
                    <button
                        id="saveCategory"
                        className="btn btn-primary pull-left ml-2"
                        onClick={ () => this.save() }
                    >
                        { this.state.saving
            && <span>...saving</span>
                        }
                        { !this.state.saving
            && <span>Save</span>
                        }

                    </button>
                    <button
                        id="resetCategory"
                        className="btn btn-outline-danger pull-left ml-2"
                        disabled={ this.state.saving }
                        onClick={ this.reset }
                    >
            Reset
                    </button>
                    <button
                        id="resetCategory"
                        className="btn btn-outline-info pull-left ml-2"
                        disabled={ this.state.saving }
                        onClick={ this.download }
                    >
            Download
                    </button>
                    <button
                        id="resetCategory"
                        className="btn btn-outline-info pull-left ml-2"
                        disabled={ this.state.saving }
                        onClick={ this.default }
                    >
            Set as default
                    </button>
                    <Dropzone
                        multiple={ false }
                        ref={ ( node ) => { dropzoneRef = node; } }
                        onDrop={ this.onFilesDrop }
                        className="dropZone"
                        style={ { display: 'none' } } />
                    <button className="btn btn-outline-info  ml-2" type="button" onClick={ () => { dropzoneRef.open(); } }>
                        <Display when={ this.state.error }>
                            <span className="m-0 text-danger">{this.state.error}</span>
                        </Display>
                        <Display when={ !this.state.error }>
              Upload new structure
                        </Display>
                    </button>
                </div>
                <div className="col-lg-4 p-0">
                    {/* <span style={ { fontSize: 16, paddingLeft: 5 } }>Document types:</span> */}
                    <Button
                        style={ { maxWidth: 200, display: 'inline-block', float: 'right', marginTop: 5, marginLeft: 5 } }
                        className="btn-primary btn-block"
                        id="docTypesManagerBtn"
                        onClick={ this.toggleOptionBuilderModal }>
            Manage form fields
                    </Button>
                    <Button
                        style={ { maxWidth: 200, display: 'inline-block', float: 'right', marginTop: 5 } }
                        className="btn-primary btn-block"
                        id="docTypesManagerBtn"
                        onClick={ this.toggleDocTypesModal }>
            Add / edit document type
                    </Button>
                    { this.state.isDocTypesModalOpen
          && <DocumentTypes toggle={ this.toggleDocTypesModal } toggleDefaultOptions={ this.toggleDefaultOptionsModal } open={ this.state.isDocTypesModalOpen } />
                    }
                    { this.state.openFoldersList
          && <OptionsList toggle={ this.toggleOptionBuilderModal } toggleDefaultOptions={ this.toggleDefaultOptionsModal } open={ this.state.openFoldersList } />
                    }
                    { this.state.isDefaultOptionsModalOpen
          && <DefaultOptions toggle={ this.toggleDefaultOptionsModal } open={ this.state.isDefaultOptionsModalOpen } />
                    }
                </div>
                <div className="col-lg-8 p-0">
                    <Display when={ this.state.reservedWords }>
                        <p className="red">{this.state.reservedWords}</p>
                    </Display>
                    <SortableTree
                        treeData={ this.state.treeData }
                        onChange={ treeData => this.setState( { treeData, changesMade: true } ) }
                        dndType={ externalNodeType }
                        // isVirtualized={false}
                        maxDepth={ 10 }
                        canDrop={ this.canDrop }
                        generateNodeProps={ generateProps }
                    />
                </div>
                <div className="col-lg-4 p-0 customHeight">
                    <div className="pull-right" id="categoryHandlers">
                        { docTypes }
                    </div>
                </div>
            </div>

        );
    }
}

const tree = DragDropContext( HTML5Backend )( Tree );
export default tree;

function compareNames( a, b ) {
    if ( a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ) { return -1; }
    if ( a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ) { return 1; }
    return 0;
}

function handleScroll( e ) {
    if ( typeof document === 'undefined' ) {
        return;
    }
    const innerScrollContainer = document.getElementsByClassName( 'ReactVirtualized__Grid__innerScrollContainer' )[0];
    const categoryHandlers = document.getElementById( 'categoryHandlers' );

    if ( typeof innerScrollContainer === 'undefined' || typeof categoryHandlers === 'undefined' ) {
        return;
    }

    const categoryHandlersTopPosition = categoryHandlers.getBoundingClientRect().top;
    const innerScrollContainerTopPosition = innerScrollContainer.getBoundingClientRect().top;

    if ( ( categoryHandlersTopPosition < 0 ) || ( categoryHandlersTopPosition === 0 ) ) {
        categoryHandlers.style.position = 'fixed';
        categoryHandlers.style.top = '0';
        categoryHandlers.style.right = '15px';
    }
    if ( ( innerScrollContainerTopPosition === 0 ) || ( innerScrollContainerTopPosition > 0 ) ) {
        categoryHandlers.style.position = 'relative';
        categoryHandlers.style.right = 'auto';
    }
}

function composePathForChangedItems( treeData, changedTree, changes ) {
    const changesArray = [];
    Object.keys( changes ).map( id => {
        const originalNode = treeData.filter( entry => entry.node.id === id )[0];
        let newNode;
        if ( typeof originalNode === 'undefined' ) {
            newNode = changedTree.filter( entry => entry.node.id === id )[0];
        }
        if ( typeof newNode === 'undefined' && typeof originalNode === 'undefined' ) {
            // a new node was created and then deleted. no need to process it.
            return;
        }
        // compose parents breadcrumb
        const rootBreadcrumb = [];
        const acc = [];
        let node = originalNode;
        let newCategory = false;
        if ( typeof newNode !== 'undefined' ) {
            node = newNode;
            newCategory = true;
        }
        if ( node.parentNode ) {
            const pathX = node.path;
            pathX.splice( -1 );
            pathX.map( trIndex => {
                rootBreadcrumb.push( treeData.having( 'treeIndex', Number( trIndex ) )[0].node.name );
            } );
        }

        composeBreadcrumbs( node.node, [ ...rootBreadcrumb ], ( array ) => { acc.push( array ); } );
        const change = changes[id];
        if ( change.type !== 'delete' ) {
            if ( !newCategory ) {
                const acc2 = [];
                const changedNode = changedTree.filter( entry => entry.node.id === id )[0];
                composeBreadcrumbs( changedNode.node, [ ...rootBreadcrumb ], ( array ) => { acc2.push( array ); } );

                acc.map( ( array, index ) => {
                    const oldBreadcrumb = array.join( '/' );
                    const newBreadcrumb = acc2[index].join( '/' );
                    if ( oldBreadcrumb !== newBreadcrumb ) {
                        changesArray.push( { oldBreadcrumb, newBreadcrumb } );
                    }
                } );
            } else {
                acc.map( ( array ) => changesArray.push( { newBreadcrumb: array.join( '/' ) } ) );
            }
        } else {
            acc.map( ( array ) => changesArray.push( { oldBreadcrumb: array.join( '/' ) } ) );
        }
    } );
    return changesArray;
}

function composeBreadcrumbs( current, a = [], call ) {
    let b = [ ...a ];
    b.push( current.name );
    if ( typeof current.children === 'undefined' || ( typeof current.children[0] !== 'undefined' && current.children[0].type === 'docType' ) || current.children.isEmpty() ) {
        if ( typeof current.children !== 'undefined' && ( typeof current.children[0] !== 'undefined' && current.children[0].type === 'docType' ) ) {
            call( b );
        }
        b = [];
        return;
    }
    // visit children of current
    current.children.map( kid => composeBreadcrumbs( kid, b, call ) );
}

function downloadTextFile( text, name ) {
    const a = document.createElement( 'a' );
    const type = name.split( '.' ).pop();
    a.href = URL.createObjectURL( new Blob( [ text ], { type: `text/${type === 'txt' ? 'plain' : type}` } ) );
    a.download = name;
    a.click();
}

function sanitaze( current ) {
    let entry = current;
    if ( typeof entry.name === 'undefined' && typeof current.node !== 'undefined' ) {
        entry = current.node;
    }
    if ( typeof entry.name === 'undefined' ) {
        return;
    }
    entry.name = fullStringClean( entry.name );
    if ( typeof entry.children !== 'undefined' ) {
        entry.children.map( kid => sanitaze( kid ) );
    }
}
