import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, ListGroup } from 'reactstrap';
import classnames from 'classnames';

export default class GroupsTabs extends React.Component {
    constructor( props ) {
        super( props );

        this.toggle = this.toggle.bind( this );
        this.state = {
            activeTab: '1'
        };
    }

    toggle( tab ) {
        if ( this.state.activeTab !== tab ) {
            this.setState( {
                activeTab: tab
            } );
        }
    }

    render() {
        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={ classnames( { active: this.state.activeTab === '0' } ) }
                            onClick={ () => { this.toggle( '0' ); } }
                        >
                      Escalation
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={ classnames( { active: this.state.activeTab === '1' } ) }
                            onClick={ () => { this.toggle( '1' ); } }
                        >
              Groups
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={ classnames( { active: this.state.activeTab === '2' } ) }
                            onClick={ () => { this.toggle( '2' ); } }
                        >
              Users
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={ this.state.activeTab }>
                    <TabPane tabId="0">
                        <Row>
                            <Col sm="12">
                                <ListGroup>
                                    { this.props.escalationsDom}
                                </ListGroup>
                            </Col>
                        </Row>
                    </TabPane><TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <ListGroup>
                                    { this.props.groupsDom}
                                </ListGroup>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <ListGroup>
                                    { this.props.usersDom}
                                </ListGroup>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}
