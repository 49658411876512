import * as React from 'react';

import { ListGroupItem, Table } from 'reactstrap';


export default class SingleItemGroup extends React.PureComponent {
    constructor( props ) {
        super( props );

        this.state = {
            collapse: false,
            edit: false
        };
        this.toggle = this.toggle.bind( this );
        this.deleteUserFromGroup = this.deleteUserFromGroup.bind( this );
        this.deleteGroup = this.deleteGroup.bind( this );
    }

    toggle() {
        this.setState( { collapse: !this.state.collapse } );
    }

    deleteUserFromGroup( userId ) {
        const newGroup = { ...this.props.data };
        delete newGroup.members[userId];
        this.props.updateGroup( newGroup );
    }

    deleteGroup() {
        const payload = { accountId: this.props.data.accountId, id: this.props.data.id };
        this.props.deleteGroup( payload );
    }

    buildDOM( data ) {
        return (
            <tr>
                <td>{data.name}</td>
                <td>Role: {data.role}</td>
                <td>
                    <div><span
                        className="icon-trash"
                        style={ { marginRight: '10px', color: 'primary' } }
                        onClick={ () => ( this.props.deleteGroup( { id: data.id, accountId: data.accountId } ) ) }
                        role="presentation" />
                    </div>
                </td>
            </tr>
        );
    }

    render() {
        return (
            <React.Fragment>
                <ListGroupItem onClick={ this.toggle } key={ Math.random() }>
                    <Table size="sm" responsive>
                        <tbody>
                            { this.buildDOM( extractMember( this.props.data ) ) }
                        </tbody>
                    </Table>
                </ListGroupItem>
            </React.Fragment>
        );
    }
}

function extractMember( data ) {
    const member = { ...data };
    Object.keys( data.members ).map( x => {
        member.role = data.members[x].role;
    } );
    return member;
}
