import React from 'react';
import ReactQuill from 'react-quill';

class RichTextField extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { ...props.formData };
        this.onChange = this.onChange.bind( this );
        this.modules = {
            toolbar: [
                [ { header: [ 1, 2, 3, 4, 5, 6, false ] } ],
                [ { align: [] } ],
                [ { color: [] }, { background: [] } ], // dropdown with defaults from theme
                [ 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block' ],
                [ { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' } ],
                [ 'link' ]
            ],
        };
    }

    onChange( data ) {
        this.setState( {
            value: data,
        } );
        setTimeout( () => { this.props.onChange( data ); }, 300 );
    }

    UNSAFE_componentWillMount() {
        if ( typeof this.props.formData !== 'undefined' ) {
            this.setState( { value: this.props.formData } );
        }
    }

    render() {
        return (
            <div>
                <label className="control-label" htmlFor={ this.props.idSchema.$id }>
                    {this.props.schema.title}
                    {this.props.required
            && <span className="required">*</span>
                    }
                </label>
                <ReactQuill
                    theme={'bubble'}
                    readOnly={ this.props.disabled }
                    value={ this.state.value || '' }
                    modules={ this.modules }
                    onChange={ this.onChange } />
            </div>
        );
    }
}

export default RichTextField;
