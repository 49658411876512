/* eslint-disable no-duplicate-case,no-fallthrough */
import types from './types';
import applicationTypes from '../application/types';

const initialState = null;
const aa = {
    acl: [],
    invites: [],
    isAdmin: false,
    isOwner: false,
    isUser: false,
    isReadOnly: false,
    canCreate: false,
    isDefault: true,
    prettyPermissions: []
};

const currentUserReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case types.TERMS_AND_CONDITIONS_COMPLETED: return { ...state, termsAccepted: action.payload.data.user.termsAccepted };
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'currentUser' ) {
                const user = typeof action.payload.data.user !== 'undefined' ? action.payload.data.user : {};
                return Object.assign( {}, state, user );
            }
            if ( action.payload.target === 'myInvites' ) {
                const invites = typeof action.payload.data !== 'undefined' ? action.payload.data : [];
                return Object.assign( {}, state, { invites } );
            }
            return state;
        default: return state;
    }
};

export default currentUserReducer;
