import types from './types';
const saveSummary = ( data, extraData ) => {
    const body = data;
    return {
        type: types.SAVE_SUMMARY,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/summary',
            method: 'POST',
            body
        }
    };
};
export default {
    saveSummary
};
