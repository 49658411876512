import React, { Component } from 'react';
import moment from 'moment';
import Switch from './Switch';
import './repeatFooter.scss';
import DateSelector from '../Date/DateSelector';
import PropTypes from 'prop-types';


class RepeatFooter extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            endRepeat: true,
            count: 1,
            until: moment( props.eventDate ).add( 1, 'day' ),
            activeStep: 'interval'
        };
        this.toggle = this.toggle.bind( this );
        this.updateOccurances = this.updateOccurances.bind( this );
        this.updateOn = this.updateOn.bind( this );
        this.selectOption = this.selectOption.bind( this );
    }

    componentWillMount() {
        if ( typeof this.props.data !== 'undefined' && Object.keys( this.props.data ).hasItems() && Object.keys( this.props.data.endRepeatOptions ).hasItems() ) {
            const newState = {};
            if ( !this.props.data.endRepeatOptions.count ) {
                newState.activeStep = 'date';
                newState.count = false;
                newState.until = this.props.data.endRepeatOptions.until;
            } else {
                newState.activeStep = 'interval';
                newState.until = '';
                newState.count = this.props.data.endRepeatOptions.count;
            }
            this.setState( { ...newState } );
        } else {
            this.props.callback( { type: 'endRepeat', payload: { endRepeat: true, count: 1, until: false } } );
        }
    }

    componentWillReceiveProps( nextProps ) {
        if ( nextProps.eventDate !== this.props.eventDate ) {
            this.setState( {
                until: moment( nextProps.eventDate ).add( 1, 'day' ),
            } );
        }
    }

    toggle( ) {
        if ( this.props.forceEnd || this.props.disabled ) {
            return;
        }
        this.setState( { endRepeat: !this.state.endRepeat } );
        this.props.callback( { type: 'endRepeat', payload: { endRepeat: !this.state.endRepeat, count: 1, until: false } } );
    }

    updateOccurances( value ) {
        if ( this.props.disabled || value > 99 ) {
            return;
        }
        this.setState( { count: value } );
        this.props.callback( { type: 'endRepeat', payload: { count: value, until: false, endRepeat: this.state.endRepeat } } );
    }

    updateOn( val ) {
        if ( this.props.disabled ) {
            return;
        }
        let value = val;
        if ( val.isBefore( moment() ) ) {
            value = moment();
        }
        this.setState( { until: value } );
        this.props.callback( { type: 'endRepeat', payload: { count: false, until: value, endRepeat: this.state.endRepeat } } );
    }

    selectOption( option ) {
        if ( this.props.disabled ) {
            return;
        }
        if ( option === 'interval' ) {
            closeCalendar(); this.setState( { activeStep: 'interval', until: '' } );
            this.props.callback( { type: 'endRepeat', payload: { count: this.state.count, until: false, endRepeat: this.state.endRepeat } } );
        }
        if ( option === 'until' ) {
            this.setState( { activeStep: 'date', until: moment(this.props.eventDate).add( 1, 'day' ) } );
            this.props.callback( { type: 'endRepeat', payload: { count: false, until: this.state.until === '' ? moment(this.props.eventDate).add( 1, 'day' ) : this.state.until, endRepeat: this.state.endRepeat } } );
        }
    }

    render() {
        let dotClass = 'radiobutton-icon-dot';
        if ( this.props.disabled ) {
            dotClass = 'radiobutton-icon-dot disabled';
        }
        return (
            <React.Fragment>
                <div style={ { marginBottom: 5 } }>Ends:</div>
                { !this.props.forceEnd
                  && <Switch toggle={ this.toggle } disabled={ this.props.disabled } />
                }
                { this.state.endRepeat
                && (
                    <div className="widget collection">
                        <div
                            onClick={ () => { this.selectOption( 'interval' ); } }
                            className={ `item noselect radiobutton ${this.state.activeStep === 'interval' ? 'radiobutton-checked' : ''}` }
                            role="radio"
                            aria-checked="false">
                            <div className="radio-value-container">
                                <div className={ `radiobutton-icon ${this.state.activeStep === 'interval' ? 'radiobutton-icon-checked' : ''}` }>
                                    <div className={ dotClass } />
                                </div>
                            </div>
                            <div className="item-content noselect">
                              After
                                <input
                                    type="number"
                                    className="texteditor-input"
                                    min="1"
                                    max={ 99 }
                                    defaultValue="1"
                                    disabled={ this.props.disabled }
                                    value={ this.state.count }
                                    onChange={ e => this.updateOccurances( e.target.value ) } /> <span>occurrence(s)</span>
                            </div>
                        </div>
                        <p />
                        <div
                            onClick={ () => { this.selectOption( 'until' ); } }
                            className={ `item noselect radiobutton ${this.state.activeStep === 'date' ? 'radiobutton-checked' : ''}` }
                            role="radio"
                            aria-checked="false">
                            <div className="radio-value-container">
                                <div className={ `radiobutton-icon ${this.state.activeStep === 'date' ? 'radiobutton-icon-checked' : ''}` }>
                                    <div className={ dotClass } />
                                </div>
                            </div>
                            <div className="item-content noselect">
                              On
                                <div style={ { display: 'inline-block', marginLeft: 10 } }>
                                    <DateSelector specialClass="endRecurrence" value={ this.state.until } onChange={ this.updateOn } disablePreviousDates disabled={ this.props.disabled } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
            </React.Fragment>
        );
    }
}
function closeCalendar() {
    const calendar = document.getElementsByClassName( 'rdrCalendarWrapper PreviewArea' );
    if ( calendar.length > 0 ) {
        document.getElementsByClassName( 'endRecurrence' )[0].click();
    }
}

export default RepeatFooter;

RepeatFooter.propTypes = {
    forceEnd: PropTypes.bool,
};
RepeatFooter.defaultProps = {
    forceEnd: true
};
