import types from './types';

const changeEmail = ( data, extraData ) => {
    const body = data;
    return {
        type: types.CHANGE_EMAIL,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/changeEmail',
            method: 'POST',
            body
        }
    };
};

const saveTermsAndConditions = ( data, extraData ) => {
    const body = data;
    return {
        type: types.TERMS_AND_CONDITIONS,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/termsAndConditions',
            method: 'POST',
            body
        }
    };
};

export default {
    changeEmail,
    saveTermsAndConditions
};
