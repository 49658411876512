import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useAuth0 } from './auth0_provider';

const RouteEnhancer = ( { component: Component, path, ...rest } ) => {
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const render = props => {
        if ( !isAuthenticated ) {
            loginWithRedirect();
        } else {
            return ( <Component user={ user } logout={ logout } { ...props } /> );
        }
    };

    return <Route path={ path } render={ render } { ...rest } />;
};

RouteEnhancer.propTypes = {
    component: PropTypes.oneOfType( [ PropTypes.element, PropTypes.func, PropTypes.object ] )
        .isRequired,
    path: PropTypes.oneOfType( [
        PropTypes.string,
        PropTypes.arrayOf( PropTypes.string )
    ] ).isRequired
};

export default RouteEnhancer;
