import types from './types';

const initialState = {
    files: [],
    queue: [],
    uploadingFiles: false
};

const uploadsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.UPLOAD_STARTED: return { ...state, uploadingFiles: true };
        case types.UPLOAD_FINISHED: return { ...state, uploadingFiles: false };
        case types.UPLOAD: {
            const uploadFile = Object.assign( {}, { fileName: action.file.name }, { signedUrl: action.signedUrl }, { progressPercent: 0, id: action.id } );
            return {
                ...state,
                files: [ ...state.files, uploadFile ]
            };
        }

        case types.UPLOAD_PROGRESS: {
            // TODO find file in array by url and add progress property
            const newFileArray = [ ...state.files ];
            const updatedFile = newFileArray.having( 'signedUrl', action.signedUrl )[0];
            // For some reason that progress update is for a file which is NOT already in the list
            // Just add to the array
            if ( typeof updatedFile === 'undefined' ) {
                return {
                    ...state,
                    files: [ ...state.files, updatedFile ]
                };
            }
            newFileArray.having( 'signedUrl', action.signedUrl )[0].progressPercent = action.progress;
            return {
                ...state,
                files: newFileArray
            };
        }

        case types.UPLOAD_COMPLETE: {
            const newFileArray = [ ...state.files ];
            let newQueue = [ ...state.queue ];
            const filtered = newFileArray.filter( x => x.signedUrl !== action.signedUrl );
            let uploadingFiles = true;
            newQueue.having( 'id', action.id )[0].completed = true;
            if ( newQueue.filter( entry => !entry.completed ).isEmpty() ) {
                newQueue = [];
                uploadingFiles = false;
            }
            return {
                ...state,
                files: filtered,
                queue: newQueue,
                uploadingFiles
            };
        }
        case types.UPLOAD_ERROR: {
            console.error( `Upload Error file ${action.file.name}  ${action.error}` );
            return state;
        }
        case types.REMOVE: {
            return state;
        }
        case types.UPDATE: {
            return state;
        }
        case types.BUILD_UPLOAD_QUEUE: {
            return { ...state, queue: [ ...state.queue, ...action.payload.files ] };
        }
        case types.CLEAR: return initialState;

        default: return state;
    }
};

export default uploadsReducer;
