import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { sortAscendingByOptionTitle } from '../../utils/sortAscendingByOptionTitle';
import OptionBuilder from './OptionBuilder';
import { optionsOperations } from '../../redux/ducks/options';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import { fullStringClean } from '../../utils/cleanString';
import { documentTypesOperations } from '../../redux/ducks/documentTypes';
import TooltipWrapper from '../Tooltip/Tooltip';
import Display from '../Display';

class OptionManager extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            currentFolder: null,
            editOption: false,
            filtering: false,
            selectedFolder: '',
            searchValue: '',
        };
        this.add = this.add.bind( this );
        this.onChange = this.onChange.bind( this );
        this.save = this.save.bind( this );
        this.saveRename = this.saveRename.bind( this );
        this.buildOptionsFoldersList = this.buildOptionsFoldersList.bind( this );
        this.changeFolder = this.changeFolder.bind( this );
        this.deleteOption = this.deleteOption.bind( this );
        this.editOption = this.editOption.bind( this );
        this.filterAllOptions = this.filterAllOptions.bind( this );
        this.edit = this.edit.bind( this );
        this.delete = this.delete.bind( this );
        this.deleteFolder = this.deleteFolder.bind( this );
        this.confirmationModalToggle = this.confirmationModalToggle.bind( this );
        this.selectDocType = this.selectDocType.bind( this );
        this.buildUsage = this.buildUsage.bind( this );
        this.toggle = this.toggle.bind( this );
    }

    UNSAFE_componentWillMount() {
        if ( this.props.folders ) {
            this.setState( { currentFolder: this.props.folders[0] } );
        }
        const availableOptions = this.props.options.sort( sortAscendingByOptionTitle );
        this.setState( { allOptions: availableOptions, options: availableOptions } );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        const availableOptions = nextProps.options.sort( sortAscendingByOptionTitle );
        this.setState( { allOptions: availableOptions, options: availableOptions, searchValue: '' } );
    }

    componentWillUnmount() {
        this.setState( { selectedOption: null } );
    }

    add() {
        const name = fullStringClean( document.getElementById( 'docType-name' ).value );
        if ( name === '' ) {
            return;
        }
        const id = name.replace( ' ', '_' ).toLowerCase();
        if ( this.state.docTypes.having( 'id', id ).hasItems() ) {
            this.setState( { erorr: 'Name has to be unique' } );
        } else {
            this.setState( {
                docTypes: [ ...this.state.docTypes, { name, id, color: this.state.color } ],
                color: colorsArray[Math.floor( Math.random() * colorsArray.length )],
                erorr: null
            } );
            document.getElementById( 'docType-name' ).value = '';
        }
    }

    onChange( color ) {
        this.setState( { color: color.hex } );
    }

    save() {
        if ( !this.state.docTypes.hasItems() ) {
            return;
        }
        const data = {
            docs: this.state.docTypes,
            accountId: this.props.currentAccount.id
        };
        this.props.saveDocumentsTypes( data )
            .then( () => { console.log( 'worked' ); this.setState( { filtering: false, searchValue: '' } ); } )
            .catch( () => { console.log( 'failed' ); this.setState( { filtering: false, searchValue: '' } ); } );
    }

    saveRename() {
        if ( this.state.inProgress ) {
            return;
        }
        this.setState( { inProgress: true } );
        const newName = document.getElementById( 'customNewName' ).value;
        this.props.renameFolder( this.state.selectedFolder, newName )
            .then(
                () => {
                    this.setState( { editTitle: false, inProgress: false } );
                    this.changeFolder( newName );
                }
            ).catch(
                () => {
                    this.setState( { editTitle: false, inProgress: false } );
                }
            );
    }

    buildOptionsFoldersList( array, callback, edit, del, editTitle, selectedFolder ) {
        return array.sort().map( folder => {
            if ( editTitle && folder === selectedFolder ) {
                return (
                    <div
                        key={ Math.random() }>
                        <input id="customNewName" defaultValue={ folder } style={ { width: '60%' } } />
                        <div style={ { fontSize: 14, top: 3, position: 'relative', float: 'right', cursor: 'pointer', display: 'inline-block' } }>
                            <Badge
                                className="bg-primary"
                                style={ { marginLeft: 2, marginRight: 2 } }
                                onClick={ this.saveRename }
                            >save
                            </Badge>
                            <Badge
                                className="bg-secondary"
                                style={ { marginLeft: 2, marginRight: 2 } }
                                onClick={ () => this.setState( { editTitle: false } ) }
                            >cancel
                            </Badge>
                        </div>
                    </div>
                );
            }
            return (
                <div
                    key={ Math.random() }
                    className={ `folder ${this.state.currentFolder === folder ? 'active' : ''}` }>
                    <span onClick={ () => callback( folder ) }><i className="fa fa-folder" /> { folder }</span>
                    <div className="pull-right" style={ { display: 'inline-block' } }>
                        <span className="icon-trash pull-right pl-1 pr-1" onClick={ () => del( folder ) } />
                        <span className="icon-wrench pull-right pl-1 pr-1" onClick={ () => edit( folder ) } />
                    </div>
                </div>
            );
        } );
    }

    changeFolder( name ) {
        this.setState( { currentFolder: name, filtering: false, searchValue: '', allOptions: this.state.options } );
    }

    buildFolderContentList( options, edit, deleteCallback, usage ) {
        if ( typeof options === 'undefined' ) {
            return <script />;
        }
        const workingOptions = [ ...options.sort( sortAscendingByOptionTitle ) ].except( 'id', 'filename' );
        return workingOptions.map( option => (
            <div className="col-lg-12 optionRow" key={ option.id }>
                <div className="row">
                    <div className="col-lg-8">
                        { option.title.capitalize() }
                    </div>
                    <div className="col-lg-2">
                        { option.optionType.toUpperCase() }
                    </div>
                    {/* <div className="col-lg-2"> */}
                    {/* { option.mandatory ? 'Yes' : 'No' } */}
                    {/* </div> */}
                    <div className="col-lg-2">
                        <i className="icon-info mr-2" id={ `usage-${option.id}` } onClick={ () => { usage( option ); } } />
                        <TooltipWrapper refId={ `usage-${option.id}` } content="See usage" />
                        <i className="icon-pencil mr-2" id={ `edit-${option.id}` } onClick={ () => { edit( option ); } } />
                        <TooltipWrapper refId={ `edit-${option.id}` } content="Edit option definition" />
                        <i className="icon-trash" id={ `trash-${option.id}` } onClick={ () => deleteCallback( option.id ) } />
                        <TooltipWrapper refId={ `trash-${option.id}` } content="Delete option definition" />
                    </div>
                </div>
            </div>
        ) );
    }

    deleteOption( id ) {
        this.props.deleteOptions( { optionId: id } );
    }

    editOption( option ) {
        this.setState( { editOption: true, selectedOption: option } );
    }

    buildUsage( option ) {
        const usedIn = this.props.docTypesList.filter( docType => docType.options.having( 'id', option.id ).hasItems() );
        this.setState( { docTypesList: usedIn, isDropDownOpen: true, selectedOption: option } );
    }

    selectDocType( docType ) {
        this.props.selectDocType( docType.name );
        const option = docType.options.having( 'id', this.state.selectedOption.id ).firstOrDefault();
        this.setState( { editOption: true, selectedOption: { ...option, docTypeId: docType.id } } );
    }

    toggle() {
        this.setState( { isDropDownOpen: false } );
    }

    filterAllOptions( e ) {
        if ( e.target.value === '' ) {
            this.setState( { allOptions: this.state.options, filtering: false, searchValue: '' } );
        }
        this.setState( { filtering: true, searchValue: e.target.value, allOptions: this.state.options.filter( option => option.title.trim().toLocaleLowerCase().contains( e.target.value.trim().toLocaleLowerCase() ) ) } );
    }

    edit( folder ) {
        this.setState( { selectedFolder: folder, editTitle: true } );
    }

    delete( folder ) {
        this.setState( { selectedFolder: folder, confirmationModalOpen: true } );
    }

    confirmationModalToggle() {
        this.setState( { confirmationModalOpen: !this.state.confirmationModalOpen } );
    }

    deleteFolder( folder ) {
        this.props.deleteFolder( folder )
            .then( res => {
                console.log( 'got response' );
                this.setState( { confirmationModalOpen: false } );
            } )
            .catch( () => {
                console.log( 'got response' );
                this.setState( { confirmationModalOpen: false } );
            } );
    }

    render() {
        const { folders, options } = this.props;
        const { allOptions, currentFolder, confirmationModalOpen, selectedFolder, editTitle } = this.state;
        // accounts = accounts || [];
        if ( !options ) {
            return <script />;
        }
        const dataToDisplay = this.state.filtering ? allOptions : allOptions.having( 'folderName', currentFolder );
        const folderContent = this.buildFolderContentList( dataToDisplay, this.editOption, this.deleteOption, this.buildUsage );
        const folderListDom = this.buildOptionsFoldersList( folders, this.changeFolder, this.edit, this.delete, editTitle, selectedFolder );
        return (
            <div className="optionsModal">
                { this.state.editOption
                    && (
                        <OptionBuilder
                            selectedOption={ this.state.selectedOption }
                            toggle={ () => { this.setState( { editOption: false, isDropDownOpen: false } ); } }
                            open={ this.state.editOption } />
                    )
                }
                <ConfirmationModal
                    toggle={ this.confirmationModalToggle }
                    open={ confirmationModalOpen }
                    action={ () => this.deleteFolder( selectedFolder ) }
                    message={ `Are you sure you want to delete folder: ${selectedFolder}` }
                />
                <div className="row">
                    <div className="col-lg-4" />
                    <div className="col-lg-8 mb-2 p-0">
                        <input
                            type="text"
                            placeholder="Search form fields"
                            onChange={ this.filterAllOptions }
                            value={ this.state.searchValue }
                            className="form-control"
                            style={ { width: '100%' } }
                        />
                    </div>
                    <div className="col-lg-4"><h6 className="header">Folders</h6></div>
                    <div className="col-lg-8">
                        <div className="row" style={ { marginRight: 0 } }>
                            <div className="col-lg-8" style={ { backgroundColor: '#e4e5e6' } }><h6 className="header">Form field name</h6></div>
                            <div className="col-lg-2" style={ { backgroundColor: '#e4e5e6' } }><h6 className="header pl-0">Type</h6></div>
                            {/* <div className="col-lg-2"><h6 className="header pl-0">Required</h6></div> */}
                            <div className="col-lg-2" style={ { backgroundColor: '#e4e5e6' } }><h6 className="header pl-0">Actions</h6></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 optionColumn optionFolders">
                        { folderListDom }
                    </div>
                    <div className="col-lg-8 optionColumn pl-0" id="selectableOptions">
                        { this.state.editOption
                      && (
                          <OptionBuilder
                              selectedOption={ this.state.selectedOption }
                              toggle={ () => { this.setState( { editOption: false, isDropDownOpen: false } ); } }
                              open={ this.state.editOption } />
                      )
                        }
                        {checkOptionUsage( this.state.docTypesList, this.state.isDropDownOpen, this.selectDocType, this.toggle )}
                        { folderContent }
                    </div>
                </div>
            </div>
        );
    }
}

OptionManager.propTypes = {
    folders: PropTypes.array.isRequired,
    currentAccount: PropTypes.object.isRequired
    // setAccountId: PropTypes.func.isRequired,
};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    folders: state.options.folders,
    options: state.options.options,
    docTypesList: state.documentTypes.list,
    breadcrumbs: state.categories.breadcrumbs
} );

const mapDispatchToProps = dispatch => ( {
    deleteOptions: ( id ) => dispatch( optionsOperations.deleteOption( id ) ),
    renameFolder: ( folder, newName ) => dispatch( optionsOperations.renameFolder( folder, newName ) ),
    selectDocType: ( name ) => dispatch( documentTypesOperations.changeFolder( name ) ),
    deleteFolder: ( folder ) => dispatch( optionsOperations.deleteFolder( folder ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( OptionManager );


function checkOptionUsage( docTypesList = null, isDropDownOpen, selectDocType, toggle ) {
    if ( !docTypesList ) {
        return <script />;
    }
    let items = docTypesList.map( docType => (
        <DropdownItem
            className=""
            id={ `download-${docType.id}` }
            key={ `download-${docType.id}` }
            onClick={ () => { selectDocType( docType ); } }>{docType.name}
        </DropdownItem>
    ) );
    if ( items.length === 0 ) {
        items = <p className="text-center">Option is not being used.</p>;
    }

    return (
        <Modal isOpen={ isDropDownOpen } toggle={ toggle }>
            <ModalHeader toggle={ toggle }>Edit option</ModalHeader>
            <ModalBody>
                <Display when={ items.length > 0 && items instanceof Array }>
                    <React.Fragment>
                Please select a document type from below to see/edit the option usage.
                        <br />
                    </React.Fragment>
                </Display>
                { items }
            </ModalBody>
            <ModalFooter>
                {' '}
                <Button color="secondary" onClick={ toggle }>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}
