import React, { Fragment } from 'react';
import Modal from 'reactstrap/lib/Modal';
import PropTypes from 'prop-types';
import { Button, ModalFooter } from 'reactstrap';

class FileViewModal extends React.PureComponent {
    render() {
        const { showFileView, url, onClose } = this.props;

        return (
            <Fragment>
                <Modal style={ { width: '100%', height: '100%' } } isOpen={ showFileView || false }>
                    <object
                        data={ url }
                        style={ { width: '100%', height: '100%', frameBorder: '0' } }
                    >
                    </object>
                    <ModalFooter>>
                        <Button id="close" color="secondary" onClick={ onClose }>Close</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

FileViewModal.defaultProps = {
    showFileView: false,
    url: ''
};

FileViewModal.propTypes = {
    showFileView: PropTypes.bool,
    url: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default FileViewModal;
