import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import { Button, Row, Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import VaultBreadcrumb from '../../../components/Breadcrumb/VaultBreadcrumb';
import { documentOperations } from '../../../redux/ducks/documents';
import { categoriesBreadcrumbsOperations } from '../../../redux/ducks/categoriesBreadcrumbs';
import categoryOperations from '../../../redux/ducks/categories/operations';

function mapIconClassName( category ) {
    let iconName;

    switch ( category.name.toLowerCase() ) {
        case 'commercial':
            iconName = 'building';
            break;
        case 'general corporate':
            iconName = 'bank';
            break;
        case 'compliance':
            iconName = 'thumbs-up';
            break;
        case 'assets':
            iconName = 'barcode';
            break;
        case 'material':
            iconName = 'won';
            break;
        case 'human resources':
            iconName = 'users';
            break;
        case 'insurance':
            iconName = 'ambulance';
            break;
        case 'banking & financials':
            iconName = 'money';
            break;
        case 'health & safety':
            iconName = 'medkit';
            break;
        case 'litgation':
            iconName = 'exclamation';
            break;
        case 'information technology':
            iconName = 'at';
            break;
        case 'accounts':
            iconName = 'list';
            break;
        case 'real property':
            iconName = 'building';
            break;
        case 'intellectual property':
            iconName = 'eye';
            break;
        case 'environmental':
            iconName = 'leaf';
            break;
        case 'general':
            iconName = 'folder-open-o';
            break;
        default:
            iconName = 'folder-open-o';
    }

    return iconName;
}

function getDocCountByCategory( documents, category, location ) {
    if ( category === 'Unassigned' ) {
        return documents.filter( doc => doc.dirty === true ).length;
    }
    let path = location.includes( '/docvault/' ) ? location.replace( '/docvault/', '' ) : location.replace( '/docvault', '' );
    if ( path === '' ) {
        path = category;
    } else {
        path += `/${category}`;
    }
    return documents.filter( doc => locationHasCategory( doc.formData.location, path ) && !doc.dirty ).length;
}
function locationHasCategory( location, currentPath ) {
    return location.toLowerCase().indexOf( currentPath.toLowerCase() ) === 0;
}
function tile( category, link, icon, handleButtonClick, documents, location ) {
    return (
        <Col xs="16" sm="16" lg="4" style={ { padding: '15px' } } key={ Math.random() }>
            <Button
                className="categoryBtn"
                style={ { height: 80 } }
                color="primary"
                size="lg"
                block
                onClick={ () => handleButtonClick( category ) }>
                <i className={ `mr-1 fa fa-${mapIconClassName( category )}` } />
                <span>{category.name} ({getDocCountByCategory( documents, category.name, location )})</span>
            </Button>
        </Col>
    );
}

function calculateCurrentCategory( cat, array, categoriesBreadcrumbs ) {
    const current = cat.children.filter( child => child.name === array.first() ).firstOrNull();
    if ( !current ) {
        return;
    }
    categoriesBreadcrumbs.push( current );
    if ( array.length === 1 ) {
        return current;
    }
    array.shift();
    calculateCurrentCategory( current, array, categoriesBreadcrumbs );
}

class VaultNavigator extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            search: false,
            categories: []
        };
        this.listener1 = () => {};
        this.history = this.props.history;
        this.determineDataToDisplay = this.determineDataToDisplay.bind( this );
    }

    componentWillMount() {
        if ( this.props.history.location.pathname === '/docvault' ) {
            this.props.selectCategory( null );
        }

        if ( `/docvault/${this.props.categoriesBreadcrumbs.map( x => x.name ).join( '/' )}` !== this.props.history.location.pathname ) {
            this.determineDataToDisplay( this.props.history.location.pathname );
        } else {
            try {
                if ( this.props.categoriesBreadcrumbs.last().children.filter( x => x.type === 'docType' ).hasItems() ) {
                    this.props.selectCategoryForDocDisplay( this.props.categoriesBreadcrumbs.last() );
                    this.history.push( '/docvault/category' );
                }
            } catch ( err ) {
                console.error( err );
            }
        }

        const that = this;
        this.listener1 = this.props.history.listen( ( location, action ) => {
            // reset view when navigating away from docvault
            if ( location.pathname === '/docvault' ) {
                this.props.selectCategory( null );
                return;
            } if ( !location.pathname.includes( 'docvault' ) ) {
                this.props.selectCategory( null );
                return;
            }
            that.determineDataToDisplay( location.pathname );
        } );
    }

    determineDataToDisplay( location ) {
        if ( location === '/docvault/category' ) {
            return;
        }
        if ( location === '/docvault' ) {
            this.props.selectCategory( null );
            return;
        }
        let categoriesArray = location.replace( '/docvault', '' ).split( '/' );
        categoriesArray.shift();
        categoriesArray = categoriesArray.map( x => decodeURI( x ) );
        const categoriesBreadcrumbs = [];
        const parent = this.props.category.treeData.filter( cat => cat.name === categoriesArray[0] ).firstOrNull();
        if ( !parent && location !== '/docvault/category' ) {
            this.props.selectCategory( null );
            this.setState( { categories: this.props.category.treeData } );
            return;
        }
        categoriesBreadcrumbs.push( parent );
        if ( categoriesArray.length === 1 ) {
            this.props.selectCategory( categoriesBreadcrumbs );
            this.setState( { categories: parent.children } );
            return;
        }
        categoriesArray.shift();
        const currentCategory = calculateCurrentCategory( parent, categoriesArray, categoriesBreadcrumbs );
        this.props.selectCategory( categoriesBreadcrumbs );
        this.setState( { categories: typeof currentCategory !== 'undefined' ? currentCategory.children : [] } );
    }

    componentDidMount() {
        if ( this.props.categoriesBreadcrumbs.isEmpty() && this.props.location.pathname !== '/docvault' ) {
            this.history.push( '/docvault' );
        }
    }

    componentWillUnmount() {
        this.listener1();
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        if ( this.props.location !== nextProps.location ) {
            return;
        }
        this.setState( { categories: nextProps.category.treeData } );
    }

  handleButtonClick = ( category ) => {
      let isBottom = true;
      const categoryNavigationDepth = this.props.currentAccount.categoryNavigationDepth || 1;
      // if we will have doctypes alongside other cat nodes we need to change this.

      if ( categoryNavigationDepth > 1 ) {
          if ( typeof category.children !== 'undefined' ) {
              isBottom = category.children.filter( x => x.type === 'docType' ).hasItems();
          }
          if ( this.props.categoriesBreadcrumbs.length + 1 === this.props.currentAccount.categoryNavigationDepth ) {
              isBottom = true;
          }
      } else {
          isBottom = true;
      }


      if ( category.id === 'unassigned' ) {
          isBottom = true;
      }
      if ( isBottom ) {
          this.props.selectCategory( category );
          this.props.selectCategoryForDocDisplay( category );
          this.history.push( '/docvault/category' );
      } else {
          this.history.push( `${this.props.location}/${category.name}` );
      }
  };

  render() {
      const { categoriesLoading, category, documents, categoriesBreadcrumbs } = this.props;
      const { categories } = this.state;
      if ( !Object.keys( category ).hasItems() ) {
          window.location.href = '/account';
      }
      let catToPaint = [];
      if ( Object.keys( categoriesBreadcrumbs ).isEmpty() ) {
          catToPaint = category.treeData;
      } else {
          catToPaint = categoriesBreadcrumbs.last().children || [];
      }
      let categoriesDom = '';
      if ( categoriesLoading ) {
          categoriesDom = ( <h2>Loading...</h2> );
      } else if ( catToPaint.hasItems() ) {
          categoriesDom = catToPaint.map( cat => tile( cat, '', 'folder', this.handleButtonClick, documents, this.props.location ) );
          const dirtyCategory = tile( { id: 'unassigned', name: 'Unassigned', children: [] }, '', 'folder', this.handleButtonClick, documents );
          if ( !this.props.currentUser.isReadOnly && this.props.location === '/docvault' ) {
              categoriesDom.push( dirtyCategory );
          }
      } else {
          categoriesDom = <Col className="mt-3">No documents in this category</Col>;
      }
      return (
          <Container fluid className="p-0">
              <Row className="flexLeft">
                  <Col>
                      <VaultBreadcrumb location={ this.props.location } />
                  </Col>
              </Row>
              <Row className="flexLeft">
                  {categoriesDom}
              </Row>
          </Container>

      );
  }
}

VaultNavigator.propTypes = {
    category: PropTypes.object.isRequired,
    selectCategory: PropTypes.func.isRequired,
    categoriesLoading: PropTypes.bool.isRequired,
    location: PropTypes.string.isRequired,
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ( {
    category: state.categories.categoryTree,
    categoriesBreadcrumbs: state.categoriesBreadcrumbs,
    categoriesLoading: state.application.categoriesLoading,
    documents: state.documents.list,
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
    selectCategory: ( data ) => dispatch( categoriesBreadcrumbsOperations.selectCategory( data ) ),
    selectCategoryForDocDisplay: ( data ) => dispatch( categoryOperations.selectCategoryForDocDisplay( data ) ),
    selectDocument: ( data ) => dispatch( documentOperations.selectDocument( data ) )
} );


export default connect( mapStateToProps, mapDispatchToProps )( VaultNavigator );
