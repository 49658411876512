/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Display from '../Display';
import { buildIconClassName } from '../../utils/buildIconClassName';
import DocumentSearch from '../Search/DocumentSearch';
import { documentOperations } from '../../redux/ducks/documents';
import shallowCompare from '../../utils/shallowCompare';
import TableView from '../Table/Table';

class DocumentAddWizard extends React.Component {
    constructor() {
        super();
        this.state = {
            uploadStatusModal: false,
            step: 0,
            new: false,
            old: false,
            error: null
        };
    }

    UNSAFE_componentWillMount( ) {
        if ( this.props.docs ) {
            this.setState( { docTypes: this.props.docs, allDocTypes: this.props.docs } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.props.docs !== nextProps.docs ) {
            this.setState( { docTypes: nextProps.docs, allDocTypes: nextProps.docs } );
        }
    }

    shouldComponentUpdate( nextProps, nextState ) {
    // if ()
        return shallowCompare( this, nextProps, nextState );
    }

    closeModal = () => {
        this.setState( { uploadStatusModal: false, step: 0, old: false, new: false } );
    };

    toggleModal = () => {
        this.setState( { uploadStatusModal: !this.state.uploadStatusModal, step: 0, old: false, new: false } );
    };

    uploadToDocument = id => {
        const selectedDoc = this.props.documents.having( 'id', id )[0];
        const potentialBreadcrumbs = this.props.breadcrumbs.filter(x=>x.type === selectedDoc.docType);
        if ( potentialBreadcrumbs.length === 0 ||
          potentialBreadcrumbs.filter(x => x.breadcrumb === selectedDoc.formData.location).length === 0 ) {
          this.setState( { error: `Selected document doesn't have a valid location.` } );
          return;
        }
        const { title, location } = selectedDoc.formData;
        this.setState( { uploadStatusModal: true, step: 2, docTitle: title, docLocation: location, docId: id, error: null } );
    };

    startUpload = () => {
        this.props.selectDocument( this.state.docId );
        setTimeout( () => { this.props.existingDocumentSelected(); this.closeModal(); }, 500 );
    };

    filterAllOptions = e => {
        if ( e.target.value === '' ) {
            this.setState( { allDocTypes: this.state.docTypes } );
        }
        this.setState( { allDocTypes: this.state.docTypes.filter( doc => doc.name.trim().toLocaleLowerCase().contains( e.target.value.trim().toLocaleLowerCase() ) ) } );
    };

    render() {
        const data = buildRow( this.state.allDocTypes );
        return (
            <div>
                <Button
                    style={ { display: 'none' } }
                    id="uploadDocWizardTrigger"
                    color="primary"
                    className="btn btn-outline-secondary openOptionsModal mr-2"
                    onClick={ this.toggleModal }>
Wizard
                </Button>
                <Display when={ this.state.uploadStatusModal }>
                    <Modal className="documentAddWizard" isOpen={ this.state.uploadStatusModal } toggle={ this.toggleModal }>
                        <ModalHeader toggle={ this.toggleModal }>
                          Wizard
                            <button
                                style={ { right: 50, position: 'absolute', top: 10 } }
                                className="btn btn-outline-secondary"
                                onClick={ () => { this.setState( { step: 0, old: false, new: false } ); } }>
                              Start over
                            </button>
                        </ModalHeader>
                        <ModalBody>
                            <Display when={ this.state.step === 0 }>
                                <div className="row">
                                    <div className="col-md-6 holder" onClick={ () => { this.setState( { new: false, old: true, step: 1 } ); } }>
                                        <span id="addToExisting" className="existingDoc fa fa-file-text" />
                                        <span className="text">
                                            <div className="title">Add to existing Entry</div>
                                            <div className="subtitle">Upload your files to an existing entry.</div>
                                        </span>
                                    </div>
                                    <div className="col-md-6 holder" onClick={ () => { this.setState( { old: false, new: true, step: 1 } ); } }>
                                        <span id="addToNew" className="existingDoc fa fa-file" />
                                        <span className="text">
                                            <div className="title">Add new Entry </div>
                                            <div className="subtitle">Create a new entry that will contain your uploaded files.</div>
                                        </span>
                                    </div>
                                </div>
                            </Display>
                            <Display when={ this.state.step === 1 }>
                                <div>
                                    <Display when={ this.state.new }>
                                        <div>Please select the entry type</div>
                                        <br />
                                        <div className="mb-1">
                                            <input
                                                type="text"
                                                id="docTypeSearchField"
                                                placeholder="Search"
                                                onChange={ this.filterAllOptions }
                                                className="form-control"
                                                style={ { width: '100%' } }
                                            />
                                        </div>
                                        <div className="customTable clickableRow">
                                            <TableView
                                                rowsDOM={ data }
                                                columns={ [
                                                    { name: 'name', title: 'Document type name' }
                                                ] }
                                                tableStyle={ { minHeight: 'auto' } }
                                                rowClickCallback={ ( e ) => { this.props.handleSelect( e.id ); } }
                                                showFilter={ false }
                                                defaultFilters={ [ ] }
                                            />
                                        </div>
                                    </Display>
                                    <Display when={ this.state.old }>
                                        <div>Document name</div>
                                        <div className="row" style={ { marginTop: 15 } }>
                                            <Display when={this.state.error}>
                                              <div className="pl-3 red pb-2">{this.state.error}</div>
                                            </Display>
                                            <DocumentSearch
                                                auth={ this.props.auth }
                                                currentAccount={ this.props.currentAccount }
                                                history={ this.props.history }
                                                redirect={ false }
                                                selectDocument={ this.uploadToDocument } />
                                        </div>
                                    </Display>
                                </div>
                            </Display>
                            <Display when={ this.state.step === 2 }>
                                <div>
Are you sure that you want to upload file(s) to the entry:
                                    {' '}
                                    <strong>{ this.state.docTitle }</strong>
                                    {' '}
?
                                    {' '}
                                </div>
                                <br />
                                <div className="optionsBuilderModal">
                                    <div className="centerKids">
                                        <button
                                            name="mandatory"
                                            value
                                            className="btn btn-outline-info btn-block booleanOption m-0 mr-2"
                                            onClick={ () => { this.startUpload(); } }>
Yes
                                        </button>
                                        <button
                                            name="mandatory"
                                            value={ false }
                                            className="btn btn-outline-warning btn-block booleanOption m-0"
                                            onClick={ () => { this.setState( { step: 1 } ); } }>
No
                                        </button>
                                    </div>
                                </div>
                            </Display>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={ this.closeModal }>Close</Button>
                        </ModalFooter>
                    </Modal>
                </Display>
            </div>
        );
    }
}

DocumentAddWizard.defaultProps = {
};

const { string, func } = PropTypes;

DocumentAddWizard.propTypes = {

};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    documents: state.documents.list
} );

const mapDispatchToProps = dispatch => ( {
    selectDocument: ( data ) => dispatch( documentOperations.selectDocument( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DocumentAddWizard );

function buildRow( data ) {
    return data.sort( compareNames ).map( doc => (
        {
            name: doc.name.capitalize(),
            id: doc.id
        }
    ) );
}

function compareNames( a, b ) {
    if ( a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ) { return -1; }
    if ( a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ) { return 1; }
    return 0;
}
