import { createStore, compose, applyMiddleware } from 'redux';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import * as reducers from './ducks';
import middleware from './middleware';

const encryptor = createEncryptor( {
    secretKey: 'xnkandklasu73563hwoiewwy2@3q0dij9w28hknd76cweuf83o%^$@R%KH',
    onError( error ) {
        console.error( error.message );
    }
} );

const persistConfig = {
    key: 'root',
    transforms: [ encryptor ],
    storage,
    blacklist: [ 'upload', 'messages', 'resources', 'groups', 'categoriesBreadcrumbs', 'versions' ]// prevent persist store for uploads ( until we have a flag for them that they are uploaded )
};

export default ( initialState = {} ) => {
    // ======================================================
    // Store Enhancers
    // ======================================================
    const enhancers = [];
    if ( window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV !== 'production' ) {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        if ( typeof devToolsExtension === 'function' ) {
            enhancers.push( devToolsExtension() );
        }
    }

    const appReducer = combineReducers( {
        ...reducers
    } );

    const rootReducer = ( state, action ) => {
        if ( action.type === 'LOGOUT' ) {
            storage.removeItem( 'persist:root' );
            state = undefined;
        }
        return appReducer( state, action );
    };

    const persistedReducer = persistReducer(
        persistConfig,
        rootReducer
    );

    const composedEnhancers = compose(
        applyMiddleware( ...middleware ),
        ...enhancers
    );

    const store = createStore(
        persistedReducer,
        initialState,
        composedEnhancers
    );

    // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
    // store.unsubscribeHistory = browserHistory.listen(updateLocation(store))

    if ( module.hot ) {
    // Enable Webpack hot module replacement for reducers
        module.hot.accept( './ducks', () => {
            const nextRootReducer = require( './ducks' );
            store.replaceReducer( nextRootReducer );
        } );
    }
    const persistor = persistStore( store );
    return { store, persistor };
};
