import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';

// This is a link to an action defined in actions.js.
const { } = actions;
const changeEmail = ( data ) => ( dispatch, getState ) => {
    const { user } = getState().application;
    dispatch( actions.changeEmail( data, { user } ) );
    messageOperations.showSuccess( 'Change email request sent.' );
};

const saveTermsAndConditions = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.saveTermsAndConditions( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Terms and condtions option saved' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

export default {
    changeEmail,
    saveTermsAndConditions
};
