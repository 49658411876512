import moment from 'moment-timezone';

export const isThisMonth = ( date ) => moment( date ).isSame( new Date(), 'month' );
export const isNextMonth = ( date ) => moment( date ).isSame( moment( new Date() ).add( 1, 'months' ), 'month' );
export const isThisWeek = ( date ) => moment( date ).isSame( new Date(), 'isoWeek' );
export const isToday = ( date ) => moment( date ).isSame( new Date(), 'day' );
export const isTomorrow = ( date ) => {
    const tomorrow = moment( new Date() ).clone().add( 1, 'days' ).startOf( 'day' );
    return moment( date ).isSame( tomorrow, 'day' );
};
export const isBeforeToday = ( date ) => moment( date ).isBefore( new Date(), 'd' );
export const getToday = () => moment();
// export const getTheWeek = () => {
//     const weekStart = moment(new Date() ). isoWeek( momentweek ).startOf( 'isoweek' );
//     const weekEnd = moment( startDate ).endOf( 'isoweek' );
//     return { weekStart, weekEnd };
// };
