import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableView from '../Table/Table';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';

const columns = [
    { name: 'email', title: 'Email' },
    { name: 'status', title: 'Status' },
    // { name: 'date', title: 'Creation date' },
    { name: 'actions', title: 'Actions' }
];

const columnWidths = [
    { columnName: 'email', width: '30%' },
    { columnName: 'status', width: '20%' },
    // { columnName: 'date', width: '10%' },
    { columnName: 'actions', width: '10%' }
];

function composeMembersTable( invites, deleteInvite, updating, canAccess, generateInvite ) {
    return invites.map( member => (
        {
            email: member.email.toLocaleLowerCase(),
            level: member.access,
            status: member.deleted ? 'Deleted' : member.status.capitalize(),
            date: member.updatedAt ? member.updatedAt : member.creationDate,
            actions: <span>
                { updating === member.id
                    && <span>updating ...</span>
                }
                { ( updating !== member.id ) && canAccess && member.status !== 'accepted'
          && (
              <React.Fragment>
                  <button
                      style={ { marginLeft: '10px' } }
                      type="button"
                      disabled={ member.deleted }
                      className="btn btn-outline-info"
                      onClick={ () => { generateInvite( member ); } }>
                    reissue
                  </button>
                  <button
                      style={ { marginLeft: '10px' } }
                      type="button"
                      disabled={ member.deleted }
                      className="btn btn-outline-danger"
                      onClick={ () => { deleteInvite( { accountId: member.accountId, inviteId: member.id } ); } }>
            delete
                  </button>
              </React.Fragment>
          )
                }
                     </span>
        }
    ) );
}
class ListInvites extends Component {
    static log( form ) { }

    constructor() {
        super();
        this.state = {};
        this.deleteInvite = this.deleteInvite.bind( this );
        this.generateInvite = this.generateInvite.bind( this );
        this.triggerDeleteModal = this.triggerDeleteModal.bind( this );
        this.toggleConfirmation = this.toggleConfirmation.bind( this );
    }

    deleteInvite( ) {
        if ( this.state.updating ) {
            return;
        }
        this.setState( { updating: this.state.deleteData.inviteId } );
        this.props.deleteInvite( this.state.deleteData )
            .then( () => { this.setState( { updating: null, showConfirmModal: false } ); } )
            .catch( () => { this.setState( { updating: null, showConfirmModal: false } ); } );
    }

    generateInvite( data ) {
        this.props.reissueInvite( {
            accountName: data.accountName,
            email: data.email,
            accountId: data.accountId
        } )
            .then( () => { this.setState( { updating: null } ); } )
            .catch( () => { this.setState( { updating: null } ); } );
    }

    triggerDeleteModal( data ) {
        this.setState( { deleteData: data, showConfirmModal: true } );
    }

    toggleConfirmation() {
        this.setState( { showConfirmModal: !this.state.showConfirmModal } );
    }

    render() {
        const { invites, canAccess } = this.props;
        if ( !invites.hasItems() ) {
            return ( <script /> );
        }

        const filteredInvited = invites.filter( ( invite ) => invite.deleted !== true );
        const data = composeMembersTable( filteredInvited, this.triggerDeleteModal, this.state.updating, canAccess, this.generateInvite );
        return (
            <div className="customTable clickableRow white-box">
                <ConfirmationModal
                    toggle={ this.toggleConfirmation }
                    open={ this.state.showConfirmModal }
                    action={ this.deleteInvite }
                    processing={ this.state.updating }
                    message="Are you sure you want to delete this invite ?"
                />
                <div className="card-header pt-0">
                    <i className="fa fa-align-justify" />
                Invites
                </div>
                <TableView
                    rowsDOM={ data }
                    columns={ columns }
                    columnWidths={ columnWidths }
                    disableSortingFor={ [ 'actions' ] }
                />
            </div>
        );
    }
}

const { array, func, bool } = PropTypes;
ListInvites.propTypes = {
    canAccess: bool.isRequired,
    invites: array.isRequired,
    deleteInvite: func.isRequired,
    reissueInvite: func.isRequired,
};

export default ListInvites;
