import React from 'react';
import { connect } from 'react-redux';
import { Alert, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Uploader from '../../components/File/Uploader';
import DocumentAdd from '../../components/Document/DocumentAdd';
import { documentOperations } from '../../redux/ducks/documents';
import { isEqual } from '../../utils/isEqual';
import DocumentAddWizard from '../../components/Document/DocumentAddWizard';
import { buildIconClassName } from '../../utils/buildIconClassName';
import { uploadsOperations } from '../../redux/ducks/uploads';
import Display from '../../components/Display';
import TooltipWrapper from '../../components/Tooltip/Tooltip';

const defaultDocs = [];

function buildDoc( document, handleSelect ) {
    return (

        <div
            style={ { cursor: 'pointer' } }
            onClick={ () => handleSelect( document.id ) }
            key={ Math.random() }
            className="col-lg-3 col-sm-6 col-xs-12"
        >
            <div
                className="docType"
                style={ { backgroundColor: 'white',
                    color: '#4c6f7d',
                    padding: 25,
                    marginBottom: 30,
                    display: 'flex',
                    '-ms-flex-align': 'center',
                    '-webkit-align-items': 'center',
                    '-webkit-box-align': 'center',
                    alignItems: 'center' } }>
                {buildIconClassName( document.name )}
                <h5 className="text-left" style={ { display: 'inline-block', margin: '0 0 0 10px', position: 'relative', top: 2 } }>{document.name.capitalize()}</h5>
            </div>

        </div>

    );
}

function buildRoute( document, url, files ) {
    return (
        <Route
            exact
            key={ Math.random() }
            path={ `${url}/${document.id}` }
            name={ `Add ${document.name}` }
            render={ ( props ) => (
                <DocumentAdd
                    history={ props.history }
                    location={ props.location }
                    docName={ document.name }
                    docType={ document.id }
                    files={ files } />
            ) } />
    );
}

class DocumentSelector extends React.Component {
    constructor( ) {
        super();
        this.state = {
            docs: defaultDocs,
            searchValue: '',
            isShowAll: false
        };
        this._isMounted = false;
    }

    UNSAFE_componentWillMount() {
        if ( Object.keys( this.props.currentAccount ).hasItems() && typeof this.props.documentTypes !== 'undefined' ) {
            this.setState( { docs: [ ...this.props.documentTypes ].filter( x => typeof x.name !== 'undefined' && this.props.writeDocTypes.includes( x.name ) ) } );
        }
        this._isMounted = true;
        this.props.clearDocument();
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( !isEqual( this.props.documentTypes, nextProps.documentTypes ) && this._isMounted ) {
            this.setState( { docs: [ ...nextProps.documentTypes ].filter( x => typeof x.name !== 'undefined' && nextProps.writeDocTypes.includes( x.name ) ) } );
        }
    }

    componentWillUnmount( ) {
        this._isMounted = false;
    }

    filesDropped = files => {
        this.setState( { files } );
        setTimeout( () => { document.getElementById( 'uploadDocWizardTrigger' ).click(); }, 500 );
    };

    existingDocumentSelected = () => {
        this.props.buildUploadQueue( this.state.files );
        setTimeout( () => { this.props.triggerUpload(); }, 500 );
    };

    handleChange = event => {
        event.preventDefault();
        if ( this._isMounted ) {
            this.setState( { searchValue: event.target.value } );
        }
    };

    toggleShowAll = event => {
        event.preventDefault();
        if ( this._isMounted ) {
            this.setState( { isShowAll: !this.state.isShowAll } );
        }
    };

    render() {
        const { category, match } = this.props;
        if ( !Object.keys( category ).hasItems() ) {
            this.props.history.replace( '/account' );
        }
        if ( this.props.currentUser.isDefault || ( this.props.location.pathname.indexOf( '/add' ) > -1 && this.props.currentUser.isReadOnly || !this.props.currentUser.canCreate ) ) {
            return (
                <div className="col-lg-12">
                    <Alert color="danger">
                Sorry, you do not have access here.
                    </Alert>
                </div>
            );
        }
        const handleSelect = ( documentType ) => {
            this.props.selectDocument( {} );

            // this is needed to preserve form data in case of refresh
            this.props.clearTempDoc();

            this.props.history.push( `/add/${documentType}` );
        };
        const hasAccount = Object.keys( this.props.currentAccount ).hasItems();
        const docsDom = this.state.docs.filter( x => x.name.toLowerCase().includes( this.state.searchValue.toLowerCase() ) ).map( doc => buildDoc( doc, handleSelect ) );
        const DocTypeSelector = () => (
            <div className="">
                <div className="row">
                    { !hasAccount
                        && (
                            <div className="col-lg-12">
                                <Alert color="danger">
                            First you need to create an account or be added to one.
                                </Alert>
                            </div>
                        )
                    }
                    <div className="col-lg-12 mb-3">
                        <br />
                        <InputGroup className="mb-3">
                            <InputGroupAddon addonType="append" style={ { position: 'absolute', zIndex: 99, top: 14, left: 10 } }><i id="showAllDocTypes" role="tooltip" aria-roledescription="tooltip" className="fa fa-list text-secondary" onClick={ this.toggleShowAll }></i></InputGroupAddon>
                            <TooltipWrapper refId="showAllDocTypes" content="Show All Types" />
                            <Input
                                className="insideIcon"
                                name="searchValue"
                                type="text"
                                placeholder="Search entry types"
                                required
                                autoFocus
                                value={ this.state.searchValue }
                                onChange={ this.handleChange } />
                        </InputGroup>
                    </div>
                    <Display when={ !this.state.isShowAll && this.state.searchValue.length === 0 }>
                        <div className="col-lg-12">
                            <Uploader
                                accountId={ this.props.currentAccount.id }
                                callback={ this.filesDropped }
                            />
                        </div>
                    </Display>
                    <Display when={ this.state.isShowAll || this.state.searchValue.length > 0 }>
                        <div className="col-lg-12" style={ { marginTop: '10px' } }>
                            <div className="card-body">
                                <div>
                                    <div className="row">
                                        { docsDom }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Display>
                    <DocumentAddWizard
                        docs={ this.state.docs }
                        auth={ this.props.auth }
                        handleSelect={ handleSelect }
                        existingDocumentSelected={ this.existingDocumentSelected }
                        breadcrumbs={ this.props.breadcrumbs }
                    />
                </div>
            </div>
        );

        const routes = this.state.docs.map( doc => buildRoute( doc, match.url, this.state.files ) );
        return (
            <div>
                { routes }

                <Route
                    exact
                    path={ `${match.url}/` }
                    name="Select type"
                    render={ ( props ) => (
                        <DocTypeSelector { ...props } />
                    ) } />

                <div style={ { marginBottom: '50px' } } />

            </div>

        );
    }
}

DocumentSelector.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired
};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    currentUser: state.currentUser,
    category: state.categories.categoryTree,
    breadcrumbs: state.categories.breadcrumbs,
    documentTypes: state.documentTypes.list,
    writeDocTypes: state.categories.writeDocTypes
} );

const mapDispatchToProps = dispatch => ( {
    selectDocument: ( data ) => dispatch( documentOperations.selectDocument( data ) ),
    clearDocument: ( data ) => dispatch( documentOperations.clearDocument( data ) ),
    buildUploadQueue: ( files ) => dispatch( uploadsOperations.buildUploadQueue( files ) ),
    clearTempDoc: () => dispatch( documentOperations.clearTempDoc() ),
    triggerUpload: ( ) => dispatch( uploadsOperations.triggerUpload( ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DocumentSelector );
