import React from 'react';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Display from '../Display';
import Uploader from '../File/Uploader';
import messageOperations from '../../redux/ducks/messages/operations';
import { uploadsOperations } from '../../redux/ducks/uploads';

const ALLOWED_EXTENSION = 'dotx';

class UploadTemplateModal extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.filesDropped = this.filesDropped.bind( this );
        this.closeUploadModal = this.closeUploadModal.bind( this );
    }

    filesDropped( files ) {
        if ( files.length > 1 ) {
            this.props.showNotificationWarning( 'Single file allowed. Please try again!' );
            return;
        }
        this.setState( { fileMeta: files[0], uploadModal: false } );
    }

    async closeUploadModal() {
        const { docTypeName, currentUser, showNotificationSuccess, showNotificationError, toggleUploadModal, uploadTarget, uploadDocTypeTemplate, currentAccount } = this.props;
        await storeTemplate( this.state.fileMeta, uploadTarget, docTypeName, currentUser.id, showNotificationSuccess, showNotificationError, toggleUploadModal, uploadDocTypeTemplate, currentAccount.id );
    }

    render() {
        return (
            <div>
                <Display when={ this.props.uploadModal }>
                    <Modal isOpen={ this.props.uploadModal } toggle={ this.props.toggleUploadModal }>
                        <ModalHeader toggle={ this.props.toggleUploadModal }>Upload file</ModalHeader>
                        <ModalBody>
                            <Uploader
                                permittedType={ ALLOWED_EXTENSION }
                                accountId={ this.props.currentAccount.id }
                                callback={ this.filesDropped }
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={ this.closeUploadModal }>Save and close</Button>
                        </ModalFooter>
                    </Modal>
                </Display>
            </div>
        );
    }
}

const { string, func, bool, obj } = PropTypes;

UploadTemplateModal.defaultProps = {
    currentAccount: {},
    currentUser: {},
    showNotificationSuccess: () => {},
    showNotificationError: () => {},
    showNotificationWarning: () => {}
};
UploadTemplateModal.propTypes = {
    currentAccount: obj,
    currentUser: obj,
    toggleUploadModal: func.isRequired,
    showNotificationSuccess: func,
    showNotificationError: func,
    showNotificationWarning: func,
    uploadModal: bool.isRequired,
    uploadTarget: string.isRequired,
    docTypeName: string.isRequired
};

const mapStateToProps = state => ( {
    currentUser: state.currentUser,
    currentAccount: state.currentAccount,
    selectedDocument: state.documents.selectedDocument,
} );

const mapDispatchToProps = dispatch => ( {
    showNotificationWarning: ( message ) => dispatch( messageOperations.showWarning( message ) ),
    showNotificationError: ( message ) => dispatch( messageOperations.showError( message ) ),
    showNotificationSuccess: ( message ) => dispatch( messageOperations.showSuccess( message ) ),
    uploadDocTypeTemplate: ( data ) => dispatch( uploadsOperations.uploadDocTypeTemplate( data ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( UploadTemplateModal );

async function storeTemplate( file, docTypeId, docTypeName, userId, successCallback, errorCallback, toggleUploadModal, upload, accountId ) {
    // We could fetch the file extension as well.
    const extension = file.name.split( '.' ).last();

    const payload = {
        filename: `/${accountId}/docTypeTemplates/${docTypeId}^${docTypeName}.${extension}`,
        file,
        docId: docTypeId,
        accountId,
        uploadType: 'docTypeTemplate'
    };
    upload( payload );
    toggleUploadModal();
}
