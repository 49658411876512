import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';
import applicationActions from '../application/actions';

const { selectCategoryForDocDisplay } = actions;

const addCategory = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.addCategory( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Categories saved' ) );
                dispatch( actions.triggerDocumentCategoryUpdate( { accountId: data.accountId }, { user } ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const deleteCategory = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.deleteCategory( data, { user } ) )
        .then(
            ( res ) => {
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const resetCategories = ( data ) => ( dispatch, getState ) => {
    const { currentUser, currentAccount } = getState();
    dispatch( actions.resetCategories( data, { currentAccount, currentUser } ) );
};


const resetDefaultCategoryData = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.resetDefaultCategoryData( data, { user } ) )
        .then(
            ( res ) => {
                resolve( res.data );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

export default {
    deleteCategory,
    addCategory,
    selectCategoryForDocDisplay,
    resetCategories,
    resetDefaultCategoryData
};
