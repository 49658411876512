import types from './types';

const selectAccount = ( data ) => ( {
    type: types.SELECT_ACCOUNT,
    payload: {
        data
    }
} );

const addMember = ( data, extraData ) => {
    const body = data;
    return {
        type: types.ADD_MEMBER,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/addMember',
            method: 'POST',
            body
        }
    };
};
const deleteMember = ( data, extraData ) => {
    const body = data;
    return {
        type: types.DELETE_MEMBER,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/deleteMember',
            method: 'POST',
            body
        }
    };
};
const updateMember = ( data, extraData ) => {
    const body = data;
    return {
        type: types.UPDATE_MEMBER,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/updateMember',
            method: 'POST',
            body
        }
    };
};

const updateAccount = ( data, extraData ) => {
    const body = data;
    return {
        type: types.UPDATE_ACCOUNT,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/account',
            method: 'PUT',
            body
        }
    };
};

const deleteAccount = ( data, extraData ) => {
    const body = data;
    return {
        type: types.DELETE_ACCOUNT,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/account',
            method: 'DELETE',
            body
        }
    };
};

export default {
    selectAccount,
    addMember,
    deleteMember,
    updateMember,
    updateAccount,
    deleteAccount
};
