import actions from './actions';
import messageOperations from '../messages/operations';
import activityActions from '../activity/actions';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';

// This is a link to an action defined in actions.js.
const { } = actions;
const saveDocumentsTypes = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.saveDocumentsTypes( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Document type saved.' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const addMember = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.addMember( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Invitation sent' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );
const deleteMember = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.deleteMember( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Member deleted' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );
const updateMember = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.updateMember( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Member updated' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const updateAccount = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.updateAccount( data, { user } ) )
        .then(
            ( res ) => {
                if ( typeof res === 'undefined' ) {
                    return;
                }
                dispatch( messageOperations.showSuccess( 'Account updated' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const selectAccount = ( data ) => ( dispatch, getState ) => {
    const { user } = getState().application;
    dispatch( actions.selectAccount( data ) );
    dispatch( activityActions.getActivity(
        { accountId: data.id, activityType: 'ALL', from: null, to: null }, { user }
    ) );
};

const deleteAccount = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.deleteAccount( data, { user } ) )
        .then(
            ( res ) => {
                if ( typeof res === 'undefined' ) {
                    return;
                }
                dispatch( messageOperations.showSuccess( 'Account updated' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

export default {
    updateAccount,
    deleteAccount,
    saveDocumentsTypes,
    selectAccount,
    addMember,
    deleteMember,
    updateMember
};
