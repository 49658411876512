const SAVE_OPTION = 'SAVE_OPTION';
const SAVE_OPTION_COMPLETED = 'SAVE_OPTION_COMPLETED';
const SAVE_OPTION_FAILED = 'SAVE_OPTION_FAILED';

const RECALCULATE = 'RECALCULATE';

export default {
    RECALCULATE,
    SAVE_OPTION,
    SAVE_OPTION_COMPLETED,
    SAVE_OPTION_FAILED
};
