
import * as Sentry from '@sentry/browser';
import psl from 'psl';
import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';
import { currentAccountOperations } from '../currentAccount';

// This is a link to an action defined in actions.js.
const { setFilter } = actions;

const logToSentryAccount = ( currentAccount ) => {
    Sentry.addBreadcrumb( {
        category: 'currentAccount',
        message: `Account ID: ${currentAccount.accountId} | Account Name: ${currentAccount.accountName}`,
        level: 'info'
    } );
    Sentry.configureScope( ( scope ) => {
        scope.setExtra( 'accountName', currentAccount.accountName );
        scope.setExtra( 'accountId', currentAccount.accountId );
    } );
};

const getData = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { currentAccount, groups, currentUser, resources, categories, application, events } = getState();
    const { readBreadcrumbs } = categories;
    const { calendarEvents } = events;
    const { documentId, breadcrumb, dataset, type, recurrencyId } = data;
    if ( typeof breadcrumb !== 'undefined'
      && readBreadcrumbs.having( 'breadcrumb', breadcrumb ).isEmpty() ) {
        // no call as user does not have access to this.
        return;
    }
    dispatch( actions.getData( data ) )
        .then(
            ( res ) => {
                const payload = { target: data.dataset, data: res.data };
                if ( typeof documentId !== 'undefined' && typeof type !== 'undefined' && type !== 'delete' ) {
                    payload.type = 'concat';
                    payload.documentId = documentId;
                }
                if ( typeof type !== 'undefined' && type === 'delete' ) {
                    payload.type = 'delete';
                }
                if ( typeof recurrencyId !== 'undefined' ) {
                  payload.type = 'concat';
                    payload.recurrencyId = recurrencyId;
                }
                if ( payload.target === 'accounts' ) {
                    if ( !payload.data.having( 'id', currentAccount.id ).hasItems( ) ) {
                        dispatch( currentAccountOperations.selectAccount( payload.data[0] || {} ) );
                    }
                }
                dispatch( actions.parseSubscription( payload, { currentAccount, groups, currentUser, resources, categories, calendarEvents } ) );
                resolve( res );
                // dispatch( messageOperations.showSuccess( res.data.message ) );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );


const parseSubscription = ( data ) => ( dispatch, getState ) => {
    const { currentAccount, groups, currentUser, resources, categories, application } = getState();
    if ( data.data.size === 0 ) {
        console.info( `no data found for ${data.target}` );
    }
    logToSentryAccount( currentAccount );
    // console.info('parseSubscription', currentUser, currentAccount)
    try {
        window.Intercom( 'boot', {
            app_id: 'idj2qwhm',
            name: currentUser ? currentUser.displayName : '', // Full name
            email: currentUser ? currentUser.email : '', // Email address
            account: currentAccount ? currentAccount.id : '',
            accountName: currentAccount ? currentAccount.accountName : '',
            company: currentAccount ? currentAccount.company : '',
            user_hash: application.intercom.data ? application.intercom.data.signature : ''
        } );
    } catch ( e ) {
        console.error( e );
    }

    dispatch( actions.parseSubscription( data, { currentAccount, groups, currentUser, resources, categories } ) );
};

const logSentryUser = ( data ) => {
    Sentry.configureScope( ( scope ) => {
        scope.setUser( { email: data.user.email } );
        scope.setUser( { id: data.user.uid } );
    } );
};

const getPersonalization = ( ) => ( dispatch ) => new Promise( ( ( resolve, reject ) => {
    const parsed = psl.parse( window.location.host );
    const subdomain = parsed.subdomain ? parsed.subdomain : 'app';
    dispatch( actions.getPersonalization( subdomain ) )
        .then(
            ( res ) => {
                resolve( res );
                // dispatch( messageOperations.showSuccess( res.data.message ) );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const displayRegisteredMessage = () => ( dispatch ) => {
    dispatch( messageOperations.showSuccess( 'Registration completed. Please check your email address' ) );
};

export default {
    parseSubscription,
    getPersonalization,
    displayRegisteredMessage,
    setFilter,
    getData
};
