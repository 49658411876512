/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { connect } from 'react-redux';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import PropTypes from 'prop-types';

import { documentOperations } from '../../redux/ducks/documents';


class DocumentDeletePopup extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
            documentName: ''
        };
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.props.open !== nextProps.open ) {
            this.setState( { open: nextProps.open } );
        }
    }

    deleteDocument = () => {
        if ( this.state.saving ) {
            return;
        }
        const data = {
            documentId: this.props.currentDocument.id,
            accountId: this.props.currentAccount.id,
            title: this.props.currentDocument.formData.title
        };
        this.setState( { saving: true } );
        this.props.deleteDocument( data )
            .then( () => { this.setState( { saving: false } ); this.props.callback(); } )
            .catch( () => { this.setState( { saving: false } ); } );
    };

    toggleModal = () => {
        this.setState( { open: !this.state.open } );
    };

    handleInputChange = event => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        this.setState( {
            [name]: value
        } );
    };

    render() {
        const { open, callback } = this.props;
        if ( Object.keys( this.props.currentDocument ).length === 0 ) {
            return <script />;
        }
        const docName = this.props.currentDocument.formData.title;
        return (
            <Modal isOpen={ open } toggle={ callback } className="">
                <ModalHeader toggle={ callback }>Are you absolutely sure?</ModalHeader>
                <ModalBody>
                    <p>This action <strong>cannot</strong> be undone. It will <strong>permanently delete</strong> document <strong><i><div dangerouslySetInnerHTML={ { __html: docName.replace( '/s\g', '&nbsp;' ) } } /></i></strong> and all events and summaries associated with it.</p>
                    <p>Type <span className="text-danger">'I AGREE'</span> to confirm:</p>
                    <input
                        style={ { width: '100%' } }
                        name="documentName"
                        type="text"
                        onChange={ this.handleInputChange } />
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={ { width: '100%' } }
                        color="danger"
                        onClick={ () => { this.deleteDocument(); } }
                        disabled={ this.state.documentName.toLowerCase().trim() !== 'i agree' }>
                        { !this.state.saving
                            && <span>I understand the consequences, delete this document !</span>
                        }
                        { this.state.saving
                            && <span>...deleting</span>
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

DocumentDeletePopup.defaultProps = {
    currentDocument: {},
    currentAccount: {},
};

const { object } = PropTypes;

DocumentDeletePopup.propTypes = {
    currentDocument: object,
    currentAccount: object
};

const mapStateToProps = state => ( {
    currentDocument: state.documents.selectedDocument,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
    deleteDocument: ( data ) => dispatch( documentOperations.deleteDocument( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DocumentDeletePopup );
