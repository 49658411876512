import React from 'react';
import { Container, Row } from 'reactstrap';

function Maintenance( ) {
    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <div className="content-container in" style={ { top: 332 } }>
                        <div className="head-line secondary-text-color" style={ { fontSize: 48, lineHeight: '60px', color: '#054d6791', marginBottom: 20 } }>
                      Maintenance
                        </div>
                        <div className="subheader primary-text-color" style={ { fontSize: 36, lineHeight: '46px' } }>
                      We're undergoing a bit of<br />
                      scheduled maintenance.
                        </div>
                        <hr style={ { margin: '35px 0', border: 'none' } } />
                        <div className="clearfix" />
                        <div className="context primary-text-color">

                            <p>Sorry for the inconvenience. We'll be back up and running as fast as possible.</p>

                        </div>
                        <div style={ { textAlign: 'left', marginTop: 30 } }>
                            <button className="btn btn-outline-secondary" onClick={ () => { window.location.href = '/dashboard'; } }>login</button>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    );
}

export default Maintenance;
