import React, { Component } from 'react';
import { Container, Row, Col, Button, Input, InputGroupAddon, InputGroup, InputGroupButton } from 'reactstrap';
import { useAuth0 } from '../../../auth0_provider';
const qs = require( 'qs' );

function LoginError( props ) {
    const { user, logout, loginWithRedirect } = useAuth0();
    const parsedData = qs.parse( props.location.search, { ignoreQueryPrefix: true } );
    if ( parsedData.error_description === 'maintenance' ) {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <div className="content-container in" style={ { top: 332 } }>
                            <div className="head-line secondary-text-color" style={ { fontSize: 48, lineHeight: '60px', color: '#054d6791', marginBottom: 20 } }>
                  Maintenance
                            </div>
                            <div className="subheader primary-text-color" style={ { fontSize: 36, lineHeight: '46px' } }>
                  We're undergoing a bit of<br />
                  scheduled maintenance.
                            </div>
                            <hr style={ { margin: '35px 0', border: 'none' } } />
                            <div className="clearfix" />
                            <div className="context primary-text-color">
                                <p>Sorry for the inconvenience. We'll be back up and running as fast as possible.</p>
                            </div>
                            <div style={ { textAlign: 'left', marginTop: 30 } }>
                                <button className="btn btn-outline-secondary" onClick={ logout }>login</button>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="6">
                        <div className="clearfix">
                            <h1 className="float-left display-3 mr-4">
                                <img src="img/error.png" width="100px" />
                            </h1>
                            <h4 className="pt-3">{parsedData.error_description}</h4>
                        </div>
                        <div style={ { textAlign: 'center' } }>
                            <button className="btn btn-outline-secondary" onClick={ logout }>try again</button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default LoginError;
