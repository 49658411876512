/* eslint-disable import/no-named-as-default,import/no-named-as-default-member */
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Auth0Provider } from './auth0_provider';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
const history = createBrowserHistory();
const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};
ReactDOM.render(
    ( <Auth0Provider
        domain={ process.env.auth0_domain }
        client_id={ process.env.auth0_clientId }
        audience={ process.env.auth0_audience }
        redirect_uri={ window.location.origin }
        onRedirectCallback={ onRedirectCallback }
    >
        <App />
      </Auth0Provider>
    ), document.getElementById( 'root' )
);
registerServiceWorker();
