import React, { Fragment } from 'react';
import Modal from 'reactstrap/lib/Modal';
import PropTypes from 'prop-types';
import { Button, ModalFooter, ModalHeader } from 'reactstrap';
import ModalBody from 'reactstrap/lib/ModalBody';
import Display from '../Display';

class FileRenameModal extends React.PureComponent {
    render() {
        const { showModal, file = { fileName: '' }, onClose, onSave, saveInProgress } = this.props;
        return (
            <Fragment>
                <Modal style={ { width: '100%', height: '100%' } } isOpen={ showModal || false }>
                    <ModalHeader toggle={ onClose }>
                    File rename
                    </ModalHeader>
                    <ModalBody className="text-left">
                        <div className="form-group">
                            <label htmlFor="email" className="text-left">New name: </label>
                            <input
                            // className={ inputNameClassNames }
                            // disabled={ this.props.edit }
                                style={ { width: '100%' } }
                                name="name"
                                id="newFileName"
                                type="text"
                                defaultValue={ file ? file.fileName.split( '.' ).first() : '' } />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            id="close"
                            color="info"
                            onClick={ () => {
                                onSave( document.getElementById( 'newFileName' ).value, file );
                            } }>
                            <Display when={ !saveInProgress }>Save</Display>
                            <Display when={ saveInProgress }>Saving ...</Display>
                        </Button>
                        <Button id="close" color="secondary" onClick={ onClose }>Close</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

FileRenameModal.defaultProps = {
    showFileView: false,
    url: ''
};

FileRenameModal.propTypes = {
    showFileView: PropTypes.bool,
    url: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default FileRenameModal;
