const UPLOAD = 'UPLOAD';
const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE';
const UPLOAD_ERROR = 'UPLOAD_ERROR';
const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
const REMOVE = 'REMOVE';
const UPDATE = 'UPDATE';
const CLEAR = 'CLEAR';
const BUILD_UPLOAD_QUEUE = 'BUILD_UPLOAD_QUEUE';
const UPLOAD_FINISHED = 'UPLOAD_FINISHED';
const UPLOAD_STARTED = 'UPLOAD_STARTED';

export default {
    UPLOAD,
    UPLOAD_COMPLETE,
    UPLOAD_ERROR,
    UPLOAD_PROGRESS,
    REMOVE,
    UPDATE,
    CLEAR,
    BUILD_UPLOAD_QUEUE,
    UPLOAD_FINISHED,
    UPLOAD_STARTED
};
