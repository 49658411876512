import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import messageOperations from '../../redux/ducks/messages/operations';

const MessageCenter = ( props ) => {
    const { messages, deleteMessage } = props;
    if ( typeof messages !== 'undefined' && messages.hasItems() ) {
        messages.map( showAlertMessage( deleteMessage ) );
    }

    return (
        <ToastContainer
            position="top-right"
            autoClose={ 5000 }
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={ false }
            pauseOnHover
        />
    );
};
const options = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true
};
function showAlertMessage( ) {
    return function ( message ) {
        if ( toast.isActive( message.id ) ) {
            return;
        }
        switch ( message.type ) {
            case 'success': toast.success( message.text, { ...options, toastId: message.id } ); console.log( '1', message.id );
                break;
            case 'warning': toast.warn( message.text, { ...options, toastId: message.id } );
                break;
            case 'error': toast.error( message.text, { ...options, toastId: message.id } );
                break;
            case 'info': toast.info( message.text, { ...options, toastId: message.id } );
                break;
            default: return '';
        }
    };
}

const { string, arrayOf, number, shape, object, func } = PropTypes;
MessageCenter.propTypes = {
    messages: arrayOf( shape( {
        id: number.isRequired,
        type: string.isRequired,
        text: string.isRequired,
        options: object.isRequired
    } ) ),
    deleteMessage: func
};

const mapStateToProps = state => ( {
    messages: state.messages
} );

const mapDispatchToProps = dispatch => ( {
    deleteMessage: id => dispatch( messageOperations.deleteMessage( id ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( MessageCenter );
