// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const COUNT = 'COUNT';
const HISTOGRAM = 'HISTOGRAM';
const SEARCH = 'SEARCH';
const CLEAR = 'SEARCH';

export default {
    COUNT,
    HISTOGRAM,
    SEARCH,
    CLEAR
};
