import React from 'react';
import { SortingState, IntegratedFiltering, DataTypeProvider, PagingState, IntegratedPaging, IntegratedSorting, FilteringState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableFilterRow, PagingPanel, TableColumnResizing } from '@devexpress/dx-react-grid-bootstrap4';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Display from '../Display';
import DateIntervalFilter from './DateIntervalFilter';

const FilterIcon = ( { type } ) => {
    if ( type === 'month' ) {
        return (
            <span
                className="icon oi oi-calendar"
            />
        );
    }
    return <TableFilterRow.Icon type={ type } />;
};
const toLowerCase = value => String( value ).toLowerCase();
const cityPredicate = ( value, filter ) => toLowerCase( value ).startsWith( toLowerCase( filter.value ) );
const months = [ 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december' ];
/**
 * determineMonth
 * @param val
 * @returns {number}
 * will convert a typed value ( string of number ) to corresponding month
 */
const determineMonth = ( val ) => {
    if ( Number( val ) ) {
        return Number( val ); // 0 = Jan & 11 = Dec
    }
    const selectedMonth = months.filter( month => month.startsWith( val.toLocaleLowerCase() ) )[0];
    if ( typeof selectedMonth !== 'undefined' ) {
        return Number( moment().month( selectedMonth ).format( 'M' ) );
    }
};

const handleDateFiltering = ( value, filter, row ) => {
    if ( filter.operation === 'between' ) {
        // RANGE handling
        const range = filter.value;
        // reset date range
        if ( range.start.length === 0 && range.end.length === 0 ) {
            return true;
        }
        return moment( value ).isBetween( moment( range.start ), moment( range.end ), 'days', '[]' );
    }
    if ( filter.operation === 'contains' ) {
        return moment( value ).format( 'DD MMM YYYY' ).toLocaleLowerCase().contains( filter.value.toLocaleLowerCase() );
    }
    if ( !filter.value.length || Number( filter.value ) === 0 ) return true;
    if ( filter && filter.operation === 'month' ) {
        // +1 is needed as it extracts the value of month starting from 0
        const momentMonth = moment( value ).month() + 1;
        const determined = determineMonth( filter.value );
        return ( momentMonth === determined );
    }

    return IntegratedFiltering.defaultPredicate( value, filter, row );
};

const DateFormatter = ( { value } ) => moment( value ).format( 'DD MMM YYYY' );

class TableView extends React.Component {
    constructor() {
        super();
        this.state = {
            pageSize: 10,
            sorting: [ ],
            dateColumns: [ 'date' ],
            dateFilterOperations: [ 'month', 'between', 'contains' ],
            dateFilterOperationsMessages: { month: 'Month', between: 'Between', contains: 'Contains', startsWith: 'Starts with', endsWith: 'Ends with' },
            filteringColumnExtensions: [
                { columnName: 'date', predicate: handleDateFiltering },
                { columnName: 'Priority', predicate: cityPredicate },
            ],
        };
        this.buildCellDom = this.buildCellDom.bind( this );
        this.filterCell = this.filterCell.bind( this );
        this.changeSorting = sorting => this.setState( { sorting } );
        this.changeColumnWidths = ( columnWidths ) => {
            this.setState( { columnWidths } );
        };
    }

    UNSAFE_componentWillMount( ) {
        if ( this.props.columnWidths.isEmpty() ) {
            const width = 100 / this.props.columns.length;
            const columnWidths = this.props.columns.map( column => ( { columnName: column.name, width: `${width}%` } ) );
            this.setState( { columnWidths } );
        }
    }

    buildCellDom( columnName, id, value, style, row, rowClickCallback ) {
        if ( typeof this.props.cellTemplates[columnName] === 'undefined' ) {
            if ( columnName === 'date' ) {
                return <td key={ Math.random() } onClick={ () => { rowClickCallback( row ); } } style={ style }>{moment( value ).format( 'DD MMM YYYY' )}</td>;
            }
            if ( columnName === 'action' ) {
                return <td style={ style } key={ Math.random() }>{value}</td>;
            }
            return <td key={ Math.random() } onClick={ () => { rowClickCallback( row ); } } style={ style }>{value}</td>;
        }
        return <td style={ style } onClick={ () => { rowClickCallback( row ); } } key={ Math.random() }>{this.props.cellTemplates[columnName]( value, id )}</td>;
    }

    filterCell( props ) {
        const { column } = props;
        const { dateFilterOperations, dateFilterOperationsMessages } = this.state;

        if ( column.name === 'date' ) {
            return (
                <TableFilterRow.Cell { ...props }>
                    <DateIntervalFilter
                        availableOptions={ dateFilterOperations }
                        availableMessages={ dateFilterOperationsMessages }
                        { ...props }
                    />
                </TableFilterRow.Cell>
            );
        }
        return <TableFilterRow.Cell { ...props } />;
    }

    render() {
        const { sorting, columnWidths, dateColumns, dateFilterOperations, filteringColumnExtensions } = this.state;

        const { rowsDOM, columns, rowClickCallback = () => {}, tableStyle, showFilter, defaultFilters = [], defaultSorting = [] } = this.props;
        // if ( rowsDOM.isEmpty() ) {
        //     return ( <Alert color="info">No data to display.</Alert> );
        // }
        let extraSorting = sorting;
        if ( sorting.length === 0 ) {
            extraSorting = defaultSorting;
        }
        const disableSorting = this.props.disableSortingFor.map( column => ( { columnName: column, sortingEnabled: false } ) );

        return (
            <Grid
                rows={ rowsDOM }
                columns={ columns }
            >
                <SortingState
                    sorting={ extraSorting }
                    columnExtensions={ disableSorting }
                    onSortingChange={ this.changeSorting }
                />
                <PagingState />
                <FilteringState defaultFilters={ defaultFilters } />
                <IntegratedFiltering columnExtensions={ filteringColumnExtensions } />
                <IntegratedSorting />
                <IntegratedPaging />
                <DataTypeProvider
                    availableFilterOperations={ dateFilterOperations }
                    formatterComponent={ DateFormatter }
                    for={ dateColumns }
                />
                <Table
                    className="table-outline mb-0 d-none d-sm-table "
                    style={ tableStyle }
                    rowComponent={ ( { row, ...restProps } ) => (
                        <Table.Row
                            { ...restProps }
                            style={ typeof row !== 'undefined' ? row.rowStyling : {} }
                        />
                    ) }
                    cellComponent={ ( { row, column, value, style } ) => ( this.buildCellDom( column.name, row.id, value, style, row, rowClickCallback ) ) }
                />

                <TableColumnResizing
                    columnWidths={ columnWidths }
                    onColumnWidthsChange={ this.changeColumnWidths }
                />
                <TableHeaderRow showSortingControls />
                <Display when={ showFilter === true }>
                    <TableFilterRow
                        showFilterSelector
                        cellComponent={ this.filterCell }
                    />
                </Display>
                <Display when={ rowsDOM.length > this.state.pageSize }>
                    <PagingPanel
                        className="nav float-right d-none d-sm-flex"
                    />
                </Display>
            </Grid>
        );
    }
}


const { array, func, object, bool } = PropTypes;

TableView.defaultProps = {
    columnWidths: [],
    disableSortingFor: [],
    tableStyle: {},
    cellTemplates: {},
    paginate: true,
    showFilter: false
};

TableView.propTypes = {
    rowsDOM: array.isRequired,
    columns: array.isRequired,
    columnWidths: array,
    disableSortingFor: array,
    rowClickCallback: func,
    tableStyle: object,
    cellTemplates: object,
    paginate: bool,
    showFilter: bool
};

export default TableView;
