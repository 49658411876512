const GET_VERSION_COMPLETED = 'GET_VERSION_COMPLETED';
const GET_VERSION_FAILED = 'GET_VERSION_FAILED';
const RESTORE_VERSION = 'RESTORE_VERSION';
const RESTORE_FILE = 'RESTORE_FILE';
const GET_VERSION = 'GET_VERSION';

export default {
    GET_VERSION,
    RESTORE_FILE,
    GET_VERSION_COMPLETED,
    GET_VERSION_FAILED,
    RESTORE_VERSION
};
