import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { arrayMove } from 'react-sortable-hoc';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import { buildFolderContentList, buildOptionsFoldersList, convertToSchema, SortableList } from '../Document/helpers';
import TooltipWrapper from '../Tooltip/Tooltip';
import { sortAscendingByOptionTitle } from '../../utils/sortAscendingByOptionTitle';

class CustomizeContractFieldsModal extends React.Component {
    constructor() {
        super();
        this.state = {
            optionsSelected: [],
            modal: false
        };
        this.onSortEnd = this.onSortEnd.bind( this );
        this.selectOption = this.selectOption.bind( this );
        this.addOptionsToContract = this.addOptionsToContract.bind( this );
        this.changeFolder = this.changeFolder.bind( this );
        this.handleModalToggle = this.handleModalToggle.bind( this );
        this.removeOption = this.removeOption.bind( this );
        this.filterAllOptions = this.filterAllOptions.bind( this );
    }

    UNSAFE_componentWillMount() {
        this.setState( {
            optionsSelected: this.props.optionsSelected,
            selectedFolder: this.props.folders[0],
            order: this.props.order,
            folders: this.props.folders,
            options: this.props.options
        } );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        this.setState( {
            optionsSelected: nextProps.optionsSelected,
            selectedFolder: nextProps.folders[0],
            order: nextProps.order,
            folders: nextProps.folders,
            options: nextProps.options
        } );
    }


    onSortEnd( { oldIndex, newIndex } ) {
        this.setState( {
            order: arrayMove( this.state.order, oldIndex, newIndex ),
        } );
    }

    selectOption( e ) {
        let value = false;
        // need to check as user can click only on the checkbox and not the label.
        if ( typeof e.target.children[0] !== 'undefined' ) {
            value = e.target.children[0].value;
        } else {
            value = e.target.value;
        }
        if ( !value ) {
            return;
        }
        let currentOption = { ...this.props.options.having( 'id', value )[0], folder: this.state.selectedFolder };
        if ( ( currentOption.mandatory && ( this.props.currentUser.isUser || this.props.currentUser.isReadOnly ) ) || e.target.name === 'title' ) {
            return;
        }
        const data = new Set( this.state.optionsSelected.map( x => x.id ) );
        // eslint-disable-next-line prefer-destructuring
        currentOption = convertToSchema( [ currentOption ] )[0];
        let newOptions = [ ...this.state.optionsSelected ];
        let newOrder = [ ...this.state.order ];
        if ( data.has( value ) ) {
            data.delete( value );
            newOptions = newOptions.except( 'id', value );
            newOrder = newOrder.filter( item => item !== value );
        } else {
            data.add( value );
            newOptions.push( currentOption );
            newOrder.push( currentOption.id );
        }
        this.setState( { optionsSelected: newOptions, order: newOrder } );
    }

    removeOption( id ) {
        const data = new Set( this.state.optionsSelected.map( x => x.id ) );
        let newOptions = [ ...this.state.optionsSelected ];
        data.delete( id );
        newOptions = newOptions.except( 'id', id );
        this.setState( { optionsSelected: newOptions } );
    }

    addOptionsToContract() {
        this.props.addOptionsToContract( this.state.optionsSelected, this.state.order );
        this.setState( { modal: false } );
    }

    changeFolder( folderId ) {
        this.setState( { selectedFolder: folderId } );
    }

    handleModalToggle() {
        this.setState( { modal: !this.state.modal } );
    }

    filterAllOptions( e ) {
        if ( e.target.value === '' ) {
            // this.setState( { allOptions: this.state.options, filtering: false, searchValue: '' } );
        }
        const options = this.props.options.filter( option => option.title.trim().toLocaleLowerCase().contains( e.target.value.trim().toLocaleLowerCase() ) );
        const folders = Array.from( new Set( options.map( option => option.folderName ) ) );
        this.setState( {
            filtering: true,
            searchValue: e.target.value,
            options,
            folders,
            selectedFolder: folders[0] || null
        } );
    }

    render() {
        const { options, currentUser } = this.props;
        let dom = '';
        if ( options ) {
            dom = buildFolderContentList( this.state.options.having( 'folderName', this.state.selectedFolder ), this.selectOption, this.state.optionsSelected );
        }
        const folderListDom = buildOptionsFoldersList( this.state.folders, this.state.selectedFolder, this.changeFolder );
        const canAccess = !!( currentUser.isAdmin || currentUser.isOwner );
        return (
            <div>
                <TooltipWrapper refId="addFields" content="Add fields" />
                <div
                    id="addFields"
                    className="btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5 float-right"
                    style={ { marginRight: '5px' } }
                    onClick={ this.handleModalToggle }>
                    <div className="fa fa-plus-square" style={ { paddingTop: '6px' } } />
                </div>
                <Modal isOpen={ this.state.modal } toggle={ this.handleModalToggle } className="optionsModal">
                    <ModalHeader toggle={ this.handleModalToggle }>Customise contract fields</ModalHeader>
                    <ModalBody>
                        {/* <div className="row"> */}
                        <div className="col-lg-12 mb-2 p-0">
                            <input
                                type="text"
                                placeholder="Search form fields"
                                onChange={ this.filterAllOptions }
                                value={ this.state.searchValue }
                                className="form-control"
                                style={ { width: '100%' } }
                            />
                        </div>
                        {/* </div> */}
                        <div className="row">
                            <div className="col-lg-4"><h6 className="header">Fields</h6></div>
                            <div className="col-lg-4"><h6 className="row header">{ this.state.selectedFolder }</h6></div>
                            <div className="col-lg-4"><h6 className="header">Selected fields</h6></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 optionColumn optionFolders" id="optionFoldersList">
                                { folderListDom }
                            </div>
                            <div className="col-lg-4 optionColumn" id="selectableOptions">
                                { dom }
                            </div>
                            <div className="col-lg-4 optionColumn" id="selectedFieldsList">
                                <SortableList
                                    helperClass="sortableHelper"
                                    removeOption={ this.removeOption }
                                    items={ this.state.optionsSelected }
                                    isUserAdmin={ canAccess }
                                    order={ this.state.order }
                                    docType={ this.props.docType }
                                    onSortEnd={ this.onSortEnd }
                                    useDragHandle />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ this.addOptionsToContract } id="save">
                            { this.state.saving
                    && <span>...saving</span>
                            }
                            { !this.state.saving
                    && <span>Save</span>
                            }
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
CustomizeContractFieldsModal.defaultProps = {
    folders: [],
    options: [],
    breadcrumbs: [],
    currentUser: {},
    docType: ''
};

const { func, array, bool, object, string } = PropTypes;

CustomizeContractFieldsModal.propTypes = {
    docType: string,
    addOptionsToContract: func.isRequired,
    optionsSelected: array.isRequired
};

const mapStateToProps = state => ( {
    breadcrumbs: state.categories.breadcrumbs,
    folders: state.options.folders,
    options: state.options.options,
    currentUser: state.currentUser
} );

const mapDispatchToProps = dispatch => ( {
    resolveEvent: ( data ) => dispatch( calendarEventsOperations.resolveEvent( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( CustomizeContractFieldsModal );
