import React from 'react';
import Alert from 'reactstrap/lib/Alert';
import PropTypes from 'prop-types';
import Select from 'react-select';

function alertForDuplicatedUser( ) {
    document.getElementById( 'root_email' ).value = '';
    alert( 'this email was already added. please try another one.' );
}

class AddMember extends React.Component {
    constructor() {
        super();
        this.state = {
            isEdit: false,
            isError: false,
            nameError: '',
            groupError: '',
            emailError: '',
            email: '',
            name: '',
            saving: false
        };
        this.onSubmit = this.onSubmit.bind( this );
        this.selectGroup = this.selectGroup.bind( this );
        this.handleInputChange = this.handleInputChange.bind( this );
    }

    onSubmit( e ) {
        e.preventDefault();
        if ( this.state.saving ) {
            return;
        }
        if ( this.state.name === '' ) {
            this.setState( { nameError: 'Name is required' } );
        }
        if ( this.state.email === '' ) {
            this.setState( { emailError: 'Email is required' } );
        }
        if ( typeof this.state.group === 'undefined' || this.state.group === 'none' ) {
            this.setState( { groupError: 'Group is required' } );
            return;
        }
        if ( this.state.email === '' || this.state.name === '' || typeof this.state.group === 'undefined' ) {
            return;
        }
        const data = { name: this.state.name.trim(), email: this.state.email, group: this.state.group.value };
        data.email = data.email.toLocaleLowerCase().trim();
        this.setState( { saving: true } );

        if ( this.props.invites.hasItems() ) {
            if ( this.props.invites.filter( invite => invite.email.toLocaleLowerCase().trim() === data.email.toLocaleLowerCase().trim() && !invite.deleted ).hasItems() ) {
                alertForDuplicatedUser();
                this.setState( { saving: false } );
                return;
            }
        }
        this.props.addMember( {
            formData: data,
            accountId: this.props.currentAccount.id,
            accountName: this.props.currentAccount.accountName
        } )
            .then(
                () => { this.setState( { saving: false, email: '', group: 'none', name: '' } ); }
            )
            .catch(
                () => { this.setState( { saving: false } ); }
            );
    }

    selectGroup( data ) {
        this.setState( { group: data } );
    }

    handleInputChange( event ) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState( {
            [name]: value,
            [`${name}Error`]: ''
        } );
    }

    render() {
        // const groupsNames = GroupService.extractCompoundGroupNames( this.props.groups );
        const options = this.props.groups.filter( group => !group.single && !group.escalation ).map( group => ( { value: group.id, label: group.name } ) );
        // const options = groupsNames.map( name => ( <option key={ Math.random() } value={ name }>{name.capitalize()}</option> ) );
        // options.unshift( <option key={ Math.random() } value="none" /> );
        return (
            <div className="card white-box" style={ { marginBottom: 30 } }>
                <div className="card-header pt-0">
                    <i className="fa fa-align-justify" />
Add new member
                </div>
                <div className="card-block">
                    { this.state.nameError !== '' || this.state.groupError !== '' || this.state.emailError !== ''
                        ? (
                            <Alert color="danger">
                                <p>{this.state.nameError}</p>
                                <p>{this.state.groupError}</p>
                                <p>{this.state.nameError}</p>
                            </Alert>
                        )
                        : <div />
                    }
                    <form onSubmit={ this.onSubmit } className="rjsf">
                        <div style={ { marginBottom: 5 } }>
                            <label className="control-label" htmlFor="email">Name* </label>
                            <input
                                value={ this.state.name }
                                className="form-control"
                                name="name"
                                onChange={ this.handleInputChange } />
                        </div>
                        <div style={ { marginBottom: 5 } }>
                            <label className="control-label" htmlFor="email">Email* </label>
                            <input
                                value={ this.state.email }
                                className="form-control"
                                id="root_email"
                                name="email"
                                onChange={ this.handleInputChange } />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Group: </label>
                            {/* <select */}
                            {/* className="form-control" */}
                            {/* name="group" */}
                            {/* value={ this.state.group } */}
                            {/* onChange={ this.handleInputChange }> */}
                            {/* { options } */}
                            {/* </select> */}
                            <Select
                                options={ options }
                                value={ this.state.group }
                                className="customReactSelect"
                                onChange={ this.selectGroup }
                            />
                        </div>
                        <button className="btn btn-outline-primary" type="submit">
                            { !this.state.saving && <span>Save</span> }
                            { this.state.saving && <span>...saving</span> }
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}
const { func, object, array } = PropTypes;
AddMember.propTypes = {
    currentAccount: object.isRequired,
    addMember: func.isRequired,
    invites: array.isRequired
};

export default AddMember;
