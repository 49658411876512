import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Modal, Button,
    ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import Form from 'react-jsonschema-form';
import Geosuggest from 'react-geosuggest';
import root from 'window-or-global';

import formTemplate from '../../constants/forms/party/partyDetails';

class Party extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            modal: false,
            isEdit: true,
            isCreate: true,
            isError: false,
            errorMessage: null,
            isDropdownOpen: false,
            formData: {
                street: '', postCode: '', city: '', state: '', country: ''
            }
        };
    }

    UNSAFE_componentWillMount() {
    // this is needed in case of refresh on route /account
        this.populateFields( this.props );
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        this.populateFields( nextProps );
    }

    populateFields = props => {
        if ( Object.keys( props.partyInfo ).hasItems() ) {
            this.setState( { formData: props.partyInfo, isEdit: true, isCreate: false } );
        }
    };

    render() {
        const {
            onSubmit, onDelete, log, uid, partyInfo
        } = this.props;
        const handleToggleEdit = () => ( this.setState( { isEdit: !this.state.isEdit } ) );
        if ( !partyInfo && !this.state.isEdit ) {
            return (
                <div className="col-lg-12 text-center">
                    Look like you are not a account member.
                    Please ask to be added to one, or create a new account.
                    <p style={ { marginTop: 30 } }>
                        <Button type="button" className="btn btn-outline-info" onClick={ handleToggleEdit }>Create account</Button>
                    </p>
                </div>
            );
        }
        const formDataOrig = {};
      Object.keys(this.state.formData).map(key=>{
        // this is needed because form is initialised with null values which breaks the react-form validation
        formDataOrig[key] = this.state.formData[key] || undefined;
      });
        const { schema } = formTemplate;
        const uiSchema = {
            'ui:order': [
                'partyName',
                'partyType',
                '*',
                'street',
                'city',
                'postCode',
                'state',
                'country'
            ],
            partyName: {
                // 'ui:help': 'Your account name',
                'ui:readonly': !this.state.isEdit,
            },
            partyType: {
                'ui:readonly': !this.state.isEdit,
            },
            idCard: {
                'ui:readonly': !this.state.isEdit,
            },
            company: {
                'ui:readonly': !this.state.isEdit,
            },
            abnacn: {
                'ui:readonly': !this.state.isEdit,
                'ui:emptyValue': ''
            },
            subpremise: {
                'ui:readonly': !this.state.isEdit,
                'ui:emptyValue': ''
            },
            street: {
                'ui:readonly': !this.state.isEdit,
            },
            city: {
                'ui:readonly': !this.state.isEdit,
            },
            postCode: {
                'ui:readonly': !this.state.isEdit,
            },
            state: {
                'ui:readonly': !this.state.isEdit,
            },
            country: {
                'ui:readonly': !this.state.isEdit,
            },
            contactPerson: {
                'ui:readonly': !this.state.isEdit,
            },
            contactPersonEmail: {
                'ui:readonly': !this.state.isEdit,
            },
            contactPersonPhone: {
                'ui:readonly': !this.state.isEdit,
            },

        };
        const clearFormData = () => this.setState( {
            formData: partyInfo || {
                street: '', postCode: '', city: '', state: '', country: ''
            }
        } );
        const handleCancel = () => { this.setState( { isEdit: false, isCreate: false } ); clearFormData(); this.props.cancel(); };
        const handleToggleCreate = () => ( this.setState( { isCreate: !this.state.isCreate, isEdit: !this.state.isEdit } ) );
        const handleToggleDropdown = () => ( this.setState( { isDropdownOpen: !this.state.isDropdownOpen } ) );
        const handleModalToggle = () => { this.setState( { modal: !this.state.modal } ); };
        const handleDelete = () => {
            onDelete( formDataOrig );
            clearFormData();
            handleModalToggle();
            handleCancel();
        };
        const transformErrors = ( errors ) => errors.map( error => {
            if ( error.name === 'pattern' ) {
                error.message = 'Only digits are allowed';
            }
            return error;
        } );

        const mapSuggestionsToFormData = ( suggest, addressComponent, newFormData ) => {
            Object.assign( newFormData, { partyName: suggest.description.split( ',' )[0] } );
            switch ( true ) {
                case addressComponent.types.includes( 'floor' ) || addressComponent.types.includes( 'subpremise' ):
                    Object.assign( newFormData, { subpremise: addressComponent.long_name } );
                    break;
                case addressComponent.types.includes( 'street_number' ):
                    Object.assign( newFormData, { street: addressComponent.long_name } );
                    break;
                case addressComponent.types.includes( 'route' ):
                    Object.assign( newFormData, { street: `${newFormData.street} ${addressComponent.long_name}` } );
                    break;
                case addressComponent.types.includes( 'locality' ):
                    Object.assign( newFormData, { city: addressComponent.long_name } );
                    break;
                case addressComponent.types.includes( 'postal_code' ):
                    Object.assign( newFormData, { postCode: addressComponent.short_name } );
                    break;
                case addressComponent.types.includes( 'administrative_area_level_1' ):
                    Object.assign( newFormData, { state: addressComponent.long_name } );
                    break;
                case addressComponent.types.includes( 'country' ):
                    Object.assign( newFormData, { country: addressComponent.long_name } );
                    break;
                default:
                    // my code goes here
                    break;
            }
        };

        const onSuggestSelect = ( suggest ) => {
            if ( suggest === undefined ) return;
            const newFormData = { ...this.state.formData };

            suggest.gmaps.address_components.forEach( ( elem ) => mapSuggestionsToFormData( suggest, elem, newFormData ) );
            this.setState( { formData: newFormData } );
            this._geoSuggest.clear();
        };

        const EditMenu = ( () => (

            <Dropdown
                className="float-right"
                isOpen={ this.state.isDropdownOpen }
                toggle={ handleToggleDropdown }
                hidden={ this.state.isEdit }>
                <DropdownToggle caret>
                    <i className="fa fa-edit"></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={ handleToggleCreate } hidden={ formDataOrig.id }>
                        <i className="fa fa-plus"></i>
            create
                    </DropdownItem>
                    <DropdownItem onClick={ handleToggleEdit } hidden={ !formDataOrig.id }>
                        <i className="fa fa-edit"></i>
            edit
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )

        );

        if ( this.state.formData.country === 'United States' ) {
            const domEntity = document.querySelectorAll( '[for="root_abnacn"]' )[0];
            if ( typeof domEntity !== 'undefined' ) {
                console.warn('Using EIN')
                domEntity.innerText = 'EIN';
            }
        } else {
            const domEntity = document.querySelectorAll( '[for="root_abnacn"]' )[0];
            if ( typeof domEntity !== 'undefined' ) {
              console.warn('Using ABN / ACN')
                domEntity.innerText = 'ABN / ACN';
            }
        }
        return (
            <div className="">
                {/* <ConfirmDialog message="You have a notification pending" /> */}
                <div className="card-header pt-0">
                    <i className="fa fa-align-justify" />
                    {' '}
Party Information

                    { ( !this.state.isEdit && this.props.canAccess ) || ( !this.state.isEdit && Object.keys( partyInfo ).isEmpty() )
                        ? <EditMenu />
                        : <script />
                    }
                </div>
                <div className="centerContent">

                    {this.state.isError
                        ? (
                            <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        )
                        : <div />
                    }
                    {this.state.isEdit
                        ? (
                            <div style={ { width: '100%' } }>
                                <Geosuggest
                                // radius={ 10000 }
                                // country="au"
                                // location={ new root.google.maps.LatLng( -27.4674011, 153.2093 ) }
                                    onSuggestSelect={ onSuggestSelect }
                                    ref={ el => this._geoSuggest = el }
                                    placeholder="search an address or name of company"
                                />
                            </div>
                        )
                        : <div />
                    }

                    <Form
                        transformErrors={ transformErrors }
                        showErrorList={ false }
                        // FieldTemplate={ Tpl }
                        formData={ formDataOrig }
                        schema={ schema }
                        uiSchema={ uiSchema }
                        // className="col-lg-12"
                        // liveValidate={true}
                        // showErrorList={false}
                        onChange={ formData => { this.setState( { formData: formData.formData } ); } }
                        onSubmit={ formData => {
                            removeUndefinedValues( formData );
                            onSubmit( formData, uid );
                            handleCancel();
                        } }
                        onError={ log( 'errors' ) }>

                        <div className="form-actions">
                            <button
                                hidden={ !this.state.isEdit }
                                type="submit"
                                id="saveParty"
                                className="btn btn-primary float-right">
save
                            </button>
                            <button
                                hidden={ !this.state.isEdit }
                                type="button"
                                id="cancelParty"
                                className="btn btn-outline-info"
                                onClick={ handleCancel }>
cancel
                            </button>
                            <button
                                style={ { marginLeft: '10px' } }
                                hidden={ !this.state.isEdit || !formDataOrig.id }
                                type="button"
                                id="deleteParty"
                                className="btn btn-outline-danger"
                                onClick={ handleModalToggle }>
delete
                            </button>
                        </div>
                    </Form>
                    <img className="search-powered-by-google" alt="search-powered-by-google" src="../img/images/powered_by_google_on_white.png" />
                    <Modal isOpen={ this.state.modal } toggle={ handleModalToggle }>
                        <ModalHeader toggle={ handleModalToggle }>Delete Party</ModalHeader>
                        <ModalBody>
              Are you sure you want to delete party:
                            {' '}
                            <b>{formDataOrig.partyName}</b>
?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" id="confirmDeleteParty" onClick={ handleDelete }>Delete</Button>
                            {' '}
                            <Button color="secondary" id="confirmCancelDeleteParty" onClick={ handleModalToggle }>Cancel</Button>
                            <br />
                        </ModalFooter>
                    </Modal>

                </div>
            </div>

        );
    }
}

Party.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    log: PropTypes.func.isRequired,
    partyInfo: PropTypes.object.isRequired,
    uid: PropTypes.string.isRequired,
};

export default Party;

function Tpl( props ) {
    const { id, label, required, children } = props;
    return (
        <div className="col-sm-12 col-lg-6">
            <label htmlFor={ id }>
                {label}
                {required ? '*' : null}
            </label>
            {children}
        </div>
    );
}

function removeUndefinedValues( obj ) {
    Object.keys( obj.formData ).map( key => {
        if ( typeof obj.formData[key] === 'undefined' ) {
            obj.formData[key] = null;
        }
    } );
}
