import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';
import applicationActions from '../application/actions';

const { deleteResource } = actions;

const updateResource = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.updateResource( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Resource updated' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

export default {
    deleteResource,
    updateResource
};
