import types from './types';
import applicationTypes from '../application/types';
import { iterateTree } from './utils';
import { build } from '../../../utils/buildDataFromSubscription';
import canUserPerformAction from '../../../utils/acls/canUserPerformAction';
import { generateCan } from '../../../utils/Can';

const initialState = {
    categoryTree: { treeData: [], accountId: null, default: true, id: null },
    currentCategory: {},
    breadcrumbs: [],
    docTypes: [],
    readBreadcrumbs: [],
    readDocTypes: [],
    writeBreadcrumbs: [],
    writeDocTypes: []
};

const categoriesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT:
            return { ...initialState };
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'categories' ) {
                const categories = build( action.payload.data )[0] || { treeData: [], accountId: action.additionalData.currentAccount.id, default: true, id: null };
                const userGroups = action.additionalData.groups.filter( group => typeof group.members[action.additionalData.currentUser.id] !== 'undefined' );
                return calculate( action.additionalData.currentAccount.id, action.additionalData.currentUser, categories, userGroups );
            }
            // We need to fetch here the categories as well.
            if ( action.payload.target === 'groups' ) {
                const categories = action.additionalData.categories.categoryTree;
                const userGroups = build( action.payload.data ).filter( group => typeof group.members[action.additionalData.currentUser.id] !== 'undefined' );
                return calculate( action.additionalData.currentAccount.id, action.additionalData.currentUser, categories, userGroups );
            }
            return state;
        case types.SELECT_CATEGORY_FOR_DOC_DISPLAY:
            return { ...state, currentCategory: action.payload.body || {} };
        case types.CATEGORIES_RESET:
            const extra2 = {};
            extra2.currentUser = action.additionalData.currentUser;
            extra2.accountId = action.additionalData.currentAccount.id;
            const { breadcrumbs, writeBreadcrumbs, readBreadcrumbs, readDocTypes, writeDocTypes, docTypes } = iterateTree( action.payload.treeData, extra2 );
            return { ...state, breadcrumbs, writeBreadcrumbs, readBreadcrumbs, docTypes, readDocTypes, writeDocTypes };
        default:
            return state;
    }
};

const categories = categoriesReducer;

export default categories;


function calculate( accountId, currentUser, cat, userGroups ) {
    const extra = {
        currentUser,
        accountId
    };
    if ( !currentUser ) {
        return {
            categoryTree: [],
            currentCategory: '',
            breadcrumbs: [],
            docTypes: [],
            readBreadcrumbs: [],
            readDocTypes: [],
            writeBreadcrumbs: [],
            writeDocTypes: [],
        };
    }
    let { isOwner } = currentUser;
    const acls = currentUser.acl;
    if ( !isOwner ) {
        isOwner = currentUser.isAdmin;
    }
    const currentCat = cat;
    const data = {
        categoryTree: currentCat,
        currentCategory: '',
        breadcrumbs: [],
        docTypes: [],
        readBreadcrumbs: [],
        readDocTypes: [],
        writeBreadcrumbs: [],
        writeDocTypes: [],
    };

    if ( acls.length === 0 ) {
    // user does not have any acls.
        data.categoryTree.treeData = [];
        return { ...data };
    }

    if ( userGroups.length === 0 ) {
    // user is not assigned to a group
        data.categoryTree = [];
        return { ...data };
    }

    let treeData = [];
    if ( isOwner ) {
        treeData = cat.treeData;
    } else {
        // here will have to iterate every node to check if user has access to it.
        const { abilities } = generateCan( currentUser.acl );
        currentCat.treeData.map( catNode => {
            if ( canUserPerformAction( 'read', abilities, accountId, catNode.name.toLocaleLowerCase() ) ) {
                // user has access to this entire category node.
                treeData.push( catNode );
            }
        } );
    }

    const { breadcrumbs, writeBreadcrumbs, readBreadcrumbs, readDocTypes, writeDocTypes, docTypes } = iterateTree( treeData, extra );
    data.categoryTree.treeData = treeData;
    data.breadcrumbs = breadcrumbs;
    data.writeBreadcrumbs = writeBreadcrumbs;
    data.readBreadcrumbs = readBreadcrumbs;
    data.docTypes = docTypes;
    data.readDocTypes = readDocTypes;
    data.writeDocTypes = writeDocTypes;
    return { ...data };
}
