import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';

import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown
} from 'reactstrap';
import Display from '../Display';
import { sortArrayByProperty } from '../../utils/sortArrayByProperty';
import { useAuth0 } from '../../auth0_provider';
import { applicationOperations } from '../../redux/ducks/application';

function buildAccountselectionOptions( accounts ) {
    return accounts.sort( ( a, b ) => sortArrayByProperty( a, b, 'accountName' ) ).map( account => (
        <option
            key={ Math.random() }
            value={ account.id }>{account.accountName}
        </option>
    ) );
}

const HeaderDropdown = ( props ) => {
    const [ dropdownOpen, setIsOpen ] = useState( false );
    const [ selectedAccount, uiSelectAccount ] = useState( props.currentAccount ? props.currentAccount.id : '' );
    const { user, logout, loginWithRedirect } = useAuth0();
    const { currentUser, accounts, selectAccount, history, inMaintenance = false } = props;
    const handleInputChange = ( accountId ) => {
        uiSelectAccount( accountId );
        window.localStorage.setItem( 'accountId', accountId );
        selectAccount( accounts.having( 'id', accountId )[0] );
        setTimeout( () => {
            setIsOpen( false );
            if ( inMaintenance ) {
                window.location.href = '/dashboard';
            }
        }, 600 );
    };
    const goToSettings = () => history.push( '/settings' );

    const goToAccount = () => history.push( '/account' );

    const gotToGroups = () => history.push( '/groups' );

    const goToProfile = () => history.push( '/profile' );

    const logoutHandler = ( ) => {
        logout();
    };
    return (
        <div>
            <Dropdown isOpen={ dropdownOpen } toggle={ () => setIsOpen( !dropdownOpen ) } className="dropdownContainer">
                <DropdownToggle caret color="white">
                    <div className="avatar" style={ { marginRight: '5px' } }>
                        <Display when={ user }>
                            <div>
                                <Display when={ user.picture === null }>
                                    <Gravatar
                                        email={ user ? user.email : 'anonymous@anon.ous' }
                                        className="img-avatar" />
                                </Display>
                                <Display when={ user.picture !== null }>
                                    <img alt="" style={ { maxHeight: 36, borderRadius: '50%' } } src={ user.picture } />
                                </Display>
                            </div>
                        </Display>
                    </div>
                    <span className="userEmail">{user ? user.email : 'Login'}</span>
                </DropdownToggle>
                <DropdownMenu right>
                    <Display when={ user }>
                        <div>
                            <Display when={ accounts.hasItems() }>
                                <div>
                                    <DropdownItem header tag="div" className="text-center">
                                        <strong>Account selection</strong>
                                    </DropdownItem>
                                    <select
                                        className="form-control accountSelect"
                                        value={ selectedAccount }
                                        onChange={ ( event ) => {
                                            handleInputChange( event.target.value );
                                        } }>
                                        { buildAccountselectionOptions( accounts, selectAccount ) }
                                    </select>
                                </div>
                            </Display>
                            <Display when={ !inMaintenance }>
                                <React.Fragment>
                                    <Display when={ currentUser && ( currentUser.isAdmin || currentUser.isOwner ) }>
                                        <React.Fragment>
                                            <DropdownItem header tag="div" className="text-center"><strong>General</strong></DropdownItem>
                                            <div>
                                                <DropdownItem onClick={ goToSettings } id="docConfigBtn"><i className="fa fa-gears"></i>
                        Configuration
                                                </DropdownItem>
                                            </div>
                                        </React.Fragment>
                                    </Display>
                                    <DropdownItem header tag="div" className="text-center">
                                        <strong>Settings</strong>
                                    </DropdownItem>
                                    <Display when={ currentUser && ( currentUser.isAdmin || currentUser.isOwner ) }>
                                        <DropdownItem onClick={ goToAccount } id="accountConfigBtn">
                                            <i className="fa fa-wrench" />Accounts
                                        </DropdownItem>
                                    </Display>
                                    <DropdownItem onClick={ gotToGroups } id="accountConfigBtn">
                                        <i className="fa fa-wrench" />Groups
                                    </DropdownItem>
                                </React.Fragment>
                            </Display>
                            <DropdownItem onClick={ goToProfile } id="profileBtn">
                                <i className="fa fa-user" /> Profile
                            </DropdownItem>
                            <DropdownItem id="logoutBtn" onClick={ logoutHandler }>
                                <i className="fa fa-lock" /> Logout
                            </DropdownItem>
                        </div>
                    </Display>
                    <Display when={ !user }>
                        <DropdownItem onClick={ loginWithRedirect }>
                            <i className="fa fa-key" /> Login
                        </DropdownItem>
                    </Display>
                    <DropdownItem
                        header
                        tag="div"
                        className="text-center"><strong>{'[AIV]v{version} - {date}[/AIV]'}</strong>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

HeaderDropdown.propTypes = {
    history: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    accounts: PropTypes.array.isRequired,
    selectAccount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ( {
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
} );
export default connect( mapStateToProps, mapDispatchToProps )( HeaderDropdown );
