import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';

// This is a link to an action defined in actions.js.
const { } = actions;

const getVersions = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { versions } = getState();
    if ( !versions ) {
        document.body.classList.remove( 'aside-menu-hidden' );
    } else {
        const { collection, element } = versions;
        let currentId = element.id;
        let newId = data.element.id;
        if ( collection === 'filesMeta' ) {
            currentId = element.fileId;
            newId = data.element.fileId;
        }
        if ( collection !== data.collection ) {
            if ( !document.body.classList.contains( 'aside-menu-hidden' ) ) {
                document.body.classList.toggle( 'aside-menu-hidden' );
                setTimeout( () => {
                    document.body.classList.toggle( 'aside-menu-hidden' );
                }, 500 );
            } else {
                document.body.classList.toggle( 'aside-menu-hidden' );
            }
        } else {
            if ( currentId === newId ) {
            // clicks on same element;
                if ( !document.body.classList.contains( 'aside-menu-hidden' ) ) {
                    document.body.classList.toggle( 'aside-menu-hidden' );
                    return;
                }
                document.body.classList.toggle( 'aside-menu-hidden' );
            }
            if ( document.body.classList.contains( 'aside-menu-hidden' ) ) {
                document.body.classList.toggle( 'aside-menu-hidden' );
            } else {
                document.body.classList.toggle( 'aside-menu-hidden' );
                setTimeout( () => {
                    document.body.classList.toggle( 'aside-menu-hidden' );
                }, 500 );
            }
        }
    }

    dispatch( actions.getVersions( data, { user, collection: data.collection, element: data.element } ) )
        .then(
            ( res ) => {
                // dispatch( messageOperations.showSuccess( 'Invitation updated' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const restore = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { collection, element } = getState().versions;
    dispatch( actions.restore( data, { user } ) )
        .then(
            ( res ) => {
                setTimeout( () => { dispatch( actions.getVersions( data, { user, collection, element } ) ); }, 1000 );
                dispatch( messageOperations.showSuccess( 'Restore complete' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const restoreFile = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.restoreFile( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Restore of file complete' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );


export default {
    getVersions,
    restoreFile,
    restore
};
