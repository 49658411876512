const { RRule } = require( 'rrule' );

const buildRule = ( data ) => {
    const { dueTime, repeatData, date } = data;
    const { frequency, repeatOptions, endRepeatOptions } = repeatData;
    const { interval, byweekday } = repeatOptions;

    const ruleObject = {
        freq: RRule[frequency.toUpperCase()],
        dtstart: new Date( date ),
    };

    if ( typeof interval !== 'undefined' ) {
        ruleObject.interval = Number( interval );
    }
    if ( typeof endRepeatOptions !== 'undefined' ) {
        const { count, until } = endRepeatOptions;
        if ( count ) {
            ruleObject.count = Number( count );
        } else {
            ruleObject.until = new Date( until );
        }
    }
    if ( typeof byweekday !== 'undefined' ) {
        ruleObject.byweekday = byweekday.map( ( entry ) => RRule[entry.toUpperCase()] );
        if ( byweekday.length > 1 && typeof ruleObject.until === 'undefined' ) {
            // means that we have by weekday without an end Date
            ruleObject.count *= byweekday.length;
        }
    }
    if ( typeof dueTime !== 'undefined' && Number( dueTime.hour ) !== 0 && Number( dueTime.minutes ) !== 0 ) {
        ruleObject.byhour = [ Number( dueTime.hour ) ];
        ruleObject.byminute = [ Number( dueTime.minutes ) ];
    }
    const rule = new RRule( ruleObject );
    const ruleText = rule.toText();
    return { rule, ruleText, ruleObject };
};

module.exports = buildRule;

function isLastDay( dt ) {
    const test = new Date( new Date( dt ).getTime() );
    test.setDate( test.getDate() + 1 );
    return test.getDate() === 1;
}
