import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Modal, ModalHeader,
    ModalBody, ModalFooter, Button, Alert
} from 'reactstrap';

import Display from '../Display';
import { optionsOperations } from '../../redux/ducks/options';
import SpinnerModal from '../Modals/SpinnerModal';
import { documentTypesOperations } from '../../redux/ducks/documentTypes';
import { sortAscendingByOptionTitle } from '../../utils/sortAscendingByOptionTitle';
import { cleanSpecialChars, fullStringClean } from '../../utils/cleanString';
import SortableListContent from './SortableListContent';

class OptionBuilder extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            step: 1,
            completed: [],
            items: [],
            name: '',

            mandatory: false,
            savingInProgress: false,
            optionType: '',
            location: '',
            edit: false
        };
        this.onChange = this.onChange.bind( this );
        this.nextStep = this.nextStep.bind( this );
        this.gotToStep = this.gotToStep.bind( this );
        this.save = this.save.bind( this );
        this.determineStepStatus = this.determineStepStatus.bind( this );
        this.handleInputChange = this.handleInputChange.bind( this );
        this.buildMandatoryField = this.buildMandatoryField.bind( this );
        this.addValueToList = this.addValueToList.bind( this );
        this.createNewFolder = this.createNewFolder.bind( this );
        this.sortListContent = this.sortListContent.bind( this );
        this.removeListEntry = this.removeListEntry.bind( this );
    }

    UNSAFE_componentWillMount() {
        if ( this.props.selectedOption ) {
            this.setState( {
                edit: true,
                name: this.props.selectedOption.title,
                mandatory: this.props.selectedOption.mandatory,
                optionType: this.props.selectedOption.optionType,
                location: this.props.selectedOption.folderName,
                optionId: this.props.selectedOption.id,
                docTypeId: this.props.selectedOption.docTypeId
            } );
            if ( this.props.selectedOption.optionType === 'list' ) {
                const data = this.props.selectedOption.enumNames.map( ( val, index ) => ( { id: this.props.selectedOption.enum[index], title: val } ) );
                this.setState( {
                    items: data
                } );
            }
        }
    }

    onChange( color ) {
        this.setState( { color: color.hex } );
    }

    nextStep() {
        if ( this.state.step === 1 ) {
            if ( this.state.name.trim() === '' ) {
                document.getElementById( 'name' ).classList.add( 'error' );
                this.setState( { error: 'Please add a value' } );
                return;
            }
            if ( this.state.name.length !== cleanSpecialChars( this.state.name ).length ) {
                document.getElementById( 'name' ).classList.add( 'error' );
                this.setState( { error: 'Name can only contain letters, numbers, comma, dash or underscore.' } );
                return;
            }
            this.setState( { name: fullStringClean( this.state.name ) } );
        }
        if ( this.state.step === 3 ) {
            if ( this.state.optionType.trim() === '' ) {
                document.getElementById( 'optionType' ).classList.add( 'error' );
                this.setState( { error: 'Please select a value' } );
                return;
            }
            if ( this.state.optionType === 'list' && this.state.items.length === 0 ) {
                this.setState( { errorOptionName: 'Cannot save an empty list' } );
                return;
            }
        }
        this.setState( { step: this.state.step === 1 ? this.state.step + 2 : this.state.step + 1, completed: [ ...this.state.completed, this.state.step ], error: null } );
    }

    gotToStep( no ) {
        if ( !this.state.completed.contains( no ) ) {
            return;
        }
        this.setState( { step: no } );
    }

    save() {
        if ( this.state.location === '' || this.state.location === 'select a folder' ) {
            document.getElementById( 'location' ).classList.add( 'error' );
            this.setState( { error: 'Please select a value' } );
            return;
        }
        if ( this.state.error ) {
            this.setState( { error: null } );
        }
        if ( this.state.savingInProgress ) {
            return;
        }
        this.setState( { savingInProgress: true } );
        const data = {
            option: {
                title: this.state.name,
                mandatory: this.state.mandatory,
                folderName: this.state.location,
                optionType: this.state.optionType,
                deleted: false
            },
            accountId: this.props.currentAccount.id
        };
        if ( this.state.optionType === 'list' ) {
            if ( this.state.optionType === 'list' && this.state.items.length === 0 ) {
                this.setState( { errorOptionName: 'Cannot save an empty list', savingInProgress: false } );
                return;
            }
            data.option.enum = [];
            data.option.enumNames = [];
            this.state.items.map( entry => {
                data.option.enum = [ ...data.option.enum, entry.id ];
                data.option.enumNames = [ ...data.option.enumNames, entry.title ];
            } );
        }

        if ( this.state.edit ) {
            data.optionId = this.state.optionId;
            if ( this.state.docTypeId ) {
                data.docTypeId = this.state.docTypeId;
            }
        }
        const that = this;
        if ( that.props.assign ) {

        }
        this.props.saveOption( data )
            .then( ( res ) => {
                if ( that.props.assign ) {
                    const selectedDocType = that.props.docTypesList.having( 'name', that.props.selectedDocType )[0];
                    if ( res.data.option ) {
                        selectedDocType.options = [ ...selectedDocType.options, res.data.option ];
                        const newData = {
                            doc: selectedDocType,
                            accountId: selectedDocType.accountId
                        };
                        this.props.saveDocument( newData );
                    }
                }
                this.setState( { savingInProgress: false, open: false, items: [] } );
                this.props.toggle();
            } )
            .catch( () => { this.setState( { savingInProgress: false } ); } );
    }

    determineStepStatus( no ) {
        if ( this.state.completed.contains( no ) ) {
            return 'done';
        }
        if ( this.state.step === no ) {
            return 'doing';
        }
        return 'todo';
    }

    handleInputChange( event ) {
        // don't allow space at beginning
        if ( event.target.name === 'name' && this.state.name === '' && ( event.target.value === ' ' || event.target.value === 'select' ) ) {
            return;
        }
        if ( event.target.name === 'location' && event.target.value !== 'select' ) {
            this.setState( {
                location: event.target.value,
                error: null,
                completed: [ ...this.state.completed, this.state.step ]
            } );
        }
        if ( event.target.name === 'existingValue' ) {
            this.setState( { name: '' } );
        }
        if ( event.target.name === 'mandatory' ) {
            this.setState( { existingValue: 'default' } );
        }
        const { target } = event;
        let value = target.name === 'mandatory' ? JSON.parse( target.value ) : target.value;
        const { name } = target;
        if ( name === 'location' ) {
            value = fullStringClean( value );
        }
        this.setState( {
            [name]: value,
            error: null
        } );
        if ( document.getElementById( name ) ) {
            document.getElementById( name ).classList.remove( 'error' );
        }
    }

    buildMandatoryField() {
        const classes1 = this.state.mandatory ? 'btn-outline-primary' : 'btn-outline-secondary';
        const classes2 = !this.state.mandatory ? 'btn-outline-primary' : 'btn-outline-secondary';
        return (
            <div className="centerKids">
                <button
                    name="mandatory"
                    value
                    className={ `btn ${classes1} btn-block booleanOption m-0 mr-2` }
                    onClick={ this.handleInputChange }>
Yes
                </button>
                <button
                    name="mandatory"
                    value={ false }
                    className={ `btn ${classes2} btn-block booleanOption m-0` }
                    onClick={ this.handleInputChange }>
No
                </button>
            </div>
        );
    }

    addValueToList() {
        const valueInput = fullStringClean( document.getElementById( 'listValue' ).value );
        if ( valueInput === '' ) {
            return;
        }
        const id = valueInput.trim().replace( / /g, '_' ).toLowerCase();
        if ( this.state.items.having( 'id', id ).hasItems() ) {
            this.setState( { errorOptionName: 'This value already exists' } );
            return;
        }
        const newList = [ { id, title: fullStringClean( document.getElementById( 'listValue' ).value ) }, ...this.state.items ];
        this.setState( { items: newList, error: null, errorOptionName: null } );
        document.getElementById( 'listValue' ).value = '';
    }

    removeListEntry( id ) {
        this.setState( { items: this.state.items.except( 'id', id ) } );
    }

    createNewFolder() {
        this.setState( { newFolder: true } );
    }

    sortListContent( items ) {
        this.setState( { items } );
    }

    render() {
        const { folders } = this.props;
        const folderList = [ 'select a folder', ...folders ];
        const foldersDom = folderList.map( folder => <option key={ Math.random() } value={ folder }>{folder}</option> );
        return (
            <Modal isOpen={ this.props.open } toggle={ this.props.toggle } className="optionsBuilderModal">
                <ModalHeader toggle={ this.props.toggle } style={ { width: '100%' } }>
                    <Display when={ this.state.edit }>
                      Edit form field
                    </Display>
                    <Display when={ !this.state.edit }>
                        <ol className="buildOption">
                            <li
                                className={ `buildOption-${this.determineStepStatus( 1 )}` }
                                onClick={ ( () => { this.gotToStep( 1 ); } ) }>
                                <em>1</em>
                                <span>Title</span>
                            </li>
                            <li
                                className={ `buildOption-${this.determineStepStatus( 3 )}` }
                                onClick={ ( () => { this.gotToStep( 3 ); } ) }>
                                <em>3</em>
                                <span>Type</span>
                            </li>
                            <li
                                className={ `buildOption-${this.determineStepStatus( 4 )}` }
                                onClick={ ( () => { this.gotToStep( 4 ); } ) }>
                                <em>3</em>
                                <span>Category/Sub-Category</span>
                            </li>
                        </ol>
                    </Display>
                </ModalHeader>
                <ModalBody>
                    <Display when={ ( this.state.step === 1 || this.state.step === 3 ) && !this.state.edit }>
                        <span className="mandatory">mandatory field</span>
                    </Display>
                    <Display when={ this.state.step === 1 || this.state.edit }>
                        <div id="name" className="">
                            <p>Let's add a name for this new field:</p>
                            <input
                                name="name"
                                autoComplete="off"
                                type="input"
                                className={ `form-control ${this.state.name !== '' ? '' : 'blank'}` }
                                value={ this.state.name }
                                onChange={ this.handleInputChange } />
                            <span className="text-danger">{ this.state.error }</span>
                        </div>
                    </Display>
                    <br />
                    <Display when={ this.state.step === 3 || this.state.edit }>
                        <div id="optionType" className="listBuilding">
                            <p>What type of field is it:</p>
                            <select
                                className="form-control"
                                name="optionType"
                                value={ this.state.optionType }
                                onChange={ this.handleInputChange }>
                                <option value="default">select a value</option>
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="boolean">Yes/No</option>
                                <option value="currency">Currency</option>
                                <option value="pip">Period (PIP)</option>
                                <option value="pid">Date (PID)</option>
                                <option value="list">List</option>
                            </select>
                            <span className="text-danger">{ this.state.error }</span>
                            <Display when={ this.state.optionType === 'list' && this.props.editGeneral }>
                                <div className="row mt-3">
                                    <div className="centerKids" style={ { width: '100%' } }>
                                        <Alert color="info">List options can be defined per document type.</Alert>
                                    </div>
                                </div>
                            </Display>
                            <Display when={ this.state.optionType === 'list' && !this.props.editGeneral }>
                                <div className="row mt-3">
                                    <div className="centerKids" style={ { width: '100%' } }>
                                        <Display when={ !this.state.errorOptionName }>
                                            <Alert color="info">Define here the values as you want them to appear in the list.</Alert>
                                        </Display>
                                        <Display when={ this.state.errorOptionName }>
                                            <Alert color="danger">{ this.state.errorOptionName }</Alert>
                                        </Display>
                                    </div>

                                    <div className="col-lg-6">
                                        <span>List value</span>
                                        <div className="searchContainer">
                                            <input
                                                id="listValue"
                                                autoComplete="off"
                                                type="input"
                                                className={ `form-control ${this.state.name !== '' ? '' : 'blank'}` }
                                            />
                                            <span className="listEntryAdd" onClick={ this.addValueToList } />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <SortableListContent
                                            items={ this.state.items }
                                            update={ this.sortListContent }
                                            removeAction={ this.removeListEntry }
                                        />
                                    </div>
                                </div>
                            </Display>

                        </div>
                    </Display>
                    <br />
                    <Display when={ this.state.step === 4 || this.state.edit }>
                        <div className="">
                            <p>Where do you want to place it ?</p>
                            <span className="newFolder" onClick={ this.createNewFolder } />
                            { this.state.newFolder
                                && (
                                    <div id="location" className="">
                                        <p>Folder name:</p>
                                        <input
                                            name="location"
                                            autoComplete="off"
                                            type="input"
                                            className={ `form-control ${this.state.name !== '' ? '' : 'blank'}` }
                                            value={ this.state.location }
                                            onChange={ this.handleInputChange } />
                                        <span className="text-danger">{ this.state.error }</span>
                                    </div>
                                )
                            }
                            { !this.state.newFolder
                                && (
                                    <div id="location">
                                        <select
                                            className="form-control"
                                            name="location"
                                            value={ this.state.location }
                                            onChange={ this.handleInputChange }>
                                            { foldersDom }
                                        </select>
                                        <span className="text-danger">{ this.state.error }</span>
                                    </div>
                                )
                            }
                        </div>
                    </Display>

                </ModalBody>
                <ModalFooter>
                    <Display when={ this.state.step === 4 || this.state.edit }>
                        <Button onClick={ this.save }>
                            { this.state.savingInProgress
                                && <span>...saving</span>
                            }
                            { !this.state.savingInProgress
                                && <span>Save</span>
                            }
                        </Button>
                    </Display>
                    <Display when={ this.state.step !== 4 && !this.state.edit }>
                        <Button onClick={ this.nextStep }>Next</Button>
                    </Display>
                    <SpinnerModal showSpinner={ this.state.savingInProgress } />
                </ModalFooter>
            </Modal>
        );
    }
}

OptionBuilder.propTypes = {
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    folders: PropTypes.array.isRequired,
    currentAccount: PropTypes.object.isRequired,
    assign: PropTypes.bool,
    // setAccountId: PropTypes.func.isRequired,
};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    folders: state.options.folders,
    selectedDocType: state.documentTypes.selectedFolder,
    docTypesList: state.documentTypes.list,
    breadcrumbs: state.categories.breadcrumbs
} );

const mapDispatchToProps = dispatch => ( {
    saveDocument: ( data ) => dispatch( documentTypesOperations.saveDocument( data ) ),
    saveOption: ( data ) => dispatch( optionsOperations.saveOption( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( OptionBuilder );
