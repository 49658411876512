import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';

const initialState = [];

const invites = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'invites' ) {
                return build( action.payload.data );
            }
        default: return state;
    }
};

export default invites;
