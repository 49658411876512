import types from './types';

const saveOption = ( data, extraData ) => {
    const body = data;
    return {
        type: types.SAVE_OPTION,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/option',
            method: 'POST',
            body
        }
    };
};

const recalculate = ( ) => ( {
    type: types.RECALCULATE
} );

const deleteOption = ( data, extraData ) => {
    const body = data;
    return {
        type: types.SAVE_OPTION,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/option',
            method: 'DELETE',
            body
        }
    };
};

export default {
    saveOption,
    deleteOption,
    recalculate
};
