import React from 'react';
import PropTypes from 'prop-types';

const DashCountCard = ( { color, title, icon, count } ) => (
    <div className="white-box" style={ { margin: '15px 0', cursor: 'pointer' } }>
        <h4 className="text-left">{title}</h4>
        <div className="row" style={ { height: '50px', marginTop: '12px' } }>
            <div className="col-4 text-primary text-left font-5xl" style={ { verticalAlign: 'bottom', marginTop: '10' } }>{count}</div>
            <div className="col-8 text-right">
                <span>
                    <div className={ `${icon}` } style={ { fontSize: '43px', color, marginTop: '10px' } } />
                </span>
            </div>
        </div>
    </div>
);


DashCountCard.defaultProps = {
    color: 'text-primary',
    icon: 'fa fa-file-o',
    count: 0
};

DashCountCard.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    count: PropTypes.number,
};

export default DashCountCard;
