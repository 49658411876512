import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Display from '../Display';
import TooltipWrapper from '../Tooltip/Tooltip';
import { messageOperations } from '../../redux/ducks/messages';

class MiniSpinner extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.props.queue.hasItems() && nextProps.queue.isEmpty() ) {
            this.props.uploadCompleted();
        }
    }
    render() {
        if ( !this.props.uploading ) {
            return <script />;
        }
        return (
            <div
                id="uploadMiniSpinner"
                className="uploadSpinner green"
                onClick={ () => { document.getElementById( 'uploadStatusModalTrigger' ).click(); } }>
                <Display when={ this.props.queue.hasItems() && this.props.uploading }>
                    <div className="percentage fa fa-cloud-upload" />
                </Display>
                <TooltipWrapper refId="uploadMiniSpinner" content="Uploading files" />
            </div>
        );
    }
}

const { func, array, bool } = PropTypes;

MiniSpinner.defaultProps = {
};

MiniSpinner.propTypes = {
    queue: array.isRequired,
    uploading: bool.isRequired,
    uploadCompleted: func.isRequired,
};

const mapStateToProps = state => ( {
    queue: state.uploads.queue,
    uploading: state.uploads.uploadingFiles
} );

const mapDispatchToProps = dispatch => ( {
    uploadCompleted: ( ) => dispatch( messageOperations.showSuccess( 'Upload complete' ) ),
} );


export default connect( mapStateToProps, mapDispatchToProps )( MiniSpinner );
