import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { invitesOperations } from '../../redux/ducks/invites';
import Party from '../../components/Party/Party';
import { partiesOperations } from '../../redux/ducks/parties';
import { cleanSpecialChars, fullStringClean } from '../../utils/cleanString';

class PartiesModal extends Component {
    static log( form ) {
    }

    constructor() {
        super();
        this.state = {
            open: false,
            new: false
        };
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
    }

    componentWillUnmount() {
        const { currentParty } = this.props;
        if ( Object.keys( currentParty ).hasItems() ) {

        }
    }

    onSubmit = ( form, ownerRef ) => {
    // if the form data has an id - its an update
        const data = form.formData;
        if ( data.email ) {
            data.email = data.email.toLocaleLowerCase();
        }
        if ( data.contactPersonEmail ) {
            data.contactPersonEmail = data.contactPersonEmail.toLocaleLowerCase();
        }

        if ( data.partyName.length !== cleanSpecialChars( data.partyName ).length ) {
            form.formData.partyName = fullStringClean( form.formData.partyName );
        }

        if ( !data.id ) {
            this.create( form, ownerRef );
        } else {
            this.update( form );
        }
    };

    onDelete = form => {
        this.props.deleteParty( { partyId: form.id, accountId: this.props.currentAccount.id, ...form } )
            .then( () => {
                this.setState( { isError: false, errorMessage: '', open: false } );
                this.props.selectParty( {} );
            } )
            .catch( ( error ) => {
                this.setState( { isError: true, errorMessage: 'Error deleting account !' } );
                console.error( error );
            } );
    };

    update = form => {
        const payload = {
            ...form.formData,
            accountId: this.props.currentAccount.id
        };
        this.props.updateParty( payload )
            .then( () => {
                this.setState( { isError: false, errorMessage: '', open: false } );
                this.props.selectParty( {} );
            } )
            .catch( ( error ) => {
                this.setState( { isError: true, errorMessage: 'Error updating party !' } );
                console.error( error );
            } );
    };

    create = form => {
        const payload = {
            ...form.formData,
            accountId: this.props.currentAccount.id
        };
        this.props.createParty( payload )
            .then( () => {
                this.setState( { isError: false, errorMessage: '', open: false } );
                this.props.selectParty( {} );
            } )
            .catch( ( error ) => {
                this.setState( { isError: true, errorMessage: 'Error creating party !' } );
                console.error( error );
            } );
    };

    toggle = () => {
        this.setState( { open: !this.state.open } );
        this.props.selectParty( {} );
    };

    cancel = () => {
        this.setState( { open: !this.state.open } );
    };

    render() {
        const { currentParty, currentUser, hideBtn } = this.props;
        const canAccess = currentUser ? !currentUser.isReadOnly : false;
        const title = Object.keys( currentParty ).isEmpty() ? 'Create party' : 'Edit party';
        return (
            <div className="animated fadeIn" style={ { minHeight: '50px' } }>
                <Button
                    style={ hideBtn ? { marginTop: '10px', display: 'none' } : { marginTop: '10px' } }
                    id="create_new_party"
                    color="primary mb-2 pull-right"
                    onClick={ () => { this.setState( { open: true, new: true } ); } }>
Create new party
                </Button>
                <Modal isOpen={ this.state.open } toggle={ this.toggle } className="partyModal">
                    <ModalHeader toggle={ this.toggle }>
                        { title }
                    </ModalHeader>
                    <ModalBody>
                        <Party
                            canAccess={ canAccess }
                            onSubmit={ this.onSubmit }
                            onDelete={ this.onDelete }
                            cancel={ this.toggle }
                            partyInfo={ currentParty }
                            log={ PartiesModal.log }
                            uid={ currentUser.uid }
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const { object, array, func, bool } = PropTypes;

PartiesModal.defaultProps = {
    currentParty: object.isRequired || null.isRequired,
    hideBtn: false,
};

PartiesModal.propTypes = {
    currentParty: object.isRequired || null.isRequired,
    invites: array.isRequired,
    deleteInvite: func.isRequired,
    hideBtn: bool
};


const mapStateToProps = state => ( {
    currentParty: state.parties.currentParty,
    currentUser: state.currentUser,
    currentAccount: state.currentAccount,
    invites: state.invites
} );

const mapDispatchToProps = dispatch => ( {
    deleteInvite: ( data ) => dispatch( invitesOperations.deleteInvite( data ) ),
    selectParty: ( data ) => dispatch( partiesOperations.selectParty( data ) ),
    createParty: ( data ) => dispatch( partiesOperations.createParty( data ) ),
    updateParty: ( data ) => dispatch( partiesOperations.updateParty( data ) ),
    deleteParty: ( data ) => dispatch( partiesOperations.deleteParty( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( PartiesModal );
