import types from './types';

const addCategory = ( data, extraData ) => {
    const body = data;
    return {
        type: types.ADD_CATEGORY,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/category',
            method: 'POST',
            body
        }
    };
};
const triggerDocumentCategoryUpdate = ( data, extraData ) => ( {
    type: types.UPDATE_DOCUMENTS_CATEGORIES,
    additionalData: extraData,
    async: true,
    generalFetching: true,
    payload: {
        path: '/handleCatChangeForDocuments',
        method: 'POST',
        body: data
    }
} );
const resetDefaultCategoryData = ( data, extraData ) => ( {
    type: types.ADD_CATEGORY,
    additionalData: extraData,
    async: true,
    generalFetching: true,
    payload: {
        path: '/resetCategory',
        method: 'POST',
        body: data
    }
} );

const deleteCategory = ( data, extraData ) => {
    const body = data;
    return {
        type: types.DELETE_CATEGORY,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/category',
            method: 'DELETE',
            body
        }
    };
};

const selectCategoryForDocDisplay = ( data ) => {
    const body = data;
    return {
        type: types.SELECT_CATEGORY_FOR_DOC_DISPLAY,
        payload: {
            body
        }
    };
};
const resetCategories = ( data, extraData ) => ( {
    type: types.CATEGORIES_RESET,
    additionalData: extraData,
    payload: data

} );

export default {
    addCategory,
    deleteCategory,
    selectCategoryForDocDisplay,
    triggerDocumentCategoryUpdate,
    resetDefaultCategoryData,
    resetCategories
};
