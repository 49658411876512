/* eslint-disable react/jsx-boolean-value,no-restricted-globals,prefer-destructuring */
import React, { Component } from 'react';
import Form from 'react-jsonschema-form';
import { connect } from 'react-redux';
import { Alert, Col, Row } from 'reactstrap';
import { arrayMove } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import uuidv1 from 'uuid/v1';
import { Prompt } from 'react-router-dom';

import qs from 'query-string';
import AlertsList from '../Alerts/AlertsList';
import {
    log, buildContractFields, fillDefault, composeDataForExistingDoc, buildPartiesDOM
} from './helpers';

import { documentOperations } from '../../redux/ducks/documents';
import CalendarEventModal from '../Modals/CalendarEventModal';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import messageOperations from '../../redux/ducks/messages/operations';
import GroupedSelector from './GroupedSelector';
import DocumentDateIntervalSelector from './DocumentDateIntervalSelector';
import DateSelectorWrapper from './DocumentDateSelector';
import PartyAssignModal from '../Modals/PartyAssignModal';
import CustomizeContractFieldsModal from '../Modals/CustomizeContractFieldsModal';
import DocumentUploadModal from '../Modals/DocumentUploadModal';
import FileList from '../File/FileList';

import Display from '../Display';
import Summary from './Summary';
import { uploadsOperations } from '../../redux/ducks/uploads';
import SpinnerModal from '../Modals/SpinnerModal';
import TooltipWrapper from '../Tooltip/Tooltip';

import db from '../../utils/indexDB';
import BooleanStatement from './BooleanStatement';
import { generateCan } from '../../utils/Can';
import canUserPerformAction from '../../utils/acls/canUserPerformAction';
import RichTextField from './RichTextField';
import 'react-quill/dist/quill.bubble.css';
import { versionOperations } from '../../redux/ducks/version';
import { fullStringClean } from '../../utils/cleanString';
import VaultBreadcrumb from '../Breadcrumb/VaultBreadcrumb';
const deepDiff = require( 'deep-diff' );

let contractType;
let changedField;
let filesX = [];
function detectFieldClick( field, value ) {
    if ( field === 'root_contractType' ) {
        contractType = value;
        changedField = field;
    } else {
        changedField = undefined;
    }
}

class DocumentAdd extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            schema: {
                type: 'object',
                required: [],
                properties: {}
            },
            uiSchema: {
                options: {
                    defaultOptions: { readonly: true }
                }
            },
            parties: [],
            files: [],
            showHistory: false,
            noLocations: false,
            editAction: true,
            calendarEvent: false,
            saved: false,
            changesMade: false,
            calendarEventModal: false,
            optionsSelected: [],
            order: [],
            formData: {},
            priority: 'low',
            date: moment(),
            eventsToBeCreated: {},
            asideExpanded: false,
        };
        this.onSortEnd = this.onSortEnd.bind( this );
        this.createNewEvent = this.createNewEvent.bind( this );
        this.asideToggle = this.asideToggle.bind( this );
        this.addOptionsToContract = this.addOptionsToContract.bind( this );
        this.buildFields = this.buildFields.bind( this );
        this.toggleCalendarEvent = this.toggleCalendarEvent.bind( this );
        this.saveDocument = this.saveDocument.bind( this );
        this.saveParties = this.saveParties.bind( this );
        this.saveAddedFilesToState = this.saveAddedFilesToState.bind( this );
        this.handleFormChange = this.handleFormChange.bind( this );
        this.saveCalendarEvent = this.saveCalendarEvent.bind( this );
        this.saveAutomaticCalendarEvents = this.saveAutomaticCalendarEvents.bind( this );
        this.createCalendarEvent2 = this.createCalendarEvent2.bind( this );
        this.execute = this.execute.bind( this );
    }

    execute( data ) {
        filesX = data.last() || [];
    }

    UNSAFE_componentWillMount() {
        db.open().catch( ( error ) => {
            console.error( `Uh oh : ${error}` );
        } );
        db.items.toArray( this.execute );
        if ( this.props.location.pathname.indexOf( '/add' ) > -1 && !this.props.currentUser.canCreate ) {
            this.props.history.replace( '/docvault' );
            return '';
        }
        const { tempDoc, breadcrumbs, writeBreadcrumbs } = this.props;
        let innerBreadcrumbs = breadcrumbs;
        if ( this.props.location.pathname.indexOf( '/add' ) > -1 ) {
            innerBreadcrumbs = writeBreadcrumbs;
        }
        if ( this.props.docType && innerBreadcrumbs && this.props.documentTypes ) {
            // this is needed to preserve form data in case of refresh
            if ( this.props.location.pathname.indexOf( '/add' ) > -1 ) {
                const currentSavedId = tempDoc.currentDocId;
                const urlId = this.props.location.pathname.split( '/' )[2];
                if ( !currentSavedId ) {
                    this.props.clearTempDoc();
                    this.props.addTempDocData( { currentDocId: urlId } );
                } else if ( currentSavedId !== urlId ) {
                    this.props.clearTempDoc();
                }
            }
            const { formData, parties, files, eventsToBeCreated, order, schema, uiSchema } = tempDoc;

            const docTypeName = this.props.documentTypes.having( 'id', this.props.docType )[0].name;
            const selectedDoc = this.props.documentTypes.having( 'id', this.props.docType )[0];
            let defaultOptions = [];
            if ( selectedDoc.options instanceof Array ) {
                defaultOptions = selectedDoc.options.filter( x => x );
            }
            if ( defaultOptions.filter( ( option ) => option.id === 'info' ).length < 1 ) {
                defaultOptions.push( {
                    deleted: false,
                    mandatory: false,
                    textarea: true,
                    optionType: 'text',
                    id: 'info',
                    title: 'Info'
                } );
            }
            const dummySchema = Object.assign( {}, uiSchema );
            delete dummySchema['ui:order'];
            Object.keys( dummySchema ).map( key => {
                const option = this.props.options.having( 'id', key )[0];
                if ( typeof option === 'undefined' ) {
                    return;
                }
                if ( defaultOptions.having( 'id', key ).length === 0 ) {
                    defaultOptions.push( option );
                }
            } );

            const { optionsSelected } = fillDefault( innerBreadcrumbs, docTypeName, defaultOptions, uiSchema, this.props.options );
            this.setState( {
                optionsSelected,
                formData: formData || {},
                docType: docTypeName,
                eventsToBeCreated: eventsToBeCreated || {},
                files: filesX,
                parties: parties || [],
                selectedFolder: this.props.folders[0],
                order,
                schema,
                uiSchema,
            } );
        }
        if ( this.props.location.pathname === '/edit-document' ) {
            const params = qs.parse( window.location.search );
            if ( this.props.currentAccount.id !== params.accountId ) {
                const desiredAccount = this.props.accounts.having( 'id', params.accountId )[0];
                if ( typeof desiredAccount === 'undefined' ) {
                    this.setState( { cannotAccess: true } );
                }
            }
            if ( this.props.documents.hasItems() && innerBreadcrumbs.hasItems() ) {
                const params = qs.parse( window.location.search );
                if ( typeof params.id === 'undefined' || typeof params.accountId === 'undefined' ) {
                    window.history.replaceState( null, null, window.location.pathname );
                    return;
                }
                const desiredDoc = this.props.documents.having( 'id', params.id )[0];
                if ( typeof desiredDoc === 'undefined' ) {
                    // this will remove the query params from the url.
                    window.history.replaceState( null, null, window.location.pathname );
                }
                const { documentId, schema, formData, docType, optionsSelected, newUiSchema, parties, currentDoc, invalidDocId = false, currentLocationValid } = composeDataForExistingDoc( innerBreadcrumbs, this.props.documents, params.id );
                this.props.selectDocument( documentId );
                if ( !invalidDocId ) {
                    if ( Object.keys( this.props.selectedDocument ).length === 0 ) {
                        this.props.selectDocument( this.props.documents.having( 'id', params.id )[0].id );
                    }
                    setTimeout( () => {
                        this.buildFields();
                    }, 350 );

                    this.setState( {
                        editDoc: true,
                        schema,
                        uiSchema: newUiSchema,
                        formData,
                        docType,
                        editAction: false,
                        documentId,
                        optionsSelected,
                        parties,
                        currentDoc,
                        currentLocationValid,
                        order: currentDoc.schema.order
                    } );
                } else {
                    this.setState( { invalidDocId: true } );
                }
            }
        }
        setTimeout( () => {
            this.buildFields();
        }, 250 );
    }

    componentWillUnmount() {
        if ( !document.body.classList.contains( 'aside-menu-hidden' ) ) {
            document.body.classList.add( 'aside-menu-hidden' );
        }

        this.props.clearDocument();
        if ( !this.state.saving ) {
            db.items.clear();
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.props.location.pathname === '/edit-document' ) {
            if ( nextProps.documents.hasItems() && nextProps.breadcrumbs.hasItems() ) {
                const params = qs.parse( window.location.search );
                if ( typeof params.id === 'undefined' || typeof params.accountId === 'undefined' ) {
                    window.history.replaceState( null, null, window.location.pathname );
                    return;
                }
                const desiredDoc = nextProps.documents.having( 'id', params.id )[0];
                if ( typeof desiredDoc === 'undefined' ) {
                    // this will remove the query params from the url.
                    window.history.replaceState( null, null, window.location.pathname );
                }
                const { documentId, schema, formData, docType, optionsSelected, newUiSchema, parties, currentDoc, invalidDocId, currentLocationValid } = composeDataForExistingDoc( nextProps.breadcrumbs, nextProps.documents, params.id );
                if ( invalidDocId ) {
                    this.setState( {
                        invalidDocId: true
                    } );
                    return;
                }
                this.setState( {
                    editDoc: true,
                    schema,
                    uiSchema: newUiSchema,
                    formData,
                    docType,
                    editAction: false,
                    documentId,
                    optionsSelected,
                    parties,
                    currentDoc,
                    currentLocationValid,
                    order: currentDoc.schema.order
                } );
            }
        }
        if ( this.props.location.pathname !== '/edit-document' && nextProps.breadcrumbs.hasItems() ) {
        }
    }

    onSortEnd( { oldIndex, newIndex } ) {
        this.setState( {
            optionsSelected: arrayMove( this.state.optionsSelected, oldIndex, newIndex ),
        } );
    }

    shouldComponentUpdate( nextProps, nextState ) {
        const { options, documentTypes, folders, location, documents } = this.props;
        const diff = deepDiff( this.props.selectedDocument.formData, nextProps.selectedDocument.formData );
        if ( typeof diff !== 'undefined' ) {
            return true;
        }
        if ( options !== nextProps.options
      || documentTypes !== nextProps.documentTypes
      || folders !== nextProps.folders
      || location !== nextProps.location
      || documents !== nextProps.documents ) { return false; }

        return true;
    }

    createNewEvent() {
        if ( !this.state.currentLocationValid ) {
            return;
        }
        this.props.clearSelectedEvent();
        this.setState( { calendarEventModal: !this.state.calendarEventModal, createNewEvent: true } );
    }

    asideToggle( e ) {
        e.preventDefault();
        this.props.getVersions( {
            accountId: this.props.currentAccount.id,
            elementId: this.props.selectedDocument.id,
            element: this.props.selectedDocument,
            collection: 'documents'
        } );
    }

    addOptionsToContract( data, order ) {
        this.setState( { optionsSelected: data, order } );
        setTimeout( () => {
            this.buildFields();
        }, 100 );
    }

    buildFields() {
        const newSchema = Object.assign( {}, this.state.schema );
        const { properties, required, uiSchema } = buildContractFields( [ ...this.state.optionsSelected ], this.state.order );
        newSchema.properties = properties;
        const newUiSchema = uiSchema;
        newSchema.required = [ ...required ];
        newSchema.order = uiSchema['ui:order'];
        this.props.addTempDocData( { order: newSchema.order, schema: newSchema, uiSchema: newUiSchema } );
        this.setState( { schema: newSchema, uiSchema: newUiSchema, order: newSchema.order } );
    }

    toggleCalendarEvent( status ) {
        if ( status ) {
            this.props.clearSelectedEvent();
            this.setState( { newDoc: true } );
        }
        if ( this.state.calendarEventModal ) {
            this.setState( { newDoc: false } );
        }
        this.setState( { calendarEventModal: !this.state.calendarEventModal } );
    }

    async saveDocument() {
    // for new document save calendar event at the same time with document creation
        const that = this;
        if ( this.state.repeatError ) {
            return;
        }
        const trimmedTitle = fullStringClean( this.state.formData.title );
        const data = Object.assign( this.state.formData, { title: trimmedTitle } );

        // this is needed for the case when options are removed form existing doc.
        Object.keys( data ).map( key => {
            if ( !this.state.schema.order.contains( key ) ) {
                delete data[key];
            }
        } );

        const payload = {
            docType: this.state.docType,
            formData: data,
            schema: this.state.schema,
            accountId: this.props.currentAccount.id,
            calendarEvent: false,
            parties: this.state.parties,
            deleted: false
        };
        if ( this.state.documentId ) {
            payload.documentId = this.state.documentId;
        }
        this.setState( { saving: true, saved: true, changesMade: false } );
        document.body.classList.add( 'aside-menu-hidden' );

        // we don't need to preserve existing data for location as we always use the categories.
        delete payload.schema.properties.location.data;
        let res = null;
        if ( this.props.location.pathname === '/edit-document' ) {
            res = await this.props.updateDocument( payload, true )
                .catch( ( e ) => {
                    this.setState( { saving: false } );
                    return console.error( e );
                } );
        } else {
            res = await this.props.addDocument( payload, true )
                .catch( ( e ) => {
                    this.setState( { saving: false } );
                    return console.error( e );
                } );
        }
        this.props.clearTempDoc();
        const extras = {
            documentId: res.data.data.id,
            documentName: fullStringClean( payload.formData.title ),
            accountId: payload.accountId
        };

        this.setState( { saving: false } );

        if ( that.props.files.hasItems() || that.state.files.hasItems() ) {
            const extendedFiles = [ ...that.props.files, ...that.state.files ].map( file => ( {
                file,
                accountId: that.props.currentAccount.id,
                documentId: res.data.data.id,
                id: uuidv1(),
                completed: false
            } ) );
            that.props.buildUploadQueue( extendedFiles, true );
            setTimeout( () => {
                if ( this.props.location.pathname === '/edit-document' ) {
                    this.props.triggerUpload( 'put' );
                } else {
                    this.props.triggerUpload();
                }
            }, 1000 );
        }
        setTimeout( () => {
            this.saveAutomaticCalendarEvents( extras );
        }, 500 );
        db.items.clear();
        if ( this.props.location.pathname === '/edit-document' ) {
            that.props.history.goBack();
        } else {
            that.props.history.replace( { pathname: '/docvault', query: { location: this.state.formData.location } } );
        }
    }

    saveParties( data ) {
        this.setState( { parties: data } );
        this.props.addTempDocData( { parties: data } );
    }

    saveAddedFilesToState( data ) {
        const newFiles = [ ...this.state.files, ...data ];
        const uniqueFiles = Array.from( new Set( newFiles.map( entry => entry.name ) ) ).map( key => newFiles.having( 'name', key )[0] );
        db.items.add( uniqueFiles );
        this.setState( { files: uniqueFiles } );
        this.props.addTempDocData( { files: uniqueFiles } );
    }

    handleFormChange( data ) {
        const newData = { ...data.formData };
        Object.keys( newData ).forEach( key => typeof newData[key] === 'undefined' && delete newData[key] );
        this.setState( { formData: data.formData, changesMade: typeof deepDiff( this.state.formData, newData ) !== 'undefined' && this.state.editAction } );

        // this is needed to preserve form data in case of refresh
        this.props.addTempDocData( { formData: data.formData } );

        if ( typeof data.formData.contractType !== 'undefined' && data.formData.contractType !== contractType && changedField === 'root_contractType' ) {
            this.setState( { selectedFolder: data.formData.contractType, modal: true } );
        }
    }

    saveCalendarEvent( data ) {
        if ( this.state.newDoc ) {
            const payload = {
                date: data.date || this.state.date,
                priority: data.priority || this.state.priority,
                name: data.name,
                repeatData: data.repeatData,
                id: uuidv1()
            };
            this.createCalendarEvent2( payload );
            this.props.showNotificationSuccess( 'Submit the document in order to save the created event' );
        }
        if ( this.state.createNewEvent ) {
            const payload = {
                id: data.id,
                name: data.name,
                priority: data.priority || this.state.priority,
                date: data.date || this.state.date,
                dateTimestamp: data.dateTimestamp || new Date( this.state.date ).getTime(),
                documentId: data.documentId || this.state.documentId,
                accountId: data.accountId || this.props.currentAccount.id,
                documentName: data.documentName || this.state.formData.title,
                categoryNode: this.state.formData.location,
                dueTime: data.dueTime,
                groups: data.groups,
                notes: data.notes,
                repeatData: data.repeatData
            };
            payload.date = moment.utc( payload.date );
            // add default group to event
            if ( Object.keys( payload.groups ).length === 0 ) {
                const singleGroup = this.props.groups.filter( group => group.single && typeof group.members[this.props.currentUser.id] !== 'undefined' )[0];
                if ( typeof singleGroup !== 'undefined' ) {
                    payload.groups[singleGroup.id] = true;
                } else {
                    console.error( 'User does not have single group' );
                }
            }
            this.props.createCalendarEvent( payload );
        }
        this.toggleCalendarEvent();
    }

    saveAutomaticCalendarEvents( extras ) {
        Object.keys( this.state.eventsToBeCreated ).map( key => {
            const newEvent = { ...this.state.eventsToBeCreated[key], ...extras };
            // this will set the selected time to the date.
            const date = moment( newEvent.date );
            newEvent.date = date.utc().format();
            newEvent.dateTimestamp = new Date( newEvent.date ).getTime();
            if ( newEvent.notes[0].value !== '' ) {
                newEvent.notes[0].by = this.props.currentUser.email;
            } else {
                newEvent.notes = [];
            }
            newEvent.categoryNode = this.state.formData.location;
            delete newEvent.id;
            // add default group to event
            if ( Object.keys( newEvent.groups ).length === 0 ) {
                const singleGroup = this.props.groups.filter( group => group.single && typeof group.members[this.props.currentUser.id] !== 'undefined' )[0];
                if ( typeof singleGroup !== 'undefined' ) {
                    newEvent.groups[singleGroup.id] = true;
                } else {
                    console.error( 'User does not have single group' );
                }
            }
            this.props.createCalendarEvent( newEvent );
        } );
    }

    createCalendarEvent2( data, repeatError ) {
        const newData = { ...this.state.eventsToBeCreated };
        newData[data.id] = { dueTime: { hour: '0', minutes: '00' }, notes: [ {} ], groups: {}, ...data };
        this.setState( { eventsToBeCreated: newData, repeatError } );
        this.props.addTempDocData( { eventsToBeCreated: newData } );
    }

    render() {
        if ( !this.props.currentUser ) {
            return (
                <script />
            );
        }
        let canSee = true;
        let canCreate = true;
        let canUpdate = true;
        let location = null;
        if ( typeof this.state.formData.location !== 'undefined' ) {
            location = `${this.state.formData.location}/${this.state.formData.title}`;
            const categoryName = this.state.formData.location.indexOf( '/' ) > -1 ? this.state.formData.location.split( '/' )[0] : this.state.formData.location;
            const { abilities } = generateCan( this.props.currentUser.acl );
            canSee = canUserPerformAction( 'read', abilities, this.props.currentAccount.id, categoryName );
            canCreate = canUserPerformAction( 'create', abilities, this.props.currentAccount.id, categoryName );
            canUpdate = canUserPerformAction( 'update', abilities, this.props.currentAccount.id, categoryName );
        }

        if ( this.state.cannotAccess || !canSee ) {
            return (
                <Alert color="warning" className="text-center">
          Sorry, you do not have access to this section.
                </Alert>
            );
        }
        const { options } = this.props;
        if ( this.state.noLocations && !this.state.editDoc ) {
            return (
                <Alert color="info">
                    {'Please define a category with <strong>{this.props.selectedDocument.docType}</strong> as document type'}
                </Alert>
            );
        }
        if ( this.state.invalidDocId ) {
            return (
                <Alert color="warning" className="text-center">
          Document not available
                </Alert>
            );
        }
        if ( !options && !this.state.editDoc ) {
            return (
                <Alert color="info">Please create options first.</Alert>
            );
        }
        const fields = {
            booleanStatement: BooleanStatement,
            locationSelect: GroupedSelector,
            dateIntervalSelect: DocumentDateIntervalSelector,
            richTextField: RichTextField,
            dateSelect: HOCforDateSelector( DateSelectorWrapper, this.createCalendarEvent2, !!this.state.currentDoc, this.state )
        };
        const schema = Object.assign( {}, this.state.uiSchema );
        Object.keys( schema ).map( key => {
            if ( key !== 'ui:order' ) {
                schema[key]['ui:readonly'] = !this.state.editAction;
            }
        } );
        const partiesDOM = buildPartiesDOM( this.state.parties );
        if ( !this.state.currentLocationValid ) {
            location = null;
        }
        const unsavedChanges = ( this.state.files.length > 0 && !this.state.saved ) || this.state.changesMade;
        return (
            <React.Fragment>

                <Row className="flexLeft mt-3">
                    <Col>
                        <VaultBreadcrumb
                            enableClickOnLast
                            location={ buildLocation( location, this.props.currentAccount.enhancedNavigation, this.props.currentAccount.categoryNavigationDepth ) }
                        />
                    </Col>
                </Row>

                <div className="animated fadeIn white-box">
                    <Prompt
                        when={ unsavedChanges }
                        message={ location => 'You have unsaved changes. Please save the current doc before navigating away. Click Cancel to return to document edit or Ok to continue.' }
                    />
                    <SpinnerModal showSpinner={ this.state.saving } />
                    <Display when={ this.state.calendarEventModal }>
                        <CalendarEventModal
                            toggle={ this.toggleCalendarEvent }
                            action={ this.saveCalendarEvent }
                            explicitEdit={ true }
                            openState={ this.state.calendarEventModal }
                        />
                    </Display>

                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header pt-0">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-8">
                                            <Display when={ this.state.editDoc }>
                                                <h2 className="pull-left mb-0" style={ { width: '100%' } }>{this.state.formData.title}</h2>
                                                <div className="pull-left">Type: <i>{this.state.docType }</i></div>
                                            </Display>
                                            <Display when={ !this.state.editDoc }>
                                                <h2 className="pull-left mb-0" style={ { width: '100%' } }>
                                                    {`Add new  ${this.props.docName}`}
                                                </h2>
                                                <div className="pull-left">Type: <i>{this.state.docType }</i></div>
                                            </Display>
                                        </div>
                                        <div className="col-xs-12 col-md-4" id="documentControlBtns">
                                            <div className="openOptionsModal">
                                                {/* TODO display only when not a new doc */}
                                                <Display when={ !window.location.pathname.startsWith( '/add/' ) }>
                         <>
                          <TooltipWrapper refId="Versions" content="Versions" />
                          <div
                              id="Versions"
                              className="btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5"
                              style={ { marginTop: '-10px', marginLeft: '3px' } }
                              onClick={ this.asideToggle }>

                              <div
                                  className="fa fa-clock-o"
                                  style={ { paddingTop: '6px' } }
                              />

                          </div>
                         </>
                                                </Display>
                                            </div>
                                            <Display when={ canCreate || canUpdate }>
                                                <span>
                                                    <Display when={ this.state.editDoc && !this.state.editAction }>
                                                        <div className="openOptionsModal">
                                                            <TooltipWrapper refId="Edit" content="Edit" />
                                                            <div
                                                                id="Edit"
                                                                className="btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5"
                                                                style={ { marginTop: '-10px' } }
                                                                onClick={ () => {
                                                                    this.setState( { editAction: true } );
                                                                } }>

                                                                <div
                                                                    className="fa fa-edit"
                                                                    style={ { paddingTop: '6px' } }
                                                                />

                                                            </div>
                                                        </div>
                                                    </Display>

                                                    <Display when={ this.state.editDoc && this.state.editAction }>
                                                        <div>
                                                            <div className="openOptionsModal">
                                                                <TooltipWrapper refId="cancel" content="Cancel" />
                                                                <div
                                                                    id="cancel"
                                                                    className="btn btn-outline-primary btn-outline btn-circle btn-lg"
                                                                    style={ { marginTop: '-10px', marginRight: '0' } }
                                                                    onClick={ () => {
                                                                        this.setState( { editAction: false } );
                                                                    } }>
                                                                    <div
                                                                        className="fa fa-close"
                                                                        style={ { paddingTop: '6px' } } />
                                                                </div>
                                                            </div>
                                                            <PartyAssignModal
                                                                save={ this.saveParties }
                                                                list={ this.state.parties } />
                                                        </div>
                                                    </Display>

                                                    <Display
                                                        when={ ( !this.state.editDoc || ( this.state.editDoc && this.state.editAction ) ) }>
                                                        <span>
                                                            <Display when={ !this.state.editDoc }>
                                                                <div>
                                                                    <PartyAssignModal
                                                                        save={ this.saveParties }
                                                                        list={ this.state.parties } />

                                                                    <TooltipWrapper refId="addAlert" content="Add Alert" />
                                                                    <div
                                                                        id="addAlert"
                                                                        className="btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5"
                                                                        style={ {
                                                                            float: 'right',
                                                                            position: 'relative',
                                                                            marginRight: '5px'
                                                                        } }
                                                                        onClick={ () => this.toggleCalendarEvent( 'new' ) }>
                                                                        <div
                                                                            className="fa fa-bell"
                                                                            style={ { paddingTop: '6px' } } />
                                                                    </div>
                                                                </div>
                                                            </Display>
                                                            <CustomizeContractFieldsModal
                                                                docType={ this.props.selectedDocument.docType }
                                                                optionsSelected={ this.state.optionsSelected }
                                                                order={ this.state.order }
                                                                addOptionsToContract={ this.addOptionsToContract } />
                                                            <DocumentUploadModal
                                                                currentLocationValid={ this.state.editDoc ? this.state.currentLocationValid : true }
                                                                accountId={ this.props.currentAccount.id }
                                                                documentId={ this.props.selectedDocument.id }
                                                                saveAddedFilesToState={ this.saveAddedFilesToState }
                                                            />
                                                        </span>
                                                    </Display>
                                                </span>
                                            </Display>
                                            <Display when={ this.state.editDoc }>
                                                <Summary isEditDoc={ this.state.editDoc } />
                                            </Display>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="control-label">
                Parties:
                                    <span className="required">*</span>
                                </label>
                                <Display when={ this.state.parties.isEmpty() && this.state.editAction }>
                                    <Alert
                                        style={ { cursor: 'pointer' } }
                                        color="info"
                                        id="addPartiesModalTrigger"
                                        onClick={ () => {
                                            document.getElementById( 'partyModalBtnTrigger' )
                                                ? document.getElementById( 'partyModalBtnTrigger' ).click() : () => {};
                                        } }>
                  Please add parties
                                    </Alert>
                                </Display>
                                <Display when={ this.state.parties.isEmpty() && !this.state.editAction }>
                                    <Alert
                                        color="info"
                                        onClick={ () => {
                                            document.getElementById( 'partyModalBtnTrigger' )
                                                ? document.getElementById( 'partyModalBtnTrigger' ).click() : () => {};
                                        } }>
                  No Parties added
                                    </Alert>
                                </Display>
                                <Display when={ this.state.parties.hasItems() }>
                                    {partiesDOM}
                                </Display>
                            </div>

                            <Form
                                schema={ this.state.schema }
                                formData={ this.state.formData }
                                uiSchema={ schema }
                                fields={ fields }
                                disabled={ !this.state.editAction }
                                onChange={ this.handleFormChange }
                                onFocus={ detectFieldClick }
                                onSubmit={ this.saveDocument }
                                className={ this.state.editAction ? '' : 'formDisabled' }
                                transformErrors={ ( errors ) => transformErrors( errors, this.state.optionsSelected ) }
                                showErrorList={ false }
                                onError={ log( 'errors' ) } />

                        </div>
                    </div>
                    <Display when={ this.state.editDoc }>
                        <div>
                            <h3 style={ { marginBottom: 12 } }>
              Events/Alerts/Milestones
                                <Display when={ this.state.editAction }>
                                    {/* <Button */}
                                    {/* color="primary" */}
                                    {/* style={ { top: '-5px' } } */}
                                    {/* className="btn btn-outline-info openOptionsModal" */}
                                    {/* onClick={ this.createNewEvent }>Add */}
                                    {/* </Button> */}
                                    <div className="openOptionsModal" style={ { top: -8 } }>
                                        <TooltipWrapper refId="addEvent" content={ this.state.currentLocationValid ? 'Add event' : 'Fix document location before creating events.' } />
                                        <div
                                            id="addEvent"
                                            className={ `btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5 ${this.state.currentLocationValid ? '' : 'disabled'}` }
                                            style={ { float: 'right', position: 'relative', marginRight: '5px' } }
                                            onClick={ this.createNewEvent }>
                                            <div className="fa fa-bell" style={ { paddingTop: '6px' } } />
                                        </div>
                                    </div>
                                </Display>
                            </h3>
                            <div style={ { margin: '-15px -25px 0' } }>
                                <AlertsList document={ this.state.currentDoc } />
                            </div>
                        </div>
                    </Display>
                    <Display when={ !!this.props.selectedDocument.id }>
                        <FileList
                            canCreate={ canCreate }
                            accountId={ this.props.currentAccount.id }
                            documentId={ this.props.selectedDocument.id }
                            document={ this.props.selectedDocument }
                        />
                    </Display>
                    <div style={ { marginTop: '50px' } } />
                </div>
            </React.Fragment>
        );
    }
}

const { array, object, string, func } = PropTypes;

DocumentAdd.defaultProps = {
    selectedDocument: {},
    currentAccount: {},
    currentUser: {},
    location: {},
    history: {},
    options: [],
    documents: [],
    groups: [],
    files: [],
    versions: null,
    createCalendarEvent: () => {
    },
    selectDocument: () => {
    },
    showNotificationSuccess: () => {
    },
    addDocument: () => {
    },
    addTempDocData: () => {
    },
    clearTempDoc: () => {
    }
};

DocumentAdd.propTypes = {
    breadcrumbs: array.isRequired,
    folders: array.isRequired,
    options: array,
    documents: array,
    files: array,
    selectedDocument: object,
    currentAccount: object,
    location: object,
    history: object,
    createCalendarEvent: func,
    addTempDocData: func,
    clearTempDoc: func,
    showNotificationSuccess: func,
    addDocument: func,
    selectDocument: func,
    currentUser: object,
    versions: object
};

const mapStateToProps = state => ( {
    breadcrumbs: state.categories.breadcrumbs,
    categoriesBreadcrumbs: state.categoriesBreadcrumbs,
    writeBreadcrumbs: state.categories.writeBreadcrumbs,
    currentAccount: state.currentAccount,
    accounts: state.accounts,
    currentUser: state.currentUser,
    folders: state.options.folders,
    options: state.options.options,
    documents: state.documents.list,
    tempDoc: state.documents.tempDoc,
    documentTypes: state.documentTypes.list,
    selectedDocument: state.documents.selectedDocument,
    resources: state.resources,
    groups: state.groups,
    versions: state.versions
} );

const mapDispatchToProps = dispatch => ( {
    addDocument: ( status, isUpdate ) => dispatch( documentOperations.addDocument( status, isUpdate ) ),
    updateDocument: ( status, isUpdate ) => dispatch( documentOperations.updateDocument( status, isUpdate ) ),
    showNotificationSuccess: ( message ) => dispatch( messageOperations.showSuccess( message ) ),
    showNotificationError: ( message ) => dispatch( messageOperations.showError( message ) ),
    selectDocument: ( data ) => dispatch( documentOperations.selectDocument( data ) ),
    getVersions: ( data ) => dispatch( versionOperations.getVersions( data ) ),
    clearDocument: () => dispatch( documentOperations.clearDocument() ),
    clearTempDoc: () => dispatch( documentOperations.clearTempDoc() ),
    addTempDocData: ( data ) => { if ( window.location.pathname === '/edit-document' ) { return; } dispatch( documentOperations.addTempDocData( data ) ); },
    createCalendarEvent: ( data ) => dispatch( calendarEventsOperations.createCalendarEvent( data ) ),
    clearSelectedEvent: () => dispatch( calendarEventsOperations.clearSelectedEvent() ),
    buildUploadQueue: ( files, flag ) => dispatch( uploadsOperations.buildUploadQueue( files, flag ) ),
    triggerUpload: ( action ) => dispatch( uploadsOperations.triggerUpload( action ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DocumentAdd );


const HOCforDateSelector = ( WrappedComponent, createCalendarEvent2, edit, extra, groups, categoryNode, resources ) => {
    class HOC extends React.Component {
        render() {
            return (
                <WrappedComponent
                    { ...this.props }
                    createCalendarEvent={ createCalendarEvent2 }
                    edit={ edit }
                    extra={ extra }
                    groups={ groups }
                    categoryNode={ categoryNode }
                    resources={ resources }
                />
            );
        }
    }

    return HOC;
};


const HOCforRichTextField = ( WrappedComponent, edit, extra ) => {
    class HOC extends React.Component {
        render() {
            return (
                <WrappedComponent
                    { ...this.props }
                    edit={ edit }
                    extra={ extra }
                />
            );
        }
    }

    return HOC;
};

function dataURLtoBlob( dataurl ) {
    const arr = dataurl.split( ',' ); const mime = arr[0].match( /:(.*?);/ )[1];


    const bstr = atob( arr[1] ); let n = bstr.length; const
        u8arr = new Uint8Array( n );
    while ( n-- ) {
        u8arr[n] = bstr.charCodeAt( n );
    }
    return new Blob( [ u8arr ], { type: mime } );
}


function transformErrors( errors, options ) {
    return errors.map( error => {
        if ( typeof error.params.missingProperty !== 'undefined' ) {
            const entity = options.filter( entry => entry.id === error.params.missingProperty )[0];
            let name = error.params.missingProperty;
            if ( typeof entity !== 'undefined' ) {
                name = entity.title;
            }
            error.message = `${name} ${error.message}`;
        }
        return error;
    } );
}
const buildLocation = ( docLocation, enhancedNavigation = null, categoryNavigationDepth = 1 ) => {
    if ( !enhancedNavigation || !docLocation ) {
        if ( docLocation ) {
            return `/docvault/${docLocation.split( '/' )[0]}`;
        }
        return '/docvault';
    }
    const array = [];
    const splittedPath = docLocation.split( '/' );
    let i = 0;
    while ( i < categoryNavigationDepth ) {
        if ( typeof splittedPath[i] === 'undefined' ) {
            break;
        }
        array.push( splittedPath[i] );
        i++;
    }
    return `/docvault/${array.join( '/' )}`;
};
