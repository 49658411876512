/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import { Progress } from 'reactstrap';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Display from '../Display';


class UploadStatusModal extends React.Component {
    constructor() {
        super();
        this.state = {
            uploadStatusModal: false,
        };
    }

    UNSAFE_componentWillMount() {
    }

    closeUploadModal = () => {
        this.setState( { uploadStatusModal: false } );
    };

    toggleUploadModal = () => {
        this.setState( { uploadStatusModal: !this.state.uploadStatusModal } );
    };

    render() {
        const uploadBarsDOM = this.props.uploads.map( upload => buildUploadBar( upload ) );
        return (
            <div>
                <Button
                    style={ { display: 'none' } }
                    id="uploadStatusModalTrigger"
                    color="primary"
                    className="btn btn-outline-secondary openOptionsModal mr-2"
                    onClick={ this.toggleUploadModal }>Upload status
                </Button>
                <Display when={ this.state.uploadStatusModal }>
                    <Modal isOpen={ this.state.uploadStatusModal } toggle={ this.toggleUploadModal }>
                        <ModalHeader toggle={ this.toggleUploadModal }>Upload status</ModalHeader>
                        <ModalBody>
                            <Display when={ this.props.uploads.isEmpty() }>
                            No files to upload
                            </Display>
                            <Display when={ this.props.uploads.hasItems() }>
                                { uploadBarsDOM }
                            </Display>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={ this.closeUploadModal }>Close</Button>
                        </ModalFooter>
                    </Modal>
                </Display>
            </div>
        );
    }
}

UploadStatusModal.defaultProps = {
};

const { array } = PropTypes;

UploadStatusModal.propTypes = {
    uploads: array.isRequired
};

const mapStateToProps = state => ( {
    uploads: state.uploads.files
} );

const mapDispatchToProps = dispatch => ( {

} );

export default connect( mapStateToProps, mapDispatchToProps )( UploadStatusModal );

function buildUploadBar( data ) {
    return (
        <div key={ Math.random() } className="mb-3 clearfix">
            <div className="text-center">{data.fileName}</div>
            <Progress color="info" value={ data.progressPercent }>{ data.progressPercent }%</Progress>
        </div>
    );
}
