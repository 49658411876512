import { isBeforeToday, isNextMonth, isThisMonth, isThisWeek, isToday, isTomorrow } from './datetime';

export const determineStatus = ( event ) => {
    if ( event.deleted ) {
        return '';
    }
    if ( event.resolved ) {
        return 'Resolved';
    }
    if ( isBeforeToday( event.date ) ) {
        return 'Overdue';
    }
    if ( isToday( event.date ) ) {
        return 'Today';
    }
    if ( isTomorrow( event.date ) ) {
        return 'Tomorrow';
    }
    if ( isThisWeek( event.date ) && !isToday( event.date ) && !isTomorrow( event.date ) ) {
        return 'This week';
    }
    if ( isThisMonth( event.date ) && !isThisWeek( event.date ) && !isToday( event.date ) && !isTomorrow( event.date ) ) {
        return 'This month';
    }
    if ( isNextMonth( event.date ) && !isThisMonth( event.date ) && !isThisWeek( event.date ) && !isToday( event.date ) && !isTomorrow( event.date ) ) {
        return 'Next month';
    }
};
