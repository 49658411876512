import { combineReducers } from 'redux';
import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';

const initialState = null;
const initialTempDoc = {
    currentDocId: null,
    formData: {},
    parties: [],
    files: [],
    eventsToBeCreated: {},
    order: [],
    schema: {
        type: 'object',
        required: [],
        properties: {}
    },
    uiSchema: {
        options: {
            defaultOptions: { readonly: true }
        }
    }
};

const documentsReducer = ( state = [], action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return [];
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'documents' ) {
                const documents = build( action.payload.data );
                let newState = [ ...documents ];
                if ( action.payload.type === 'concat' && documents.hasItems() ) {
                    const newState2 = [ ...state ];
                    const position = newState2.findIndex( x => x.id === action.payload.documentId );
                    newState2.splice( position, 1, documents[0] );
                    newState = newState2;
                }
                return newState;
            }
            return state;

        default: return state;
    }
};
const selectedDocumentReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case types.CLEAR_DOCUMENT: return {};
        case types.SELECT_DOCUMENT: return action.payload || {};
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'documents' ) {
                const documents = build( action.payload.data );
                if ( documents.hasItems() ) {
                    return documents.having( 'id', state.id ).firstOrDefault();
                }
            }
            return state;
        default: return state;
    }
};

const temporaryDocumentReducer = ( state = initialTempDoc, action ) => {
    switch ( action.type ) {
        case types.CLEAR_TEMP_DOC: return initialTempDoc;
        case types.ADD_TEMP_DOC_DATA: return { ...state, ...action.payload };
        default: return state;
    }
};

const categories = combineReducers( {
    list: documentsReducer,
    selectedDocument: selectedDocumentReducer,
    tempDoc: temporaryDocumentReducer
} );

export default categories;
