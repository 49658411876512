import React from 'react';
import Dropzone from 'react-dropzone';


class ImageUpload extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { pictures: [], photoURL: '', error: '' };
        this.onFilesDrop = this.onFilesDrop.bind( this );
    }

    componentDidMount() {
        this.setState( { photoURL: this.props.currentLogo } );
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.setState( { photoURL: nextProps.currentLogo } );
    }

    onFilesDrop( files ) {
        if ( this.state.resetInProgress ) {
            return;
        }
        this.setState( { resetInProgress: true } );

        const file = files[0];
        const imageExtension = file.name.slice( ( file.name.lastIndexOf( '.' ) - 1 >>> 0 ) + 2 );
        let name = `${this.props.accountId}/images/accountLogo.${imageExtension}`;
        if ( this.props.userId ) {
            name = `images/profilePictures/${this.props.userId}/${file.name}`;
        }
        this.props.uploadAccountLogo(
            {
                filename: name,
                accountId: this.props.accountId,
                uploadType: 'accountLogo',
                file
            }
        )
            .then( () => {
                this.setState( { resetInProgress: false, } );
            } )
            .catch( () => {
                this.setState( { resetInProgress: false, } );
            } );
    }

    render() {
        let dropzoneRef;
        const imgSrc = this.state.previewImage
            ? this.state.previewImage : this.state.photoURL
                ? this.state.photoURL : 'https://ssl.gstatic.com/images/branding/product/1x/avatar_circle_blue_512dp.png';
        return (

            <div className="card-block">
                <div className="col-lg-12 text-center">
                    <img className="" src={ imgSrc } width="150px" />
                    <Dropzone
                        ref={ ( node ) => { dropzoneRef = node; } }
                        onDrop={ this.onFilesDrop }
                        className="dropZone"
                        accept="image/*"
                        style={ { display: 'none' } } />
                    <br />
                    <br />
                    <button className="btn btn-outline-info" type="button" onClick={ () => { dropzoneRef.open(); } }>
                      Upload picture
                    </button>
                </div>
            </div>


        );
    }
}

export default ImageUpload;
