import { combineReducers } from 'redux';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';

const initialState = [];

const resourcesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT:
            return [];
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'currentUser' ) {
                return typeof action.payload.data.resources !== 'undefined' ? action.payload.data.resources : [];
            }
            if ( action.payload.target === 'resources' ) {
                const resources = build( action.payload.data );
                return resources.hasItems() ? resources : initialState;
            }
            return state;

        default:
            return state;
    }
};


// const categories = combineReducers( {
//     resources: resourcesReducer,
// } );

export default resourcesReducer;
