import React from 'react';

import Select from 'react-select';
import { isLocationValid } from './helpers';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

class BooleanStatement extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { ...props.formData, value: false };
        this.onChange = this.onChange.bind( this );
    }

    onChange( e ) {
        const value = e.target.checked;
        this.setState( {
            value
        } );
        setTimeout( () => { this.props.onChange( value ); }, 300 );
    }

    UNSAFE_componentWillMount() {
        if ( typeof this.props.formData !== 'undefined' ) {
            this.setState( { value: this.props.formData } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( typeof nextProps.formData !== 'undefined' ) {
            this.setState( { value: nextProps.formData } );
        }
    }

    render() {
        const colourStyles = {
            control: styles => ( { ...styles, backgroundColor: 'white' } ),
            option: ( styles, { data, isDisabled, isFocused, isSelected } ) => ( {
                ...styles,
                margin: '0 0 0 15px',
                cursor: isDisabled ? 'not-allowed' : 'default',
            } ),
        };
        return (
            <div>
                <label className="control-label" htmlFor={ this.props.idSchema.$id }>
                    { this.props.schema.title }
                    { this.props.required
          && <span className="required">*</span>
                    }
                </label>
                <div>
                    <span style={ { marginRight: 20 } }>
                        <label style={ { marginRight: 5 } }>Yes </label>
                        <input
                            name="selection"
                            type="checkbox"
                            disabled={ this.props.readonly }
                            checked={ this.state.value }
                            style={ colourStyles }
                            onChange={ this.onChange } />
                    </span>
                </div>
            </div>
        );
    }
}

export default BooleanStatement;
