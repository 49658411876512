import types from './types';

const logout = ( ) => ( {
    type: types.LOGOUT
} );

const saveIntercomSigniture = ( data ) => ( {
    type: types.SAVE_INTERCOM_SIGNITURE,
    payload: data
} );


const parseSubscription = ( data, extraData ) => ( {
    type: types.PARSE_SUBSCRIPTION,
    additionalData: extraData,
    payload: data
} );

const getData = ( data, extraData ) => {
    const params = Object.keys( data ).map( key => `${key}=${data[key]}` ).join( '&' );
    return {
        type: 'GET_DATA',
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: `/getData?${params}`,
            method: 'GET'
        }
    };
};


const setFilter = ( data ) => ( {
    type: types.SET_FILTER,
    payload: data
} );

const getPersonalization = ( tenant ) => ( {
    type: types.GET_PERSONALIZATION,
    async: true,
    generalFetching: true,
    payload: {
        path: `/getPersonalization?tenant=${tenant}`,
        method: 'GET'
    }
} );

const verifyIntercomUser = ( data = {} ) => ( {
    type: types.VERIFY_INTERCOM_USER,
    async: true,
    generalFetching: true,
    payload: {
        path: '/verifyIntercomUser',
        method: 'POST'
    }
} );

export default {
    logout,
    parseSubscription,
    setFilter,
    verifyIntercomUser,
    saveIntercomSigniture,
    getPersonalization,
    getData
};
