// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const ADD_MEMBER = "ADD_MEMBER";
const ADD_MEMBER_COMPLETED = "ADD_MEMBER_COMPLETED";
const ADD_MEMBER_FAILED = "ADD_MEMBER_FAILED";

export default {
  ADD_MEMBER,
  ADD_MEMBER_COMPLETED,
  ADD_MEMBER_FAILED
};
