import types from './types';
import applicationTypes from '../application/types';

const initialState = null;

const versions = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case types.GET_VERSION_COMPLETED:
            return { data: action.payload.data, collection: action.additionalData.collection, element: action.additionalData.element };
        default: return state;
    }
};

export default versions;
