function isAdmin( email, accountMembers ) {
    let isUserAdmin = false;
    if ( typeof accountMembers === 'undefined' ) {
        return false;
    }
    Object.keys( accountMembers ).map( key => {
        if ( accountMembers[key].email === email ) {
            isUserAdmin = accountMembers[key].access === 'Admin';
        }
    } );
    return isUserAdmin;
}

function isOwner( email, accountMembers ) {
    let isUserOwner = false;
    if ( typeof accountMembers === 'undefined' ) {
        return false;
    }
    Object.keys( accountMembers ).map( key => {
        if ( accountMembers[key].email === email ) {
            isUserOwner = accountMembers[key].access === 'Owner';
        }
    } );
    return isUserOwner;
}

function isUserLvl( email, accountMembers ) {
    let isUser = false;
    if ( typeof accountMembers === 'undefined' ) {
        return false;
    }
    Object.keys( accountMembers ).map( key => {
        if ( accountMembers[key].email === email ) {
            isUser = accountMembers[key].access === 'User';
        }
    } );
    return isUser;
}

function isUserReadOnlyLvl( email, accountMembers ) {
    let isUserReadOnly = false;
    if ( typeof accountMembers === 'undefined' ) {
        return false;
    }
    Object.keys( accountMembers ).map( key => {
        if ( accountMembers[key].email === email ) {
            isUserReadOnly = accountMembers[key].access === 'Read Only';
        }
    } );
    return isUserReadOnly;
}

function buildUserPermissions( groups, resources ) {
    // iterate the groups and push the userId to an Object. to that object add another key with the groupId.
    // for that group key add an object that contains the group name, permissions, etc ..
    // example: {
    //     userId: {
    //         groupId: {
    //             groupName: '',
    //             permissions: [
    //                 {
    //                     resource: '',
    //                     access: '',
    //                     type: ''
    //                 }
    //             ]
    //         }
    //     }
    // };
    const data = {};
    groups.map( group => {
        const permissions = [];
        if ( group.resources instanceof Array ) {
            group.resources.map( resourceId => {
                const currentResource = resources.having( 'id', resourceId )[0];
                let resource = '';
                let type = 'account';
                if ( typeof currentResource !== 'undefined' ) {
                    if ( currentResource.resourceId.contains( 'category:' ) ) {
                        [ , resource ] = currentResource.prettyResourceName.split( '::' )[2].split( ':' );
                        type = 'category';
                    } else {
                        [ , resource ] = currentResource.prettyResourceName.split( '::' )[1].split( ':' );
                    }

                    permissions.push( {
                        resource,
                        access: currentResource.acl[0].actions.join( ', ' ),
                        type
                    } );
                }
            } );
        } else {
            console.error( 'group without resources', group );
        }

        Object.keys( group.members ).map( userId => {
            if ( typeof data[userId] === 'undefined' ) {
                data[userId] = {};
            }
            data[userId][group.id] = { groupName: group.name, permissions };
        } );
    } );
    return data;
}


function userAccessLevel( email, accountMembers ) {
    let x = 'readOnly';
    if ( typeof accountMembers === 'undefined' ) {
        return false;
    }
    Object.keys( accountMembers ).map( key => {
        if ( accountMembers[key].email === email ) {
            x = accountMembers[key].access;
        }
    } );
    return x;
}
export {
    isAdmin,
    isOwner,
    buildUserPermissions,
    isUserLvl,
    isUserReadOnlyLvl,
    userAccessLevel
};
