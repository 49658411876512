import actions from './actions';
import utils from './utils';

const MESSAGE_TIMEOUT = 7000;
let timeoutHook = null;

const showMessage = ( text, type, options ) => ( dispatch, getState ) => {
    const showMessageAction = actions.showMessage( text, type, options );

    const messages = getState( ).messages;
    const existingMessageId = utils.getIdOfExistingMessage( messages, showMessageAction.payload );

    if ( existingMessageId ) {
        clearTimeout( timeoutHook );
    } else {
        dispatch( showMessageAction );
    }

    if ( !options.permanent ) {
        const deleteMessageAction = actions.deleteMessage( existingMessageId || showMessageAction.payload.id );

        timeoutHook = setTimeout( ( ) => {
            dispatch( deleteMessageAction );
        }, MESSAGE_TIMEOUT );
    }
};

const showSuccess = ( text, options = { } ) => showMessage( text, 'success', options );
const showInfo = ( text, options = { } ) => showMessage( text, 'info', options );

const showWarning = ( text, options = { } ) => showMessage( text, 'warning', options );

const showError = ( text, options = { } ) => showMessage( text, 'error', options );

const { deleteMessage } = actions;

export default {
    showSuccess,
    showInfo,
    showWarning,
    showError,
    deleteMessage
};
