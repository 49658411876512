const GET_ACTIVITY = 'GET_ACTIVITY';
const GET_ACTIVITY_COMPLETED = 'GET_ACTIVITY_COMPLETED';
const GET_ACTIVITY_FAILED = 'GET_ACTIVITY_FAILED';


export default {
    GET_ACTIVITY,
    GET_ACTIVITY_COMPLETED,
    GET_ACTIVITY_FAILED
};
