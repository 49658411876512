import types from './types';

const count = ( data ) => ( {
    data,
    type: types.COUNT
} );

const histogram = ( ) => ( {
    type: types.HISTOGRAM,

} );

const search = ( ) => ( {
    type: types.SEARCH,

} );

const clear = ( ) => ( {
    type: types.CLEAR,

} );

export default {
    count,
    histogram,
    search,
    clear
};
