import React from 'react';
import TooltipWrapper from '../Tooltip/Tooltip';

function SidebarHeader(props) {
// return null;
// Uncomment following code lines to add Sidebar Header
    if ( Object.keys( props.currentAccount ).hasItems() && typeof props.currentAccount.accountName !== 'undefined' ) {
        return (
            <div className="sidebar-header" style={ { backgroundColor: '#131b1f' } }>
                <div>
                    <span className="accountName" style={ { color: 'white' } }>{props.currentAccount.accountName}</span>
                    <TooltipWrapper refId="mini" content={ props.currentAccount.accountName } />
                    <span
                        id="mini"
                        className="miniAccountName"
                        style={ { color: 'white' } }>
                        {props.currentAccount.accountName.charAt( 0 ).toLocaleUpperCase()}
                    </span>
                </div>
            </div>
        );
    }
    return ( '' );
}


export default SidebarHeader;
