

const formTemplate = {

    schema: {
        description: '',
        type: 'object',
        properties: {
            accountName: {
                title: 'Account Name',
                type: 'string',
                minLength: 3,
                maxLength: 256,
            },
            accountTimezone: {
                title: 'Account Timezone',
                type: 'string',
                minLength: 3,
                maxLength: 256,
            },
            accountType: {
                type: 'string',
                title: 'Account Type',
                enum: [
                    'Individual',
                    'Private Company',
                    'Public Company',
                    'Sole Trader',
                    'Partnership',
                    'Company limited by Guarantee',
                    'Unincorporated Joint Venture',
                ]
            },
            street: {
                title: 'Street',
                type: 'string',
                minLength: 3,
                maxLength: 100,
            },
            city: {
                title: 'City',
                type: 'string',
                minLength: 3,
                maxLength: 100,
            },
            postCode: {
                title: 'Post/Zip code',
                type: 'string',
                minLength: 4,
                maxLength: 10,
            },
            state: {
                title: 'State',
                type: 'string',
                minLength: 3,
                maxLength: 100,
            },
            country: {
                type: 'string',
                title: 'Country',
                enum: [
                    'Australia',
                    'Canada',
                    'New Zealand',
                    'United States'
                ]
            },
        },
        required: [
            'accountName',
            'accountTimezone',
            'accountType',
            'street',
            'city',
            'state',
            'postCode',
            'country',
        ],
        dependencies: {
            accountType: {
                oneOf: [
                    {
                        properties: {
                            accountType: {
                                enum: [
                                    'Individual'
                                ]
                            },
                            idCard: {
                                title: 'ID number',
                                type: 'string',
                                minLength: 4,
                                maxLength: 10,
                            }
                        },
                        required: [ 'idCard' ]
                    },
                    {
                        properties: {
                            accountType: {
                                enum: [
                                    'Private Company',
                                    'Public Company',
                                    'Sole Trader',
                                    'Partnership',
                                    'Company limited by Guarantee',
                                    'Unincorporated Joint Venture'
                                ]
                            },
                            company: {
                                title: 'Company Name',
                                type: 'string',
                                minLength: 3,
                                maxLength: 100,
                            },
                            abnacn: {
                                title: 'ABN / ACN',
                                type: 'string',
                                // pattern: '/^(\\s*|\\d+)$/',
                                maxLength: 30,
                            },
                            subpremise: {
                                title: 'Floor / Unit / Level',
                                type: 'string',
                            },
                        },
                        required: [ 'subpremise', 'company' ]
                    }
                ]

            }
        }
    },

};

export default formTemplate;
