import reduxThunk from 'redux-thunk';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import reduxMulti from 'redux-multi';
import api from './api';

export default [
    api,
    reduxThunk.withExtraArgument( ), // Thunk middleware for Redux
    reduxMulti, // Dispatch multiple actions
    reduxPromiseMiddleware(), // Resolve, reject promises with conditional optimistic updates
];
