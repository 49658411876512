import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';
import { buildUserPermissions } from '../currentUser/utils';

const initialState = {
    usersPermissions: {}
};

const currentAccount = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case types.SELECT_ACCOUNT: return action.payload.data;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'accounts' ) {
                // replace old current account data with newly changed value.
                if ( Object.keys( state ).hasItems() && state.id ) {
                    const newData = build( action.payload.data );
                    const current = newData.having( 'id', state.id ).firstOrNull();
                    if ( current ) {
                        return { ...state, ...current };
                    }
                    return build( action.payload.data )[0];
                }
                if ( action.payload.data.empty ) {
                    return state;
                }
                return { ...state, ...build( action.payload.data )[0] };
            }
            if ( action.payload.target === 'getUsersPermissions' ) {
                return { ...state, ...action.payload.data };
            }
            return state;
        case types.DELETE_ACCOUNT_COMPLETED:
            return {};
        default: return state;
    }
};

export default currentAccount;
