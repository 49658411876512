import React, { Component } from 'react';
import { connect } from 'react-redux';
import GroupsList from './GroupsList';
import AddGroupMembers from './addGroupMember';
import AddEscalationGroupMembers from './addEscalationGroupMember';
import AddGroup from './addGroup';
import { groupOperations } from '../../redux/ducks/groups';
import { GroupService } from '../../../Services';
import Display from '../Display';

class Groups extends Component {
    constructor( props ) {
        super( props );
        this.state = {};
        this.onDeleteUser = this.onDeleteUser.bind( this );
    }

    deleteBtn( callback, userId, groupId ) {
        return (
            <div><span
                className="icon-trash"
                style={ { marginRight: '10px', color: 'primary' } }
                onClick={ () => ( callback( userId, groupId ) ) }
                role="presentation" />
            </div>
        );
    }

    onDeleteUser( id ) {
        console.log( 'clicked on delete for id', id );
    }

    render() {
        const { isAdmin, isOwner } = this.props.currentUser;
        const names = GroupService.extractGroupNames( this.props.groups );
        return (
            <div className="accountSettingsLayout">
                <div className="row">
                    <div className={ isAdmin || isOwner ? 'col-lg-8' : 'col-lg-12' }>
                        <GroupsList />
                    </div>
                    <Display when={ isAdmin || isOwner }>
                        <div className="col-lg-4">
                            <AddGroup
                                create={ this.props.createGroup }
                                update={ this.props.updateGroupWithAcls }
                                accounts={ this.props.accounts }
                                documents={ this.props.documents }
                                events={ this.props.events }
                                names={ names }
                                accountId={ this.props.currentAccount.id } />
                            <AddGroupMembers
                                updateGroup={ this.props.updateGroup }
                                members={ this.props.members }
                                groups={ this.props.groups } />
                            <AddEscalationGroupMembers
                                updateGroup={ this.props.updateGroup }
                                members={ this.props.members }
                                groups={ this.props.groups } />
                        </div>
                    </Display>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ( {
    members: state.currentAccount.members,
    currentAccount: state.currentAccount,
    currentUser: state.currentUser,
    accounts: [ state.currentAccount ],
    documents: state.documents.list,
    events: state.events.calendarEvents,
    groups: state.groups
} );

const mapDispatchToProps = dispatch => ( {
    createGroup: ( data ) => dispatch( groupOperations.createGroup( data ) ),
    updateGroup: ( data ) => dispatch( groupOperations.updateGroup( data ) ),
    updateGroupWithAcls: ( data ) => dispatch( groupOperations.updateGroupWithAcls( data ) ),
} );
export default connect( mapStateToProps, mapDispatchToProps )( Groups );
