import React from 'react';
import { Route, Link } from 'react-router-dom';
import Breadcrumb from 'reactstrap/lib/Breadcrumb';
import BreadcrumbItem from 'reactstrap/lib/BreadcrumbItem';
import routes from '../../routes';
import Display from '../Display';

const findRouteName = url => {
    if ( url.includes( 'docvault' ) ) {
        return url.split( '/' ).last();
    }
    return routes[url];
};

const getPaths = ( pathname ) => {
    const paths = [ '/' ];
    if ( pathname === '/' ) return paths;

    pathname.split( '/' ).reduce( ( prev, curr, index ) => {
        const currPath = `${prev}/${curr}`;
        paths.push( currPath );
        return currPath;
    } );
    return paths;
};

const BreadcrumbsItem = ( { path, last } ) => {
    let routeName = findRouteName( path );
    if ( routeName === 'Home' ) {
        return null;
    }
    if ( routeName === 'docvault' ) {
        routeName = 'Doc Vault';
    }
    if ( routeName ) {
        return (
            window.location.pathname === path ? (
                <BreadcrumbItem active key={ Math.random() }>{ routeName }</BreadcrumbItem>
            ) : (
                <BreadcrumbItem className="truncate" key={ Math.random() }>
                    <Display when={ !last }>
                        <Link to={ path || '' }>
                            { routeName }
                        </Link>
                    </Display>
                    <Display when={ last }>
                        <span style={ { color: '#7b8e98' } }>
                            { routeName }
                        </span>
                    </Display>

                </BreadcrumbItem>
            )
        );
    }
    return null;
};

// const composeDynamicRoute

const Breadcrumbs = ( { location } ) => {
    const paths = getPaths( location );
    const items = paths.map( ( path, i ) => <BreadcrumbsItem key={ Math.random() } path={ path } last={ i === paths.length - 1 } /> );
    return (
        <div className="vaultBreadcrumb">
            <Breadcrumb key={ Math.random() }>
                {items}
            </Breadcrumb>
        </div>
    );
};

export default Breadcrumbs;
