// read about Object.prototype.toString.call at https://toddmotto.com/understanding-javascript-types-and-reliable-type-checking/#true-object-types
import axios from 'axios';
import axios2 from 'axios';
import axiosRetry from 'axios-retry';

const baseURL = process.env.google_functions_url || 'http://localhost:5001/contracts-dev-firestore/us-central1';

function addHeaders() {
    if ( typeof document !== 'undefined' ) {
        const sessionId = localStorage.getItem( 'sessionId' );
        const userId = localStorage.getItem( 'userId' );
        const userEmail = localStorage.getItem( 'userEmail' );
        const accountId = localStorage.getItem( 'accountId' );
        if ( typeof sessionId !== 'undefined' ) {
            return {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: `Bearer ${sessionId}`,
                userId,
                email: userEmail,
                accountId
            };
        }
    }
    return {
    // Accept: "application/json",
    // "Content-Type": "application/json"
    };
}

function requestHeaders( extraHeaders ) {
    return Object.assign(
        { },
        addHeaders(),
        extraHeaders
    );
}

export default ( url, method, extraHeaders, body ) => {
    const details = {
        baseURL,
        method,
        url,
        data: body,
        headers: requestHeaders( extraHeaders ),
        responseType: 'json' // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream',
    };
    return axios( details );
};

axiosRetry( axios2, { retries: 3,
    retryDelay: ( retryCount ) => retryCount * 2000 } );
export const baseAxios = () => axios2;
