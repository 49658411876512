import React from 'react';
import Select from 'react-select';
import { sortArrayByProperty } from '../../utils/sortArrayByProperty';
import Display from '../Display';


class AddEscalationGroupMembers extends React.Component {
    constructor() {
        super();
        this.state = {
            group: null,
            error: null
        };
        this.handleInputChange = this.handleInputChange.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.handleUserSelection = this.handleUserSelection.bind( this );
        this.handleGroupSelect = this.handleGroupSelect.bind( this );
    }

    handleInputChange( event ) {
        const { target } = event;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        if ( name === 'email' ) {
            value = value.toLocaleLowerCase();
        }

        this.setState( {
            [name]: value
        } );
    }

    handleUserSelection( userId ) {
        this.setState( { userId } );
    }

    handleGroupSelect( group ) {
        this.setState( { group } );
    }

    handleSubmit( event ) {
        event.preventDefault();
        if ( this.state.saving ) {
            return;
        }
        if ( !this.state.userId ) {
            this.setState( { error: 'Email is required', showEmailError: true } );
            return;
        }
        if ( !this.state.group ) {
            this.setState( { error: 'Group is required', showNameError: true } );
            return;
        }
        this.setState( { saving: true } );
        const selectedGroup = this.props.groups.having( 'id', this.state.group.value )[0];
        const ownersGroup = this.props.groups.having( 'name', 'Owners and admins' )[0];
        const noAccessGroup = this.props.groups.having( 'name', 'No Access' )[0];
        const readOnlyGroup = this.props.groups.having( 'name', 'Read Only' )[0];
        const usersGroup = this.props.groups.having( 'name', 'Users' )[0];
        const groups = [];
        if ( this.state.group.value === 'Owners and admins' ) {
            // check that the user does not exists in other groups first
            if ( typeof noAccessGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'No Access' ); }
            if ( typeof usersGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Users' ); }
            if ( typeof readOnlyGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Read Only' ); }
        }
        if ( this.state.group.value === 'users' ) {
            if ( typeof noAccessGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'No Access' ); }
            if ( typeof ownersGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Owners and admins' ); }
            if ( typeof readOnlyGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Read Only' ); }
        }
        if ( this.state.group.value === 'No Access' ) {
            if ( typeof usersGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Users' ); }
            if ( typeof ownersGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Owners and admins' ); }
            if ( typeof readOnlyGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Read Only' ); }
        }
        if ( this.state.group.value === 'read only' ) {
            if ( typeof usersGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Users' ); }
            if ( typeof ownersGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'Owners and admins' ); }
            if ( typeof noAccessGroup.members[this.state.userId.value] !== 'undefined' ) { groups.push( 'No Access' ); }
        }
        if ( groups.length > 0 ) {
            this.setState( { error: <span>Please remove user from groups <strong> {groups.join( ', ' )}</strong> before adding it to group <strong>{this.state.group.capitalize()}</strong></span>, showNameError: true, saving: false } );
        } else {
            const member = this.props.members[this.state.userId.value];
            selectedGroup.members[this.state.userId.value] = { email: member.email, role: member.access };
            delete selectedGroup.updatedAt;
            this.props.updateGroup( selectedGroup )
                .then( () => { this.setState( { group: null, userId: null, saving: false, error: null } ); } )
                .catch( () => { this.setState( { saving: false } ); } );
        }
    }

    render() {
        const members = Object.keys( this.props.members ).map( key => ( { value: key, label: this.props.members[key].email } ) );
        const user = this.state.userId ? this.state.userId : null;
        const group = this.state.group ? this.state.group : null;
        const escalationGroupsNames = this.props.groups.filter( group => group.escalation && !group.single).sort( ( a, b ) => sortArrayByProperty( a, b, 'name' ) );
        const options = escalationGroupsNames.map( entry => ( { value: entry.id, label: entry.name } ) );
        return (
            <div className="card white-box">
                <div className="card-header pt-0">
                    <i className="fa fa-align-justify" /> Add member to escalation group
                </div>
                <form onSubmit={ this.handleSubmit }>
                    <Display when={ this.state.error }>
                        <div style={ { color: 'red', fontSize: 12, marginBottom: 15 } }>{this.state.error}</div>
                    </Display>
                    <div className="form-group">
                        <label htmlFor="email">Email: </label>
                        <Select
                            options={ members }
                            value={ user }
                            className="customReactSelect"
                            onChange={ this.handleUserSelection }
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Group: </label>
                        <Select
                            options={ options }
                            value={ group }
                            className="customReactSelect"
                            onChange={ this.handleGroupSelect }
                        />
                    </div>
                    <button className="btn btn-outline-primary" type="submit">
                        { !this.state.saving && <span>Save</span> }
                        { this.state.saving && <span>...saving</span> }
                    </button>
                </form>
            </div>
        );
    }
}


export default AddEscalationGroupMembers;
