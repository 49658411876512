import React, { Component } from 'react';
import './weeklyViews.scss';
import Option from '../../Option';

class WeeklyView extends Component {
    constructor() {
        super();
        this.state = {
            interval: 1,
            byweekday: [ 'MO' ]
        };
        this.toggle = this.toggle.bind( this );
        this.updateOn = this.updateOn.bind( this );
    }

    componentWillMount() {
        if ( typeof this.props.data !== 'undefined' && Object.keys( this.props.data ).hasItems() && Object.keys( this.props.data.repeatOptions ).hasItems() ) {
            this.setState( { interval: this.props.data.repeatOptions.interval, byweekday: this.props.data.repeatOptions.byweekday ? this.props.data.repeatOptions.byweekday : [ 'MO' ] } );
        } else {
            this.props.callback( { type: 'WEEKLY', payload: { interval: this.state.interval, byweekday: this.state.byweekday } } );
        }
    }

    toggle() {
        if ( this.props.disabled ) {
            return;
        }
        this.setState( { repeat: !this.state.repeat } );
    }

    updateOccurances( value ) {
        if ( Number( value ) > 9999 || Number( value ) < 1 || this.props.disabled ) {
            return;
        }
        this.setState( { interval: value } );
        this.props.callback( { type: 'WEEKLY', payload: { interval: value, byweekday: this.state.byweekday } } );
    }

    updateOn( val ) {
        if ( this.props.disabled ) {
            return;
        }
        let newArray = [ ...this.state.byweekday ];
        if ( newArray.indexOf( val ) !== -1 ) {
            newArray = newArray.filter( x => x !== val );
        } else {
            newArray.push( val );
        }

        this.setState( { byweekday: newArray } );
        this.props.callback( { type: 'WEEKLY', payload: { interval: this.state.interval, byweekday: newArray } } );
    }

    render() {
        return (
            <React.Fragment>
        Every:
                <input
                    type="number"
                    className="texteditor-input"
                    min="1"
                    defaultValue="1"
                    disabled={ this.props.disabled }
                    value={ this.state.interval }
                    onChange={ e => this.updateOccurances( e.target.value ) } />
                <span>week(s)</span>
                <br />
        On:
                <div className="widget collection" style={ { marginBottom: 10 } }>
                    <Option callback={ this.updateOn } type="MO" text="Mo" selected={ this.state.byweekday.indexOf( 'MO' ) !== -1 } disabled={ this.props.disabled } />
                    <Option callback={ this.updateOn } type="TU" text="Tu" selected={ this.state.byweekday.indexOf( 'TU' ) !== -1 } disabled={ this.props.disabled } />
                    <Option callback={ this.updateOn } type="WE" text="We" selected={ this.state.byweekday.indexOf( 'WE' ) !== -1 } disabled={ this.props.disabled } />
                    <Option callback={ this.updateOn } type="TH" text="Th" selected={ this.state.byweekday.indexOf( 'TH' ) !== -1 } disabled={ this.props.disabled } />
                    <Option callback={ this.updateOn } type="FR" text="Fr" selected={ this.state.byweekday.indexOf( 'FR' ) !== -1 } disabled={ this.props.disabled } />
                    <Option callback={ this.updateOn } type="SA" text="Sa" selected={ this.state.byweekday.indexOf( 'SA' ) !== -1 } disabled={ this.props.disabled } />
                    <Option callback={ this.updateOn } type="SU" text="Su" selected={ this.state.byweekday.indexOf( 'SU' ) !== -1 } disabled={ this.props.disabled } />
                </div>
            </React.Fragment>
        );
    }
}

export default WeeklyView;
