import types from './types';

const renameFile = ( data, extraData ) => {
    const body = data;
    return {
        type: types.RENAME_FILE,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/renameFile',
            method: 'POST',
            body
        }
    };
};

export default {
    renameFile
};
