import types from './types';
import applicationTypes from '../application/types';

const initialState = [];

const categoriesBreadcrumbsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT:
            return [];
        case types.SELECT_CATEGORY:
            if ( action.payload.body ) {
                if ( action.payload.body instanceof Array ) {
                    return action.payload.body;
                }
                if ( action.payload.body instanceof Object ) {
                    return [ ...state, action.payload.body ];
                }
            }
            return [ ];
        default:
            return state;
    }
};

const categoriesBreadcrumbs = categoriesBreadcrumbsReducer;

export default categoriesBreadcrumbs;
