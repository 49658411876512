import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState( {}, document.title, window.location.pathname );

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext( Auth0Context );
export const Auth0Provider = ( {
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...initOptions
} ) => {
    const [ isAuthenticated, setIsAuthenticated ] = useState();
    const [ user, setUser ] = useState();
    const [ auth0Client, setAuth0 ] = useState();
    const [ loading, setLoading ] = useState( true );
    const [ popupOpen, setPopupOpen ] = useState( false );

    useEffect( () => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client( initOptions );
            setAuth0( auth0FromHook );

            if ( window.location.search.includes( 'error=' ) && window.location.pathname !== '/error' ) {
                // setIsAuthenticated( false );
                // setLoading( false );
                window.location.href = `/error${window.location.search}`;
                setTimeout( () => {
                    window.localStorage.removeItem( 'sessionId' );
                    window.localStorage.removeItem( 'userId' );
                    window.localStorage.removeItem( 'userEmail' );
                    auth0Client.logout( { returnTo: `${window.location.origin}/error${window.location.search}` } );
                }, 300 );
                return;
            }

            if (
                window.location.search.includes( 'code=' )
        && window.location.search.includes( 'state=' )
            ) {
                const { appState } = await auth0FromHook.handleRedirectCallback();
                onRedirectCallback( appState );
            }

            const isAuthenticated = await auth0FromHook.isAuthenticated();

            setIsAuthenticated( isAuthenticated );

            if ( isAuthenticated ) {
                const user = await auth0FromHook.getUser();
                const token = await auth0FromHook.getTokenSilently();
                user.token = token;
                window.localStorage.setItem( 'sessionId', token );
                user.uid = user.sub.split( '|' )[1];
                window.localStorage.setItem( 'userId', user.uid );
                window.localStorage.setItem( 'userEmail', user.email );
                setUser( user );
            }

            setLoading( false );
        };
        initAuth0();
    // eslint-disable-next-line
  }, []);

    const loginWithPopup = async ( params = {} ) => {
        setPopupOpen( true );
        try {
            await auth0Client.loginWithPopup( params );
        } catch ( error ) {
            console.error( error );
        } finally {
            setPopupOpen( false );
        }
        const user = await auth0Client.getUser();
        setUser( user );
        setIsAuthenticated( true );
    };

    const handleRedirectCallback = async () => {
        setLoading( true );
        await auth0Client.handleRedirectCallback();
        const user = await auth0Client.getUser();
        setLoading( false );
        setIsAuthenticated( true );
        setUser( user );
    };
    const logout = async ( params ) => {
        window.localStorage.removeItem( 'sessionId' );
        window.localStorage.removeItem( 'userId' );
        window.localStorage.removeItem( 'userEmail' );
        auth0Client.logout( { returnTo: window.location.origin } );
    };
    return (
        <Auth0Context.Provider
            value={ {
                isAuthenticated,
                user,
                loading,
                popupOpen,
                loginWithPopup,
                handleRedirectCallback,
                getIdTokenClaims: ( ...p ) => auth0Client.getIdTokenClaims( ...p ),
                loginWithRedirect: ( ...p ) => auth0Client.loginWithRedirect( ...p ),
                getTokenSilently: ( ...p ) => auth0Client.getTokenSilently( ...p ),
                getTokenWithPopup: ( ...p ) => auth0Client.getTokenWithPopup( ...p ),
                logout
            } }
        >
            {children}
        </Auth0Context.Provider>
    );
};
