import React from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import CategoriesGroupedSelector from '../CategoriesGroupedSelector/CategoriesGroupedSelector';
import Display from '../Display';
import SimpleSelector from '../SimpleSelector/SimpleSelector';

export default class EditResource extends React.Component {
    constructor() {
        super();
        this.state = {
            category: undefined,
            selectedDocument: false,
            selectedAccount: false,
            escalationTimeType: 'day',
            resourceType: 'category',
            escalationTime: 1,
            rules: { read: true }
        };
        this.handleInputChange = this.handleInputChange.bind( this );
        this.handleSubmit = this.handleSubmit.bind( this );
        this.changeAccount = this.changeAccount.bind( this );
        this.handleCategoryChange = this.handleCategoryChange.bind( this );
        this.handleResourceTypeChange = this.handleResourceTypeChange.bind( this );
        this.clickRules = this.clickRules.bind( this );
        this.changeDocs = this.changeDocs.bind( this );
        this.changeFiles = this.changeFiles.bind( this );
        this.changeEvents = this.changeEvents.bind( this );
    }

    componentWillMount() {
        const rules = {};
        this.props.selectedResource.acl[0].actions.map( x => { rules[x] = true; } );
        const catName = this.props.selectedResource.prettyResourceName.split( '::' )[2].split( ':' ).last();
        this.setState( { category: catName, addedCategories: [], rules, groupId: this.props.selectedResource.groupId, resId: this.props.selectedResource.id } );
    }

    handleInputChange( event ) {
        const { target } = event;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        if ( name === 'name' ) {
            value = value.toLocaleLowerCase();
        }

        this.setState( {
            [name]: value,
            showNameError: false
        } );
    }

    handleResourceTypeChange( e ) {
        this.setState( {
            resourceType: e.target.value,
            showNameError: false
        } );
        if ( e.target.value === 'account' ) {
            this.setState( { selectedAccount: this.props.accounts[0] } );
        }
    }

    handleCategoryChange( data ) {
        if ( this.state.addedCategories.contains( data ) ) {
            this.setState( { error: 'Rules already defined for selected category.', showCategoryError: true } );
            return;
        }
        this.setState( { category: data, showCategoryError: false, error: '' } );
    }

    handleSubmit( event ) {
        event.preventDefault();
        const { saving, category, name, escalationTime, escalationTimeType, resourceType } = this.state;
        const { create, update, accountId, names } = this.props;
        if ( saving || this.state.showCategoryError ) {
            return;
        }
        if ( !category && resourceType === 'category' ) {
            this.setState( { error: 'Category is required', showCategoryError: true } );
            return;
        }

        this.setState( { saving: true } );
        let resourceId = 'rn';
        let prettyResourceName = 'rn';
        // order DOES matter, it should respect the following rn::account:id::category:id::document:id::event:id
        // if it ends with * it means, all below, in case of document would be events and files.
        if ( this.state.selectedDocument ) {
            const documentAccount = this.props.accounts.having( 'id', this.state.selectedDocument.accountId )[0];
            prettyResourceName += `::account:${documentAccount.accountName}`;
            prettyResourceName += `::category:${this.state.selectedDocument.formData.location}`;
            prettyResourceName += `::document:${this.state.selectedDocument.formData.title}`;
            prettyResourceName += '::*';
            resourceId += `::account:${this.state.selectedDocument.accountId}`;
            resourceId += `::category:${this.state.selectedDocument.formData.location}`;
            resourceId += `::document:${this.state.selectedDocument.accountId}`;
            resourceId += '::*';
        }
        if ( this.state.selectedEvent ) {
            const documentAccount = this.props.accounts.having( 'id', this.state.selectedEvent.accountId )[0];
            prettyResourceName += `::account:${documentAccount.accountName}`;
            prettyResourceName += `::category:${this.state.selectedEvent.categoryNode}`;
            prettyResourceName += `::document:${this.state.selectedEvent.documentName}`;
            prettyResourceName += `::event:${this.state.selectedEvent.name}`;
            resourceId += `::account:${this.state.selectedEvent.accountId}`;
            resourceId += `::category:${this.state.selectedEvent.categoryNode}`;
            resourceId += `::document:${this.state.selectedEvent.documentId}`;
            resourceId += `::event:${this.state.selectedEvent.id}`;
        }
        if ( this.state.category ) {
            const selectedAccount = this.props.accounts[0];
            prettyResourceName += `::account:${selectedAccount.accountName}`;
            prettyResourceName += `::category:${this.state.category}`;
            prettyResourceName += '::*';
            resourceId += `::account:${selectedAccount.id}`;
            resourceId += `::category:${this.state.category.toLocaleLowerCase()}`;
            resourceId += '::*';
        }
        if ( this.state.selectedAccount ) {
            prettyResourceName += `::account:${this.state.selectedAccount.accountName}`;
            prettyResourceName += '::*';
            resourceId += `::account:${this.state.selectedAccount.id}`;
            resourceId += '::*';
        }
        const permissions = Object.keys( this.state.rules ).map( key => key.toLowerCase() );
        const groupId = uuidv1();

        const aclData = {
            resId: this.state.resId,
            permissions,
            resourceId,
            prettyResourceName,
            groupId,
            accountId
        };

        aclData.groupId = this.state.groupId;

        update( aclData )
            .then( () => {
                this.props.modalCallback();
                this.setState( { selectedDocument: null, selectedEvent: null, name: '', category: undefined, saving: false, escalationTime: 1, escalationTimeType: 'day' } );
            } )
            .catch( ( err ) => {
                this.setState( { saving: false } );
            } );
    }

    changeAccount( id ) {
        this.setState( {
            selectedAccount: this.props.accounts[0]
        } );
    }

    changeDocs( id ) {
        this.setState( {
            selectedDocument: this.props.documents.having( 'id', id )[0]
        } );
    }

    changeEvents( id ) {
        this.setState( {
            selectedEvent: this.props.events.having( 'id', id )[0]
        } );
    }

    changeFiles( data ) {
    }

    clickRules( data ) {
        const rules = { ...this.state.rules };
        if ( typeof rules[data] === 'undefined' ) {
            rules[data] = true;
            if ( data === 'create' ) {
                rules.update = true;
            }
            if ( data === 'update' ) {
                rules.create = true;
            }
        } else {
            if ( data === 'read' ) {
                // don't allow user to deselect read.
                return;
            }
            delete rules[data];
        }
        this.setState( { rules } );
    }

    render() {
        const resourceGroup = this.props.groups.having( 'id', this.props.selectedResource.groupId )[0];
        const { saving, name, showNameError, showCategoryError, error } = this.state;
        const { edit, accounts, documents, events } = this.props;
        const inputNameClassNames = showNameError ? 'form-control inputError' : 'form-control';
        const categoryClassNames = showCategoryError ? 'inputError' : '';
        const accountsList = accounts.map( account => ( { value: account.id, label: account.accountName } ) );
        const documentsList = documents.map( document => ( { value: document.id, label: document.formData.title } ) );
        const eventsList = events.map( event => ( { value: event.id, label: event.name } ) );
        const filesList = [];
        return (
            <div className="card white-box">
                <div className="card-header p-0">
                    <Display when={ showCategoryError || showNameError }>
                        <div className="error mb-3">{error}</div>
                    </Display>
                </div>
                <form onSubmit={ this.handleSubmit }>
                    <div className="form-group">
                        <Display when={ !this.props.edit }>
                            <label>Select a resource:</label>
                        </Display>
                        <Display when={ false }>
                            <React.Fragment>
                                <Display when={ this.props.edit }>
                                    <label>Add resource</label>
                                </Display>
                                <select
                                    className="form-control"
                                    name="resourceType"
                                    disabled
                                    value={ this.state.resourceType }
                                    onChange={ this.handleResourceTypeChange }>
                                    <option value="default">select resource type</option>
                                    {/* <option value="account">Account</option> */}
                                    <option value="category">Category</option>
                                    {/* <option value="document">Document</option> */}
                                    {/* <option value="event">Event</option> */}
                                    {/* <option value="file">File</option> */}
                                </select>
                            </React.Fragment>
                        </Display>
                    </div>
                    <Display when={ this.state.resourceType === 'account' }>
                        <div className="form-group">
                            <label htmlFor="email">Account:* </label>
                            <SimpleSelector value={ { value: this.state.selectedAccount.id, label: this.state.selectedAccount.accountName } } data={ accountsList } callback={ this.changeAccount } />
                        </div>
                    </Display>
                    <Display when={ this.state.resourceType === 'document' }>
                        <div className="form-group">
                            <label htmlFor="email">Document:* </label>
                            <SimpleSelector data={ documentsList } callback={ this.changeDocs } />
                        </div>
                    </Display>
                    <Display when={ this.state.resourceType === 'event' }>
                        <div className="form-group">
                            <label htmlFor="email">Event:* </label>
                            <SimpleSelector data={ eventsList } callback={ this.changeEvents } />
                        </div>
                    </Display>
                    <Display when={ this.state.resourceType === 'file' }>
                        <div className="form-group">
                            <label htmlFor="email">File:* </label>
                            <SimpleSelector data={ filesList } callback={ this.changeFiles } />
                        </div>
                    </Display>
                    <Display when={ this.state.resourceType === 'category' }>
                        <React.Fragment>
                            <div className="form-group">
                                <label htmlFor="email">Category:* </label>
                                <CategoriesGroupedSelector
                                    showAll
                                    showFirstLevel
                                    onChange={ this.handleCategoryChange }
                                    value={ this.state.category }
                                    categoryClassNames={ categoryClassNames }
                                />
                            </div>
                        </React.Fragment>
                    </Display>
                    <Display when={ this.state.resourceType !== 'default' }>
                        <div className="form-group">
                            <label htmlFor="email">Access rules:* </label>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div>
                                        <label style={ { width: '25%', minWidth: 60, maxWidth: 150 } }>Read </label>
                                        <input
                                            type="checkbox"
                                            checked={ this.state.rules.read }
                                            disabled={ resourceGroup.escalation }
                                            onChange={ () => { this.clickRules( 'read' ); } }
                                        />
                                    </div>
                                    <div>
                                        <label style={ { width: '25%', minWidth: 60, maxWidth: 150 } }>Create </label>
                                        <input
                                            type="checkbox"
                                            checked={ this.state.rules.create }
                                            disabled={ resourceGroup.escalation }
                                            onChange={ () => { this.clickRules( 'create' ); } }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div>
                                        <label style={ { width: '25%', minWidth: 60, maxWidth: 150 } }>Update </label>
                                        <input
                                            type="checkbox"
                                            checked={ this.state.rules.update }
                                            disabled={ resourceGroup.escalation }
                                            onChange={ () => { this.clickRules( 'update' ); } }
                                        />
                                    </div>
                                    <div>
                                        <label style={ { width: '25%', minWidth: 60, maxWidth: 150 } }>Delete </label>
                                        <input
                                            type="checkbox"
                                            checked={ this.state.rules.delete }
                                            disabled={ resourceGroup.escalation }
                                            onChange={ () => { this.clickRules( 'delete' ); } }
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Display>
                    <button className="btn btn-outline-primary" type="submit">
                        { !saving && <span>Save</span> }
                        { saving && <span>...saving</span> }
                    </button>
                </form>
            </div>
        );
    }
}
const { array, func, bool, string, object } = PropTypes;
EditResource.defaultProps = {
    edit: false,
    selectedGroup: {},
    modalCallback: () => {},
    create: () => {}
};
EditResource.propTypes = {
    names: array.isRequired,
    create: func,
    modalCallback: func,
    accountId: string.isRequired,
    edit: bool,
    selectedGroup: object,
};

function prepareCreateGroupData( data ) {
    const escalationTime = calculateEscalationTime( data.escalationTime, data.escalationTimeType );
    const payload = { ...data, escalationTime, single: data.single || false, deleted: false, members: {} };
    if ( data.resourceType !== 'category' ) {
        delete payload.escalationTime;
    }
    delete payload.escalationTimeType;
    delete payload.resourceType;
    delete payload.prettyResourceName;
    return payload;
}

function calculateEscalationTime( escalationTime, escalationTimeType ) {
    let time = 24;
    if ( escalationTimeType === 'day' ) {
        time = 24 * Number( escalationTime );
    } else {
        time = escalationTime;
    }
    return Number( time );
}
function prepareUpdateGroupData( data ) {
    const escalationTime = calculateEscalationTime( data.escalationTime, data.escalationTimeType );
    const payload = { ...data, escalationTime, single: data.single || false, deleted: false };
    if ( data.resourceType !== 'category' ) {
        delete payload.escalationTime;
        delete payload.category;
    }
    delete payload.escalationTimeType;
    delete payload.resourceType;
    delete payload.prettyResourceName;
    return payload;
}
