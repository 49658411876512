// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const SAVE_DOCUMENT_TYPES = 'SAVE_DOCUMENT_TYPES';
const SAVE_DOCUMENT_TYPES_COMPLETED = 'SAVE_DOCUMENT_TYPES_COMPLETED';
const SAVE_DOCUMENT_TYPES_FAILED = 'SAVE_DOCUMENT_TYPES_FAILED';

const ADD_MEMBER = 'ADD_MEMBER';
const ADD_MEMBER_COMPLETED = 'ADD_MEMBER_COMPLETED';
const ADD_MEMBER_FAILED = 'ADD_MEMBER_FAILED';

const DELETE_MEMBER = 'DELETE_MEMBER';
const DELETE_MEMBER_COMPLETED = 'DELETE_MEMBER_COMPLETED';
const DELETE_MEMBER_FAILED = 'DELETE_MEMBER_FAILED';

const UPDATE_MEMBER = 'UPDATE_MEMBER';
const UPDATE_MEMBER_COMPLETED = 'UPDATE_MEMBER_COMPLETED';
const UPDATE_MEMBER_FAILED = 'UPDATE_MEMBER_FAILED';

const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
const UPDATE_ACCOUNT_COMPLETED = 'UPDATE_ACCOUNT_COMPLETED';
const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';

const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
const DELETE_ACCOUNT_COMPLETED = 'DELETE_ACCOUNT_COMPLETED';
const DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED';

const SELECT_ACCOUNT = 'SELECT_ACCOUNT';

export default {
    SELECT_ACCOUNT,
    SAVE_DOCUMENT_TYPES,
    SAVE_DOCUMENT_TYPES_COMPLETED,
    SAVE_DOCUMENT_TYPES_FAILED,
    ADD_MEMBER,
    ADD_MEMBER_COMPLETED,
    ADD_MEMBER_FAILED,
    DELETE_MEMBER,
    DELETE_MEMBER_COMPLETED,
    DELETE_MEMBER_FAILED,
    UPDATE_MEMBER,
    UPDATE_MEMBER_COMPLETED,
    UPDATE_MEMBER_FAILED,
    UPDATE_ACCOUNT,
    UPDATE_ACCOUNT_COMPLETED,
    UPDATE_ACCOUNT_FAILED,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_COMPLETED,
    DELETE_ACCOUNT_FAILED
};
