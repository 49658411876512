import React from 'react';
import { connect } from 'react-redux';
import { currentUserOperations } from '../../redux/ducks/currentUser';
import { buildPermissionsDom } from '../../utils/buildPermissionsDom';
import { uploadsOperations } from '../../redux/ducks/uploads';
import { useAuth0 } from '../../auth0_provider';

const Profile = ( props ) => {
    const { user } = useAuth0();
    const { currentUser } = props;
    const permissionsDom = buildPermissionsDom( currentUser.prettyPermissions );
    const imgSrc = user.picture;
    return (
        <div className="accountSettingsLayout">
            <div className="row">
                <div className="col-lg-6 text-center">
                    <img src={ imgSrc } />
                </div>
                <div className="col-lg-6">
                    <div className="card white-box">
                        <div className="card-header  pt-0">
                            <i className="fa fa-align-justify" />  Profile information
                        </div>
                        <form aria-disabled>
                            <div className="form-group">
                                <label htmlFor="email">Name: </label>
                                <input
                                    className="form-control"
                                    name="displayName"
                                    value={ user.name }
                                    disabled
                                    type="text" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email: </label>
                                <input
                                    className="form-control"
                                    name="email"
                                    type="email"
                                    value={ user.email }
                                    disabled />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card white-box">
                        <div className="card-header pt-0">
                            <i className="fa fa-align-justify" /> Permissions
                        </div>
                        <div className="col-lg-12">
                            { permissionsDom }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ( {
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
    changeEmail: ( data ) => dispatch( currentUserOperations.changeEmail( data ) ),
    uploadAccountLogo: ( data ) => dispatch( uploadsOperations.uploadAccountLogo( data ) )
} );
export default connect( mapStateToProps, mapDispatchToProps )( Profile );
