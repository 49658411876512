import React from 'react';

class SidebarMinimizer extends React.Component {
    sidebarMinimize() {
        document.body.classList.toggle( 'sidebar-minimized' );
    }

    brandMinimize() {
        document.body.classList.toggle( 'brand-minimized' );
    }

    render() {
        return (
            <div style={ { height: 50, display: 'flex', justifyContent: 'space-between', backgroundColor: '#131b1f' } }>
                <div>
                    {/* <span className="accountName" style={ { lineHeight: '50px', color: 'white' } }>{this.props.currentAccount.accountName}</span> */}
                </div>
                <button className="sidebar-minimizer" type="button" onClick={ ( event ) => { this.sidebarMinimize(); this.brandMinimize(); } }></button>
            </div>
        );
    }
}

export default SidebarMinimizer;
