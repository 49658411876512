export const displayErrorFromServer = ( err ) => {
    if ( typeof err.err !== 'undefined' ) {
        if ( typeof err.err.response !== 'undefined' ) {
            if ( typeof err.err.response.data.message !== 'undefined' ) {
                return err.err.response.data.message;
            }
            return 'There was a problem. Please try again';
        }
        return 'There was a problem. Please try again';
    }
    if ( typeof err.response !== 'undefined' ) {
        if ( typeof err.response.data !== 'undefined' && err.response.data ) {
            return err.response.data.message;
        }
    }
    return 'There was a problem. Please try again';
};
