import React, { Component } from 'react';
import './switch.scss';

class Switch extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            status: props.repeat || false
        };
        this.toggle = this.toggle.bind( this );
    }

    toggle() {
        if ( this.props.disabled ) {
            return;
        }
        this.setState( { status: !this.state.status } );
        this.props.toggle( !this.state.status );
    }

    render() {
        let handleStyle = {
            transform: 'translateX(0%)',
            transition: 'none 0s ease 0s'
        };
        let innerSwitchStyle = {
            transform: 'translateX(-50%)',
            transition: 'none 0s ease 0s'
        };
        if ( this.state.status ) {
            handleStyle = {
                transform: 'translateX(-100%)',
                transition: 'none 0s ease 0s'
            };
            innerSwitchStyle = {
                transform: 'translateX(0%)',
                transition: 'none 0s ease 0s'
            };
        }
        return (
            <div className={ this.props.disabled ? 'switch disabled' : 'switch' }>
                <div className="switch-wrapper">
                    <div className={ this.state.status ? 'switch-container active' : 'switch-container' } onClick={ this.toggle }>
                        <div className="switch-inner noselect" style={ innerSwitchStyle }>
                            <div className="switch-on noselect">ON</div>
                            <div className="switch-handle" style={ handleStyle } />
                            <div className="switch-off noselect">OFF</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Switch;
