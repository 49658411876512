/* eslint-disable react/jsx-boolean-value */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import uuidv1 from 'uuid/v1';

import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import Summary from './Summary';
import DocumentDeletePopup from './DocumentDeletePopup';

import TooltipWrapper from '../Tooltip/Tooltip';
import TableView from '../Table/Table';
import Display from '../Display';
import { generateCan } from '../../utils/Can';
import canUserPerformAction from '../../utils/acls/canUserPerformAction';

function buildPartiesUI( parties ) {
    return parties.map( party => (
        <div key={ Math.random() }>
            { party.label.split( '(' )[0] }
            {' '}
(
            <b>{party.as }</b>
)
        </div>
    ) );
}

const columnWidths = [
    { columnName: 'name', width: '30%' },
    { columnName: 'category', width: '10%' },
    { columnName: 'parties', width: '20%' },
    { columnName: 'date', width: '10%' },
    { columnName: 'activity', width: '10%' },
    { columnName: 'action', width: '5%' },
];

const columns = [
    { name: 'name', title: 'Name' },
    { name: 'category', title: 'Category' },
    { name: 'parties', title: 'Parties' },
    { name: 'date', title: 'Updated' },
    { name: 'activity', title: 'Activity' },
    { name: 'action', title: 'Action' },
];

class DocumentsList extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            openSummary: false,
            openDeleteModal: false
        };
    }

    closedModal = () => {
        this.setState( { openSummary: false } );
    };

    closeDeleteModal = () => {
        this.setState( { openDeleteModal: false } );
    };

    buildDocumentRow = data => {
        const doc = data.formData;
        const now = moment( new Date() ); // todays date
        const end = moment( data.updatedAt ); // updated date
        const duration = moment.duration( end.diff( now ) );
        const days = duration.humanize( true );

        return ( {
            id: data.id,
            name: data.formData.title,
            category: data,
            parties: data,
            date: data.updatedAt,
            status: '100%',
            activity: days,
            action: data
        } );
    };

    render() {
        const { documentsForCategory } = this.props;
        const that = this;
        const { abilities } = generateCan( this.props.currentUser.acl );

        const docDOM = documentsForCategory && documentsForCategory.map( doc => that.buildDocumentRow( doc ) );

        const cellTemplates = {
            name: ( value, id ) => {
                const id2 = uuidv1();
                return (
                    <React.Fragment>
                        <div
                            key={ Math.random() }
                            style={ { cursor: 'pointer' } }
                            id={ `doc-${id2}` }
                            className="btn btn-link truncate"
                            onClick={ () => {
                                this.props.selectDocument( id );
                                if ( typeof this.props.redirect === 'undefined' || this.props.redirect ) {
                                    setTimeout( () => { this.props.history.push( `/edit-document?accountId=${this.props.currentAccount.id}&id=${id}` ); }, 250 );
                                }
                            } }>
                            { value }
                        </div>
                        <TooltipWrapper refId={ `doc-${id2}` } content={ value } />
                    </React.Fragment>
                );
            },
            date: ( value, id ) => (
                <div
                    key={ Math.random() }
                    style={ { cursor: 'pointer' } }
                    onClick={ () => {
                        this.props.selectDocument( id );
                        if ( typeof this.props.redirect === 'undefined' || this.props.redirect ) {
                            setTimeout( () => { this.props.history.push( `/edit-document?accountId=${this.props.currentAccount.id}&id=${id}` ); }, 250 );
                        }
                    } }>
                    { moment( value ).format( 'DD MMM YYYY' ) }
                </div>
            ),
            category: ( data, id ) => (
                <div
                    key={ Math.random() }
                    style={ { cursor: 'pointer' } }
                    onClick={ () => {
                        this.props.selectDocument( id );
                        if ( typeof this.props.redirect === 'undefined' || this.props.redirect ) {
                            setTimeout( () => {
                                this.props.history.push( `/edit-document?accountId=${this.props.currentAccount.id}&id=${id}` );
                            }, 250 );
                        }
                    } }>
                    { data.formData.location.split( '/' ).map( ( element ) => <div key={ Math.random() }><Badge key={ Math.random() } className="bg-primary" style={ { fontSize: 13 } }>{element}</Badge></div> ) }
                </div>
            ),
            parties: ( data, id ) => ( data.parties.length > 0
              && (
                  <div
                      key={ Math.random() }
                      style={ { cursor: 'pointer' } }
                      onClick={ () => {
                          this.props.selectDocument( data.id );
                          if ( typeof this.props.redirect === 'undefined' || this.props.redirect ) {
                              setTimeout( () => {
                                  this.props.history.push( `/edit-document?accountId=${this.props.currentAccount.id}&id=${id}` );
                              }, 250 );
                          }
                      } }>
                      {buildPartiesUI( data.parties )}
                  </div>
              ) ),
            activity: ( value, id ) => (
                <div key={ Math.random() }>
                    <div className="small text-muted">Last update</div>
                    {value}
                </div>
            ),
            action: ( data ) => {
                const id = uuidv1();
                const categoryName = data.formData.location.indexOf( '/' ) > -1 ? data.formData.location.split( '/' )[0] : data.formData.location;
                const canDelete = canUserPerformAction( 'delete', abilities, this.props.currentAccount.id, categoryName );

                return (
                    <div className="pull-left" key={ Math.random() }>
                        { canDelete && (
                            <React.Fragment>
                                <div
                                    id={ `docDeleteAction_${id}` }
                                    style={ { marginTop: '15px', marginLeft: 5, cursor: 'pointer' } }
                                    className="documentActions btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5 float-right"
                                    onClick={ () => {
                                        this.props.selectDocument( data.id );
                                        setTimeout( () => {
                                            this.setState( { openDeleteModal: true } );
                                        }, 200 );
                                    } }
                                >
                                    <div className="fa fa-trash-o" style={ { paddingTop: '6px' } } />
                                </div>
                                <TooltipWrapper refId={ `docDeleteAction_${id}` } content="Delete document" />
                            </React.Fragment>
                        )}

                        <TooltipWrapper refId={ `docSummaryAction_${id}` } content="View summary" />
                        <div
                            id={ `docSummaryAction_${id}` }
                            style={ { marginTop: '15px', marginLeft: 5, cursor: 'pointer' } }
                            className="documentActions btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5 float-right"
                            onClick={ () => {
                                this.props.selectDocument( data.id );
                                setTimeout( () => {
                                    this.setState( { openSummary: true } );
                                }, 200 );
                            } }>
                            <div className="fa fa-file-pdf-o" style={ { paddingTop: '6px' } } />
                        </div>

                        <TooltipWrapper refId={ `docOpenAction_${id}` } content="View document" />
                        <div
                            id={ `docOpenAction_${id}` }
                            style={ { marginTop: '15px', marginLeft: 5, cursor: 'pointer' } }
                            className="documentActions btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5 float-right"
                            onClick={ () => {
                                this.props.selectDocument( data.id );
                                if ( typeof this.props.redirect === 'undefined' || this.props.redirect ) {
                                    setTimeout( () => {
                                        this.props.history.push( `/edit-document?accountId=${this.props.currentAccount.id}&id=${data.id}` );
                                    }, 250 );
                                }
                            } }>
                            <div className="fa fa-file-o" style={ { paddingTop: '6px' } } />
                        </div>

                    </div>
                );
            }
        };

        return (
            <div className="customTable">
                <Summary
                    isEditDoc={ true }
                    list={ true }
                    open={ this.state.openSummary }
                    callback={ this.closedModal }
                />
                <Display when={ this.state.openDeleteModal }>
                    <DocumentDeletePopup
                        open={ this.state.openDeleteModal }
                        callback={ this.closeDeleteModal }
                    />
                </Display>
                <TableView
                    rowsDOM={ docDOM }
                    columns={ columns }
                    columnWidths={ columnWidths }
                    cellTemplates={ cellTemplates }
                    disableSortingFor={ [ 'category', 'parties', 'action' ] }
                    tableStyle={ { minHeight: 500 } }
                />
            </div>
        );
    }
}

const { array, func } = PropTypes;

DocumentsList.defaultProps = {
    position: []
};

DocumentsList.propTypes = {
    documentsForCategory: array.isRequired,
    position: array,
    selectDocument: func.isRequired
};

const mapStateToProps = state => ( {
    calendarEvents: state.events.calendarEvents,
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
    saveCalendarEvent: ( data ) => dispatch( calendarEventsOperations.saveCalendarEvent( data ) ),
    selectEvent: ( data ) => dispatch( calendarEventsOperations.selectEvent( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DocumentsList );
