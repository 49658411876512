const calculateEscalateTime = ( hours ) => {
    if ( ( hours < 24 && hours > 0 ) || ( hours > -24 && hours < 0 ) ) {
        return {
            time: hours,
            type: hours > 1 ? 'hour' : 'hour'
        };
    }
    if ( hours === 24 ) {
        return {
            time: 1,
            type: 'day'
        };
    }
    if ( hours === -24 ) {
        return {
            time: -1,
            type: 'day'
        };
    }
    if ( ( hours > 24 || hours < -24 ) && hours % 24 === 0 ) {
        return {
            time: hours / 24,
            type: 'day'
        };
    }
    return {
        time: hours,
        type: 'hour'
    };
};

export default calculateEscalateTime;
