import 'react-sortable-tree/style.css';
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import faker from 'faker';
import TooltipWrapper from '../Tooltip/Tooltip';


// -------------------------
// Create an drag source component that can be dragged into the tree
// https://react-dnd.github.io/react-dnd/docs-drag-source.html
// -------------------------
// This type must be assigned to the tree via the `dndType` prop as well
const externalNodeType = 'yourNodeType';
const externalNodeSpec = {
    // This needs to return an object with a property `node` in it.
    // Object rest spread is recommended to avoid side effects of
    // referencing the same object in different trees.
    beginDrag: componentProps => ( { node: { ...componentProps.node } } ),
};
const externalNodeCollect = ( connect /* , monitor */ ) => ( {
    connectDragSource: connect.dragSource(),
    // Add props via react-dnd APIs to enable more visual
    // customization of your component
    // isDragging: monitor.isDragging(),
    // didDrop: monitor.didDrop(),
} );

function externalNodeBaseComponent( props ) {
    const { connectDragSource, node } = props;
    const id = faker.lorem.word();
    return connectDragSource(
        <div className="rst__rowWrapper docType" style={ { marginRight: 0 } }>
            <div className="rst__row" style={ { opacity: 1 } }>
                <div className="rst__moveHandle" draggable="true" style={ { backgroundColor: node.color } } />
                <div className="rst__rowContents">
                    <TooltipWrapper refId={ id } content={ node.name } />
                    <div className="rst__rowLabel" id={ id }>
                        <span className="rst__rowTitle">
                            { node.name }
                        </span>
                    </div>
                </div>
            </div>
        </div>,
        { dropEffect: 'copy' }
    );
}

externalNodeBaseComponent.propTypes = {
    node: PropTypes.shape( { title: PropTypes.string } ).isRequired,
    connectDragSource: PropTypes.func.isRequired,
};
const YourExternalNodeComponent = DragSource(
    externalNodeType,
    externalNodeSpec,
    externalNodeCollect
)( externalNodeBaseComponent );

export default YourExternalNodeComponent;
