import types from './types';

const selectCategory = ( data ) => {
    const body = data;
    return {
        type: types.SELECT_CATEGORY,
        payload: {
            body
        }
    };
};


export default {

    selectCategory,
};
