import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Select from 'react-select';

import Display from '../Display';
import { optionsOperations } from '../../redux/ducks/options';
import { documentTypesOperations } from '../../redux/ducks/documentTypes';
import SpinnerModal from '../Modals/SpinnerModal';
import { sortAscendingByOptionTitle } from '../../utils/sortAscendingByOptionTitle';
import { sortArray } from '../../utils/sortArray';

class DefaultOptionAssign extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            toBeAssigned: [],
            name: '',
            mandatory: false,
            savingInProgress: false,
            listEntries: [],
            optionType: '',
            location: '',
            edit: false,
            allSelected: false,
            selectedFolder: null,
            allOptions: [],
            options: []
        };
    }


    UNSAFE_componentWillMount() {
        const availableOptions = this.props.options
            .filter( option => typeof option.defaultFor === 'undefined' || option.defaultFor.isEmpty() || !option.defaultFor.contains( this.props.currentDoc.id ) )
            .filter( option => this.props.currentDoc.options.having( 'id', option.id ).isEmpty() )
            .sort( sortAscendingByOptionTitle );
        this.setState( { allOptions: availableOptions, options: availableOptions } );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( nextProps.options.length !== this.props.options.length || typeof nextProps.currentDoc !== 'undefined' && nextProps.currentDoc.id !== typeof this.props.currentDoc === 'undefined' && this.props.currentDoc.id ) {
            const availableOptions = nextProps.options
                .filter( option => typeof option.defaultFor === 'undefined' || option.defaultFor.isEmpty() || !option.defaultFor.contains( nextProps.currentDoc.id ) )
                .filter( option => nextProps.currentDoc.options.having( 'id', option.id ).isEmpty() )
                .sort( sortAscendingByOptionTitle );
            this.setState( { allOptions: availableOptions, options: availableOptions } );
        }
    }


    unassign = optionId => {
        const newArray = this.state.toBeAssigned.except( 'id', optionId );
        this.setState( { toBeAssigned: newArray } );
    };

    assign = option => {
        const newArray = [ ...this.state.toBeAssigned, option ];
        this.setState( { toBeAssigned: newArray } );
    };

    save = () => {
        if ( this.state.toBeAssigned === '' || this.state.location === 'select a folder' ) {
            return;
        }
        if ( this.state.savingInProgress ) {
            return;
        }
        this.setState( { savingInProgress: true } );

        const newDoc = Object.assign( {}, this.props.currentDoc );
        if ( typeof newDoc.options === 'undefined' ) {
            newDoc.options = [];
        }
        this.state.toBeAssigned.map( ( option ) => {
            newDoc.options.push( option );
        } );
        const newData = {
            doc: newDoc,
            accountId: newDoc.accountId
        };
        this.props.saveDocument( newData )
            .then( () => {
                this.setState( { savingInProgress: false, open: false } );
                this.props.toggle();
            } )
            .catch( () => {
                this.setState( { savingInProgress: false } );
                // document.getElementsByClassName( 'modal' )[0].click();
            } );
    };

    selectFolder = option => {
        this.setState( { selectedFolder: option, allSelected: false } );
    };

    selectAllForCurrentFolder = () => {
        if ( this.state.selectedFolder && !this.state.allSelected ) {
            const opt = this.props.options.filter( option => option.folderName === this.state.selectedFolder.value );
            const newArray = [ ...this.state.toBeAssigned, ...opt ];
            this.setState( { toBeAssigned: newArray, allSelected: true } );
        }
        if ( this.state.selectedFolder && this.state.allSelected ) {
            const opt = this.props.options.filter( option => option.folderName === this.state.selectedFolder.value );
            this.setState( { toBeAssigned: this.state.toBeAssigned.filter( option => opt.contains( 'folderName', option.folderName ) ), allSelected: false } );
        }
    };

    renderOptionsTable = (options, folder = null) => {
        const that = this;
        let all = '';
        const body = options.map( option => (
            <div className="col-lg-12 optionRow" key={ Math.random() }>
                <div className="row">
                    <div className="col-lg-6">
                        { option.title.capitalize() }
                    </div>
                    <div className="col-lg-2">
                        { option.optionType.toUpperCase() }
                    </div>
                    <div className="col-lg-3">
                        { option.folderName }
                    </div>
                    <div className="col-lg-1">
                        <input
                            type="checkbox"
                            checked={ this.state.toBeAssigned.having( 'id', option.id ).hasItems() }
                            onChange={ () => { that.state.toBeAssigned.having( 'id', option.id ).hasItems() ? that.unassign( option.id ) : that.assign( option ); } }
                        />
                    </div>
                </div>
            </div>
        ) );
        if ( folder ) {
            all = (
                <input
                    type="checkbox"
                    style={ { position: 'absolute', right: 3, top: 13 } }
                    checked={ that.state.allSelected }
                    onChange={ that.selectAllForCurrentFolder }
                />
            );
        }
        return (
            <div className="col-lg-12">
                <div className="col-lg-12" style={ { backgroundColor: '#e4e5e6', marginBottom: 15, marginTop: 15 } }>
                    <div className="row">
                        <div className="col-lg-6 pl-0"><h6 className="header">Form field name</h6></div>
                        <div className="col-lg-2"><h6 className="header pl-0">Type</h6></div>
                        <div className="col-lg-3"><h6 className="header pl-0">Folder</h6></div>
                        <div className="col-lg-1 pl-0">
                            <h6 className="header pl-0">
                                <span style={ { position: 'absolute', right: 20 } }>Assign</span>
                                {' '}
                                {all}
                            </h6>
                        </div>
                    </div>
                </div>
                <div style={ { maxHeight: '250px', overflowY: 'scroll' } } id={ folder ? 'documentContent' : '' }>
                    { body }
                </div>
            </div>
        );
    };

    filterAllOptions = e => {
        if ( e.target.value === '' ) {
            this.setState( { allOptions: this.state.options } );
        }
        this.setState( { allOptions: this.state.options.filter( option => option.title.trim().toLocaleLowerCase().contains( e.target.value.trim().toLocaleLowerCase() ) ) } );
    };

    render() {
        const { open, toggle, folders, toggleCloneModal } = this.props;
        const { selectedFolder, allOptions, options, savingInProgress } = this.state;

        const availableFolders = folders.sort( sortArray ).map( folder => ( { value: folder, label: folder } ) );
        let selectedFoldersOptions = [];
        if ( selectedFolder ) {
            const currentOptions = options.filter( option => option.folderName === selectedFolder.value );
            selectedFoldersOptions = currentOptions.sort( sortAscendingByOptionTitle );
        }
        return (
            <Modal isOpen={ open } toggle={ toggle } className="optionsBuilderModal">
                <ModalHeader toggle={ toggle } style={ { width: '100%' } }>
                   Assign form field to document
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <Select
                                // defaultValue={ colourOptions[1] }
                                options={ availableFolders }
                                placeholder="Select a folder"
                                value={ selectedFolder }
                                simpleValue
                                menuContainerStyle={ { color: 'red' } }
                                className="customReactSelect"
                                onChange={ this.selectFolder }
                            />
                        </div>
                        <Display when={ selectedFoldersOptions.hasItems() }>
                            { this.renderOptionsTable( selectedFoldersOptions, true ) }
                        </Display>
                    </div>
                    <div className="row" style={ { marginTop: 5 } }>
                        <div className="col-lg-12">
                            <input
                                type="text"
                                placeholder="Search in all options"
                                onChange={ this.filterAllOptions }
                                className="form-control"
                                style={ { width: '100%' } }
                            />
                        </div>
                        { this.renderOptionsTable( allOptions, false ) }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        id="cloneDocument"
                        className="btn btn-primary btn-block col-lg-4 pull-left mt-0"
                        style={ { position: 'absolute', left: 15 } }
                        onClick={ toggleCloneModal }>Clone selected document type
                    </button>
                    <Button onClick={ this.save } id="saveFormFieldsBtn">
                        <Display when={ savingInProgress }>
                            <span>...saving</span>
                        </Display>
                        <Display when={ !savingInProgress }>
                            <span>Save</span>
                        </Display>
                    </Button>
                    <SpinnerModal showSpinner={ savingInProgress } />
                </ModalFooter>
            </Modal>
        );
    }
}

DefaultOptionAssign.propTypes = {
    toggle: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    folders: PropTypes.array.isRequired,
    currentAccount: PropTypes.object.isRequired,
    // setAccountId: PropTypes.func.isRequired,
};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    folders: state.options.folders,
    options: state.options.options,
    breadcrumbs: state.categories.breadcrumbs
} );

const mapDispatchToProps = dispatch => ( {
    saveOption: ( data ) => dispatch( optionsOperations.saveOption( data ) ),
    saveDocument: ( data ) => dispatch( documentTypesOperations.saveDocument( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DefaultOptionAssign );
