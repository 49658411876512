import React from 'react';
export const buildIconClassName = ( docName, size = 32 ) => {
    let iconName;

    switch ( docName.toLowerCase() ) {
        case 'contract':
            iconName = 'icon-briefcase';
            break;
        case 'document':
            iconName = 'icon-doc';
            break;
        default:
            iconName = 'icon-folder';
    }

    return <i className={ iconName } style={ { fontSize: size } } />;
};
