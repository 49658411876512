import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Account from '../../components/Account/Account';
import AddMember from '../../components/Member/AddMember';
import ListMembers from '../../components/Member/ListMembers';
import ListInvites from '../../components/Member/ListInvites';
import { invitesOperations } from '../../redux/ducks/invites';
import { currentAccountOperations } from '../../redux/ducks/currentAccount';
import ImageUpload from '../../components/Account/ImageUploader';
import { uploadsOperations } from '../../redux/ducks/uploads';
import { applicationOperations } from '../../redux/ducks/application';

class AccountView extends Component {
    static log( form ) {
    }

    constructor() {
        super();
        this.state = {};
    }

    componentWillMount() {
        this.props.getData( { dataset: 'getUsersPermissions', accountId: this.props.currentAccount.id, uid: this.props.user.uid } );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
    }

    componentWillUnmount() {
        const { currentAccount } = this.props;
        if ( Object.keys( currentAccount ).hasItems() ) {

        }
    }

    onSubmit = ( form, ownerRef ) => {
    // if the form data has an id - its an update
        if ( !form.formData.id ) {
            // this.create( form, ownerRef );
        } else {
            this.update( form );
        }
    };

    onDelete = form => {
        this.props.deleteAccount( { ...form, accountId: form.id, deleted: true, updatedAt: Date.now() } )
            .then( () => {
                this.setState( { isError: false, errorMessage: '' } );
            } )
            .catch( ( error ) => {
                this.setState( { isError: true, errorMessage: 'Error deleting account !' } );
                console.error( error );
            } );
    };

    update = form => {
        const data = form.formData;
        delete data.usersPermissions;
        this.props.updateAccount( { ...data, accountId: form.formData.id } )
            .then( () => {
                this.setState( { isError: false, errorMessage: '' } );
            } )
            .catch( ( error ) => {
                this.setState( { isError: true, errorMessage: 'Error updating account !' } );
                console.error( error );
            } );
    };

    create = ( form, ownerRef ) => {
        // firestore.collection( 'accounts' ).add( Object.assign(
        //     form.formData,
        //     { ownerRef },
        //     { deleted: false },
        //     { members: { [ownerRef]: { access: 'Owner', category: 'All', email: this.props.user.email, deleted: false } } }
        // ) )
        //     .then( () => {
        //         this.setState( { isError: false, errorMessage: '' } );
        //     } )
        //     .catch( ( error ) => {
        //         this.setState( { isError: true, errorMessage: 'Error creating account !' } );
        //         console.error( error );
        //     } );
    };

    render() {
        const { addMember, currentAccount, invites, currentUser, categories, groups } = this.props;
        const canAccess = currentUser ? !currentUser.isReadOnly : false;
        const isOwner = currentUser ? currentUser.isOwner : false;
        return (
            <div className="animated fadeIn">
                <div className="row">
                    <div className={ currentAccount ? 'col-lg-6' : 'col-lg-12' }>
                        <Account
                            isAdmin={ canAccess }
                            isOwner={ isOwner }
                            onSubmit={ this.onSubmit }
                            onDelete={ this.onDelete }
                            accountInfo={ currentAccount }
                            log={ AccountView.log }
                            uid={ currentUser.uid }
                        />
                        <div className="card white-box" style={ { marginBottom: 30 } }>
                            <div className="card-header pt-0">
                                <i className="fa fa-image" />Account logo
                            </div>
                            <ImageUpload
                                uploadAccountLogo={ this.props.uploadAccountLogo }
                                accountId={ currentAccount.id }
                                currentLogo={ this.props.currentAccount.logo }
                            />
                        </div>

                    </div>
                    { Object.keys( currentAccount ).hasItems()
                        && (
                            <div className="col-lg-6">
                                { canAccess
                            && (
                                <AddMember
                                    invites={ invites }
                                    currentAccount={ currentAccount }
                                    addMember={ addMember }
                                    groups={ groups }
                                    categories={ categories } />
                            )
                                }
                                <ListMembers
                                    canAccess={ canAccess }
                                    currentAccount={ currentAccount }
                                    deleteMember={ this.props.deleteMember }
                                    updateMember={ this.props.updateMember } />
                                <ListInvites
                                    canAccess={ canAccess }
                                    invites={ invites }
                                    deleteInvite={ this.props.deleteInvite }
                                    reissueInvite={ this.props.reissueInvite } />
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

const { object, array, string, func } = PropTypes;
AccountView.propTypes = {
    firestore: object,
    currentAccount: object.isRequired || null.isRequired,
    invites: array.isRequired,
    deleteInvite: func.isRequired,
    addMember: func.isRequired,
    deleteMember: func.isRequired,
    updateMember: func.isRequired,
};


const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    categories: state.categories,
    currentUser: state.currentUser,
    invites: state.invites,
    groups: state.groups
} );

const mapDispatchToProps = dispatch => ( {
    getData: ( data ) => dispatch( applicationOperations.getData( data ) ),
    deleteInvite: ( data ) => dispatch( invitesOperations.deleteInvite( data ) ),
    reissueInvite: ( data ) => dispatch( invitesOperations.reissueInvite( data ) ),
    deleteMember: ( data ) => dispatch( currentAccountOperations.deleteMember( data ) ),
    updateMember: ( data ) => dispatch( currentAccountOperations.updateMember( data ) ),
    addMember: ( data ) => dispatch( currentAccountOperations.addMember( data ) ),
    updateAccount: ( data ) => dispatch( currentAccountOperations.updateAccount( data ) ),
    uploadAccountLogo: ( data ) => dispatch( uploadsOperations.uploadAccountLogo( data ) ),
    deleteAccount: ( data ) => dispatch( currentAccountOperations.deleteAccount( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( AccountView );
