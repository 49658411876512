import React, { useState } from 'react';
import { connect } from 'react-redux';
import SpinnerModal from '../../components/Modals/SpinnerModal';

import { currentUserOperations } from '../../redux/ducks/currentUser';
import { applicationOperations } from '../../redux/ducks/application';
import { useAuth0 } from '../../auth0_provider';

const TermsAndConditions = ( props ) => {
    const [ accept, setAccept ] = useState( false );
    const [ isSubmitting, setIsSubmitting ] = useState( false );
    const { logout } = useAuth0();
    const handleSubmit = e => {
        e.preventDefault();
        setIsSubmitting( true );
        if ( !accept ) {
            document.getElementById( 'termAccept' ).style.color = 'red';
            document.getElementById( 'termReject' ).style.color = 'red';
            return;
        }

        props.saveTermsAndConditions( { response: accept } );
        setIsSubmitting( false );
        props.history.replace( '/dashboard' );
    };
    return (
        <div className="container" style={ { height: '100%' } }>
            <SpinnerModal showSpinner={ isSubmitting } />
            <img
                src="/img/mycontracts-logo-3-long-v3.svg"
                alt="mycontracts"
                width="250px"
                className="logo"
                style={ { margin: '30px auto', paddingLeft: 0 } } />

            <h2 style={ { color: '#5a5f61', textAlign: 'center', marginBottom: 30 } }>Terms and conditions</h2>
            <object width="100%" height="550px" data="/img/terms.pdf"></object>
            <form onSubmit={ handleSubmit }>
                <div className="field">
                    <label className="control-label" id="termAccept" style={ { marginRight: 10, marginTop: 10 } }>Accept Terms and conditions: </label>
                    <input
                        type="checkbox"
                        checked={ accept }
                        onChange={ () => { setAccept( !accept ); } }
                    />
                </div>
                <br />
                <button className="btn btn-outline-secondary pull-right" onClick={ logout }>Logout</button>
                { accept
        && <button className="btn btn-outline-success mr-3 pull-right" disabled={ isSubmitting } type="submit">Accept</button>
                }
            </form>
        </div>
    );
};

const mapStateToProps = state => ( {
    currentUser: state.currentUser
} );


const mapDispatchToProps = dispatch => ( {
    saveTermsAndConditions: ( data ) => dispatch( currentUserOperations.saveTermsAndConditions( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( TermsAndConditions );
