import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-bootstrap4';
import { Input } from 'reactstrap';
import moment from 'moment-timezone';
import DateIntervalSelector from '../Date/DateIntervalSelector';


const FilterIcon = ( { type } ) => {
    if ( type === 'month' ) {
        return (
            <span
                className="icon oi oi-calendar"
            />
        );
    }
    return <TableFilterRow.Icon type={ type } />;
};

class DateIntervalFilter extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            rangeValues: {
                start: undefined,
                end: undefined
            },
            operation: 'month'
        };
    }

    UNSAFE_componentWillMount() {
        if ( this.props.filter ) {
            if ( typeof this.props.filter.operation !== 'undefined' ) {
                this.setState( { operation: this.props.filter.operation, rangeValues: this.props.filter.value } );
            }
        }
    }

    handleFilterOperationChange = operation => {
        this.setState( {
            operation
        } );
    };

    handleFilterChange = (value, filter) => {
        this.setState( {
            ...this.state,
            [filter]: value
        }, () => {
            if ( this.state.operation !== 'between' ) {
                this.props.onFilter( { operation: this.state.operation, value } );
            } else {
                this.props.onFilter( { operation: this.state.operation, value: this.state.rangeValues } );
            }
        } );
    };

    renderFilterInput = () => {
        if ( this.state.operation === 'between' ) {
            return (
                <span className="range_filter_between">
                    <DateIntervalSelector
                        onChange={ ( e ) => this.handleFilterChange( e, 'rangeValues' ) }
                        value={ this.state.rangeValues }
                        format="DD/MM/YY" />
                </span>
            );
        }
        return (
            <Input
                onChange={ e => this.handleFilterChange( e.target.value, 'value' ) }
                placeholder="Filter..."
                type="text"
                value={ this.state.value }
            />
        );
    };

    toggleButton = props => {
        return <TableFilterRow.ToggleButton getMessage={ key => this.props.availableMessages[key] } { ...props } />;
    };

    render() {
        return (
            <React.Fragment>
                <TableFilterRow.FilterSelector
                    availableValues={ this.props.availableOptions }
                    value={ this.state.operation }
                    toggleButtonComponent={ this.toggleButton }
                    getMessage={ message => this.props.availableMessages[message] }
                    iconComponent={ FilterIcon }
                    onChange={ this.handleFilterOperationChange }
                />
                {this.renderFilterInput()}
            </React.Fragment>
        );
    }
}
export default DateIntervalFilter;
