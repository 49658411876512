import types from './types';

const updateInvite = ( data, extraData ) => {
    const body = data;
    return {
        type: types.UPDATE_INVITE,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/updateInvite',
            method: 'POST',
            body
        }
    };
};
const deleteInvite = ( data, extraData ) => {
    const body = data;
    return {
        type: types.DELETE_INVITE,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/deleteInvite',
            method: 'POST',
            body
        }
    };
};

const reissueInvite = ( data, extraData ) => {
    const body = data;
    return {
        type: types.REISSUE_INVITE,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/reissueInvite',
            method: 'POST',
            body
        }
    };
};

export default {
    updateInvite,
    deleteInvite,
    reissueInvite
};
