import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { Calendar } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import Display from '../Display'; // theme css file

class DateSelector extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            calendarDate: null,
            manualInput: '',
            error: null,
            showCalendar: false,
        };
        this.handleChange = this.handleChange.bind( this );
        this.manualInput = this.manualInput.bind( this );
        this.openCalendar = this.openCalendar.bind( this );
    }

    UNSAFE_componentWillMount() {
        const { value } = this.props;
        if ( value !== '' ) {
            this.setState( {
                calendarDate: moment( value ),
                manualInput: moment( value ).format( 'DD/MM/YYYY' )
            } );
        }
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        if ( nextProps.value !== this.props.value ) {
            const { value } = nextProps;
            if ( value !== '' ) {
                this.setState( {
                    calendarDate: moment( value ),
                    manualInput: moment( value ).format( 'DD/MM/YYYY' )
                } );
            }
        }
    }

    handleChange( which, payload ) {
        this.setState( {
            calendarDate: new Date( payload.setTime( payload.getTime() + ( 12 * 60 * 60 * 1000 ) ) ),
            manualInput: formatDateDisplay( payload ),
            showCalendar: false,
            error: null
        } );
        this.props.onChange( payload );
    }

    manualInput( e ) {
        const pattern = /^\d+$/;
        if ( !pattern.test( e.target.value.slice( -1 ) ) && e.target.value !== '' && e.target.value.length > this.state.manualInput.length ) {
            return;
        }
        if ( e.target.value.length > 10 ) {
            return;
        }
        let { value } = e.target;
        if ( ( e.target.value.length === 2 || e.target.value.length === 5 ) && this.state.manualInput.length < e.target.value.length ) {
            value += '/';
        }
        this.setState( {
            manualInput: value,
            error: null
        } );
        if ( e.target.value.length < 10 ) {
            this.setState( {
                error: 'Date must have the format DD/MM/YYYY'
            } );
            return;
        }
        if ( e.target.value.length === 10 ) {
            const dateValidity = moment( e.target.value, 'DD/MM/YYYY' )._isValid;
            if ( !dateValidity ) {
                this.setState( {
                    error: 'Incorrect value'
                } );
            } else {
                const year = e.target.value.split( '/' ).last();
                if ( Number( year.charAt( 0 ) ) === 0 || Number( year.charAt( 0 ) ) > 2 ) {
                    this.setState( {
                        error: 'Incorrect value'
                    } );
                    return;
                }
                this.setState( {
                    calendarDate: moment( e.target.value, 'DD/MM/YYYY' ),
                    error: null
                } );
                this.props.onChange( new Date( moment( e.target.value, 'DD/MM/YYYY' ).toISOString() ) );
            }
        }
    }

    openCalendar() {
        if ( this.props.disabled ) {
            return;
        }
        this.setState( { showCalendar: !this.state.showCalendar } );
    }

    render() {
        const calendarOptions = { date: this.state.calendarDate, onChange: this.handleChange.bind( this, 'calendarDate' ), className: 'PreviewArea' };
        if ( this.props.disablePreviousDates ) {
            calendarOptions.minDate = new Date();
        }
        return (
            <div className="">
                <span className="input-icon-wrap position-relative aligner">
                    <span
                        id="openCalendar"
                        style={ { position: 'absolute', left: 10, cursor: 'pointer' } }
                        className={ this.props.specialClass ? `input-icon ${this.props.specialClass}` : 'input-icon' }
                        onClick={ this.openCalendar }><span className="fa fa-calendar" />
                    </span>
                    <input
                        type="text"
                        style={ { paddingLeft: 32 } }
                        className="input-with-icon form-control customDateInput"
                        id="form-name"
                        autoComplete="off"
                        disabled={ this.props.disabled }
                        onChange={ this.manualInput }
                        placeholder="DD/MM/YY"
                        value={ this.state.manualInput }
                        onFocus={ e => e.target.select() }
                        onBlur={ () => { } }
                    />
                </span>

                <Display when={ this.state.error }>
                    <div style={ { color: 'red' } }>{this.state.error}</div>
                </Display>
                <Display when={ this.state.showCalendar }>
                    <Calendar { ...calendarOptions } />
                </Display>
            </div>
        );
    }
}

const { func, bool } = PropTypes;

DateSelector.defaultProps = {
    onChange: () => {},
    disabled: false,
    disablePreviousDates: false
};

DateSelector.propTypes = {
    onChange: func,
    disabled: bool,
    disablePreviousDates: bool
};

export default DateSelector;

function formatDateDisplay( date, defaultText ) {
    if ( !date ) return defaultText;
    return moment( date ).format( 'DD/MM/YYYY' );
}
