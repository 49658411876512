import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../assets/lf20_egjaXa.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const Loading = () => (
    <div
        style={ {
            position: 'absolute',
            display: 'flex',
          flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: '#EDF1F5',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        } }>
      {/*<div className="text-center">*/}
        <img
          className="loading-logo"
          src="https://static.mycontracts.cloud/wp-content/uploads/2018/11/mycontracts-logo-3-long-1x.png"
          alt="mycontracts.cloud" />
        <div className="loader text-center loadingText">retrieving identity...</div>
      {/*</div>*/}
        <div className="text-center flexCenter">
            <Lottie
                options={ defaultOptions }
                height={ 320 }
                width={ 320 }
                isStopped={ false }
                isPaused={ false }>
            </Lottie>

        </div>
    </div>
);

export default Loading;
