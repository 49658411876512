import types from './types';

const saveCalendarEvent = ( data, extraData ) => {
    const body = data;
    return {
        type: types.SAVE_CALENDAR_EVENT,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/calendarEvent',
            method: 'PUT',
            body
        }
    };
};

const selectEvent = ( data, extraData ) => ( {
    type: types.SELECT_EVENT,
    additionalData: extraData,
    payload: {
        data
    }
} );

const createCalendarEvent = ( data, extraData ) => {
    const body = data;
    return {
        type: types.SAVE_CALENDAR_EVENT,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/calendarEvent',
            method: 'POST',
            body
        }
    };
};
const resolveEvent = ( data, extraData ) => {
    const body = data;
    return {
        type: types.RESOLVE_EVENT,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/calendarEvent',
            method: 'PUT',
            body
        }
    };
};
const getRecurrenceDefinition = ( data, extraData ) => {
    const body = data;
    return {
        type: types.GET_RECURRENCE_DEFINITION,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/recurrenceDefinition',
            method: 'POST',
            body
        }
    };
};

const clearSelectedEvent = ( ) => ( {
    type: types.CLEAR_SELECTED_EVENT
} );

export default {
    getRecurrenceDefinition,
    saveCalendarEvent,
    createCalendarEvent,
    selectEvent,
    clearSelectedEvent,
    resolveEvent
};
