import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const DragHandle = SortableHandle( () => <span className="dragableHandle" /> ); // This can be any component you want

const SortableItem = SortableElement( ( { value, label, removeListEntry } ) => (
    <li>
        <DragHandle />
        {label}
        <span className="removeX" onClick={ () => { removeListEntry( value ); } }>x</span>
    </li>
) );

const SortableList = SortableContainer( ( { items, removeAction } ) => (
    <ul className="sortableListContent">
        {items.map( ( item, index ) => (
            <SortableItem
                key={ `item-${item.id}` }
                index={ index }
                label={ item.title }
                value={ item.id }
                removeListEntry={ removeAction }
            />
        ) )}
    </ul>
) );

const SortableListContent = ( props ) => {
    const { items, removeAction, update } = props;
    return (
        <SortableList
            useDragHandle
            helperContainer={ document.getElementsByClassName( 'sortableListContent' )[0] }
            items={ items }
            onSortEnd={ ( { oldIndex, newIndex } ) => {
                update( arrayMove( items, oldIndex, newIndex ) );
            } }
            removeAction={ removeAction } />
    );
};

export default SortableListContent;
