import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import ErrorBoundary from './ErrorBoundary';

const RestrictedRoute = ( {
    component: Component, currentAccount, user, ...rest
} ) => {
    let isUserAdmin = false;
    if ( !Object.keys( currentAccount ).hasItems() ) {
        return (
            <Alert color="danger">
                You need to create an account first!
            </Alert>
        );
    }
    // if ( user.email && Object.keys( currentAccount ).hasItems() ) {
    //     Object.keys( currentAccount.members ).map( key => {
    //         if ( currentAccount.members[key].email === user.email ) {
    //             isUserAdmin = currentAccount.members[key].access === 'Owner' || currentAccount.members[key].access === 'Admin';
    //         }
    //     } );
    // }
    // if ( !isUserAdmin ) {
    //     return (
    //         <Alert color="danger">
    //             Sorry, you do not have access here.
    //         </Alert>
    //     );
    // }
    return (
        <Route
            { ...rest }
            render={ ( props ) => {
                const NewProps = Object.assign( {}, props, {
                } );
                return (
                    <ErrorBoundary><Component { ...NewProps } /></ErrorBoundary>
                );
            } }
        />
    );
};
const { object } = PropTypes;
RestrictedRoute.defaultProps = {
    component: () => {}
};
RestrictedRoute.propTypes = {
    component: object
};
const mapStateToProps = state => ( {
    user: state.application.user,
    currentAccount: state.currentAccount
} );

export default connect( mapStateToProps )( RestrictedRoute );
