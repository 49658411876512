import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';
import applicationActions from '../application/actions';

const { clearDocument, addTempDocData, clearTempDoc } = actions;

const addDocument = ( data, isUpdate = false ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.addDocument( data, { user } ) )
        .then(
            ( res ) => {
                if ( typeof res === 'undefined' ) {
                    const message = 'Could not perform action';
                    const err = new Error( 'Could not perform action' );
                    dispatch( messageOperations.showError( message ) );
                    reject( err );
                    return;
                }
                if ( !isUpdate ) {
                    dispatch( messageOperations.showSuccess( 'Document added' ) );
                } else {
                    dispatch( messageOperations.showSuccess( 'Document updated' ) );
                }

                resolve( res );
            }
        )
        .catch( err => {
            dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
            reject( err );
        } );
} ) );

const updateDocument = ( data, isUpdate = false ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.updateDocument( data, { user } ) )
        .then(
            ( res ) => {
                if ( !isUpdate ) {
                    dispatch( messageOperations.showSuccess( 'Document added' ) );
                } else {
                    dispatch( messageOperations.showSuccess( 'Document updated' ) );
                }

                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const deleteDocument = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.deleteDocument( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Document deleted' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const selectDocument = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const selectedDocument = getState().documents.list.having( 'id', data )[0];
    dispatch( actions.selectDocument( selectedDocument ) );
} ) );

export default {
    addDocument,
    updateDocument,
    deleteDocument,
    selectDocument,
    clearDocument,
    addTempDocData,
    clearTempDoc
};
