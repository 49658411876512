import React from 'react';
import moment from 'moment-timezone';
import DateIntervalSelector from '../Date/DateIntervalSelector';

class DocumentDateIntervalSelector extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            moment: {
                start: moment(),
                end: moment().add( 3, 'days' )
            },
            valueChanged: false,
            externalValue: false
        };
    }

    onChange = data => {
        this.props.onChange( JSON.stringify( data ) );
    };

    UNSAFE_componentWillMount() {
        if ( typeof this.props.formData !== 'undefined' ) {
            const momentData = { ...JSON.parse( this.props.formData ) };
            this.setState( { moment: { start: moment( momentData.start ), end: moment( momentData.end ) }, externalValue: true } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( typeof nextProps.formData !== 'undefined' ) {
            const momentData = { ...JSON.parse( nextProps.formData ) };
            this.setState( { moment: { start: moment( momentData.start ), end: moment( momentData.end ) }, externalValue: true } );
        }
    }

    render() {
        return (
            <div>
                <label className="control-label" htmlFor={ this.props.idSchema.$id }>
                    { this.props.schema.title }
                    { this.props.required
                        && <span className="required">*</span>
                    }
                </label>
                <DateIntervalSelector onChange={ this.onChange } value={ this.props.formData } disabled={ this.props.readonly } />
            </div>
        );
    }
}

export default DocumentDateIntervalSelector;
