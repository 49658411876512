import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import OptionManager from './OptionManager';
import OptionBuilder from './OptionBuilder';

class OptionsList extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isOptionBuilderModalOpen: false
        };
    }

    toggleOptionBuilderModal = () => {
        this.setState( { isOptionBuilderModalOpen: !this.state.isOptionBuilderModalOpen } );
    };

    render() {
      const { toggle, open } = this.props;
        return (
            <Modal isOpen={ open } toggle={ toggle } className="customWidthModal">
                <ModalHeader toggle={ toggle }>Form fields manager</ModalHeader>
                <ModalBody>
                    <button className="btn btn-outline-primary btn-block col-lg-3 pull-right mt-0" onClick={ this.toggleOptionBuilderModal }>Create new form field</button>
                    <br />
                    <br />
                    <OptionManager />
                    { this.state.isOptionBuilderModalOpen
                    && <OptionBuilder toggle={ this.toggleOptionBuilderModal } open={ this.state.isOptionBuilderModalOpen } />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button id="saveBtn" className="" onClick={ toggle }>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const { } = PropTypes;
OptionsList.propTypes = {};

export default OptionsList;
