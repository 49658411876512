import actions from './actions';
import elasticsearchRequest from './utils';

const countRequest = ( data ) => async ( dispatch, getState ) => {
    const request = {
        type: 'COUNT',
        uid: getState().currentUser.uid,
        accountId: getState().currentAccount.id,
        indexName: data.indexName.toLowerCase(),
        sessionId: getState().application.user.accessToken
    };

    const result = await elasticsearchRequest( request );
    dispatch( actions.count( result ) );
};

export default {
    countRequest
};
