import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Button from 'reactstrap/lib/Button';
import {DateRange} from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import Display from '../Display';

class DateIntervalSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      existingValue: false,
      showCalendar: false,
      dateRange: {
        selection: {
          startDate: moment(),
          endDate: moment()
        },
      }
    };
    this.handleRangeChange = this.handleRangeChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    let value = {};
    if (typeof this.props.value !== 'undefined' && this.props.value !== '' && typeof this.props.value === 'string') {
      value = JSON.parse(this.props.value);
    } else {
      value = this.props.value;
    }
    if ( this.props.value !== '' )
    {
      const {start, end} = value;
      const selection = {};
      selection.startDate = moment(start);
      selection.endDate = moment(end);
      this.setState({dateRange: {selection}, existingValue: true});
      if (start !== '' && end !== '') {
        this.setState({dateRange: {selection}, existingValue: true});
      }
    }
  }


  handleRangeChange = payload => {
    const key = Object.keys(payload)[0];
    const {startDate, endDate} = payload[key];
    const selection = {
      startDate, endDate
    };
    this.setState({
      dateRange: {
        ...this.state.dateRange,
        selection
      },
      existingValue: true
    });
  };

  send = () => {
    const data = {
      start: new Date(this.state.dateRange.selection.startDate).toISOString(),
      end: new Date(this.state.dateRange.selection.endDate).toISOString()
    };
    this.setState({
      showCalendar: false
    });
    this.props.onChange(data);
  };

  clear = () => {
    const data = {
      start: '',
      end: ''
    };
    this.setState({
      dateRange: {
        ...this.state.dateRange,
        selection: {startDate: moment(), endDate: moment()}
      },
      showCalendar: false,
      existingValue: false
    });
    this.props.onChange(data);
  };

  render() {
    const {format = 'DD/MM/YYYY'} = this.props;
    return (
      <div className="simpleDateRangePicker">
                <span className="input-icon-wrap position-relative aligner">
                    <span
                      style={{position: 'absolute', left: 10, cursor: 'pointer'}}
                      className="input-icon"
                      onClick={() => {
                        if ( this.props.disabled ) {
                          return;
                        }
                        this.setState({showCalendar: !this.state.showCalendar});
                        positionPopup();
                      }}>
                        <span className="fa fa-calendar"/>
                    </span>
                    <input
                      type="text"
                      autoComplete="off"
                      // disabled={ this.props.disabled }
                      style={{paddingLeft: 32, cursor: 'pointer'}}
                      className="input-with-icon form-control customDateInput"
                      id="form-name"
                      disabled={this.props.disabled}
                      onClick={() => {
                        this.setState({showCalendar: !this.state.showCalendar});
                        positionPopup();
                      }}
                      onChange={() => {
                      }}
                      placeholder={`${format} -${format}`}
                      value={this.state.existingValue ? `${formatDateDisplay(this.state.dateRange.selection.startDate, format)} - ${formatDateDisplay(this.state.dateRange.selection.endDate, format)}` : ''}
                    />
                </span>
        <Display when={this.state.showCalendar}>
          <div id="rangeSelector">
            <div className="col-lg-12">
              <Button color="primary" onClick={this.send}>Confirm</Button>
              <Button color="secondary" outline onClick={this.clear} style={{marginLeft: 10}}>Clear</Button>
              <div
                className="fa fa-close"
                style={{position: 'absolute', right: 15, top: 5, fontSize: 16, cursor: 'pointer', padding: 5}}
                onClick={() => {
                  this.setState({showCalendar: false});
                }}
              />
            </div>
            <DateRange
              disabled={this.props.disabled}
              rangeColors={['#10B6F2', '#3ecf8e', '#fed14c']}
              onChange={this.handleRangeChange}
              moveRangeOnFirstSelection={false}
              id="dateRangeId"
              ranges={[this.state.dateRange.selection]}
              className="PreviewArea"
            />
          </div>
        </Display>
      </div>
    );
  }
}

const {func, string, bool, object, oneOfType} = PropTypes;

DateIntervalSelector.defaultProps = {
  onChange: () => {
  },
  value: '',
  disabled: false,
  format: 'DD/MM/YYYY'
};

DateIntervalSelector.propTypes = {
  onChange: func,
  value: oneOfType([string, object]),
  format: string,
  disabled: bool
};

export default DateIntervalSelector;

function formatDateDisplay(date, format = 'DD/MM/YYYY') {
  if (date) {
    return moment(date).format(format);
  }
}

function positionPopup() {
  setTimeout(() => {
    const popup = document.getElementById('rangeSelector');
    // if (document.getElementsByClassName('simpleDateRangePicker' ).length === 0 ) {return;}
    const parentPosition = document.getElementsByClassName('simpleDateRangePicker')[0].offsetTop;
    if (!popup) {
      return;
    }
    popup.style.zIndex = '999';
    if (window.innerWidth <= 1170) {
      popup.style.right = '15px';
    } else {
      popup.style.left = `${parentPosition.x}px`;
    }
    popup.style.top = `${ document.getElementsByClassName('simpleDateRangePicker')[0].offsetTop + 40}px`;
  }, 0);
}
