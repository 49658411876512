import types from './types';
const saveDocument = ( data, extraData ) => {
    const body = data;
    return {
        type: types.SAVE_DOCUMENT_TYPE,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/documentTypes',
            method: 'POST',
            body
        }
    };
};
const changeFolder = ( data ) => ( {
    type: types.CHANGE_FOLDER_FOR_DEFAULT_OPTIONS,
    payload: data
} );
const deleteDocument = ( data, extraData ) => {
    const body = data;
    return {
        type: types.DELETE_DOCUMENT_TYPE,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/documentTypes',
            method: 'DELETE',
            body
        }
    };
};
export default {
    saveDocument,
    changeFolder,
    deleteDocument
};
