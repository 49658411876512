import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import { css } from 'react-emotion';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';

const override = css`
    display: block;
     // margin: 0 auto;
    border-color: red;
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: 150px;
    background-size: 100%;
`;

class SpinnerModal extends React.Component {
    constructor() {
        super();
        this.state = {
            closeIt: false
        };
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( nextProps.showSpinner ) {
            setTimeout( () => { this.setState( { closeIt: true } ); }, 65000 );
        }
    }

    render() {
        return (
            <Modal isOpen={ !this.state.closeIt && this.props.showSpinner || false }>
                <BeatLoader
                    className={ override }
                    sizeUnit="px"
                    size={ 25 }
                    color="#87edb9"
                    loading
                />
            </Modal>
        );
    }
}

SpinnerModal.propTypes = {
    showSpinner: PropTypes.bool
};

export default SpinnerModal;
