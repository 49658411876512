import { generateCan } from '../../../utils/Can';
import canUserPerformAction from '../../../utils/acls/canUserPerformAction';

function buildBranch( cat, array, backup, docTypes, extra ) {
    if ( extra ) {
        const { accountId, currentUser } = extra;
        const acls = currentUser.acl;
        const categoryBranchResources = acls.filter( acl => acl.subject.filter( sub => sub.contains( `rn::account:${accountId}::category:${cat.name.toLocaleLowerCase()}:` ) ).length > 0 );
        const canReadAllCat = acls.filter( acl => acl.subject.filter( sub => sub.contains( `rn::account:${accountId}::category:all::*` ) ).length > 0 ).length > 0;
        if ( categoryBranchResources.length === 0 && !canReadAllCat ) {
        // user cannot see any resource on this category branch
            return;
        }
        // user has access to this entire category branch;
        extra = null;
    }

    if ( cat.type === 'docType' ) {
        docTypes.add( cat.name );
        backup.push( { breadcrumb: array.join( '/' ), type: cat.name } );
        return;
    }
    const _array = [ ...array, cat.name ];
    if ( cat.type === 'category' && cat.children && cat.children instanceof Array && cat.children.length > 0 ) {
        const _newArray = [ ..._array ];
        return cat.children.map( child => buildBranch( child, _newArray, backup, docTypes, extra ) );
    }
}

function iterateTree( categoriesArray = [], extra = null ) {
    const breadcrumbs = [];
    let readBreadcrumbs = [];
    let writeBreadcrumbs = [];
    const docTypes = new Set();
    let readDocTypes = new Set();
    let writeDocTypes = new Set();
    const { currentUser } = extra;
    let { isOwner } = currentUser;
    const acls = currentUser.acl;
    if ( !isOwner ) {
        isOwner = currentUser.isAdmin;
    }
    categoriesArray.map( category => buildBranch( category, [ ], breadcrumbs, docTypes, isOwner ? null : extra ) );
    if ( isOwner ) {
        readBreadcrumbs = [ ...breadcrumbs ];
        writeBreadcrumbs = [ ...breadcrumbs ];
        readDocTypes = docTypes;
        writeDocTypes = docTypes;
    } else {
        const { abilities } = generateCan( acls );
        breadcrumbs.map( entry => {
            if ( canUserPerformAction( 'read', abilities, extra.accountId, entry.breadcrumb.split( '/' )[0] ) ) {
                readBreadcrumbs.push( entry );
                readDocTypes.add( entry.type );
            }
            if ( canUserPerformAction( 'create', abilities, extra.accountId, entry.breadcrumb.split( '/' )[0] ) ) {
                writeBreadcrumbs.push( entry );
                writeDocTypes.add( entry.type );
            }
        } );
    }
    return {
        breadcrumbs,
        writeBreadcrumbs,
        readBreadcrumbs,
        docTypes: Array.from( docTypes ),
        readDocTypes: Array.from( readDocTypes ),
        writeDocTypes: Array.from( writeDocTypes )
    };
}

export {
    iterateTree,
};
