import React from 'react';
import { Table } from 'reactstrap';
import Display from '../Display';

export default class GroupItemsTable extends React.PureComponent {
    buildDOM() {
        const { data, deleteCallback, restrictedView, account, groupName } = this.props;

        return data.map( ( member, index ) => {
            const canDelete = groupName !== 'Owners and admins' ? true : account.ownerRef !== member.id;
            return (
                <tr key={ Math.random() }>
                    <td style={ { verticalAlign: 'middle' } }>{index + 1}</td>
                    <td>{member.email}</td>
                    <td>
                        <Display when={ !restrictedView && canDelete }>
                            <div><span
                                className="icon-trash"
                                style={ { marginRight: '10px', color: 'primary' } }
                                onClick={ () => ( deleteCallback( member.id ) ) }
                                role="presentation" />
                            </div>
                        </Display>
                    </td>
                </tr>
            );
        } );
    }

    render() {
        if ( this.props.data.isEmpty() ) {
            return <p className="text-center mt-2 mb-1">No data</p>;
        }
        return (
            <Table size="sm" responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { this.buildDOM() }
                </tbody>
            </Table>
        );
    }
}
