// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const PARSE_SUBSCRIPTION = 'PARSE_SUBSCRIPTION';
const CHECK_EMAIL = 'CHECK_EMAIL';
const SAVE_NEW_TOKEN = 'SAVE_NEW_TOKEN';
const LAST_TOKEN_UPDATE = 'LAST_TOKEN_UPDATE';
const SET_FILTER = 'SET_FILTER';
const VERIFY_INTERCOM_USER = 'VERIFY_INTERCOM_USER';
const SAVE_INTERCOM_SIGNITURE = 'SAVE_INTERCOM_SIGNITURE';
const GET_PERSONALIZATION = 'GET_PERSONALIZATION';
const GET_PERSONALIZATION_COMPLETED = 'GET_PERSONALIZATION_COMPLETED';
const GET_PERSONALIZATION_FAILED = 'GET_PERSONALIZATION_FAILED';

export default {
    GET_PERSONALIZATION,
    GET_PERSONALIZATION_COMPLETED,
    GET_PERSONALIZATION_FAILED,
    LOGIN,
    LOGOUT,
    PARSE_SUBSCRIPTION,
    CHECK_EMAIL,
    SAVE_NEW_TOKEN,
    LAST_TOKEN_UPDATE,
    SET_FILTER,
    VERIFY_INTERCOM_USER,
    SAVE_INTERCOM_SIGNITURE
};
