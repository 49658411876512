import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { categoryOperations } from '../../redux/ducks/categories';
import CategoryTree from './CategoryTree';
import { uploadsOperations } from '../../redux/ducks/uploads';

function Category( props ) {
    const { category, documentTypes, options, currentAccount, resetDefaultCategoryData, resetCategories, breadcrumbs, uploadCategory, addCategory, groups, resources } = props;
    // const { groupsArray, resourcesArray } = composeGroupsAndResources( groups, resources );
    return (
        <div className="animated fadeIn">
            <div className="container-fluid">
                <CategoryTree
                    category={ category }
                    uploadCategory={ uploadCategory }
                    breadcrumbs={ breadcrumbs }
                    resetCategories={ resetCategories }
                    resetDefaultCategoryData={ resetDefaultCategoryData }
                    addCategory={ addCategory }
                    currentAccount={ currentAccount }
                    documentTypes={ documentTypes }
                    options={ options }
                    categoryTreeDepth={ currentAccount.categoryTreeDepth }
                    groupsArray={ groups }
                    resourcesArray={ resources }
                />
            </div>
        </div>
    );
}


const { object, func } = PropTypes;
Category.propTypes = {
    firestore: object.isRequired,
    currentAccount: object.isRequired,
    category: object.isRequired,
    deleteCategory: func.isRequired,
    addCategory: func.isRequired
};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    category: state.categories.categoryTree,
    breadcrumbs: state.categories.breadcrumbs,
    documentTypes: state.documentTypes.list,
    options: state.options.options,
    groups: state.groups,
    resources: state.resources,
} );

const mapDispatchToProps = dispatch => ( {
    addCategory: ( data ) => dispatch( categoryOperations.addCategory( data ) ),
    resetCategories: ( data ) => dispatch( categoryOperations.resetCategories( data ) ),
    resetDefaultCategoryData: ( data ) => dispatch( categoryOperations.resetDefaultCategoryData( data ) ),
    deleteCategory: ( id ) => dispatch( categoryOperations.deleteCategory( id ) ),
    uploadCategory: ( data ) => dispatch( uploadsOperations.uploadCategory( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( Category );

// compose only groups that are related to categories.
// function composeGroupsAndResources( groups, resources ) {
//     const groupsArray = [];
//     const resourcesArray = [];
//     groups.map( group => {
//         if ( group.resources.hasItems() ) {
//             let validGroup = false;
//             group.resources.map( id => {
//                 const currentResource = resources.having( 'id', id )[0];
//                 if ( typeof currentResource !== 'undefined' ) {
//                     if ( currentResource.resourceId.indexOf( 'category' ) > -1 && currentResource.resourceId.indexOf( 'category:all::*' ) === -1 ) {
//                         validGroup = true;
//                         resourcesArray.push( currentResource );
//                     }
//                 }
//             } );
//             if ( validGroup ) {
//                 groupsArray.push( group );
//             }
//         }
//     } );
//     return { groupsArray, resourcesArray };
// }
