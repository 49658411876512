export default {
    items: [
        {
            id: 'dashboard',
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-speedometer',
        // badge: {
        //   variant: 'info',
        //   text: 'NEW'
        // }
        },
        {
            id: 'addDocument',
            name: 'Add Entry',
            url: '/add',
            icon: 'icon-doc'
        },
        // {
        //     divider: true
        // },
        {
            id: 'docVault',
            name: 'Doc Vault',
            url: '/docvault',
            icon: 'icon-layers'
        },
        {
            id: 'alerts',
            name: 'Alerts',
            url: '/alerts',
            icon: 'icon-exclamation'
        },
        {
            id: 'calendar',
            name: 'Calendar',
            url: '/calendar',
            icon: 'icon-calendar'
        },


        // {
        //     id: 'account',
        //     name: 'Account',
        //     url: '/account',
        //     icon: 'icon-notebook'
        // },
        {
            id: 'parties',
            name: 'Parties',
            url: '/parties',
            icon: 'icon-people'
        },
        {
            id: 'search',
            name: 'Search',
            url: '/filesearch',
            icon: 'icon-magnifier'
        },
        {
            id: 'activity',
            name: 'Activity',
            url: '/activity',
            icon: 'icon-list'
        },

        // {
        //   name: 'Upload',
        //   url: '/upload',
        //   icon: 'icon-cloud-upload'
        // },
        // {
        //     id: 'parties',
        //     name: 'Parties',
        //     url: '/parties',
        //     icon: 'icon-people'
        // },
    // {
    //   divider: true
    // },
    // {
    //   name: 'Try Analytics',
    //   url: 'https://mycontracts.cloud',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success'
    // },
    // {
    //   name: 'Try PRO',
    //   url: 'https://mycontracts.cloud',
    //   icon: 'icon-layers',
    //   variant: 'danger'
    // }
    ]
};
