/**
 * checks if there is defined an acl for the specified resource.
 * if acl is not defined, casl will return by default false, and that's not good.
 * @param {object} abilities
 * @param {string} resourceId
 * @return {boolean}
 */
function checkResourceAcl( abilities, resourceId ) {
    let aclDefinedForResourceId = false;
    abilities.rules.map( ( ability ) => {
        if ( ability.subject[0] === resourceId ) {
            aclDefinedForResourceId = true;
        }
    } );
    return aclDefinedForResourceId;
}

module.exports = checkResourceAcl;
