import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import uuidv1 from 'uuid/v1';

import { documentTypesOperations } from '../../redux/ducks/documentTypes';
import SpinnerModal from '../Modals/SpinnerModal';
import TableView from '../Table/Table';
import UploadTemplateModal from './UploadTemplateToDocType';
import { baseAxios } from '../../utils/fetchHelper';
import messageOperations from '../../redux/ducks/messages/operations';
import ActionDropdown from './ActionDropdown';
import fetchHelper from '../../utils/fetchHelper';
import Display from '../Display';
import { cleanSpecialChars, cleanString, fullStringClean } from '../../utils/cleanString';
import { displayErrorFromServer } from '../../utils/displayErrorFromServer';

const colorsArray = [ '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b' ];

class DocumentTypes extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            color: colorsArray[Math.floor( Math.random() * colorsArray.length )],
            docTypes: [],
            allDocTypes: [],
            error: null,
            isSubmitting: false
        };
        this.add = this.add.bind( this );
        this.removeError = this.removeError.bind( this );
        this.save = this.save.bind( this );
        this.delete = this.delete.bind( this );
        this.edit = this.edit.bind( this );
        this.buildRow = this.buildRow.bind( this );
        this.filterAllOptions = this.filterAllOptions.bind( this );
        this.toggleUploadModal = this.toggleUploadModal.bind( this );
        this.downloadTemplate = this.downloadTemplate.bind( this );
        this.upload = this.upload.bind( this );
        this.removeTemplate = this.removeTemplate.bind( this );
    }

    UNSAFE_componentWillMount( ) {
        if ( this.props.documentTypes ) {
            this.setState( { docTypes: this.props.documentTypes, allDocTypes: this.props.documentTypes } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.props.documentTypes !== nextProps.documentTypes ) {
            this.setState( { docTypes: nextProps.documentTypes, allDocTypes: nextProps.documentTypes } );
        }
    }

    add() {
        let name = document.getElementById( 'docType-name' ).value;
        if ( name === '' ) {
            document.getElementById( 'docName' ).classList.add( 'error' );
            this.setState( { nameErorr: 'Field is required' } );
            return;
        }
        if ( name.length !== cleanSpecialChars( name ).length ) {
            document.getElementById( 'docName' ).classList.add( 'error' );
            this.setState( { nameErorr: 'Name can only contain letters, numbers, comma, dash or underscore.' } );
            return;
        }
        name = fullStringClean( name );
        if ( this.props.documentTypes.filter( x => x.name.toLowerCase() === name.toLowerCase() ).hasItems() ) {
            document.getElementById( 'docName' ).classList.add( 'error' );
            this.setState( { nameErorr: 'Name has to be unique' } );
            return;
        }
        const id = uuidv1();
        if ( this.state.docTypes.having( 'id', id ).hasItems() ) {
            this.setState( { erorr: 'Name has to be unique' } );
        } else {
            this.setState( {
                color: colorsArray[Math.floor( Math.random() * colorsArray.length )],
                erorr: null,
                isSubmitting: true
            } );
            this.props.saveDocument( {
                doc: { name, id, color: this.state.color, accountId: this.props.currentAccount.id, options: [], deleted: false },
                accountId: this.props.currentAccount.id } )
                .then( () => { this.setState( { isSubmitting: false } ); } )
                .catch( () => { this.setState( { isSubmitting: false } ); } );
            document.getElementById( 'docType-name' ).value = '';
        }
    }

    removeError() {
        document.getElementById( 'docName' ).classList.remove( 'error' );
        this.setState( { nameErorr: null } );
    }

    save() {
        this.props.toggle();
    }

    delete( id ) {
        this.setState( prevState => ( { docTypes: prevState.docTypes.except( 'id', id ) } ) );
        this.props.deleteDocument( { doc: this.state.docTypes.having( 'id', id )[0], accountId: this.props.currentAccount.id } );
    }

    edit( name ) {
        this.props.changeFolder( name );
        this.props.toggle( );
        this.props.toggleDefaultOptions( );
    }

    upload( { id, name } ) {
        this.setState( { uploadModal: true, uploadTarget: id, docTypeName: name } );
    }

    removeTemplate( { id, name } ) {
        const { currentAccount, showNotificationSuccess, showNotificationError } = this.props;
        const storageRef = `/${currentAccount.id}/docTypeTemplates/${id}^${name}.dotx`;
        fetchHelper( 'deleteDocTypeTemplate', 'DELETE', null, { filename: storageRef, accountId: currentAccount.id } )
            .then( ( err ) => { showNotificationSuccess( 'Template file deleted' ); console.error( err ); } )
            .catch( ( err ) => { showNotificationError( displayErrorFromServer( err ) ); console.error( err ); } );
    }

    downloadTemplate( { id, name } ) {
        const { showNotificationError, showNotificationSuccess, currentAccount } = this.props;
        const storageRef = `/${currentAccount.id}/docTypeTemplates/${id}^${name}.dotx`;
        fetchHelper( 'requestDownloadUrl', 'POST', null, { filename: storageRef, uploadType: 'docTypeTemplate', accountId: currentAccount.id } )
            .then( ( result ) => {
                baseAxios()( {
                    url: `${result.data.downloadUrl}&alt=media`,
                    method: 'GET',
                    responseType: 'blob', // important
                } ).then( ( response ) => {
                    if ( typeof response !== 'undefined' ) {
                        showNotificationSuccess( 'Downloading starting' );
                    }

                    const url = window.URL.createObjectURL( new Blob( [ response.data ] ) );
                    const link = document.createElement( 'a' );
                    link.href = url;
                    link.setAttribute( 'download', `Template for ${name}.dotx` );
                    document.body.appendChild( link );
                    link.click();
                } )
                    .catch( ( err ) => { showNotificationError( 'Could not download file' ); console.error( err ); } );
            } )
            .catch( ( err ) => {
                console.log( err );
            } );
    }

    buildRow( data, del, edit, upload, download, removeTemplate ) {
        return data.sort( compareNames ).map( doc => (
            {
                name: doc.name,
                count: <div>{`(${doc.options.length})`}</div>,
                templateName: doc.hasTemplate ? <div><span>yes</span></div> : <div><span>no</span></div>,
                action: <ActionDropdown items={ composeDropdown( doc, del, edit, upload, download, removeTemplate ) } />
            }
        ) );
    }

    filterAllOptions( e ) {
        if ( e.target.value === '' ) {
            this.setState( prevState => ( { allDocTypes: prevState.docTypes } ) );
        }
        const { value } = e.target;
        this.setState( prevState => ( { allDocTypes: prevState.docTypes.filter( doc => doc.name.toLocaleLowerCase().contains( value.toLocaleLowerCase() ) ) } ) );
    }

    toggleUploadModal() {
        this.setState( prevState => ( { uploadModal: !prevState.uploadModal } ) );
    }

    render() {
        const data = this.buildRow( this.state.allDocTypes, this.delete, this.edit, this.upload, this.downloadTemplate, this.removeTemplate );
        return (
            <React.Fragment>
                <UploadTemplateModal
                    toggleUploadModal={ this.toggleUploadModal }
                    uploadModal={ this.state.uploadModal }
                    docTypeName={ this.state.docTypeName }
                    uploadTarget={ this.state.uploadTarget } />
                <Modal isOpen={ this.props.open } toggle={ this.props.toggle } style={ { minWidth: '60%' } }>
                    <SpinnerModal showSpinner={ this.state.isSubmitting } />
                    <ModalHeader toggle={ this.props.toggle }>Document types manager X</ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <div className="row mb-1">
                                <label className="control-label" htmlFor="docType-name">
                            New document type name
                                    <span className="required">*</span>
                                </label>
                                <div className="col-lg-12 p-0">
                                    <div id="docName" className="form-group field field-string">
                                        <input
                                            style={ { display: 'inline-block', width: '40%', paddingRight: 15 } }
                                            className="form-control"
                                            id="docType-name"
                                            required
                                            placeholder=""
                                            onChange={ this.removeError }
                                            type="text" />
                                        <div
                                            className="btn btn-outline-info"
                                            style={ { display: 'inline-block', marginLeft: 10 } }
                                            id="save"
                                            onClick={ this.add }>
                                        add
                                        </div>
                                        <br />
                                        <span className="text-danger" style={ { fontSize: 12 } }>{ this.state.nameErorr }</span>
                                    </div>

                                </div>

                            </div>
                            <div className="row mb-1">
                                <input
                                    type="text"
                                    id="docTypesSearchBar"
                                    placeholder="Search in all document types"
                                    onChange={ this.filterAllOptions }
                                    className="form-control"
                                    style={ { width: '100%' } }
                                />
                            </div>
                            <div className="customTable clickableRow limitHeight" style={ { margin: '0 -15px' } }>
                                <TableView
                                    rowsDOM={ data }
                                    columns={ [
                                        { name: 'name', title: 'Document type name' },
                                        { name: 'count', title: 'Fields' },
                                        { name: 'templateName', title: 'Template' },
                                        { name: 'action', title: 'Action' }
                                    ] }
                                    tableStyle={ { minHeight: 'auto' } }
                                    rowClickCallback={ () => {} }
                                    showFilter={ false }
                                    defaultFilters={ [ ] }
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="saveBtn" className="btn btn-outline-info" onClick={ this.save }>Close</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const { array, func, bool, obj } = PropTypes;

DocumentTypes.propTypes = {
    documentTypes: array,
    toggle: func.isRequired,
    saveDocument: func.isRequired,
    toggleDefaultOptions: func.isRequired,
    changeFolder: func.isRequired,
    deleteDocument: func.isRequired,
    showNotificationError: func,
    showNotificationSuccess: func,
    showNotificationWarning: func,
    open: bool.isRequired,
    currentAccount: obj
};

DocumentTypes.defaultProps = {
    currentAccount: {},
    documentTypes: [],
    showNotificationError: () => {},
    showNotificationWarning: () => {},
    showNotificationSuccess: () => {}
};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    documentTypes: state.documentTypes.list
} );

const mapDispatchToProps = dispatch => ( {
    saveDocument: ( data ) => dispatch( documentTypesOperations.saveDocument( data ) ),
    deleteDocument: ( data ) => dispatch( documentTypesOperations.deleteDocument( data ) ),
    changeFolder: ( name ) => dispatch( documentTypesOperations.changeFolder( name ) ),
    showNotificationError: ( message ) => dispatch( messageOperations.showError( message ) ),
    showNotificationWarning: ( message ) => dispatch( messageOperations.showWarning( message ) ),
    showNotificationSuccess: ( message ) => dispatch( messageOperations.showSuccess( message ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DocumentTypes );


function compareNames( a, b ) {
    if ( a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ) { return -1; }
    if ( a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ) { return 1; }
    return 0;
}

function composeDropdown( element, del, edit, upload, download, removeTemplate ) {
    return (
        <React.Fragment>
            <DropdownItem
                className=""
                id={ `download-${element.id}` }
                onClick={ () => del( element.id ) }
            >Delete type
            </DropdownItem>
            <DropdownItem
                className=""
                id={ `edit-${element.id}` }
                onClick={ () => edit( element.name ) }
            >Edit type
            </DropdownItem>
            <DropdownItem
                className=""
                id={ `upload-${element.id}` }
                onClick={ () => upload( { id: element.id, name: element.name } ) }
            >Upload template
            </DropdownItem>
            <Display when={ element.hasTemplate }>
                <DropdownItem
                    className=""
                    id={ `donwload-${element.id}` }
                    onClick={ () => download( { id: element.id, name: element.name } ) }
                >Download template
                </DropdownItem>
                <DropdownItem
                    className=""
                    id={ `remove-${element.id}` }
                    onClick={ () => removeTemplate( { id: element.id, name: element.name } ) }
                >Remove template
                </DropdownItem>
            </Display>
        </React.Fragment>
    );
}
