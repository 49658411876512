import React, { Component } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import Versions from './Versions';

class Aside extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            activeTab: '1'
        };
    }

    toggle = tab => {
        if ( this.state.activeTab !== tab ) {
            this.setState( {
                activeTab: tab
            } );
        }
    };

    render() {
        return (
            <aside className="aside-menu">
                <TabContent activeTab={ this.state.activeTab }>
                    <TabPane tabId="1" className="">
                        <Versions { ...this.props } />
                    </TabPane>
                </TabContent>
            </aside>
        );
    }
}

export default Aside;
