import React from 'react';
import * as Sentry from '@sentry/browser';

function logErrorToMyService( error, info ) {
    console.error( 'we have an error', error, info );
    Sentry.configureScope( scope => {
        Object.keys( info ).forEach( key => {
            scope.setExtra( key, info[key] );
        } );
    } );
    Sentry.captureException( error );
}

/**
 * catches errors and prevents the entire app from crashing in case of problem.
 */
class ErrorBoundary extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { hasError: false, error: null };
    }

    componentDidCatch( error, info ) {
    // Display fallback UI
        this.setState( { hasError: true, error } );
        // You can also log the error to an error reporting service
        logErrorToMyService( error, info );
    }

    render() {
        if ( this.state.hasError ) {
        // You can render any custom fallback UI
            return (
                <div>
                    <div>
                        <div className="col text-center mt-5">Darn, something went wrong. We'll make some coffee and wake up the developers.</div>
                    </div>
                    <div className="text-center mt-4">
                        <a style={ { color: 'red', textDecoration: 'underline' } } onClick={ () => Sentry.showReportDialog() }>Report feedback</a>
                    </div>
                    <div className="text-center mt-4">
                        <a className="btn btn-primary" href={ process.env.host }>Back to Homepage</a>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
