import React from 'react';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import PropTypes from 'prop-types';

class ActionDropdown extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            isDropDownOpen: false
        };
        this.toggle = this.toggle.bind( this );
    }

    toggle() {
        this.setState( prevState => ( {
            isDropDownOpen: !prevState.isDropDownOpen
        } ) );
    }

    render() {
        const { items } = this.props;

        return (
            <Dropdown isOpen={ this.state.isDropDownOpen } toggle={ this.toggle } className="dropdownContainer">
                <DropdownToggle caret color="white" className="font-lg">
                </DropdownToggle>
                <DropdownMenu right>
                    { items }
                </DropdownMenu>

            </Dropdown>

        );
    }
}

ActionDropdown.propTypes = {
    items: PropTypes.array.isRequired
};

export default ActionDropdown;
