import React from 'react';
import TimezonePicker from 'react-timezone';

class TimezoneSelector extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { value: props.formData };
    }

    onChange( timezone ) {
        this.setState( {
            value: timezone
        }, () => this.props.onChange( timezone ) );
    }

    render() {
        const { lat, lon } = this.state;
        return (
            <div id="timezonePickerHolder">
                <label className="control-label" htmlFor={ this.props.idSchema.$id }>
                    { this.props.schema.title }
                    { this.props.required
                && <span className="required">*</span>
                    }
                </label>
                <TimezonePicker
                    value={ this.state.value }
                    style={ { width: '100%' } }
                    onChange={ timezone => this.onChange( timezone ) }
                    inputProps={ {
                        placeholder: 'Select Timezone...',
                        name: 'timezone',
                        autoComplete: 'off',
                        disabled: this.props.readonly,
                        className: 'form-control'
                    } }
                />
            </div>
        );
    }
}

export default TimezoneSelector;
