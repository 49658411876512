import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import DocumentSearch from '../../components/Search/DocumentSearch';
import { documentOperations } from '../../redux/ducks/documents';
import VaultNavigator from './VaultNavigator/VaultNavigator';
import { categoriesBreadcrumbsOperations } from '../../redux/ducks/categoriesBreadcrumbs';

class DocumentVault extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            search: false
        };
        this.history = this.props.history;
    }

    UNSAFE_componentWillMount() {
        if ( this.props.category && Object.keys( this.props.category ).hasItems() && this.props.location.query ) {
            const desiredCatName = this.props.location.query.location.split( '/' )[0];
            const desiredCatId = this.props.category.treeData.having( 'name', desiredCatName )[0].id;
        }
    }


  searchMade = isSearch => {
      this.setState( { search: isSearch } );
  };

  render() {
      const { categoriesLoading, category, documents } = this.props;
      if ( !Object.keys( category ).hasItems() ) {
          window.location.href = '/account';
      }

      return (
          <div>
              <Row>
                  <DocumentSearch
                      currentAccount={ this.props.currentAccount }
                      history={ this.props.history }
                      callback={ this.searchMade }
                      auth={ this.props.auth }
                      selectDocument={ this.props.selectDocument } />
              </Row>
              {!this.state.search && <VaultNavigator history={ this.props.history } location={ this.props.location.pathname } /> }
          </div>

      );
  }
}

DocumentVault.propTypes = {
    category: PropTypes.object.isRequired,
    selectCategory: PropTypes.func.isRequired,
    categoriesLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ( {
    category: state.categories.categoryTree,
    categoriesLoading: state.application.categoriesLoading,
    documents: state.documents.list,
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
    selectCategory: ( data ) => dispatch( categoriesBreadcrumbsOperations.selectCategory( data ) ),
    selectDocument: ( data ) => dispatch( documentOperations.selectDocument( data ) )
} );


export default connect( mapStateToProps, mapDispatchToProps )( DocumentVault );
