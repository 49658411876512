import actions from './actions';
import applicationActions from '../application/actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';

// This is a link to an action defined in actions.js.
const { clearSelectedEvent } = actions;
const getRecurrenceDefinition = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.getRecurrenceDefinition( data, { user } ) )
        .then(
            ( res ) => {
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const selectEvent = ( data ) => ( dispatch, getState ) => {
    dispatch( actions.selectEvent( data ) );
    if ( typeof data.recurrencyId !== 'undefined' && data.recurrencyId.length > 0 ) {
        const payload = { id: data.recurrencyId, accountId: data.accountId };
        const { user } = getState().application;
        dispatch( actions.getRecurrenceDefinition( payload, { user } ) );
    }
};

const saveCalendarEvent = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, categories } = getState();
    dispatch( actions.saveCalendarEvent( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Event saved' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const createCalendarEvent = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, categories } = getState();
    dispatch( actions.createCalendarEvent( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Event created' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const resolveEvent = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount } = getState();
    dispatch( actions.resolveEvent( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Event updated' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

export default {
    saveCalendarEvent,
    createCalendarEvent,
    resolveEvent,
    selectEvent,
    clearSelectedEvent,
    getRecurrenceDefinition
};
