import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import nav from './_nav';
import SidebarFooter from '../SidebarFooter';
import SidebarForm from '../SidebarForm';
import SidebarHeader from '../SidebarHeader';
import SidebarMinimizer from '../SidebarMinimizer';
import MiniSpinner from '../Upload/MiniSpinner';
import UploadStatusModal from '../Upload/UploadStatusModal';
import Loading from '../Loading';

class Sidebar extends React.Component {
    handleClick( e ) {
        e.preventDefault();
        e.target.parentElement ? e.target.parentElement.classList.toggle( 'open' ) : '';
    }

    activeRoute( routeName, props ) {
        return props.location.pathname.indexOf( routeName ) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    }

    componentDidMount() {
        const anchor = document.querySelector( "a[href = \'/alerts\']" );
        if ( anchor ) {
            anchor.classList.add( 'hidePseudo' );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        const { overdue, today, tomorrow, week } = nextProps.eventsBreakdown;
        let criticalEvents = overdue + today;
        let warningEvents = tomorrow;
        const anchor = document.querySelector( "a[href = \'/alerts\']" );

        // reset alerts to 0
        criticalEvents = 0;
        warningEvents = 0;
        if ( anchor ) {
            anchor.classList.add( 'hidePseudo' );
        }

        // if ( criticalEvents > 0 ) {
        //     if ( anchor ) {
        //         anchor.setAttribute( 'data-content', criticalEvents );
        //         anchor.classList.remove( 'warn' );
        //         anchor.classList.remove( 'hidePseudo' );
        //     }
        // } else if ( warningEvents > 0 ) {
        //     if ( anchor ) {
        //         anchor.setAttribute( 'data-content', warningEvents );
        //         anchor.classList.add( 'warn' );
        //         anchor.classList.remove( 'hidePseudo' );
        //     }
        // }
    }

    // todo Sidebar nav secondLevel
    // secondLevelActive(routeName) {
    //   return this.props.location.pathname.indexOf(routeName) > -1 ?
    // "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    // }

    build( item, badge, variant, iconStyle ) {
        if ( item.name === 'Alerts' ) {
            return (
                <NavLink to={ item.url } className={ variant } activeClassName="active" data-content="0">
                    <i style={ iconStyle } className={ item.icon }></i>
                    {item.name}
                    {badge( item.badge )}
                </NavLink>
            );
        }
        return (
            <NavLink to={ item.url } className={ variant } activeClassName="active">
                <i style={ iconStyle } className={ item.icon }></i>
                {item.name}
                {badge( item.badge )}
            </NavLink>
        );
    }

    render() {
        const { props } = this;
        const { activeRoute } = this;
        const { handleClick } = this;
        const that = this;
        if ( this.props.currentAccount.maintenance ) {
            return (
                <div className="sidebar">
                    <SidebarMinimizer currentAccount={ this.props.currentAccount } />
                    <SidebarHeader currentAccount={ this.props.currentAccount } />
                    <SidebarFooter />
                </div>
            );
        }

        const iconStyle = {
            size: '25px'
        };

        // badge addon to NavItem
        const badge = ( badge ) => {
            if ( badge ) {
                const classes = classNames( badge.class );
                return ( <Badge className={ classes } color={ badge.variant }>{ badge.text }</Badge> );
            }
        };

        // simple wrapper for nav-title item
        const wrapper = item => ( item.wrapper && item.wrapper.element
            ? ( React.createElement( item.wrapper.element, item.wrapper.attributes, item.name ) ) : item.name );

        // nav list section title
        const title = ( title, key ) => {
            const classes = classNames( 'nav-title', title.class );
            return (
                <li key={ key } className={ classes }>
                    {wrapper( title )}
                    {' '}
                </li>
            );
        };

        // nav list divider
        const divider = ( divider, key ) => ( <li key={ key } className="divider"></li> );

        // nav item with nav link
        const navItem = ( item, key ) => {
            const classes = classNames( item.class );
            const isExternal = ( url ) => url.substring( 0, 4 ) === 'http';
            const variant = classNames( 'nav-link', item.variant ? `nav-link-${item.variant}` : '' );
            return (
                <NavItem key={ key } className={ classes }>
                    { isExternal( item.url )
                        ? (
                            <RsNavLink href={ item.url } className={ variant } active data-content="1">
                                <i style={ iconStyle } className={ item.icon }></i>
                                {item.name}
                                {badge( item.badge )}
                            </RsNavLink>
                        )
                        : this.build( item, badge, variant, iconStyle )
                    }
                </NavItem>
            );
        };

        // nav dropdown
        const navDropdown = ( item, key ) => (
            <li key={ key } className={ activeRoute( item.url, props ) }>
                <a
                    className="nav-link nav-dropdown-toggle"
                    href="#"
                    onClick={ handleClick.bind( this ) }>
                    <i className={ item.icon }></i>
                    {item.name}
                </a>
                <ul className="nav-dropdown-items">
                    {navList( item.children )}
                </ul>
            </li>
        );

        // nav link
        const navLink = ( item, idx ) => ( item.title ? title( item, idx )
            : item.divider ? divider( item, idx )
                : item.children ? navDropdown( item, idx )
                    : navItem( item, idx ) );

        // nav list
        const navList = ( items ) => {
            let newItems = items;
            if ( !Object.keys( that.props.categoryTree ).hasItems() || Object.keys( that.props.currentAccount ).isEmpty() ) {
                newItems = items.except( 'id', 'addDocument' );
                newItems = newItems.except( 'id', 'docVault' );
                newItems = newItems.except( 'id', 'alerts' );
                newItems = newItems.except( 'id', 'calendar' );
                newItems = newItems.except( 'id', 'parties' );
            }
            if ( this.props.currentUser && ( this.props.currentUser.isReadOnly ) ) {
                newItems = newItems.except( 'id', 'addDocument' ).except( 'id', 'parties' );
            }
            if ( this.props.currentUser && ( !this.props.currentUser.canCreate ) ) {
                newItems = newItems.except( 'id', 'addDocument' );
            }
            if ( this.props.currentUser && ( this.props.currentUser.isDefault ) ) {
                newItems = [];
            }
            return newItems.map( ( item, index ) => navLink( item, index ) );
        };

        // sidebar-nav root
        return (
            <div className="sidebar">
                <SidebarMinimizer currentAccount={ this.props.currentAccount } />
                <SidebarHeader currentAccount={ this.props.currentAccount } />
                { process.env.firestore_projectId === 'contracts-dev-firestore' && window.location.host === 'localhost:8080'
                  && <Badge className="badge-danger p-3">DEVELOPMENT</Badge>
                }
                { process.env.firestore_projectId === 'contracts-demo-firestore' && window.location.host === 'localhost:8080'
                  && <Badge className="badge-danger p-3">DEMO</Badge>
                }
                <SidebarForm />
                <nav className="sidebar-nav">
                    <Nav>
                        {navList( nav.items )}
                    </Nav>
                </nav>
                <MiniSpinner />
                <UploadStatusModal />
                <SidebarFooter />
            </div>
        );
    }
}

const mapStateToProps = state => ( {
    eventsBreakdown: state.events.breakdown,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
} );

export default connect( mapStateToProps, mapDispatchToProps )( Sidebar );
