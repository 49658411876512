import { combineReducers } from 'redux';
import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';

const initialState = null;

const optionsReducer = ( state = null, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT:
            return [];
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'options' ) {
                const options = build( action.payload.data );
                return options.hasItems() ? options : null;
            }
            return state;
        default:
            return state;
    }
};

const foldersReducer = ( state = [], action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT:
            return [];
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'options' ) {
                const options = build( action.payload.data );
                return options.hasItems() ? Array.from( new Set( options.map( option => option.folderName ) ) ) : [];
            }
          return state;
        default:
            return state;
    }
};

const categories = combineReducers( {
    options: optionsReducer,
    folders: foldersReducer
} );

export default categories;
