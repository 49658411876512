/* eslint-disable react/jsx-boolean-value */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Display from '../Display';
import { partiesOperations } from '../../redux/ducks/parties';
import shallowCompare from '../../utils/shallowCompare';
import TableView from '../Table/Table';
import CalendarEventAction from '../CalendarEvent/CalendarEventAction';
import { versionOperations } from '../../redux/ducks/version';

const columns = [
    { name: 'name', title: 'Name' },
    { name: 'type', title: 'Type' },
    { name: 'contactPerson', title: 'Contact person' },
    { name: 'state', title: 'State' },
    { name: 'action', title: 'Action' }
];
const columnWidths = [
    { columnName: 'name', width: '40%' },
    { columnName: 'type', width: '10%' },
    { columnName: 'contactPerson', width: '40%' },
    { columnName: 'state', width: '10%' },
    { columnName: 'action', width: '10%' }
];

const buildDocumentRow = ( data, toggle ) => data.map( ( party ) => ( {
    id: party.id,
    rowStyling: {},
    name: party.partyName,
    type: party.partyType,
    contactPerson: party.contactPerson,
    state: party.state,
    action: <CalendarEventAction event={ party } asideToggle={ toggle } />
} ) );

class PartiesList extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            open: false,
            operation: 'edit',
            cannotAccess: false
        };
        this.asideToggle = this.asideToggle.bind( this );
    }

    UNSAFE_componentWillMount() {
        if ( window.location.pathname !== '/alerts' ) {
            return;
        }
        const params = qs.parse( window.location.search );

        if ( typeof params.id === 'undefined' || typeof params.accountId === 'undefined' ) {
            window.history.replaceState( null, null, window.location.pathname );
            return;
        }
        const desiredParty = this.props.parties.having( 'id', params.id )[0];
        if ( typeof desiredParty !== 'undefined' ) {
            this.setState( { open: true, operation: 'edit', selectedId: params.id } );
            setTimeout( () => {
                this.action();
            }, 150 );
        } else {
            this.setState( { cannotAccess: true, desiredParty: params.id } );
            // this will remove the query params from the url.
            window.history.replaceState( null, null, window.location.pathname );
        }
    }

    shouldComponentUpdate( nextProps, nextState ) {
        return shallowCompare( this, nextProps, nextState );
    }

    UNSAFE_componentWillUpdate( nextProps ) {
        if ( nextProps.parties !== this.props.parties ) {
            this.props.refreshEsQuery();
        }
    }

    edit = id => {
        this.setState( { open: true, operation: 'edit', selectedId: id } );
    };

    delete = id => {
        this.setState( { open: true, operation: 'delete', selectedId: id } );
    };

    action = () => {
        if ( this.state.inProgress ) {
            return;
        }
        if ( this.state.operation === 'edit' ) {
            const selectedParty = this.props.parties ? this.props.parties.having( 'id', this.state.selectedId )[0] : this.props.externalData;
            this.props.selectParty( selectedParty );
            setTimeout( () => {
                this.setState( { open: false, inProgress: false } );
                document.getElementById( 'create_new_party' ).click();
            }, 250 );
        } else {
            this.setState( { inProgress: true } );
            this.props.deleteParty( { partyId: this.state.selectedId, accountId: this.props.currentAccount.id } )
                .then( () => { this.setState( { open: false, inProgress: false } ); } )
                .catch( () => { this.setState( { inProgress: false } ); } )
                .finally( () => this.props.refreshEsQuery() );
        }
    };

    rowClick = row => {
        if ( this.props.currentUser.isReadOnly ) {
            return;
        }
        this.edit( row.id );
    };

    asideToggle( event ) {
        this.props.getVersions( {
            accountId: event.accountId,
            elementId: event.id,
            element: event,
            collection: 'parties'
        } );
    }

    render() {
        if ( this.state.cannotAccess ) {
            return (
                <h3>{`Sorry, party with id #${this.state.desiredParty} does not exist.`}</h3>
            );
        }
        let { parties, externalData, isSearch } = this.props;
        function compareIds( a, b ) {
            if ( a.id < b.id ) { return -1; }
            if ( a.id > b.id ) { return 1; }
            return 0;
        }
        parties = parties.sort( compareIds );
        parties = parties.sort();

        externalData = externalData.sort( compareIds );
        externalData = externalData.sort();

        let data = [];
        if ( typeof externalData !== 'undefined' && isSearch ) {
            data = buildDocumentRow( externalData, this.asideToggle );
        } else {
            data = buildDocumentRow( parties, this.asideToggle );
        }

        const editText = 'You are updating an existing party. The changes you are about to make will not reflect in already existing documents.';
        const deleteText = 'You are about to delete a party. This will not reflect in already existing documents.';
        return (
            <div className="customTable clickableRow">
                <Display when={ this.state.open }>
                    <Modal
                        isOpen={ this.state.open }
                        toggle={ () => this.setState( { open: false } ) }
                        className="partiesAssignModal">
                        <ModalHeader toggle={ this.toggleModal }>
Party
                        </ModalHeader>
                        <ModalBody>
                            <Alert color="info">
                                {' '}
                                { this.state.operation === 'edit' ? editText : deleteText }
                            </Alert>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-outline-secondary" onClick={ () => this.setState( { open: false } ) }>Cancel</button>
                            <button
                                className={ `btn btn-outline-${this.state.operation === 'edit' ? 'primary' : 'danger'} ` }
                                onClick={ this.action }>
                                View
                            </button>
                        </ModalFooter>
                    </Modal>
                </Display>
                <TableView
                    rowsDOM={ data }
                    columns={ columns }
                    columnWidths={ columnWidths }
                    tableStyle={ { minHeight: 'auto' } }
                    rowClickCallback={ this.rowClick }
                    defaultSorting={ [ { columnName: 'name', direction: 'asc' } ] }
                />
            </div>
        );
    }
}

const { array, func, bool, object } = PropTypes;

PartiesList.defaultProps = {
    parties: [],
    externalData: [],
    currentAccount: {},
    selectParty: func,
    isSearch: false
};

PartiesList.propTypes = {
    parties: array,
    currentAccount: object,
    externalData: array,
    selectParty: func,
    refreshEsQuery: func.isRequired,
    isSearch: bool
};

const mapStateToProps = state => ( {
    parties: state.parties.list,
    currentAccount: state.currentAccount,
    currentUser: state.currentUser
} );

const mapDispatchToProps = dispatch => ( {
    deleteParty: ( data ) => dispatch( partiesOperations.deleteParty( data ) ),
    getVersions: ( data ) => dispatch( versionOperations.getVersions( data ) ),
    selectParty: ( data ) => dispatch( partiesOperations.selectParty( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( PartiesList );
