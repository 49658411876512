import types from './types';

const buildUploadQueue = ( files ) => ( {
    type: types.BUILD_UPLOAD_QUEUE,
    payload: {
        files
    }
} );

const upload = ( file, signedUrl ) => ( {
    type: types.UPLOAD,
    payload: {
        file,
        signedUrl
    }
} );

const remove = ( file ) => ( {
    type: types.REMOVE,
    payload: {
        file
    }
} );

const update = ( file ) => ( {
    type: types.UPDATE,
    payload: {
        file
    }
} );

const clear = ( ) => ( {
    type: types.CLEAR,
} );


export default {
    upload,
    remove,
    update,
    clear,
    buildUploadQueue
};
