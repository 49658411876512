import React from 'react';

function SidebarFooter(props) {
// return null
// Uncomment following code lines to add Sidebar Footer
    return (
     <div></div>
    );
}

export default SidebarFooter;
