

const permissions = {
    deny:
[
    {
        extension: 'APK',
        'Automator Action': 'Application',
        macOS: 'Android'
    },
    {
        extension: 'APP',
        'Automator Action': 'Executable',
        macOS: 'macOS'
    },
    {
        extension: 'BAT',
        'Automator Action': 'Batch File',
        macOS: 'Windows'
    },
    {
        extension: 'BIN',
        'Automator Action': 'Binary Executable',
        macOS: 'Windows, macOS, Linux'
    },
    {
        extension: 'CMD',
        'Automator Action': 'Command Script',
        macOS: 'Windows'
    },
    {
        extension: 'COM',
        'Automator Action': 'Command File',
        macOS: 'Windows'
    },
    {
        extension: 'COMMAND',
        'Automator Action': 'Terminal Command',
        macOS: 'macOS'
    },
    {
        extension: 'CPL',
        'Automator Action': 'Control Panel Extension',
        macOS: 'Windows'
    },
    {
        extension: 'CSH',
        'Automator Action': 'C Shell Script',
        macOS: 'macOS, Linux'
    },
    {
        extension: 'EXE',
        'Automator Action': 'Executable',
        macOS: 'Windows'
    },
    {
        extension: 'GADGET',
        'Automator Action': 'Windows Gadget',
        macOS: 'Windows'
    },
    {
        extension: 'INF1',
        'Automator Action': 'Setup Information File',
        macOS: 'Windows'
    },
    {
        extension: 'INS',
        'Automator Action': 'Internet Communication Settings',
        macOS: 'Windows'
    },
    {
        extension: 'INX',
        'Automator Action': 'InstallShield Compiled Script',
        macOS: 'Windows'
    },
    {
        extension: 'IPA',
        'Automator Action': 'Application',
        macOS: 'iOS'
    },
    {
        extension: 'ISU',
        'Automator Action': 'InstallShield Uninstaller Script',
        macOS: 'Windows'
    },
    {
        extension: 'JOB',
        'Automator Action': 'Windows Task Scheduler Job File',
        macOS: 'Windows'
    },
    {
        extension: 'JSE',
        'Automator Action': 'JScript Encoded File',
        macOS: 'Windows'
    },
    {
        extension: 'KSH',
        'Automator Action': 'Unix Korn Shell Script',
        macOS: 'Linux'
    },
    {
        extension: 'LNK',
        'Automator Action': 'File Shortcut',
        macOS: 'Windows'
    },
    {
        extension: 'MSC',
        'Automator Action': 'Microsoft Common Console Document',
        macOS: 'Windows'
    },
    {
        extension: 'MSI',
        'Automator Action': 'Windows Installer Package',
        macOS: 'Windows'
    },
    {
        extension: 'MSP',
        'Automator Action': 'Windows Installer Patch',
        macOS: 'Windows'
    },
    {
        extension: 'MST',
        'Automator Action': 'Windows Installer Setup Transform File',
        macOS: 'Windows'
    },
    {
        extension: 'OSX',
        'Automator Action': 'Executable',
        macOS: 'macOS'
    },
    {
        extension: 'OUT',
        'Automator Action': 'Executable',
        macOS: 'Linux'
    },
    {
        extension: 'PAF',
        'Automator Action': 'Portable Application Installer File',
        macOS: 'Windows'
    },
    {
        extension: 'PIF',
        'Automator Action': 'Program Information File',
        macOS: 'Windows'
    },
    {
        extension: 'PRG',
        'Automator Action': 'Executable',
        macOS: 'GEM'
    },
    {
        extension: 'PS1',
        'Automator Action': 'Windows PowerShell Cmdlet',
        macOS: 'Windows'
    },
    {
        extension: 'REG',
        'Automator Action': 'Registry Data File',
        macOS: 'Windows'
    },
    {
        extension: 'RGS',
        'Automator Action': 'Registry Script',
        macOS: 'Windows'
    },
    {
        extension: 'RUN',
        'Automator Action': 'Executable',
        macOS: 'Linux'
    },
    {
        extension: 'SCR',
        'Automator Action': 'Screensaver Executable',
        macOS: 'Windows'
    },
    {
        extension: 'SCT',
        'Automator Action': 'Windows Scriptlet',
        macOS: 'Windows'
    },
    {
        extension: 'SHB',
        'Automator Action': 'Windows Document Shortcut',
        macOS: 'Windows'
    },
    {
        extension: 'SHS',
        'Automator Action': 'Shell Scrap Object',
        macOS: 'Windows'
    },
    {
        extension: 'U3P',
        'Automator Action': 'U3 Smart Application',
        macOS: 'Windows'
    },
    {
        extension: 'VB',
        'Automator Action': 'VBScript File',
        macOS: 'Windows'
    },
    {
        extension: 'VBE',
        'Automator Action': 'VBScript Encoded Script',
        macOS: 'Windows'
    },
    {
        extension: 'VBS',
        'Automator Action': 'VBScript File',
        macOS: 'Windows'
    },
    {
        extension: 'VBSCRIPT',
        'Automator Action': 'Visual Basic Script',
        macOS: 'Windows'
    },
    {
        extension: 'WORKFLOW',
        'Automator Action': 'Automator Workflow',
        macOS: 'macOS'
    },
    {
        extension: 'WS',
        'Automator Action': 'Windows Script',
        macOS: 'Windows'
    },
    {
        extension: 'WSF',
        'Automator Action': 'Windows Script',
        macOS: 'Windows'
    },
    {
        extension: 'WSH',
        'Automator Action': 'Windows Script Preference',
        macOS: 'Windows '
    },
    {
        extension: 'DMG',
        'Automator Action': 'Windows Script Preference',
        macOS: 'Windows '
    },
    {
        extension: 'SH',
        'Automator Action': 'Windows Script Preference',
        macOS: 'Windows '
    }
]
};

export default permissions;
