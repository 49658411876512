const CREATE_GROUP = 'CREATE_GROUP';
const CREATE_GROUP_COMPLETED = 'CREATE_GROUP_COMPLETED';
const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';

const UPDATE_GROUP = 'UPDATE_GROUP';
const UPDATE_GROUP_COMPLETED = 'UPDATE_GROUP_COMPLETED';
const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';

const STORE_RESOURCE = 'STORE_RESOURCE';
const STORE_RESOURCE_COMPLETED = 'STORE_RESOURCE_COMPLETED';
const STORE_RESOURCE_FAILED = 'STORE_RESOURCE_FAILED';

export default {
    STORE_RESOURCE,
    STORE_RESOURCE_COMPLETED,
    STORE_RESOURCE_FAILED,
    CREATE_GROUP,
    CREATE_GROUP_COMPLETED,
    CREATE_GROUP_FAILED,
    UPDATE_GROUP,
    UPDATE_GROUP_COMPLETED,
    UPDATE_GROUP_FAILED
};
