import React from 'react';

import Select from 'react-select';
import { connect } from 'react-redux';
import { buildFirstLevelCategories, buildLocationOptions } from '../Document/helpers';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={ groupStyles }>
        <span>{ data.label }</span>
        <span style={ groupBadgeStyles }>{ data.options.length }</span>
    </div>
);

class CategoriesGroupedSelector extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { ...props.formData, currentLocationValid: true };
        this.onChange = this.onChange.bind( this );
    }

    componentWillMount( ) {
        if ( typeof this.props.value !== 'undefined' ) {
            let categoryData = {
                category: 'General Corporate',
                label: 'General Corporate',
                value: 'General Corporate',
            };
            if ( this.props.value.indexOf === -1 ) {
                categoryData = {
                    category: this.props.value,
                    label: this.props.value,
                    value: this.props.value,
                };
            } else {
                categoryData = {
                    category: this.props.value.split( '/' )[0],
                    label: this.props.value,
                    value: this.props.value,
                };
            }
            this.setState( { value: categoryData } );
        }
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        if ( typeof nextProps.value === 'undefined' ) {
            this.setState( { value: null } );
        }
    }

    onChange( data ) {
        this.setState( {
            location: data.value,
            value: data
        } );
        this.props.onChange( data.value );
    }

    render() {
        const colourStyles = {
            control: styles => ( { ...styles, backgroundColor: 'white' } ),
            option: ( styles, { data, isDisabled, isFocused, isSelected } ) => ( {
                ...styles,
                margin: '0 0 0 15px',
                cursor: isDisabled ? 'not-allowed' : 'default',
            } ),
        };
        const data = this.props.showFirstLevel ? buildFirstLevelCategories( this.props.categoryTree.treeData, this.props.filterUsed, this.props.used ) : buildLocationOptions( this.props.breadcrumbs, true, this.props.showAll, this.props.filterUsed, this.props.used );
        return (
            <Select
                options={ data }
                value={ this.state.value }
                className={ `customReactSelect ${this.props.categoryClassNames}` }
                onChange={ this.onChange }
                styles={ colourStyles }
            />
        );
    }
}

const mapStateToProps = state => ( {
    breadcrumbs: state.categories.breadcrumbs,
    categoryTree: state.categories.categoryTree,
} );

const mapDispatchToProps = dispatch => ( {
} );
export default connect( mapStateToProps, mapDispatchToProps )( CategoriesGroupedSelector );
