import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Switch from './Switch';
import RepeatBody from './RepeatBody';
import RepeatFooter from './RepeatFooter';

class Repeater extends Component {
    constructor() {
        super();
        this.state = {
            repeat: false,
            endRepeat: true,
            endRepeatOptions: {
                count: 1, until: false
            }
        };
        this.toggle = this.toggle.bind( this );
        this.callback = this.callback.bind( this );
    }

    UNSAFE_componentWillMount() {
        let state = {};
        if ( this.props.open && !this.state.repeat && typeof this.props.state === 'undefined' ) {
            state.repeat = true;
        }
        if ( this.props.state ) {
            state = { ...this.props.state, repeat: this.props.open };
        }
        this.setState( { ...state } );
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        let state = {};
        if ( nextProps.open !== this.state.repeat && typeof nextProps.open !== 'undefined' ) {
            state.repeat = nextProps.open;
        }
        if ( nextProps.state ) {
            state = { ...nextProps.state, repeat: nextProps.open };
        }
        this.setState( { ...state } );
    }

    toggle() {
        const newState = this.state;
        if ( this.state.repeat ) {
            delete newState.repeatOptions;
            delete newState.endRepeat;
            delete newState.frequency;
            delete newState.endRepeatOptions;
            newState.repeat = false;
        }
        if ( !this.state.repeat ) {
            newState.repeat = true;
            newState.endRepeat = false;
            newState.frequency = 'daily';
        }
        this.setState( { ...newState } );
    }

    callback( data ) {
        const { type, payload } = data;
        const newState = this.state;
        switch ( type ) {
            case 'DAILY':
                newState.frequency = 'daily';
                newState.repeatOptions = payload;
                break;
            case 'WEEKLY':
                newState.frequency = 'weekly';
                newState.repeatOptions = payload;
                break;
            case 'MONTHLY':
                newState.frequency = 'monthly';
                newState.repeatOptions = payload;
                break;
            case 'YEARLY':
                newState.frequency = 'yearly';
                newState.repeatOptions = payload;
                break;
            case 'endRepeat':
                if ( payload.endRepeat ) {
                    newState.endRepeat = true;
                    delete payload.endRepeat;
                    newState.endRepeatOptions = payload;
                } else {
                    delete newState.endRepeatOptions;
                    newState.endRepeat = false;
                }
                break;
            default:
                newState.frequency = 'daily';
                newState.repeatOptions = payload;
                break;
        }
        this.setState( { ...newState } );
        this.props.callback( newState );
    }

    render() {
        return (
            <React.Fragment>
                <div style={ { marginBottom: 10 } }>
                    { !this.props.open
                        && <Switch toggle={ this.toggle } />
                    }
                    { this.state.repeat
                    && (
                        <React.Fragment>
                            <RepeatBody
                                callback={ this.callback }
                                data={ this.props.state }
                                disabled={ this.props.disabled } />
                            <RepeatFooter
                                callback={ this.callback }
                                forceEnd={ this.props.forceEnd }
                                eventDate={ this.props.eventDate }
                                data={ this.props.state }
                                disabled={ this.props.disabled } />
                        </React.Fragment>
                    )
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default Repeater;

Repeater.propTypes = {
    callback: PropTypes.func.isRequired,
    open: PropTypes.bool,
    forceEnd: PropTypes.bool,
    disabled: PropTypes.bool,
    state: PropTypes.object
};
Repeater.defaultProps = {
    forceEnd: true,
    disabled: false,
    open: false,
    state: {}
};
