import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { arrayMove } from 'react-sortable-hoc';

import { optionsOperations } from '../../redux/ducks/options';

import OptionBuilder from './OptionBuilder';
import DefaultOptionAssign from './DefaultOptionAssign';
import { documentTypesOperations } from '../../redux/ducks/documentTypes';

import { SortableList } from '../Document/helpers';
import Display from '../Display';
import CloneDocumentType from './CloneDocumentType';

class DefaultOptionManager extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            editOption: false,
            isCloneModalOpen: false,
            options: [
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
            ]
        };
    }

    UNSAFE_componentWillMount() {
        if ( this.props.documentTypes && !this.props.currentFolder ) {
            this.props.changeFolder( this.props.documentTypes[0].name );
        }
        if ( this.props.currentFolder ) {
            const currentDoc = this.props.documentTypes.having( 'name', this.props.currentFolder )[0];
            const docOptions = [];
            if ( currentDoc && currentDoc.options ) {
                currentDoc.options.map( option => {
                    if ( option ) {
                        docOptions.push( option );
                    } else {
                        console.error( `Found doc with null options, docId: ${currentDoc.id}` );
                    }
                } );
            }
            this.setState( { docOptions, currentDoc } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.props.currentAccount.accountId !== nextProps.currentAccount.accountId || this.props.documentTypes.length !== nextProps.documentTypes.length ) {
            this.setState( { currentFolder: nextProps.documentTypes[0].name } );
            this.props.changeFolder( nextProps.documentTypes[0].name );
        }
        if ( nextProps.currentFolder ) {
            let currentDoc = nextProps.documentTypes.having( 'name', nextProps.currentFolder )[0];
            // we need to handle the case when the user changes the account.
            if ( this.props.currentAccount.accountId !== nextProps.currentAccount.accountId ) {
                currentDoc = nextProps.documentTypes[0];
            }
            const docOptions = [];

            if ( typeof ( currentDoc ) !== 'undefined' && currentDoc.options instanceof Array ) {
                currentDoc.options.map( option => {
                    if ( option ) {
                        docOptions.push( option );
                    } else {
                        console.error( `Found doc with null options, docId: ${currentDoc.id}` );
                    }
                } );
            }
            this.setState( { docOptions, currentDoc } );
        }
    }

    buildOptionsFoldersList = ( array, callback ) => array.sort( compareNames ).map( doc => {
        if ( typeof doc.name !== 'undefined' ) {
            return (
                <div
                    role="ListItem"
                    key={ Math.random() }
                    className={ `folder ${this.props.currentFolder === doc.name ? 'active' : ''}` }
                    onClick={ () => callback( doc.name ) }>
                    <i className="fa fa-folder" />
                    {' '}
                    {doc.name.capitalize()}
                </div>
            );
        }
    } );

    changeFolder = name => {
        this.props.changeFolder( name );
    };

    mandatoryValueChange( option, value ) {
        const currentDoc = this.props.documentTypes.having( 'name', this.props.currentFolder )[0];

        currentDoc.options.having( 'id', option.id )[0].mandatory = JSON.parse( value );

        const newData = {
            doc: currentDoc,
            accountId: currentDoc.accountId
        };
        this.props.saveDocument( newData );
    }

    onSortEnd = ( { oldIndex, newIndex } ) => {
        const docOptions = arrayMove( this.state.docOptions, oldIndex, newIndex );
        const currentDoc = this.props.documentTypes.having( 'name', this.props.currentFolder )[0];
        const newData = {
            doc: currentDoc,
            accountId: currentDoc.accountId
        };
        newData.doc.options = docOptions;
        this.props.saveDocument( newData );
        this.setState( {
            docOptions: arrayMove( this.state.docOptions, oldIndex, newIndex ),
        } );
    };

    buildFolderContentList( options, deleteCallback, editCallback ) {
        if ( typeof options === 'undefined' ) {
            return <script />;
        }
        const ka = options.map( option => (
            <div className="optionRow" key={ Math.random() } style={ { width: '100%' } }>
                <div className="row">
                    <div className="col-lg-8" style={ { lineHeight: 'inherit' } }>
                        { option.title.capitalize() }
                    </div>
                    <div className="col-lg-2">
                        { option.optionType.toUpperCase() }
                    </div>
                    <div className="col-lg-1">
                        <select
                            style={ { position: 'relative', background: 'transparent' } }
                            value={ !!option.mandatory }
                            className="smallReactSelect"
                            onChange={ ( e ) => { this.mandatoryValueChange( option, e.target.value ); } }>
                            <option value>Yes</option>
                            <option value={ false }>No</option>
                        </select>
                    </div>
                    <div className="col-lg-1">
                        <i className="icon-trash" onClick={ () => deleteCallback( option ) } />
                        <i style={ { marginLeft: 10 } } className="icon-pencil" onClick={ () => editCallback( option ) } />
                    </div>
                </div>
            </div>
        ) );
        return (
            <SortableList
                helperClass="sortableHelper"
                removeOption={ () => {} }
                itemDom
                items={ ka }
                isUserAdmin
                docType={ this.props.docType }
                onSortEnd={ this.onSortEnd }
                useDragHandle />
        );
    }

    deleteOption = option => {
        const selectedDoc = Object.assign( {}, this.props.documentTypes.filter( doc => doc.name === this.props.currentFolder )[0] );
        selectedDoc.options = selectedDoc.options.filter( x => x ).filter( x => x.id !== option.id );
        const newData = {
            doc: selectedDoc,
            accountId: selectedDoc.accountId
        };
        this.props.saveDocument( newData );
    };

    editOption = option => {
        this.setState( { editOption: true, selectedOption: { ...option, docTypeId: this.state.currentDoc.id } } );
    };

    toggleCloneModal = () => {
        this.setState( { isCloneModalOpen: !this.state.isCloneModalOpen } );
    };

    render() {
        const { currentFolder, options, documentTypes, isOptionAssignModalOpen, toggle, changingAccount } = this.props;
        const { isCloneModalOpen, docOptions, editOption, selectedOption } = this.state;
        // accounts = accounts || [];
        if ( !options || !documentTypes ) {
            return <script />;
        }

        const folderContent = this.buildFolderContentList( docOptions, this.deleteOption, this.editOption );
        const folderListDom = this.buildOptionsFoldersList( documentTypes, this.changeFolder );
        return (
            <div className="optionsModal">
                { editOption
                    && (
                        <OptionBuilder
                            selectedOption={ selectedOption }
                            toggle={ () => { this.setState( { editOption: false } ); } }
                            open={ editOption } />
                    )
                }

                { isOptionAssignModalOpen
                    && (
                        <DefaultOptionAssign
                            currentDoc={ documentTypes.having( 'name', currentFolder )[0] }
                            toggle={ toggle }
                            toggleCloneModal={ this.toggleCloneModal }
                            open={ isOptionAssignModalOpen } />
                    )
                }
                { isCloneModalOpen
              && <CloneDocumentType toggle={ this.toggleCloneModal } open={ isCloneModalOpen } />
                }
                <Display when={ changingAccount }>
                    <h4 className="text-center">Loading content ...</h4>
                </Display>
                <Display when={ !changingAccount }>
                    <div className="row">
                        <div className="col-lg-4"><h6 className="header">Document type</h6></div>
                        <div className="col-lg-8">
                            <div className="row" style={ { marginRight: 0 } }>
                                <div className="col-lg-8" style={ { backgroundColor: '#e4e5e6' } }><h6 className="header">Form field name</h6></div>
                                <div className="col-lg-2" style={ { backgroundColor: '#e4e5e6' } }><h6 className="header pl-0">Type</h6></div>
                                <div className="col-lg-1" style={ { backgroundColor: '#e4e5e6' } }><h6 className="header pl-0">Required</h6></div>
                                <div className="col-lg-1" style={ { backgroundColor: '#e4e5e6' } }><h6 className="header pl-0">Actions</h6></div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 optionColumn optionFolders">
                            { folderListDom }
                        </div>
                        <div className="col-lg-8 optionColumn sortDefaultoptions pl-0" id="selectableOptions">
                            { folderContent }
                        </div>
                    </div>
                </Display>
            </div>
        );
    }
}

DefaultOptionManager.defaultProps = {
    documentTypes: [],
    options: [],
    currentFolder: ''
};

DefaultOptionManager.propTypes = {
    saveDocument: PropTypes.func.isRequired,
    documentTypes: PropTypes.array,
    options: PropTypes.array,
    currentFolder: PropTypes.string,
    changeFolder: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    folders: state.options.folders,
    options: state.options.options,
    breadcrumbs: state.categories.breadcrumbs,
    documentTypes: state.documentTypes.list,
    currentFolder: state.documentTypes.selectedFolder,
    changingAccount: state.application.general.changingAccount
} );

const mapDispatchToProps = dispatch => ( {
    deleteOptions: ( id ) => dispatch( optionsOperations.deleteOption( id ) ),
    saveDocument: ( data ) => dispatch( documentTypesOperations.saveDocument( data ) ),
    changeFolder: ( name ) => dispatch( documentTypesOperations.changeFolder( name ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DefaultOptionManager );

function compareNames( a, b ) {
    if ( typeof a.name === 'undefined' || typeof b.name === 'undefined' || !a.name || !b.name ) {
        return 0;
    }
    if ( a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ) { return -1; }
    if ( a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ) { return 1; }
    return 0;
}

function compareTitles( a, b ) {
    if ( typeof a.title === 'undefined' || typeof b.title === 'undefined' || !a.title || !b.title ) {
        return 0;
    }
    if ( a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase() ) { return -1; }
    if ( a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ) { return 1; }
    return 0;
}
