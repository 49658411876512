import React from 'react';
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Header from '../../../components/Header';
import { applicationOperations } from '../../../redux/ducks/application';

function AccountMaintenance( props ) {
    return (
        <div id="account_maintenance">
            <Header
                location={ props.location }
                match={ props.match }
                history={ props.history }
                auth={ props.user }
                fullWidth
                inMaintenance
                currentUser={ props.currentUser } />

            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <div className="content-container in" style={ { top: 332 } }>
                            <div className="head-line secondary-text-color" style={ { fontSize: 38, lineHeight: '60px', color: '#054d6791', marginBottom: 20 } }>
                              Account <strong>{props.currentAccount.accountName}</strong><br /> is in maintenance.
                            </div>
                            <div className="subheader primary-text-color" style={ { fontSize: 32, lineHeight: '46px' } }>
                      We're undergoing a bit of<br />
                      scheduled maintenance.
                            </div>
                            <hr style={ { margin: '35px 0', border: 'none' } } />
                            <div className="clearfix" />
                            <div className="context primary-text-color">
                                <p>Sorry for the inconvenience. We'll be back up and running as fast as possible.</p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
const mapStateToProps = state => ( {
    currentUser: state.currentUser,
    currentAccount: state.currentAccount
} );

const mapDispatchToProps = dispatch => ( {
} );
export default connect( mapStateToProps, mapDispatchToProps )( AccountMaintenance );
