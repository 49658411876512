import React from 'react';

function Footer( { personalization } ) {
    const versionInfo = '[AIV]v{version} - {date}[/AIV]';
    const dataset = {
        companyName: 'Delphisquare IPM Pty Ltd',
        brand: 'Mycontracts',
        ...personalization
    };
    return (
        <footer className="app-footer">
            <span><a href={ process.env.productLink }>{dataset.brand}</a> &copy; {new Date().getFullYear()} {dataset.companyName}</span>
            <span
                className="ml-auto">Powered by <a href={ process.env.productLink }>{dataset.brand} {versionInfo}</a>
            </span>
        </footer>
    );
}

export default Footer;
