import types from './types';
const saveOption = ( data, extraData ) => {
    const body = data;
    return {
        type: types.SAVE_OPTION,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/option',
            method: 'POST',
            body
        }
    };
};
const selectParty = ( data ) => ( {
    type: types.SELECT_PARTY,
    payload: data
} );

const createParty = ( data, extraData ) => {
    const body = data;
    return {
        type: types.CREATE_PARTY,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/party',
            method: 'POST',
            body
        }
    };
};

const deleteParty = ( data, extraData ) => {
    const body = data;
    return {
        type: types.DELETE_PARTY,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/party',
            method: 'DELETE',
            body
        }
    };
};
export default {
    deleteParty,
    selectParty,
    createParty
};
