import elasticsearch from 'elasticsearch';
import types from './types';

const esHost = process.env.elasticsearch_host;

const request = async ( requestObject ) => {
    let result = { isError: true, errorMessage: 'Execution error', result: {} };
    if ( typeof requestObject === 'undefined' ) return result;

    const client = new elasticsearch.Client( {
        host: {
            host: esHost.replace( 'https://', '' ) || 'us-central1-contracts-dev-firestore.cloudfunctions.net',
            path: 'search',
            port: 443,
            protocol: 'https',
            log: 'trace',
            headers: {
                accept: 'application/ndjson',
                authorization: `Bearer ${requestObject.sessionId}`,
                accountId: requestObject.accountId,
                email: requestObject.email,
                uid: requestObject.uid,
                'content-type': 'application/json'
            }
        }
    } );

    switch ( requestObject.type ) {
        case 'COUNT': {
            const query = {
                query: {
                    bool: {
                        must: [
                            { match: { accountId: requestObject.accountId } }
                        ]
                    }
                }
            };

            const filter = {};

            try {
                const { count } = await client.count( {
                    index: requestObject.indexName,
                    body: query,
                    // Add filters for status here

                } );
                result = { indexName: requestObject.indexName, isError: false, errorMessage: '', result: { count } };
            } catch ( err ) {
                console.error( err );
                result = { indexName: requestObject.indexName, isError: true, errorMessage: err.errorMessage, result: 0 };
                return result;
            }
            break;
        }
        case 'HISTOGRAM': {
            try {
                const { count } = await client.count( {
                    index: requestObject.indexName,
                    body: {
                        query: {
                            match: {
                                accountId: requestObject.accountId
                            }
                        },
                        aggs: {
                            documents: {
                                date_histogram: {
                                    field: 'updatedAt',
                                    interval: '1d',
                                    missing: 0
                                }
                            }
                        }
                    },
                    size: 0

                } );
                result = { indexName: requestObject.indexName, isError: false, errorMessage: '', result: { count } };
            } catch ( err ) {
                console.error( err );
                result = { indexName: requestObject.indexName, isError: true, errorMessage: err.errorMessage, result: 0 };
                return result;
            }
            break;
        }

        default:
            console.error( 'Bad requestObject:', JSON.stringify( requestObject ) );
    }

    return result;
};


export default request;
