function constructGroupListData( group, isDefaultUser, currentUserId ) {
    const members = Object.keys( group.members );
    if ( members.isEmpty() ) {
        return { group: group.name, groupId: group.id, category: group.category, members: [] };
    }
    let membersArray = members.map( userId => ( { ...group.members[userId], id: userId } ) );
    if ( isDefaultUser ) {
        membersArray = membersArray.having( 'id', currentUserId );
    }
    return { group: group.name, groupId: group.id, category: group.category, members: membersArray };
}

function prepareCreateGroupData( data ) {
    const escalationTime = calculateEscalationTime( data.escalationTime, data.escalationTimeType );
    return {
        single: data.single || false,
        category: data.category || 'All',
        members: {},
        name: data.name,
        accountId: data.accountId,
        deleted: false,
        escalationTime
    };
}

function prepareUpdateGroupData( data ) {
    const escalationTime = calculateEscalationTime( data.escalationTime, data.escalationTimeType );
    return {
        single: data.single || false,
        category: data.category || 'All',
        members: data.members || {},
        name: data.name,
        id: data.id,
        accountId: data.accountId,
        deleted: false,
        escalationTime
    };
}

/**
 *
 * @param escalationTime
 * @param escalationTimeType
 * @returns {*|number} numbers of hours until event escalates.
 */
function calculateEscalationTime( escalationTime, escalationTimeType ) {
    let time = 24;
    if ( escalationTimeType === 'day' ) {
        time = 24 * Number( escalationTime );
    } else {
        time = escalationTime;
    }
    return Number( time );
}

function extractGroupNames( data ) {
    return data.map( group => group.name );
}

function extractCompoundGroupNames( data ) {
    return data.filter( group => !group.single ).map( group => group.name );
}

export default {
    constructGroupListData,
    prepareCreateGroupData,
    extractGroupNames,
    extractCompoundGroupNames,
    prepareUpdateGroupData
};
