import React from 'react';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { sortArrayByProperty } from '../../utils/sortArrayByProperty';

const log = ( type ) => console.log.bind( console, type );

function buildFolderContentList( options, selectOption, selections ) {
    if ( typeof options === 'undefined' ) {
        return <script />;
    }
    let workingOptions = [ ...options ].except( 'id', 'filename' );
    workingOptions = [ ...workingOptions ].filter( x => typeof x.defaultFor === 'undefined' || x.defaultFor === '' );
    const names = workingOptions.map( option => option.title );
    const optionsMap = new Map();
    names.sort().map( ( elem, index ) => {
        optionsMap.set( index, workingOptions.having( 'title', elem )[0] );
    } );
    const array = [];
    optionsMap.forEach( ( value, key ) => array.push(
        <Label check key={ Math.random() } onClick={ selectOption }>
            <Input
                name={ value.id }
                type="checkbox"
                onChange={ () => {} }
                value={ value.id }
                checked={ selections.having( 'id', value.id ).hasItems() }
            />
            { value.title }
        </Label>
    ) );
    return array;
}

const DragHandle = SortableHandle( () => <span className="dragableHandle" /> ); // This can be any component you want

const SortableItem = SortableElement( ( { value, id, removeOption, required, isUserAdmin, docType, option, itemDom } ) => (
    <div className="dragableItem">
        <DragHandle />
        {value}
        { ( ( id !== 'info' && id !== 'location' && id !== 'title' && !required && !itemDom )
          || ( isUserAdmin && id !== 'info' && id !== 'location' && id !== 'title' && option.defaultFor !== docType ) )
            && <span id={ id } className="remove" onClick={ () => removeOption( id ) }>x</span>
        }
    </div>
) );

function convertToSchema( data ) {
    return data.map( option => {
        const newOption = { ...option };
        newOption.type = 'string';
        if ( newOption.optionType.toLowerCase() === 'boolean' ) {
            newOption.type = 'boolean';
        }

        newOption.optionType = option.optionType.toUpperCase();
        return newOption;
    } ).except( 'id', 'filename' );
}

function isLocationValid( oldLocation, availableLocations = [] ) {
    const catName = oldLocation.split( '/' )[0];
    const sameCategoryList = availableLocations.filter( entry => entry.label === catName );
    if ( sameCategoryList.hasItems() ) {
        let entryFound = true;
        sameCategoryList.map( a => {
            if ( typeof a.options !== 'undefined' && a.options.filter( x => x.label === oldLocation ).isEmpty() ) {
                entryFound = false;
            }
        } );
        return entryFound;
    }
    return false;
}

function buildContractFields( options, order ) {
    const properties = {};
    const uiSchema = { 'ui:order': [] };
    const existingOptions = options.map( option => option.id );
    if ( order ) {
        let newOrder = order.map( id => {
            if ( existingOptions.contains( id ) ) {
                return id;
            }
        } ).filter( x => x );
        if ( options.length > order.length ) {
            newOrder = options.map( ( x ) => x.id );
        }
        uiSchema['ui:order'] = newOrder;
    }
    const required = [];
    options.map( ( option ) => {
        if ( option.mandatory ) {
            required.push( option.id );
        }
        if ( !order ) {
            uiSchema['ui:order'].push( option.id );
        }

        const a = { ...option };

        properties[a.id] = { ...a };
        // delete properties[option.id].id;
        const widgetType = 'text';

        if ( a.optionType.toUpperCase() === 'PID' ) {
            // widgetType = 'date';
            uiSchema[a.id] = {
            // 'ui:widget': 'text',
                'ui:field': 'dateSelect',
            };
            return;
        }
        if ( a.optionType.toUpperCase() === 'PIP' ) {
            // widgetType = 'date';
            uiSchema[a.id] = {
            // 'ui:widget': 'text',
                'ui:field': 'dateIntervalSelect',
            };
            return;
        }
        if ( !a.enum ) {
            uiSchema[a.id] = {
                'ui:widget': widgetType
            };
        }
        if ( option.optionType.toUpperCase() === 'BOOLEAN' ) {
            uiSchema[option.id] = {
                'ui:widget': 'boolean',
                'ui:field': 'booleanStatement',
            };
        }
        if ( option.textarea || ( option.optionType.toUpperCase() === 'TEXT' && option.id !== 'title' ) ) {
            uiSchema[a.id] = {
                'ui:widget': 'textarea',
                'ui:field': 'richTextField',
            };
        }
        if ( a.optionType === 'locationSelect' ) {
            uiSchema[a.id] = {
                // 'ui:widget': 'text',
                'ui:field': 'locationSelect',
            };
        }
    } );
    return ( { properties, required, uiSchema } );
}

function buildOptionsFoldersList( folders, currentFolder, changeFolder ) {
    return folders.map( folder => (
        <div key={ Math.random() } className={ `folder ${currentFolder === folder ? 'active' : ''}` } onClick={ () => changeFolder( folder ) }>
            <i className="fa fa-folder" />
            {' '}
            { folder }
        </div>
    ) );
}

function buildLocationOptions( data, categoryNameOnly = false, showAllFlag = false ) {
    const groupedOptions = [];
    let categoriesNames = [];
    const options = [];
    const newCategoriesArray = new Set();
    data.map( entry => {
        let catName = '';
        if ( categoryNameOnly ) {
            catName = entry.breadcrumb.split( '/' )[0];
            categoriesNames.push( catName );
            const splittedArray = entry.breadcrumb.split( '/' );
            for ( let i = 0; i < splittedArray.length; i++ ) {
                let word = '';
                for ( let j = 0; j <= i; j++ ) {
                    word += splittedArray[j];
                    if ( j + 1 <= i ) { word += '/'; }
                }
                newCategoriesArray.add( word );
            }
        } else {
            catName = entry.breadcrumb.split( '/' )[0];
            // const catName = `${entry.breadcrumb.split( '/' )[0]}/${entry.breadcrumb.split( '/' )[1]}`;
            categoriesNames.push( catName );
            const catId = catName.toLocaleLowerCase().replace( ' ', '_' );
            // const label = `${entry.breadcrumb.split( '/' )[0]}/${entry.breadcrumb.split( '/' ).last()}`;
            const label = entry.breadcrumb;

            options.push( { value: label, label, category: catName } );
        }
    } );
    if ( categoryNameOnly ) {
        Array.from( newCategoriesArray ).map( word => {
            let catName = '';
            if ( !word.contains( '/' ) ) {
                catName = word;
            } else {
                catName = word.split( '/' )[0];
            }
            options.push( { value: word, label: word, category: catName } );
        } );
    }
    categoriesNames = Array.from( new Set( categoriesNames ) );
    categoriesNames.map( cat => {
        groupedOptions.push( {
            label: cat,
            options: options.having( 'category', cat )
        } );
    } );
    if ( showAllFlag ) {
        const all = {
            label: 'All',
            options: [
                {
                    value: 'All',
                    label: 'All',
                    category: 'All'
                }
            ]
        };
        return [ all, ...groupedOptions ];
    }
    return groupedOptions;
}

function fillDefault( breadcrumbs, docType, defaultOptions ) {
    const docLocations = buildLocationOptions( breadcrumbs.having( 'type', docType ) );
    const composeDefaultOptions = defaultOptions.map( option => {
        const newOption = { ...option };
        newOption.type = 'string';
        if ( newOption.optionType.toLowerCase() === 'boolean' ) {
            newOption.type = 'boolean';
        }
        delete newOption.accountId;
        // delete newOption.defaultFor;
        delete newOption.folderName;
        delete newOption.updatedAt;
        return newOption;
    } );
    const optionsSelected = [
        {
            id: 'title',
            mandatory: true,
            optionType: 'TEXT',
            title: 'Title of entry',
            type: 'string'
        }, {
            id: 'location',
            type: 'string',
            optionType: 'locationSelect',
            title: 'Category/Sub-Category',
            data: docLocations,
            mandatory: true
        },
        ...composeDefaultOptions ];
    return { optionsSelected };
}

function composeDataForExistingDoc( breadcrumbs, documents, docId ) {
    let docLocations = [];
    let optionsSelected = [];
    let parties = [];
    let currentDocLocation = '';
    let currentLocationValid = true;
    let schema = {
        type: 'object',
        required: [],
        properties: {}
    };
    let newUiSchema = {
        options: {
            defaultOptions: { readonly: true }
        },
        'ui:order': []
    };
    let formData = {};
    let docType = null;
    let documentId = null;
    let currentDoc;
    if ( documents.hasItems() && breadcrumbs.hasItems() ) {
        documentId = docId;
        currentDoc = documents.having( 'id', docId )[0];
        if ( typeof currentDoc === 'undefined' ) {
            // handle case when doc id in url is invalid.
            return { invalidDocId: true };
        }
        docLocations = buildLocationOptions( breadcrumbs.having( 'type', currentDoc.docType ) );
        currentDocLocation = currentDoc.formData.location;
        currentLocationValid = isLocationValid( currentDocLocation, docLocations );
        if ( !currentLocationValid ) {
            currentDoc.schema.properties.location.data = docLocations;
        }
        schema = currentDoc.schema;
        formData = currentDoc.formData;
        docType = currentDoc.docType;
        optionsSelected = Object.keys( currentDoc.schema.properties ).map( key => currentDoc.schema.properties[key] );
        // use existing categories to display in doc location select.
        optionsSelected.having( 'id', 'location' )[0].data = docLocations;
        const { properties, required, uiSchema } = buildContractFields( [ ...optionsSelected ], currentDoc.schema.order || null );
        schema.properties = properties;
        newUiSchema = uiSchema;
        schema.required = [ ...required ];
        parties = currentDoc.parties;
    }
    return { documentId, schema, formData, docType, optionsSelected, newUiSchema, parties, currentDoc, currentLocationValid };
}

const SortableList = SortableContainer( ( { items, removeOption, isUserAdmin, docType, itemDom, order } ) => {
    if ( itemDom ) {
        return (
            <div>
                {Object.keys( items ).map( ( key, index ) => {
                    const val = itemDom ? items[key] : items[key].title;
                    const elementId = itemDom ? '' : items[key].id;
                    return (
                        <SortableItem
                            key={ `item-${key}` }
                            itemDom={ itemDom }
                            index={ index }
                            value={ val }
                            id={ elementId }
                            docType={ docType }
                            removeOption={ removeOption }
                            option={ items[key] }
                            required={ items[key].mandatory }
                            isUserAdmin={ isUserAdmin }
                        />
                    );
                } )}
            </div>
        );
    }
    const dom = order.map( ( elem, index ) => {
        const item = items.having( 'id', elem )[0];
        if ( typeof item === 'undefined' ) {
            return ( '' );
        }
        const val = itemDom ? item : item.title;
        const elementId = itemDom ? '' : item.id;
        return (
            <SortableItem
                key={ `item-${Math.random()}` }
                itemDom={ itemDom }
                index={ index }
                value={ val }
                id={ elementId }
                docType={ docType }
                removeOption={ removeOption }
                option={ item }
                required={ item.mandatory }
                isUserAdmin={ isUserAdmin }
            />
        );
    } );
    return (
        <div>
            { dom }
        </div>
    );
} );

function buildPartiesDOM( parties ) {
    if ( parties.isEmpty() ) {
        return [];
    }
    return parties.map( party => (
        <div className="partyFormEntry mb-3" key={ Math.random() }>
            <span>{ party.label}</span>
            {' '}
define as
            {' '}
            { party.as }
        </div>
    ) );
}

function buildFirstLevelCategories( array, filterUsed = false, used = [] ) {
    const options = [
        { value: 'all', label: 'All' }
    ];
    array.sort( ( a, b ) => sortArrayByProperty( a, b, 'name' ) ).map( cat => {
        if ( filterUsed ) {
            if ( !used.includes( cat.name ) ) {
                options.push( { value: cat.name, label: cat.name } );
            }
        } else {
            options.push( { value: cat.name, label: cat.name } );
        }
    } );
    return options;
}
export {
    SortableList,
    buildFirstLevelCategories,
    buildContractFields,
    buildOptionsFoldersList,
    buildFolderContentList,
    log,
    convertToSchema,
    isLocationValid,
    fillDefault,
    buildLocationOptions,
    composeDataForExistingDoc,
    buildPartiesDOM
};
