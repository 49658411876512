import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';

import ReactToPrint from 'react-to-print';
import { summariesOperations } from '../../redux/ducks/summaries';
import Display from '../Display';
import TooltipWrapper from '../Tooltip/Tooltip';


class ComponentToPrint extends React.Component {
    render() {
        const imageUrl = this.props.currentAccount.logo ? this.props.currentAccount.logo : '/img/mycontracts-logo-3-long-v3.svg';
        return (
            <div className="summaryTableContainer">
                <p className="printOnly header text-center">
                    <img
                        src={ imageUrl }
                        alt="mycontracts"
                        // width="105px"
                        className="logo"
                        style={ { height: '32px', display: 'inline-block' } } />
                </p>
                <Table hover responsive className="table-outline mb-0 d-none d-sm-table summaryTable">
                    <h1 className="text-center title">
                        { this.props.title }
                        {' '}
summary
                    </h1>
                    <tbody>
                        { this.props.summary }
                    </tbody>
                </Table>
                <p className="printOnly footer left">
created by
                    <img
                        src="/img/mycontracts-logo-3-long-v3.svg"
                        alt="mycontracts"
                        width="105px"
                        className="logo"
                        style={ { height: '16px', display: 'inline-block', paddingLeft: 15 } } />
                </p>
            </div>
        );
    }
}

class Summary extends Component {
    constructor() {
        super();
        this.state = {
            summary: null
        };
    }

    UNSAFE_componentWillMount() {
        if ( typeof this.props.selectedDocument === 'undefined' ) {
            return;
        }
        if ( this.props.isEditDoc && typeof this.props.selectedDocument.id !== 'undefined' ) {
            const summary = this.props.summaries.having( 'documentId', this.props.selectedDocument.id )[0];
            if ( typeof summary === 'undefined' ) {
                this.setState( {
                    summary: summary || null,
                } );
                return;
            }
            this.setState( {
                summary: summary || null,
            } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( this.props.open !== nextProps.open ) {
            this.setState( { open: nextProps.open } );
        }
        // TODO: we need a smarter way to determine when current summary is changing to avoid re renders.
        // perhaps, move logic to Reducer ?!
        const currentSummary = nextProps.summaries.having( 'documentId', this.props.selectedDocument.id )[0];
        if ( this.props.isEditDoc && typeof this.props.selectedDocument.id !== 'undefined' ) {
            this.setState( {
                summary: currentSummary || null
            } );
        }
    }

    save = () => {
        this.props.callback();
        this.setState( { open: false } );
    };

    buildSummary = options => {
        const that = this;
        return options.map( key => {
            let value = '';
            let label = '';
            if ( key === 'parties' ) {
                value = that.props.selectedDocument.parties.map( party => `${party.label} defined as ${party.as}` ).join( ' and ' );
                label = 'Parties';
            } else if ( key === 'newField' ) {
                value = '';
                label = 'newField';
            } else if ( typeof that.props.selectedDocument.formData[key] !== 'undefined' ) {
                value = that.props.selectedDocument.formData[key];
                label = that.props.selectedDocument.schema.properties[key].title;
            } else {
                value = '';
                label = typeof that.props.options.having( 'id', key )[0] !== 'undefined' ? that.props.options.having( 'id', key )[0].title : '';
            }
            const type = that.props.selectedDocument.schema.properties[key];
            if ( typeof type !== 'undefined' ) {
                if ( type.optionType.toLocaleLowerCase() === 'pid' ) {
                    value = moment( value ).format( 'DD MMM YYYY' );
                }
                if ( type.optionType.toLocaleLowerCase() === 'pip' ) {
                    const obj = JSON.parse( value );
                    value = `${moment( obj.start ).format( 'DD MMM YYYY' )} - ${moment( obj.end ).format( 'DD MMM YYYY' )}`;
                }
            }

            return (
                <tr id={ key }>
                    <td className="label">
                        { label !== 'newField'
            && `${label}`
                        }
                    </td>
                    <td dangerouslySetInnerHTML={ { __html: value } }>
                    </td>
                </tr>
            );
        } );
    };

    buildSummaryFromExisting = () => {
        const that = this;
        const options = [ 'parties', ...this.props.selectedDocument.schema.order ];
        return options.map( key => {
            let value = '';
            let label = '';
            if ( key === 'parties' ) {
                value = that.props.selectedDocument.parties.map( party => `${party.label} defined as ${party.as}` ).join( ' and ' );
                label = 'Parties';
            } else if ( key === 'newField' ) {
                value = '';
                label = 'newField';
            } else if ( typeof that.props.selectedDocument.formData[key] !== 'undefined' ) {
                value = that.props.selectedDocument.formData[key];
                label = that.props.selectedDocument.schema.properties[key].title;
            } else {
                value = '';
                label = typeof that.props.options.having( 'id', key )[0] !== 'undefined' ? that.props.options.having( 'id', key )[0].title : '';
            }
            if ( value !== '' ) {
                const type = that.props.selectedDocument.schema.properties[key];
                if ( typeof type !== 'undefined' ) {
                    if ( type.optionType.toLocaleLowerCase() === 'pid' ) {
                        value = moment( value ).format( 'DD MMM YYYY' );
                    }
                    if ( type.optionType.toLocaleLowerCase() === 'pip' ) {
                        const obj = JSON.parse( value );
                        value = `${moment( obj.start ).format( 'DD MMM YYYY' )} - ${moment( obj.end ).format( 'DD MMM YYYY' )}`;
                    }
                }
            }


            return (
                <tr id={ key }>
                    <td className="label">
                        { label !== 'newField'
                        && `${label}`
                        }
                    </td>
                    <td dangerouslySetInnerHTML={ { __html: value } }>
                    </td>
                </tr>
            );
        } );
    };

    render() {
        if ( typeof this.props.selectedDocument === 'undefined' || Object.keys( this.props.selectedDocument ).isEmpty() ) {
            return (
                <script />
            );
        }
        let summary = [];
        if ( this.props.isEditDoc && this.state.summary ) {
            summary = this.buildSummaryFromExisting( );
        } else {
            const fieldsWithValues = this.props.selectedDocument.schema.order.filter( x => Object.keys( this.props.selectedDocument.formData ).contains( x ) );
            if ( this.props.selectedDocument.parties.hasItems() ) {
                fieldsWithValues.unshift( 'parties' );
            }
            summary = this.buildSummary( [ ...fieldsWithValues ] );
        }
        const { title } = this.props.selectedDocument.formData;
        return (
            <div>
                <Display when={ !this.props.list }>
                    <TooltipWrapper refId="printSummary" content="View / print summary" />
                    <div
                        id="printSummary"
                        className="btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5 float-right"
                        style={ { marginRight: '5px' } }
                        onClick={ () => { this.setState( { open: true } ); } }>
                        <div className="fa fa-table" style={ { paddingTop: '6px' } } />
                    </div>
                </Display>
                <Modal isOpen={ this.state.open } toggle={ () => { this.setState( { open: false } ); this.props.callback(); } } className="partiesAssignModal">
                    <ModalHeader toggle={ () => { this.setState( { open: false } ); this.props.callback(); } }>
                        {' '}
                        { title }
                        {' '}
Summary Form
                        <i className="fa fa-print" style={ { fontSize: 24, position: 'absolute', right: 50, cursor: 'pointer' } } onClick={ () => { document.getElementById( 'printBtn' ).click(); } } />
                    </ModalHeader>
                    <ModalBody className="summaryModal">
                        <ReactToPrint
                            trigger={ () => <button style={ { display: 'none' } } id="printBtn">Print this out!</button> }
                            content={ () => this.componentRef }
                            bodyClass="printBody"
                            pageStyle={ { margin: '3cm 2cm 2cm 3cm;' } }
                        />
                        <ComponentToPrint
                            ref={ el => ( this.componentRef = el ) }
                            key={ Math.random() }
                            summary={ summary }
                            title={ title }
                            currentAccount={ this.props.currentAccount }
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const { array, object, string, func } = PropTypes;

Summary.defaultProps = {
    callback: () => {}
};

Summary.propTypes = {
    callback: func
};

const mapStateToProps = state => ( {
    summaries: state.summaries,
    selectedDocument: state.documents.selectedDocument,
    currentAccount: state.currentAccount,
    options: state.options.options,
} );

const mapDispatchToProps = dispatch => ( {
    saveSummary: ( data ) => dispatch( summariesOperations.saveSummary( data ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( Summary );
