/* eslint-disable no-nested-ternary,import/no-named-as-default,import/no-named-as-default-member */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PubNub from 'pubnub';
import { Detector } from 'react-detect-offline';
import socketClusterClient from 'socketcluster-client';
import Cookies from 'js-cookie';

// Components
import * as Sentry from '@sentry/browser';
import Account from '../../views/Account';
import Calendar from '../../views/Calendar';
import DocumentSelector from '../../views/Document';
import Vault from '../../views/Vault';
import CategoryView from '../../views/Vault/CategoryView';
import RouteWrapper from '../../RouteEnhancer';
import RestrictedRoute from '../../RestrictedRoute';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

import Footer from '../../components/Footer';
import Dashboard from '../../views/Dashboard';
import Category from '../../components/Category/Category';
import MessageCenter from '../../components/MessageCenter/MessageCenter';
import DocumentAdd from '../../components/Document/DocumentAdd';

import { applicationOperations } from '../../redux/ducks/application';
import AlertSearch from '../../components/Search/AlertSearch';
import PartiesView from '../../views/Parties';
import Profile from '../../components/Profile/Profile';
import { uploadsOperations } from '../../redux/ducks/uploads';
import OfflineModal from '../../components/Modals/OfflineModal';
import { invitesOperations } from '../../redux/ducks/invites';
import FileContentSearch from '../../components/Search/FileContentSearch';
import ActivityList from '../../components/Activity/ActivityList';
import Groups from '../../components/Groups/Groups';
import Aside from '../../components/Aside';
import InviteMessageModal from '../../components/Member/InviteMessageModal';
import Display from '../../components/Display';
import Loading from '../../components/Loading';

class Full extends React.Component {
    constructor() {
        super();
        this.state = {
            fetchingData: true
        };
        this.socket;
    }

    UNSAFE_componentWillMount() {
        window.onunload = () => {
            this.socket.unsubscribeAll();
            // Cookies.set( 'tabs', +Cookies.get( 'tabs' ) - 1 );
        };
        // if ( +Cookies.get( 'tabs' ) > 0 ) {
        //     const r = alert( 'Application already open !' );
        //     if ( r === true ) {
        //         window.location.href = 'https://www.google.com';
        //         return;
        //     }
        //     window.location.href = 'https://www.google.com';
        //     return;
        // }
        // Cookies.set( 'tabs', 0 );
        // Cookies.set( 'tabs', +Cookies.get( 'tabs' ) + 1 );

        const { user, currentAccount } = this.props;
        // try {
        //     this.socket = socketClusterClient.create( {
        //         hostname: process.env.socketClusterHost,
        //         port: process.env.socketClusterPort,
        //         autoConnect: true,
        //         autoReconnect: true,
        //         disconnectOnUnload: true
        //     } );
        //     ( async () => {
        //         for await ( const { error } of this.socket.listener( 'error' ) ) {
        //             Sentry.captureException( error, { extra: { user, currentAccount } } );
        //             console.error( error );
        //         }
        //         for await ( const { error } of this.socket.listener( 'subscribeFail' ) ) {
        //             Sentry.captureException( error, { extra: { user, currentAccount } } );
        //             console.error( error );
        //         }
        //         for await ( const { error } of this.socket.listener( 'disconnect' ) ) {
        //             // TODO: we should do page refresh here ?
        //             Sentry.captureException( error, { extra: { user, currentAccount } } );
        //             console.error( error );
        //         }
        //     } )();
        //
        //     ( async () => {
        //         for await ( const event of this.socket.listener( 'connect' ) ) {
        //             console.info( 'Socket is connected' );
        //         }
        //     } )();
        //     ( async () => {
        //         for await ( const event of this.socket.listener( 'subscribe' ) ) {
        //             console.info( `Subscribed to ${event.channel}` );
        //         }
        //     } )();
        // } catch ( err ) {
        //     console.error( err );
        // }

        this.socket = new PubNub( {
            subscribeKey: process.env.pubNubSubscribeKey,
            uuid: this.props.user.uid
        } );

        this.props.clear();

        const that = this;
        this.props.getPersonalization().then( ( res ) => {
            if ( res.data.generalMaintenance ) {
                this.props.logout();
                setTimeout( () => {
                    window.location.href = '/maintenance';
                }, 150 );
                return;
            }
            that.setState( { fetchingData: false } );
        } );
        this.props.getData( { dataset: 'accounts', uid: user.uid, email: user.email } )
            .then( res => {
                if ( res.data.length > 0 ) {
                    if ( typeof currentAccount.id === 'undefined' ) {
                        window.localStorage.setItem( 'accountId', res.data[0].id );
                    }
                    getData( this.props, typeof currentAccount.id !== 'undefined' ? currentAccount.id : res.data[0].id );
                } else {
                    this.props.getData( { dataset: 'myInvites', email: user.email } );
                }
            } );
    }

    componentDidMount() {
        subscribeToChannels( this.socket, this.props.currentAccount.id, this.props.user.email, this.props );
        document.getElementsByClassName( 'loaderHolder' )[0].style.display = 'none';
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        const { user, currentUser } = nextProps;

        if ( !currentUser || typeof currentUser.id === 'undefined' ) {
            return;
        }
        if ( typeof currentUser.termsAccepted === 'undefined' || !currentUser.termsAccepted ) {
            this.props.history.replace( '/terms-and-conditions' );
            return;
        }

        if ( typeof document !== 'undefined' ) {
            if ( !user ) {
                window.localStorage.removeItem( 'currentUserId' );
                return;
            }
            if ( localStorage.getItem( 'currentUserId' ) !== user.uid ) {
                window.localStorage.setItem( 'currentUserId', user.uid );
            }
        }
        if ( this.props.currentAccount.id !== nextProps.currentAccount.id ) {
            this.socket.unsubscribe( { channels: [ this.props.currentAccount.id ] } );
            this.socket.subscribe( { channels: [ nextProps.currentAccount.id ] } );
            if ( nextProps.currentAccount.maintenance ) {
                window.location.href = '/account_maintenance';
            } else {
                getData( nextProps, nextProps.currentAccount.id );
            }
        }
        if ( this.props.user.email !== nextProps.user.email ) {
            this.socket.unsubscribe( { channels: [ this.props.user.email ] } );
            this.socket.subscribe( { channels: [ nextProps.user.email ] } );
        }
    }

    componentWillUnmount() {
        this.socket.unsubscribeAll();
    }

    render() {
        if ( !this.props.currentUser || this.state.fetchingData ) {
            return <Loading />;
        }
        if ( this.props.currentAccount.maintenance && window.location.pathname !== '/profile' ) {
            window.location.href = '/account_maintenance';
            return <Loading />;
        }
        if ( this.props.accounts.length === 0 ) {
            return (
                <div>
                    <Display when={ typeof this.props.user.email !== 'undefined' }>
                        <InviteMessageModal
                            currentUser={ this.props.currentUser } />
                    </Display>
                    <h3 style={ { textAlign: 'center', marginTop: '15%', marginBottom: '30px' } }>You’re now a registered user! - please see your organisation’s administrator to be invited to the account. </h3>
                    <div style={ { textAlign: 'center' } }>
                        <button className="btn btn-outline-secondary" onClick={ this.props.logout }>Logout</button>
                    </div>
                </div>
            );
        }
        return (
            <Detector
                render={ ( { online } ) => (
                    <div>
                        <OfflineModal showsOfflineMessage={ !online } />
                        <div className="app">

                            <Sidebar { ...this.props } />
                            <Header
                                location={ this.props.location }
                                match={ this.props.match }
                                history={ this.props.history }
                                auth={ this.props.user }
                                currentUser={ this.props.currentUser } />
                            <MessageCenter />
                            <div className="app-body">
                                <main className="main">
                                    <Container fluid>
                                        <Switch>
                                            <Route
                                                path="/dashboard"
                                                name="Dashboard"
                                                render={ ( props ) => (
                                                    <Dashboard auth={ this.props.auth } history={ this.props.history } />
                                                ) }
                                            />
                                            <RouteWrapper
                                                path="/account"
                                                component={ Account } />
                                            <RouteWrapper
                                                path="/parties"
                                                component={ PartiesView } />
                                            <RouteWrapper
                                                path="/alerts"
                                                component={ AlertSearch } />
                                            <Route
                                                path="/calendar"
                                                name="Calendar"
                                                component={ Calendar } />
                                            <Route
                                                path="/activity"
                                                name="Activity"
                                                component={ ActivityList } />
                                            <RouteWrapper
                                                path="/docvault/category"
                                                name="Doc Category"
                                                component={ CategoryView } />
                                            <RouteWrapper
                                                path="/docvault"
                                                name="Doc Vault"
                                                component={ Vault } />
                                            <RouteWrapper
                                                path="/filesearch"
                                                name="File Search"
                                                component={ FileContentSearch } />
                                            <RouteWrapper
                                                path="/add"
                                                name="Add"
                                                component={ DocumentSelector } />
                                            <Route
                                                path="/edit-document"
                                                name="Edit"
                                                render={ ( props ) => (
                                                    <DocumentAdd history={ props.history } location={ props.location } />
                                                ) } />
                                            <RestrictedRoute
                                                exact
                                                path="/settings"
                                                name="Categories"
                                                component={ Category } />
                                            <RouteWrapper
                                                exact
                                                path="/profile"
                                                name="Profile"
                                                component={ Profile } />
                                            <RouteWrapper
                                                exact
                                                path="/groups"
                                                name="Groups"
                                                component={ Groups } />
                                            <Redirect
                                                from="/"
                                                to="/dashboard" />
                                            {/* <Route path="/" name="Page not found" component={Test} /> */}
                                        </Switch>
                                    </Container>
                                </main>
                            </div>
                            <Footer personalization={ this.props.personalization } />
                        </div>
                        <Aside />
                        <Display when={ typeof ( this.props.currentUser.email ) !== 'undefined' }>
                            <InviteMessageModal
                                currentUser={ this.props.currentUser } />
                        </Display>
                    </div>
                ) }
            />
        );
    }
}


const { object, string, func } = PropTypes;
Full.propTypes = {
    updateInvite: func.isRequired,
    getData: func.isRequired,
};

const mapStateToProps = state => ( {
    personalization: state.application.personalization,
    currentUser: state.currentUser,
    currentAccount: state.currentAccount,
    categoryTree: state.categories.categoryTree,
    accounts: state.accounts,
} );

const mapDispatchToProps = dispatch => ( {
    updateInvite: ( status ) => dispatch( invitesOperations.updateInvite( status ) ),
    getPersonalization: ( data ) => dispatch( applicationOperations.getPersonalization( data ) ),
    getData: ( data ) => dispatch( applicationOperations.getData( data ) ),
    clear: ( ) => dispatch( uploadsOperations.clear( ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( Full );

function getData( props, accountId ) {
    window.localStorage.setItem( 'accountId', accountId );
    props.getData( { dataset: 'myInvites', email: props.user.email } );
    props.getData( { dataset: 'currentUser', uid: props.user.uid, accountId } )
        .then( res => {
            props.getData( { dataset: 'categories', accountId } );
        } );
    props.getData( { dataset: 'accounts', uid: props.user.uid, email: props.user.email } );
    props.getData( { dataset: 'getUsersPermissions', accountId, uid: props.user.uid } );
    // props.getData( { dataset: 'eventsCount', accountId } );
    props.getData( { dataset: 'parties', accountId } );
    props.getData( { dataset: 'calendarEvents', accountId } );
    props.getData( { dataset: 'documentTypes', accountId } );
    props.getData( { dataset: 'summaries', accountId } );
    props.getData( { dataset: 'documents', accountId } );
    props.getData( { dataset: 'filesMeta', accountId } );
    props.getData( { dataset: 'options', accountId } );
    props.getData( { dataset: 'invites', accountId } );
    props.getData( { dataset: 'activity', accountId } );
}

function handleNotificationData( data, props ) {
    console.log( data );
    const accountId = props.currentAccount.id;
    const { uid, email } = props.user;
    switch ( data.target ) {
        case 'generalMaintenanceApplied': {
            props.logout();
            setTimeout( () => {
                window.location.href = '/maintenance';
            }, 150 );
            break;
        }
        case 'accountMaintenanceModeApplied': {
            window.location.href = '/account_maintenance';
            break;
        }
        case 'accountMaintenanceModeLifted': {
            window.location.href = '/dashboard';
            break;
        }
        case 'documents': {
            if ( typeof data.type !== 'undefined' ) {
                return props.getData( { dataset: 'documents', documentId: data.docId, breadcrumb: data.cat, accountId, type: data.type } );
            }
            return props.getData( { dataset: 'documents', accountId, response: data.data } );
        }
        case 'filesMeta': {
            if ( typeof data.type !== 'undefined' ) {
                return props.getData( { dataset: 'filesMeta', documentId: data.docId, breadcrumb: data.cat, accountId, type: data.type } );
            }
            return props.getData( { dataset: 'filesMeta', accountId } );
        }
        case 'accounts': {
            return props.getData( { dataset: 'accounts', uid: props.user.uid, email: props.user.email } );
        }
        case 'all': return getData( props, accountId );
        case 'activity': {
            return props.getData( { dataset: 'activity', accountId, response: data.data } );
        }
        case 'calendarEvents': {
            if ( typeof data.type !== 'undefined' ) {
                const payload = { dataset: 'calendarEvents', accountId, breadcrumb: data.cat, type: data.type };
                if ( typeof data.docId !== 'undefined' ) {
                    payload.documentId = data.docId;
                }
                if ( typeof data.recurrencyId !== 'undefined' ) {
                    payload.recurrencyId = data.recurrencyId;
                }
                // props.getData( { dataset: 'eventsCount', accountId } );
                return props.getData( payload );
            }
            props.getData( { dataset: 'calendarEvents', accountId } );
            // props.getData( { dataset: 'eventsCount', accountId } );
        }
            break;
        case 'invites': props.getData( { dataset: 'invites', accountId } );
            break;
        case 'myInvites': props.getData( { dataset: 'myInvites', email } );
            break;
        default: return props.getData( { dataset: data.target, accountId } );
    }
}

function displayImportantNotification( data ) {
    if ( data.target === 'generalMaintenanceApplied' || data.target === 'generalMaintenanceLifted' ) {
        navigator.serviceWorker.getRegistration().then( ( registration ) => {
            if ( registration ) {
                let title = 'Browsers mode';
                const body = "We\'re undergoing a bit of scheduled maintenance\n";
                if ( data.target === 'generalMaintenanceLifted' ) {
                    title = 'System is back online';
                }
                const options = {
                    body,
                    requireInteraction: true
                };
                registration.showNotification( title, options );
            }
        } );
    }
}

// function subscribeToChannels( socket, accountId, email, props ) {
//     ( async () => {
//         const accountChannel = socket.subscribe( accountId );
//         const userChannel = socket.subscribe( email );
//         const generalChannel = socket.subscribe( 'general' );
//         const channelArray = [ accountChannel, userChannel, generalChannel ];
//         channelArray.map( async channel => {
//             for await ( const data of channel ) {
//                 displayImportantNotification( data );
//                 handleNotificationData( data, props );
//             }
//         } );
//     } )();
// }
function subscribeToChannels( socket, accountId, email, props ) {
    ( async () => {
        socket.subscribe( {
            channels: [ accountId, email, 'general' ],
            withPresence: true
        } );
        socket.addListener( {
            status( statusEvent ) {
                if ( statusEvent.category === 'PNConnectedCategory' ) {
                    console.log( 'statusEvent', statusEvent );
                }
            },
            message( msg ) {
                displayImportantNotification( msg.message );
                if ( msg.message.target.contains( ',' ) ) {
                    msg.message.target.split( ',' ).map( target => {
                        const { message } = msg;
                        message.target = target.trim();
                        handleNotificationData( message, props );
                    } );
                } else {
                    handleNotificationData( msg.message, props );
                }
                console.log( 'message', msg );
            }
        } );
        // const accountChannel = socket.subscribe( accountId );
        // const userChannel = socket.subscribe( email );
        // const generalChannel = socket.subscribe( 'general' );
        // const channelArray = [ accountChannel, userChannel, generalChannel ];
        // channelArray.map( async channel => {
        //     for await ( const data of channel ) {
        //         displayImportantNotification( data );
        //         handleNotificationData( data, props );
        //     }
        // } );
    } )();
}
