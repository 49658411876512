import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';

const initialState = [];

const activity = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'activity' ) {
                return build( action.payload.data );
            }
            return state;
        case types.GET_ACTIVITY_COMPLETED:
            return action.payload.data.docList;
        default: return state;
    }
};

export default activity;
