import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

class UsersPermissionsModal extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            userId: null,
        };
        this.toggle = this.toggle.bind( this );
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.setState( { open: nextProps.open, userId: nextProps.userId } );
    }

    toggle() {
        this.props.reset();
    }

    render() {
        const { currentAccount } = this.props;
        if ( typeof currentAccount.usersPermissions === 'undefined' || !currentAccount.usersPermissions[this.state.userId] ) {
            return null;
        }
        let dom = '';
        if ( this.state.userId ) {
            dom = buildDom( this.state.userId, currentAccount.usersPermissions[this.state.userId] );
        }
        return (
            <div className="animated fadeIn" style={ { minHeight: '50px' } }>
                <Modal isOpen={ this.state.open } toggle={ this.toggle } className="partyModal">
                    <ModalHeader toggle={ this.toggle }>
              Permissions
                    </ModalHeader>
                    <ModalBody>
                        { dom }
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const { object, bool, string, func } = PropTypes;

UsersPermissionsModal.defaultProps = {
    currentAccount: null,
    userId: null
};

UsersPermissionsModal.propTypes = {
    currentAccount: object.isRequired || null.isRequired,
    open: bool.isRequired,
    userId: string,
    reset: func.isRequired
};


const mapStateToProps = state => ( {
    currentAccount: state.currentAccount
} );

export default connect( mapStateToProps, null )( UsersPermissionsModal );


function buildDom( userId, userData ) {
    const listDom = [];
    Object.keys( userData ).map( groupId => {
        const entry = userData[groupId];
        if ( entry.permissions.hasItems() ) {
            entry.permissions.map( permission => {
                let resource = `Account: ${permission.resource}`;
                const { access } = permission;
                if ( permission.type === 'category' ) {
                    resource = `Category: ${permission.resource}`;
                }

                listDom.push(
                    <div className="row mb-1" key={ Math.random() }>
                        <div style={ { width: '36%', textAlign: 'left', wordBreak: 'break-word' } }>{resource}</div>
                        <div style={ { width: '31%', textAlign: 'left', wordBreak: 'break-word' } }>{access}</div>
                        <div style={ { width: '33%', textAlign: 'left', wordBreak: 'break-word' } }>{entry.groupName}</div>
                    </div>
                );
            } );
        }
    } );
    return (
        <div className="col-lg-12">
            <div className="row mb-2">
                <div style={ { width: '36%', textAlign: 'left', fontWeight: 'bold' } }>Resource</div>
                <div style={ { width: '31%', textAlign: 'left', fontWeight: 'bold' } }>Access level</div>
                <div style={ { width: '33%', textAlign: 'left', fontWeight: 'bold' } }>Group</div>
            </div>
            { listDom }
        </div>
    );
}
