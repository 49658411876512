import types from './types';

const addDocument = ( data, extraData ) => {
    const body = data;
    return {
        type: types.ADD_DOCUMENT,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/addDocument',
            method: 'POST',
            body
        }
    };
};

const updateDocument = ( data, extraData ) => {
    const body = data;
    return {
        type: types.ADD_DOCUMENT,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/addDocument',
            method: 'PUT',
            body
        }
    };
};

const deleteDocument = ( data, extraData ) => {
    return {
        type: types.DELETE_DOCUMENT,
      additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/deleteDocument',
            method: 'DELETE',
            body: data
        }
    };
};

const selectDocument = ( data ) => ( {
    type: types.SELECT_DOCUMENT,
    payload: data
} );

const addTempDocData = ( data ) => ( {
    type: types.ADD_TEMP_DOC_DATA,
    payload: data
} );

const clearDocument = ( ) => ( {
    type: types.CLEAR_DOCUMENT
} );

const clearTempDoc = ( ) => ( {
    type: types.CLEAR_TEMP_DOC
} );

export default {
    addDocument,
    updateDocument,
    deleteDocument,
    selectDocument,
    clearDocument,
    addTempDocData,
    clearTempDoc
};
