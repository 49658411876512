function fullStringClean( string ) {
    return string
        .replace( '/', '-' )
        .replace( '[', '' )
        .replace( ']', '' )
        .replace( /[&\/\\#+^`±=!$~%'|":;*?<>{}]/g, '' )
        .replace( /\s\s+/g, ' ' )
        .trim();
}

function cleanSpecialChars( string ) {
    return string
        .replace( '/', '-' )
        .replace( '[', '' )
        .replace( ']', '' )
        .replace( /[&\/\\#+^`±=!$~%'|":;*?<>{}]/g, '' );
}

export {
    fullStringClean,
    cleanSpecialChars
};
