import types from './types';

const getVersions = ( data, extraData ) => {
    const body = data;
    return {
        type: types.GET_VERSION,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: `/version?accountId=${data.accountId}&elementId=${data.elementId}&collection=${data.collection}`,
            method: 'GET',
            body
        }
    };
};

const restore = ( data, extraData ) => {
    const body = data;
    return {
        type: types.RESTORE_VERSION,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/restoreVersion',
            method: 'POST',
            body
        }
    };
};
const restoreFile = ( data, extraData ) => {
    const body = data;
    return {
        type: types.RESTORE_FILE,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/restoreFile',
            method: 'POST',
            body
        }
    };
};


export default {
    getVersions,
    restoreFile,
    restore
};
