import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuidv1 from 'uuid/v1';


import {
    Modal, ModalHeader,
    ModalBody, ModalFooter, Button
} from 'reactstrap';

import Select from 'react-select';
import { documentTypesOperations } from '../../redux/ducks/documentTypes';
import Display from '../Display';
import SpinnerModal from '../Modals/SpinnerModal';

class CloneDocumentType extends Component {
    constructor( ) {
        super( );
        this.state = {
            name: '',
            savingInProgress: false,
            selectedDocType: null,
            error: null
        };
    }

    UNSAFE_componentWillMount() {
        this.setState( { name: `${this.props.selectedDocType} Clone` } );
    }

    handleInputChange = e => {
        this.setState( {
            name: e.target.value,
            error: null
        } );
    };

    selectFolder = option => {
        this.setState( { selectedDocType: option } );
    };

    save = () => {
        const { saveDocument, toggle, documentTypes, savingInProgress, selectedDocType } = this.props;
        const { name } = this.state;
        if ( savingInProgress ) {
            return;
        }
        if ( name.length === 0 ) {
            this.setState( { error: 'Please enter a name for the new doc type.' } );
            return;
        }
        if ( documentTypes.having( 'name', name ).hasItems() ) {
            this.setState( { error: 'Name has to be unique' } );
            return;
        }
        this.setState( { savingInProgress: true } );
        const newDocType = documentTypes.having( 'name', selectedDocType )[0];
        newDocType.name = name;
        newDocType.updatedAt = Date.now();
        newDocType.id = uuidv1();
        const newData = {
            doc: newDocType,
            accountId: newDocType.accountId
        };
        saveDocument( newData )
            .then( ( ) => { this.setState( { savingInProgress: false } ); toggle(); } )
            .catch( ( ) => { this.setState( { savingInProgress: false } ); } );
    };

    render() {
        const { toggle, open } = this.props;
        const { name, error, savingInProgress } = this.state;
        return (
            <Modal isOpen={ open } toggle={ toggle } className="optionsBuilderModal">
                <ModalHeader toggle={ toggle } style={ { width: '100%' } }>
            Edit form field
                </ModalHeader>
                <ModalBody>
                    <div id="name" className="" style={ { marginTop: 15 } }>
                        <p>Let's add a name for this new document type:</p>
                        <input
                            name="name"
                            autoComplete="off"
                            type="input"
                            className={ `form-control ${name !== '' ? '' : 'blank'}` }
                            value={ name }
                            onChange={ this.handleInputChange } />
                        <span className="text-danger">{error }</span>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={ this.save }>Clone</Button>
                    <SpinnerModal showSpinner={ savingInProgress } />
                </ModalFooter>
            </Modal>
        );
    }
}

CloneDocumentType.propTypes = {
    toggle: PropTypes.func.isRequired,
    saveDocument: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    savingInProgress: PropTypes.bool.isRequired,
    documentTypes: PropTypes.array.isRequired,
};

const mapStateToProps = state => ( {
    documentTypes: state.documentTypes.list,
    selectedDocType: state.documentTypes.selectedFolder
} );

const mapDispatchToProps = dispatch => ( {
    saveDocument: ( data ) => dispatch( documentTypesOperations.saveDocument( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( CloneDocumentType );
