

const formTemplate = {

    schema: {
        title: 'Party Info',
        description: '',
        type: 'object',
        properties: {
            partyName: {
                title: 'Party Name',
                type: 'string',
                minLength: 3,
                maxLength: 256,
            },
            partyType: {
                type: 'string',
                title: 'Party Type',
                enum: [
                    'Individual',
                    'Private Company',
                    'Public Company',
                    'Sole Trader',
                    'Partnership',
                    'Company limited by Guarantee',
                    'Unincorporated Joint Venture',
                    'Government Dept',
                    'Other'
                ]
            },
            street: {
                title: 'Street',
                type: 'string',
                minLength: 3,
                maxLength: 100,
            },
            city: {
                title: 'City',
                type: 'string',
                minLength: 3,
                maxLength: 100,
            },
            postCode: {
                title: 'Post/Zip code',
                type: 'string',
                minLength: 4,
                maxLength: 10,
            },
            state: {
                title: 'State',
                type: 'string',
                minLength: 3,
                maxLength: 100,
            },
            country: {
                type: 'string',
                title: 'Country',
                enum: [
                    'Australia',
                    'Canada',
                    'New Zealand',
                    'United States'
                ]
            },
            contactPerson: {
                title: 'Contact person name',
                type: 'string',
                minLength: 6,
                maxLength: 60,
            },
            contactPersonEmail: {
                title: 'Contact person email',
                type: 'string',
                minLength: 6,
                maxLength: 60,
            },
            contactPersonPhone: {
                title: 'Contact person phone',
                type: 'string',
                minLength: 6,
                maxLength: 60,
            },
            subpremise: {
                title: 'Floor / Unit / Level',
                type: 'string',
            }
        },
        required: [
            'partyName',
            'partyType',
            'street',
            'city',
            'state',
            'postCode',
            'country'
        ],
        dependencies: {
            partyType: {
                oneOf: [
                    {
                        properties: {
                            partyType: {
                                enum: [
                                    'Individual'
                                ]
                            }
                        }
                    },
                    {
                        properties: {
                            partyType: {
                                enum: [
                                    'Unincorporated Joint Venture'
                                ]
                            },
                            abnarbn: {
                                title: 'ABN/ARBN',
                                type: 'string',
                                // pattern: '/^(\\s*|\\d+)$/',
                                maxLength: 30,
                            },
                        }
                    },
                    {
                        properties: {
                            partyType: {
                                enum: [
                                    'Sole Trader'
                                ]
                            },
                            abn: {
                                title: 'ABN',
                                type: 'string',
                                // pattern: '/^(\\s*|\\d+)$/',
                                maxLength: 30,
                            }
                        },
                        required: [ ]
                    },
                    {
                        properties: {
                            partyType: {
                                enum: [
                                    'Partnership'
                                ]
                            },
                            abn: {
                                title: 'ABN',
                                type: 'string',
                                // pattern: '/^(\\s*|\\d+)$/',
                                maxLength: 30,
                            }
                        },
                        required: [ ]
                    },
                    {
                        properties: {
                            partyType: {
                                enum: [
                                    'Private Company',
                                    'Public Company',
                                    'Company limited by Guarantee',
                                ]
                            },
                            abnacn: {
                                title: 'ABN / ACN',
                                type: 'string',
                                // pattern: '/^(\\s*|\\d+)$/',
                                maxLength: 30,
                            }
                        },
                        required: [ ]
                    }
                ]

            }
        }
    },

};

export default formTemplate;
