import React from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

class TooltipWrapper extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            tooltipOpen: false
        };
        this._isMounted = false;
        this.toggle = this.toggle.bind( this );
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggle() {
        if ( this._isMounted ) {
            this.setState( {
                tooltipOpen: !this.state.tooltipOpen
            } );
        }
    }

    render() {
        return (
            <span>
                <Tooltip
                    className={ this.props.className }
                    autohide={ false }
                    delay={ 50 }
                    placement={ this.props.placement }
                    isOpen={ this.state.tooltipOpen }
                    target={ this.props.refId || '' }
                    toggle={ this.toggle }>
                    { this.props.content }
                </Tooltip>
            </span>
        );
    }
}

const { array, string } = PropTypes;

TooltipWrapper.defaultProps = {
    placement: 'top', // can be: top, bottom, left, right
    content: 'Tooltip Content!',
    className: '',
    target: ''
};

TooltipWrapper.propTypes = {
    placement: string,
    content: string,
    className: string,
    target: string
    // refId: string.isRequired,
};

export default TooltipWrapper;
