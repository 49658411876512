/* eslint-disable import/no-named-as-default,import/no-named-as-default-member */
import React, { useEffect } from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { AppContainer } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/browser';
// import { hot } from 'react-hot-loader/root';
// Styles
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../scss/style.scss';
// Temp fix for reactstrap
import '../scss/core/_dropdown-menu-right.scss';
import 'open-iconic/font/css/open-iconic-bootstrap.scss';
// Containers
import Full from './containers/Full';
import Browsers from './views/Pages/Browsers/Browsers';
import Maintenance from './views/Pages/Maintenance/Maintenance';
import AccountMaintenance from './views/Pages/Maintenance/AccountMaintenance';
// Pages
import LoginError from './views/Pages/LoginError/LoginError';
import Page404 from './views/Pages/Page404';
import Page500 from './views/Pages/Page500';
// store
import configureStore from './redux/store';
// constants
// Service-worker
import RouteWrapper from './RouteEnhancer';
import './utils/sugarify';
import TermsAndConditions from './views/TermsAndConditions/TermsAndConditions';
import { useAuth0 } from './auth0_provider';
import Loading from './components/Loading';

if ( process.env.NODE_ENV === 'production' ) {
    Sentry.init( {
        dsn: 'https://671ce4e449de4926af5046e5b36634b4@sentry.io/1302733',
        release: `mycontracts ${process.env.VERSION}` || 'undefined_dev_version',
        environment: process.env.environment,
    } );
}

const history = createBrowserHistory();

history.listen( ( location, action ) => {
    window.Intercom( 'update' );
    console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
    );
    console.log( `The last navigation action was ${action}` );
} );

window.intercomSettings = {
    app_id: 'idj2qwhm',
    alignment: 'left',
    horizontal_padding: 65,
    vertical_padding: 20
};

window.Intercom( 'boot', {
    app_id: 'idj2qwhm'
} );

// Initialize Redux
const { store, persistor } = configureStore();

// for hot reloading
if ( module.hot ) {
    module.hot.accept( './redux/ducks', () => {
        const nextRootReducer = require( './redux/ducks' );
        persistor.replaceReducer( nextRootReducer );
    } );
}

const Main = () => {
    const { loading } = useAuth0();
    if ( loading ) {
        return <Loading />;
    }
    return (
        <AppContainer>
            <Provider store={ store }>
                <PersistGate loading={ null } persistor={ persistor }>
                    <BrowserRouter>
                        <Switch>
                            <RouteWrapper
                                exact
                                path="/terms-and-conditions"
                                name="Terms and Conditions"
                                component={ TermsAndConditions } />
                            <Route exact path="/error" name="Page 404" component={ LoginError } />
                            <Route exact path="/browsers" name="Page 404" component={ Browsers } />
                            <Route exact path="/maintenance" name="Page 404" component={ Maintenance } />
                            <Route exact path="/404" name="Page 404" component={ Page404 } />
                            <Route exact path="/500" name="Page 500" component={ Page500 } />
                            <RouteWrapper path="/account_maintenance" name="Maintenance" component={ AccountMaintenance } />
                            <RouteWrapper path="/" name="Home" component={ Full } />
                        </Switch>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </AppContainer>
    );
};

export default hot( Main );
