import React from 'react';

export const buildPermissionsDom = ( permissions ) => {
    const data = permissions.map( permission => {
        let resource = `Account: ${permission.resource}`;
        const { access } = permission;
        if ( permission.type === 'category' ) {
            resource = `Category: ${permission.resource}`;
        }
        return (
            <div className="row mb-1">
                <div style={ { width: '36%', textAlign: 'left' } }>{resource}</div>
                <div style={ { width: '31%', textAlign: 'left' } }>{access}</div>
                <div style={ { width: '33%', textAlign: 'left' } }>{permission.group}</div>
            </div>
        );
    } );
    return (
        <React.Fragment>
            <div className="row mb-2">
                <div style={ { width: '36%', textAlign: 'left', fontWeight: 'bold' } }>Resource</div>
                <div style={ { width: '31%', textAlign: 'left', fontWeight: 'bold' } }>Access level</div>
                <div style={ { width: '33%', textAlign: 'left', fontWeight: 'bold' } }>Group</div>
            </div>
            { data }
        </React.Fragment>
    );
};
