import * as React from 'react';
import { Badge, ListGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import AddGroup from './addGroup';
import { groupOperations } from '../../redux/ducks/groups';
import { GroupService } from '../../../Services';
import SingleItemGroup from './SingleItemGroup';
import ListItemGroup from './ListItemGroup';
import GroupsTabs from './Tabs';
import { sortArrayByProperty } from '../../utils/sortArrayByProperty';
import Display from '../Display';
import { resourceOperations } from '../../redux/ducks/resources';
import EditResource from './editResource';

class GroupList extends React.PureComponent {
    constructor( props ) {
        super( props );

        this.state = {
            edit: false,
            searchFor: 'cat',
            searchValue: '',
            selectedResource: {}
        };
        this.selectedGroup = {};
        this.editGroup = this.editGroup.bind( this );
        this.saveGroup = this.saveGroup.bind( this );
        this.selectForSearch = this.selectForSearch.bind( this );
        this.search = this.search.bind( this );
        this.deleteResource = this.deleteResource.bind( this );
        this.addResourceToGroup = this.addResourceToGroup.bind( this );
        this.saveEditedResource = this.saveEditedResource.bind( this );
        this.editResource = this.editResource.bind( this );
        this.toggleEditResource = this.toggleEditResource.bind( this );
    }

    componentWillMount() {
        this.setState( { data: this.props.groups } );
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.setState( { data: nextProps.groups } );
    }

    editGroup( group ) {
        this.selectedGroup = group;
        this.setState( { edit: !this.state.edit } );
    }

    saveGroup( group ) {
        this.props.updateGroup( group );
    }

    deleteResource( groupData, resourceId ) {
        const group = { ...groupData, resources: groupData.resources.filter( x => x !== resourceId ) };
        this.props.deleteResource( { resourceId, accountId: this.props.currentAccount.id } );
        this.props.updateGroup( group );
    }

    buildDOM( ) {
        const { isAdmin, isOwner, isDefault } = this.props.currentUser;
        let { groups } = this.props;
        if ( !isAdmin && !isOwner ) {
            groups = this.props.groups.filter( group => typeof group.members[this.props.currentUser.id] !== 'undefined' );
        }
        const groupsDom = [];
        const escalationsDom = [];
        const usersDom = [];
        groups.sort( ( a, b ) => sortArrayByProperty( a, b, 'name' ) ).map( group => {
            const item = (
                <ListItemGroup
                    account={ this.props.currentAccount}
                    isDefaultUser={ this.props.currentUser.isDefault }
                    currentUserId={ this.props.currentUser.uid }
                    currentUserEmail={ this.props.currentUser.email }
                    restrictedView={ !isAdmin && !isOwner }
                    key={ Math.random() }
                    data={ group }
                    groups={ this.props.groups }
                    resources={ this.props.resources.having( 'groupId', group.id ) }
                    updateGroup={ this.props.updateGroup }
                    addResourceToGroup={ this.addResourceToGroup }
                    deleteResourceFromGroup={ this.deleteResource }
                    deleteGroup={ this.props.deleteGroup }
                    saveGroup={ this.saveGroup }
                    editResource={ this.editResource }
                    editGroup={ this.editGroup } />
            );
            if ( group.single ) {
                usersDom.push( item );
            } else if ( group.escalation ) {
                escalationsDom.push( item );
            } else {
                groupsDom.push( item );
            }
        } );
        return { groupsDom, usersDom, escalationsDom };
    }

    builSingleDOM( ) {
        const groups = this.state.data.filter( x => x.single );
        return groups.map( group => (
            <SingleItemGroup
                key={ Math.random() }
                data={ group }
                updateGroup={ this.props.updateGroup }
                deleteGroup={ this.props.deleteGroup }
                editGroup={ this.editGroup } />
        ) );
    }

    selectForSearch( type ) {
        this.setState( { searchFor: type } );
    }

    search( e ) {
        const { value } = e.target;
        let data = this.props.groups;
        switch ( this.state.searchFor ) {
            case 'cat': data = searchCategory( value, this.props.groups ); break;
            case 'user': data = searchUser( value, this.props.groups ); break;
            case 'group': data = searchGroups( value, this.props.groups ); break;
            default: data = this.props.groups;
        }
        const openGroup = this.state.searchFor === 'user' && value !== '';
        let openCat = this.state.searchFor === 'group' && value !== '';
        if ( openGroup ) {
            openCat = true;
        }
        this.setState( { searchValue: value, data, openGroup, openCat } );
    }

    addResourceToGroup( group ) {
    }

    toggleEditResource( ) {
        this.setState( { editResource: !this.state.editResource } );
    }

    editResource( group, resourceId ) {
        const resource = this.props.resources.filter( x => x.id === resourceId )[0];
        this.setState( { editResource: true, selectedResource: resource } );
    }

    saveEditedResource( resource ) {
    }

    render() {
        let names = [];
        if ( this.state.open ) {
            names = GroupService.extractGroupNames( this.state.data );
        }
        const { isAdmin, isOwner } = this.props.currentUser;
        const { groupsDom, usersDom, escalationsDom } = this.buildDOM();
        return (
            <React.Fragment>
                <Modal isOpen={ this.state.edit } toggle={ this.editGroup } className="partyModal">
                    <ModalHeader toggle={ this.editGroup }>
                    Add resource
                    </ModalHeader>
                    <ModalBody>
                        <AddGroup
                            edit
                            selectedGroup={ this.selectedGroup }
                            modalCallback={ this.editGroup }
                            create={ this.props.updateGroupWithAcls }
                            update={ this.props.updateGroup }
                            editResource={ this.saveEditedResource }
                            accounts={ this.props.accounts }
                            resources={ this.props.resources }
                            documents={ this.props.documents }
                            events={ this.props.events }
                            names={ names }
                            accountId={ this.props.currentAccount.id } />
                    </ModalBody>
                </Modal>
                <Modal isOpen={ this.state.editResource } toggle={ this.toggleEditResource } className="partyModal">
                    <ModalHeader toggle={ this.toggleEditResource }>
                    Edit resource
                    </ModalHeader>
                    <ModalBody>
                        <EditResource
                            selectedResource={ this.state.selectedResource }
                            modalCallback={ this.toggleEditResource }
                            update={ this.props.updateResource }
                            accounts={ this.props.accounts }
                            resources={ this.props.resources }
                            documents={ this.props.documents }
                            events={ this.props.events }
                            groups={ this.props.groups }
                            names={ names }
                            accountId={ this.props.currentAccount.id } />
                    </ModalBody>
                </Modal>
                <Display when={ false }>
                    <div style={ { marginBottom: 5 } }>
                        <div style={ { position: 'absolute', top: 5, paddingLeft: 5 } }>
                            <Badge
                                color="primary"
                                className={ `selectedGroup pointer ${this.state.searchFor !== 'cat' ? 'disabled' : ''}` }
                                style={ { padding: 5, fontSize: 10, margin: 2, pointer: 'cursor' } }
                                onClick={ () => { this.selectForSearch( 'cat' ); } }>category
                            </Badge>
                            <Badge
                                color="primary"
                                className={ `selectedGroup pointer ${this.state.searchFor !== 'user' ? 'disabled' : ''}` }
                                style={ { padding: 5, fontSize: 10, margin: 2 } }
                                onClick={ () => { this.selectForSearch( 'user' ); } }>user
                            </Badge>
                            <Badge
                                color="primary"
                                className={ `selectedGroup pointer ${this.state.searchFor !== 'group' ? 'disabled' : ''}` }
                                style={ { padding: 5, fontSize: 10, margin: 2, pointer: 'cursor' } }
                                onClick={ () => { this.selectForSearch( 'group' ); } }>group
                            </Badge>
                        </div>
                        <input
                            className="form-control"
                            style={ { paddingLeft: 140 } }
                            placeholder="type a value"
                            value={ this.state.searchValue }
                            onChange={ this.search } />
                    </div>
                </Display>
                <GroupsTabs escalationsDom={ escalationsDom } groupsDom={ groupsDom } usersDom={ usersDom } />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ( {
    currentUser: state.currentUser,
    currentAccount: state.currentAccount,
    groups: state.groups,
    accounts: [ state.currentAccount ],
    documents: state.documents.list,
    events: state.events.calendarEvents,
    resources: state.resources
} );

const mapDispatchToProps = dispatch => ( {
    updateGroup: ( data ) => dispatch( groupOperations.updateGroup( data ) ),
    updateGroupWithAcls: ( data ) => dispatch( groupOperations.updateGroupWithAcls( data ) ),
    deleteGroup: ( data ) => dispatch( groupOperations.deleteGroup( data ) ),
    deleteResource: ( data ) => dispatch( resourceOperations.deleteResource( data ) ),
    updateResource: ( data ) => dispatch( resourceOperations.updateResource( data ) ),
} );

export default connect( mapStateToProps, mapDispatchToProps )( GroupList );

function searchCategory( value, data ) {
    return data.filter( entry => entry.category.toLocaleLowerCase().contains( value ) );
}
function searchUser( value, data ) {
    return data.filter( entry => {
        let hasValue = false;
        Object.keys( entry.members ).map( key => {
            if ( entry.members[key].email.toLocaleLowerCase().contains( value ) ) {
                hasValue = true;
            }
        } );
        return hasValue;
    } );
}
function searchGroups( value, data ) {
    return data.filter( entry => entry.name.toLocaleLowerCase().contains( value ) );
}
