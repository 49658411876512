import types from './types';

const createGroup = ( data, extraData ) => {
    const body = data;
    return {
        type: types.CREATE_GROUP,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/group',
            method: 'POST',
            body
        }
    };
};

const storeResource = ( data, extraData ) => {
    const body = data;
    return {
        type: types.STORE_RESOURCE,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/resources',
            method: 'POST',
            body
        }
    };
};

const updateGroup = ( data, extraData ) => {
    const body = data;
    return {
        type: types.UPDATE_GROUP,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/group',
            method: 'PUT',
            body
        }
    };
};
const deleteGroup = ( data, extraData ) => {
    const body = data;
    return {
        type: types.UPDATE_GROUP,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/group',
            method: 'DELETE',
            body
        }
    };
};

export default {
    storeResource,
    createGroup,
    updateGroup,
    deleteGroup
};
