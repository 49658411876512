import React from 'react';
import { parseDiff, Diff, Hunk } from 'react-diff-view';
import { diffLines, formatLines } from 'unidiff';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'react-diff-view/style/index.css';
import ReactDOM from 'react-dom';
import Button from 'reactstrap/lib/Button';
import PropTypes from 'prop-types';
import Display from '../Display';
import TooltipWrapper from '../Tooltip/Tooltip';

const modalRoot = document.getElementById( 'modal-root' );

const DiffView = ( { oldData, newData, showDiff, close, action, allowRestore = true, callInProgress, selectedVersionNumber, currentVersionNumber } ) => {
    const diffText = formatLines( diffLines( newData, oldData ), { context: 99999999 } );
    const files = parseDiff( diffText );
    const renderFile = ( { oldRevision, newRevision, type, hunks } ) => (
        <Diff key={ `${oldRevision}-${newRevision}` } viewType="split" diffType={ type } hunks={ hunks }>
            {hunks => hunks.map( hunk => <Hunk key={ hunk.content } hunk={ hunk } /> )}
        </Diff>
    );

    return (
        ReactDOM.createPortal(
            <Display when={ showDiff }>
                <Modal isOpen={ showDiff } toggle={ close } style={ { minWidth: '80%' } }>
                    <ModalHeader toggle={ close }>
                        { !allowRestore && <TooltipWrapper refId="restoreSelectedVersion" content="Cannot restore" /> }
            Diff viewer | <Button size="sm" className={ !allowRestore ? 'disabled' : '' } id="restoreSelectedVersion" onClick={ () => { allowRestore ? action() : ''; } }>
                            { callInProgress ? 'Restoring ...' : 'Restore selected version'}
                        </Button>
                    </ModalHeader>
                    <ModalBody>
                        <div className="mb-2">
                            <div style={ { display: 'inline-block', width: '50%', textAlign: 'center', fontSize: 16, fontWeight: 500 } }>Version {selectedVersionNumber} (selected)</div>
                            <div style={ { display: 'inline-block', width: '50%', textAlign: 'center', fontSize: 16, fontWeight: 500 } }>Version {currentVersionNumber} (current)</div>
                        </div>
                        {files.map( renderFile )}
                    </ModalBody>
                </Modal>
            </Display>,
            modalRoot
        )
    );
};

const { func, string, bool } = PropTypes;
DiffView.propTypes = {
    close: func.isRequired,
    oldData: string.isRequired,
    newData: string.isRequired,
    showDiff: bool.isRequired
};

export default DiffView;
