import types from './types';

const getActivity = ( data, extraData ) => {
    const body = data;
    return {
        type: types.GET_ACTIVITY,
        additionalData: extraData,
        async: true,
        generalFetching: true,
        payload: {
            path: '/getActivities',
            method: 'POST',
            body
        }
    };
};


export default {
    getActivity
};
