// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const ADD_CATEGORY = 'ADD_CATEGORY';
const ADD_CATEGORY_COMPLETED = 'ADD_CATEGORY_COMPLETED';
const ADD_CATEGORY_FAILED = 'ADD_CATEGORY_FAILED';

const ASSIGN_CATEGORY = 'ASSIGN_CATEGORY';
const ASSIGN_CATEGORY_COMPLETED = 'ASSIGN_CATEGORY_COMPLETED';
const ASSIGN_CATEGORY_FAILED = 'ASSIGN_CATEGORY_FAILED';

const DELETE_CATEGORY = 'DELETE_CATEGORY';
const DELETE_CATEGORY_COMPLETED = 'DELETE_CATEGORY_COMPLETED';
const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';

const SELECT_CATEGORY_FOR_DOC_DISPLAY = 'SELECT_CATEGORY_FOR_DOC_DISPLAY';

const UPDATE_DOCUMENTS_CATEGORIES = 'UPDATE_DOCUMENTS_CATEGORIES';

const CATEGORIES_RESET = 'CATEGORIES_RESET';

export default {
    ADD_CATEGORY,
    ADD_CATEGORY_COMPLETED,
    ADD_CATEGORY_FAILED,
    ASSIGN_CATEGORY,
    ASSIGN_CATEGORY_COMPLETED,
    ASSIGN_CATEGORY_FAILED,
    DELETE_CATEGORY,
    DELETE_CATEGORY_COMPLETED,
    DELETE_CATEGORY_FAILED,
    SELECT_CATEGORY_FOR_DOC_DISPLAY,
    CATEGORIES_RESET,
    UPDATE_DOCUMENTS_CATEGORIES
};
