// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const ADD_MEMBER = 'ADD_MEMBER';
const ADD_MEMBER_COMPLETED = 'ADD_MEMBER_COMPLETED';
const ADD_MEMBER_FAILED = 'ADD_MEMBER_FAILED';

const CHANGE_EMAIL = 'CHANGE_EMAIL';

const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS';
const TERMS_AND_CONDITIONS_COMPLETED = 'TERMS_AND_CONDITIONS_COMPLETED';
const TERMS_AND_CONDITIONS_FAILED = 'TERMS_AND_CONDITIONS_FAILED';

export default {
    ADD_MEMBER,
    ADD_MEMBER_COMPLETED,
    ADD_MEMBER_FAILED,
    CHANGE_EMAIL,
    TERMS_AND_CONDITIONS,
    TERMS_AND_CONDITIONS_COMPLETED,
    TERMS_AND_CONDITIONS_FAILED
};
