import React, { Component } from 'react';

class MonthlyView extends Component {
    constructor() {
        super();
        this.state = {
            interval: 1
        };
        this.updateOccurances = this.updateOccurances.bind( this );
    }

    componentWillMount() {
        if ( typeof this.props.data !== 'undefined' && Object.keys( this.props.data ).hasItems() && Object.keys( this.props.data.repeatOptions ).hasItems() ) {
            this.setState( { interval: this.props.data.repeatOptions.interval } );
        } else {
            this.props.callback( { type: 'MONTHLY', payload: { interval: this.state.interval } } );
        }
    }

    updateOccurances( value ) {
        if ( Number( value ) > 12 || Number( value ) < 1 || this.props.disabled ) {
            return;
        }
        this.setState( { interval: value } );
        this.props.callback( { type: 'MONTHLY', payload: { interval: value } } );
    }

    render() {
        return (
            <React.Fragment>
                Every:
                <input
                    type="number"
                    className="texteditor-input"
                    min="1"
                    max="12"
                    defaultValue="1"
                    disabled={ this.props.disabled }
                    value={ this.state.interval }
                    onChange={ e => this.updateOccurances( e.target.value ) } />
                <span>month(s)</span>
            </React.Fragment>
        );
    }
}

export default MonthlyView;
