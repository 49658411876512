/* eslint-disable react/jsx-boolean-value */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import qs from 'query-string';
import { Badge } from 'reactstrap';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import { determineStatus } from '../../utils/determineEventStatus';
import CalendarEventModal from '../Modals/CalendarEventModal';
import shallowCompare from '../../utils/shallowCompare';
import TableView from '../Table/Table';
import Display from '../Display';
import { applicationOperations } from '../../redux/ducks/application';
import { versionOperations } from '../../redux/ducks/version';
import CalendarEventAction from '../CalendarEvent/CalendarEventAction';

const columns = [
    { name: 'name', title: 'Name' },
    { name: 'status', title: 'Status' },
    { name: 'priority', title: 'Priority' },
    { name: 'date', title: 'Due Date' },
    { name: 'time', title: 'Due Time' },
    { name: 'document', title: 'Document' },
    { name: 'action', title: 'Action' }
];

const columnWidths = [
    { columnName: 'name', width: '35%' },
    { columnName: 'priority', width: '5%' },
    { columnName: 'date', width: '5%' },
    { columnName: 'time', width: '5%' },
    { columnName: 'document', width: '40%' },
    { columnName: 'status', width: '10%' },
    { columnName: 'action', width: '10%' }
];

let close = false;
class AlertsList extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            cannotAccess: false,
            desiredEvent: ''
        };
        this.toggleModal = this.toggleModal.bind( this );
        this.selectEvent = this.selectEvent.bind( this );
        this.styleRow = this.styleRow.bind( this );
        this.buildRow = this.buildRow.bind( this );
        this.rowClick = this.rowClick.bind( this );
        this.saveEvent = this.saveEvent.bind( this );
        this.asideToggle = this.asideToggle.bind( this );
    }

    shouldComponentUpdate( nextProps, nextState ) {
        return shallowCompare( this, nextProps, nextState );
    }

    UNSAFE_componentWillUpdate( nextProps ) {
        if ( nextProps.calendarEvents.length > 0 && this.props.calendarEvents.length !== nextProps.calendarEvents.length && window.location.pathname === '/alerts' ) {
            const params = qs.parse( window.location.search );

            if ( typeof params.id === 'undefined' || typeof params.accountId === 'undefined' ) {
                window.history.replaceState( null, null, window.location.pathname );
                return;
            }
            const desiredEvent = nextProps.calendarEvents.having( 'id', params.id )[0];
            if ( typeof desiredEvent !== 'undefined' ) {
                this.selectEvent( nextProps.calendarEvents.having( 'id', params.id )[0] );
            } else {
                this.setState( { cannotAccess: true, desiredEvent: params.id } );
                // this will remove the query params from the url.
                window.history.replaceState( null, null, window.location.pathname );
            }
        }

        if ( nextProps.calendarEvents !== this.props.calendarEvents && typeof this.props.refreshEsQuery !== 'undefined' && this.props.externalData.lengh > 0 ) {
            this.props.refreshEsQuery();
        }
    }

    componentWillUnmount() {
        this.props.setFilter( {} );
    }

    toggleModal() {
        if ( this.state.calendarEventModal ) {
            close = true;
            this.setState( { calendarEventModal: false } );
        } else {
            this.setState( { calendarEventModal: true } );
        }
    }

    selectEvent( data ) {
        this.props.clearSelectedEvent();
        this.props.selectEvent( data );
        setTimeout( () => {
            this.setState( { calendarEventModal: true } );
        }, 150 );
    }

    saveEvent( data ) {
        this.toggleModal();
        this.props.saveCalendarEvent( data );
        this.props.refreshEsQuery();
    }

    styleRow( event ) {
        let style = {};

        if ( event.resolved ) {
            style = { textDecoration: 'line-through', color: 'gray' };
        }

        if ( determineStatus( event ) === 'Overdue' ) {
            style = { color: 'red' };
        }

        return style;
    }

    asideToggle( event ) {
        this.props.getVersions( {
            accountId: event.accountId,
            elementId: event.id,
            element: event,
            collection: 'calendarEvents'
        } );
    }

    buildRow( data ) {
        return data.map( ( event ) => ( {
            id: event.id,
            rowStyling: this.styleRow( event ),
            name: event.recurrencyId ? `|recurrent|${event.name}` : event.name,
            priority: event.priority,
            date: event.date,
            time: moment( event.date ).format( 'h:mm a' ),
            document: event.documentName,
            status: determineStatus( event ),
            action: <CalendarEventAction event={ event } asideToggle={ this.asideToggle } />
        } ) );
    }

    rowClick( row ) {
        this.selectEvent( this.props.calendarEvents.having( 'id', row.id )[0] );
    }

    render() {
        if ( this.state.cannotAccess ) {
            return (
                <h3>{`Sorry, alert with id #${this.state.desiredEvent} does not exist.`}</h3>
            );
        }
        const { calendarEvents, document, externalData, isSearch } = this.props;
        const cellTemplates = {
            priority: ( priority, id ) => ( <div style={ { color: priority === 'high' ? 'red' : priority === 'low' ? 'green' : 'orange' } }>{priority}</div> ),
            name: ( name ) => { const a = name.split( '|recurrent|' ); return ( a.length === 2 ? <div><span className="fa fa-repeat" /> {a[1]}</div> : <div>{a[0]}</div> ); },
        };
        let data = [];
        if ( typeof externalData !== 'undefined' && isSearch ) {
            data = this.buildRow( externalData );
        } else if ( Object.keys( document ).hasItems() ) {
            data = this.buildRow( calendarEvents.having( 'documentId', document.id ) );
        } else {
            data = this.buildRow( calendarEvents );
        }
        return (
            <div className="customTable clickableRow white-box" id="alertsList">
                <TableView
                    rowsDOM={ data }
                    columns={ columns }
                    tableStyle={ { minHeight: 'auto' } }
                    columnWidths={ columnWidths }
                    cellTemplates={ cellTemplates }
                    rowClickCallback={ this.rowClick }
                    showFilter={ this.props.showFilter }
                    defaultFilters={ [ this.props.filters ] }
                />
                <Display when={ this.state.calendarEventModal }>
                    <CalendarEventModal
                        toggle={ this.toggleModal }
                        action={ this.saveEvent }
                        openState={ this.state.calendarEventModal }
                        showEdit={ true }
                    />
                </Display>
            </div>
        );
    }
}

const { object, bool, array, func } = PropTypes;

AlertsList.defaultProps = {
    document: {},
    externalData: [],
    calendarEvents: [],
    isSearch: false,
    saveCalendarEvent: func,
    selectEvent: func,
    refreshEsQuery: func
};

AlertsList.propTypes = {
    selectEvent: func,
    refreshEsQuery: func,
    document: object,
    externalData: array,
    calendarEvents: array,
    isSearch: bool,
    saveCalendarEvent: func
};

const mapStateToProps = state => ( {
    calendarEvents: state.events.calendarEvents,
    filters: state.application.filters
} );

const mapDispatchToProps = dispatch => ( {
    saveCalendarEvent: ( data ) => dispatch( calendarEventsOperations.saveCalendarEvent( data ) ),
    clearSelectedEvent: ( data ) => dispatch( calendarEventsOperations.clearSelectedEvent( data ) ),
    selectEvent: ( data ) => dispatch( calendarEventsOperations.selectEvent( data ) ),
    getVersions: ( data ) => dispatch( versionOperations.getVersions( data ) ),
    setFilter: ( data ) => dispatch( applicationOperations.setFilter( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( AlertsList );
