import * as React from 'react';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment-timezone';
const format = 'h:mm a';

const now = moment().hour( 0 ).minute( 0 );

class Timepicker extends React.Component {
    constructor() {
        super();
        this.state = {
            initTime: moment().hour( 0 ).minute( 0 )
        };
        this.onTimeChangeHandler = this.onTimeChangeHandler.bind( this );
    }

    componentWillMount( ) {
        this.determineValue( this.props.value );
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        if ( nextProps.value === this.props.value ) {
            return;
        }
        this.determineValue( nextProps.value );
    }

    onTimeChangeHandler( value ) {
        this.props.onChange( value.toISOString() );
    }

    determineValue( val ) {
        let value = now;
        if ( typeof val === 'string' ) {
            value = moment( new Date( val ) );
        } else {
            value = moment( val );
        }
        this.setState( { initTime: value } );
    }

    render() {
        return (
            <TimePicker
                showSecond={ false }
                defaultValue={ this.state.initTime }
                className="xxx"
                minuteStep={ 15 }
                allowEmpty={ false }
                onChange={ this.onTimeChangeHandler }
                format={ format }
                use12Hours
                disabled={ this.props.disabled }
                inputReadOnly
            />
        );
    }
}

export default Timepicker;
