import types from './types';


const initialState = {
    counts: [], histograms: []
};

const elasticsearchReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.SEARCH: {
            return state;
        }
        case types.HISTOGRAM: {
            return state;
        }
        case types.COUNT: {
            return {
                ...state,
                counts: [ ...state.counts.filter( index => index.indexName !== action.data.indexName ), action.data ]
            };
        }
        case types.CLEAR: {
            return initialState;
        }

        default: return state;
    }
};

export default elasticsearchReducer;
