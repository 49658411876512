import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';

import HeaderDropdown from './HeaderDropdown';
import { currentAccountOperations } from '../../redux/ducks/currentAccount';
import { uploadsOperations } from '../../redux/ducks/uploads';

class Header extends React.Component {
    render() {
        const style = {
        };
        if ( this.props.fullWidth ) {
            style.marginLeft = 0;
        }

        return (
            <header className="app-header navbar" style={ style }>
                <div />
                <img
                    src={ this.props.currentAccount.logo || '/img/mycontracts-logo-3-long-v3.svg' }
                    alt="mycontracts"
                    className="logo" />
                <div className="mini" />
                <Nav className="userDropdown" navbar>
                    <HeaderDropdown
                        inMaintenance={ this.props.inMaintenance }
                        history={ this.props.history }
                        accounts={ this.props.accounts }
                        currentUser={ this.props.currentUser }
                        selectAccount={ this.props.selectAccount }
                    />
                </Nav>
            </header>
        );
    }
}

Header.propTypes = {
    history: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    selectAccount: PropTypes.func.isRequired,
    accounts: PropTypes.array.isRequired,
    fullWidth: PropTypes.bool,
    inMaintenance: PropTypes.bool,
};

const mapStateToProps = state => ( {
    accounts: state.accounts,
    currentAccount: state.currentAccount,
} );

const mapDispatchToProps = dispatch => ( {
    clear: ( ) => dispatch( uploadsOperations.clear( ) ),
    selectAccount: ( data ) => dispatch( currentAccountOperations.selectAccount( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( Header );
