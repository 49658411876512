import actions from './actions';
import messageOperations from '../messages/operations';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';
import applicationActions from '../application/actions';

// This is a link to an action defined in actions.js.
const { } = actions;

const updateInvite = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.updateInvite( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Invitation updated' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const deleteInvite = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.deleteInvite( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Invitation deleted' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

const reissueInvite = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    const { currentAccount, groups, currentUser, resources, categories } = getState();
    dispatch( actions.reissueInvite( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Invitation reissued' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );


export default {
    updateInvite,
    deleteInvite,
    reissueInvite
};
