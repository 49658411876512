import types from "./types";

/* State shape

[
    {
        type: string,
        text: string,
        id: uuid
    }
]

*/

const initialState = [ ];

const messagesReducers = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.SHOW_MESSAGE:
            return [ ...state, action.payload ];
        case types.DELETE_MESSAGE: {
            const index = state.pluck( "id" ).indexOf( action.payload.id );
            return [
                ...state.slice( 0, index ),
                ...state.slice( index + 1 )
            ];
        }

        default: return state;
    }
};

export default messagesReducers;
