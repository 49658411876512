import React from 'react';
import PropTypes from 'prop-types';

const Option = ( { callback, selected, type, text, disabled } ) => (
    <div
        onClick={ () => { callback( type ); } }
        className={ `item noselect radiobutton ${selected ? 'radiobutton-checked' : ''}` }
        role="radio"
        aria-checked="false">
        <div className="radio-value-container">
            <div className={ `radiobutton-icon ${selected ? 'radiobutton-icon-checked' : ''}` }>
                <div className={ disabled ? 'radiobutton-icon-dot disabled' : 'radiobutton-icon-dot' } />
            </div>
        </div>
        <div className="item-content noselect">{text}</div>
    </div>
);
Option.propTypes = {
    callback: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};
export default Option;
