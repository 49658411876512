
const routes = {
    '/': 'Home',
    '/account': 'Account',
    '/dashboard': 'Dashboard',
    '/calendar': 'Calendar',
    '/docvault': 'Doc Vault',
    '/parties': 'Parties',
    '/alerts': 'Alerts',
    '/add': 'Add',
    '/add/document': 'Document',
    '/add/contract': 'Contract',
    '/add/other': 'Other',
    '/docvault/category': 'Categories',
    '/views/Account': 'Accounts',
    '/settings': 'Account Settings',
    '/settings/options': 'Account Options',
    '/settings/categories': 'Account Categories'
};
export default routes;
