// The types file contains the names of the actions that you are dispatching in your application.
// As a good practice, you should try to scope the names based on the feature they belong to.
// This helps when debugging more complex applications.
const SAVE_CALENDAR_EVENT = 'SAVE_CALENDAR_EVENT';
const SAVE_CALENDAR_EVENT_COMPLETED = 'SAVE_CALENDAR_EVENT_COMPLETED';
const SAVE_CALENDAR_EVENT_FAILED = 'SAVE_CALENDAR_EVENT_FAILED';

const RESOLVE_EVENT = 'RESOLVE_EVENT';
const RESOLVE_EVENT_COMPLETED = 'RESOLVE_EVENT_COMPLETED';
const RESOLVE_EVENT_FAILED = 'RESOLVE_EVENT_FAILED';

const DELETE_MEMBER = 'DELETE_MEMBER';
const DELETE_MEMBER_COMPLETED = 'DELETE_MEMBER_COMPLETED';
const DELETE_MEMBER_FAILED = 'DELETE_MEMBER_FAILED';

const GET_RECURRENCE_DEFINITION = 'GET_RECURRENCE_DEFINITION';
const GET_RECURRENCE_DEFINITION_COMPLETED = 'GET_RECURRENCE_DEFINITION_COMPLETED';
const GET_RECURRENCE_DEFINITION_FAILED = 'GET_RECURRENCE_DEFINITION_FAILED';

const SELECT_EVENT = 'SELECT_EVENT';

const CLEAR_SELECTED_EVENT = 'CLEAR_SELECTED_EVENT';

export default {
    SELECT_EVENT,
    CLEAR_SELECTED_EVENT,
    SAVE_CALENDAR_EVENT,
    SAVE_CALENDAR_EVENT_COMPLETED,
    SAVE_CALENDAR_EVENT_FAILED,
    RESOLVE_EVENT,
    RESOLVE_EVENT_COMPLETED,
    RESOLVE_EVENT_FAILED,
    DELETE_MEMBER,
    DELETE_MEMBER_COMPLETED,
    DELETE_MEMBER_FAILED,
    GET_RECURRENCE_DEFINITION,
    GET_RECURRENCE_DEFINITION_COMPLETED,
    GET_RECURRENCE_DEFINITION_FAILED
};
