import types from './types';
import applicationTypes from '../application/types';

const initialState = null;

const files = ( state = initialState, action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return initialState;
        default: return state;
    }
};

export default files;
