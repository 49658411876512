/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import { uploadsOperations } from '../../redux/ducks/uploads';

import Display from '../Display';
import Uploader from '../File/Uploader';
import TooltipWrapper from '../Tooltip/Tooltip';

class DocumentUploadModal extends React.Component {
    constructor() {
        super();
        this.state = {
            uploadModal: false,
            filesMeta: [],
            files: []
        };
    }

    UNSAFE_componentWillMount() {
    }

    filesDropped = files => {
        const filesArray = files.map( file => ( { fileName: `${this.props.accountId}^${file.name}` } ) );
        this.setState( { files: filesArray, filesMeta: files } );
        // firebase.uploadFiles(filesPath, files, filesPath);

        // this.props.buildUploadQueue( files );
        this.props.saveAddedFilesToState( files );
        // setTimeout( () => { this.props.triggerUpload(); }, 500 );
    };

    closeUploadModal = () => {
        this.setState( { uploadModal: false } );
        if ( this.state.files.hasItems() ) {
            this.setState( { files: [] } );
        }
    };

    toggleUploadModal = () => {
        if ( !this.props.currentLocationValid ) {
            return;
        }
        this.setState( { uploadModal: !this.state.uploadModal } );
    };

    render() {
        const { uploads } = this.props;

        return (
            <div>
                <TooltipWrapper refId="fileUploads" content={ this.props.currentLocationValid ? 'Upload files' : 'Fix document location before uploading files.' } />
                <div
                    id="fileUploads"
                    className={ `btn btn-outline-primary btn-outline btn-circle btn-lg m-r-5 float-right ${this.props.currentLocationValid ? '' : 'disabled'}` }
                    style={ { marginRight: '5px' } }
                    onClick={ this.toggleUploadModal }>
                    <div className="fa fa-cloud-upload" style={ { paddingTop: '6px' } } />
                </div>
                <Display when={ this.state.uploadModal }>
                    <Modal isOpen={ this.state.uploadModal } toggle={ this.toggleUploadModal }>
                        <ModalHeader toggle={ this.toggleUploadModal }>Upload file</ModalHeader>
                        <ModalBody>
                            <Uploader
                                accountId={ this.props.currentAccount.id }
                                callback={ this.filesDropped }
                                files={ this.state.filesMeta }
                            />

                            {/* <Display when={ this.state.uploads }> */}
                            {/* <div> */}
                            {/* <div className="mb-3" > */}
                            {/* <h4 className="text-left">Uploading files</h4> */}
                            {/* <div style={ { marginTop: '10px' } }> */}
                            {/* { uploads.map( f => */}
                            {/* ( <div key={ Math.random() } className="mb-3 progress" > */}
                            {/* <span key={ Math.random() } className="text-primary p-lg-4">{f.fileName}</span> */}
                            {/* <span key={ Math.random() } className="progress-bar progress-bar-animated bg-primary" aria-valuenow={ f.progressPercent } role="progressbar" /> */}
                            {/* </div> ) ) } */}
                            {/* </div> */}

                            {/* </div> */}

                            {/* </div> */}
                            {/* </Display> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={ this.closeUploadModal }>Close</Button>
                            <Button color="primary" onClick={ this.toggleUploadModal }>Save and continue</Button>
                        </ModalFooter>
                    </Modal>
                </Display>
            </div>
        );
    }
}

DocumentUploadModal.defaultProps = {
    uploads: []
};

const { string, func, array } = PropTypes;

DocumentUploadModal.propTypes = {
    accountId: string.isRequired,
    saveAddedFilesToState: func.isRequired,
    uploads: array

};

const mapStateToProps = state => ( {
    breadcrumbs: state.categories.breadcrumbs,
    folders: state.options.folders,
    options: state.options.options,
    currentUser: state.currentUser,
    uploads: state.uploads.files,
    currentAccount: state.currentAccount,
    selectedDocument: state.documents.selectedDocument,
} );

const mapDispatchToProps = dispatch => ( {
    resolveEvent: ( data ) => dispatch( calendarEventsOperations.resolveEvent( data ) ),
    triggerUpload: ( ) => dispatch( uploadsOperations.triggerUpload( ) ),
    buildUploadQueue: ( files ) => dispatch( uploadsOperations.buildUploadQueue( files ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( DocumentUploadModal );
