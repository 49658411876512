import actions from './actions';
import { displayErrorFromServer } from '../../../utils/displayErrorFromServer';
import messageOperations from '../messages/operations';

// This is a link to an action defined in actions.js.
const { } = actions;

const saveSummary = ( data ) => ( dispatch, getState ) => new Promise( ( ( resolve, reject ) => {
    const { user } = getState().application;
    dispatch( actions.saveSummary( data, { user } ) )
        .then(
            ( res ) => {
                dispatch( messageOperations.showSuccess( 'Summary saved' ) );
                resolve( res );
            },
            ( err ) => {
                dispatch( messageOperations.showError( displayErrorFromServer( err ) ) );
                reject( err );
            }
        );
} ) );

export default {
    saveSummary
};
