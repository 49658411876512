/* eslint-disable react/jsx-boolean-value */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

import qs from 'query-string';
import { calendarEventsOperations } from '../../redux/ducks/calendarEvents';
import PartySearch from '../../components/Search/PartySearch';


class PartiesView extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            cannotAccess: false
        };
    }

    UNSAFE_componentWillMount() {
        const params = qs.parse( window.location.search );
        if ( typeof params.id === 'undefined' || typeof params.accountId === 'undefined' ) {
            return;
        }
        if ( this.props.currentAccount.id !== params.accountId ) {
            const desiredAccount = this.props.accounts.having( 'id', params.accountId )[0];
            if ( typeof desiredAccount === 'undefined' ) {
                this.setState( { cannotAccess: true } );
            }
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        // user goes to /parties after an denial of access.
        if ( nextProps.location.pathname === '/parties' ) {
            if ( this.props.location.search.length > nextProps.location.search.length ) {
                this.setState( { cannotAccess: false } );
            }
        }
    }

    render() {
        const hasAccount = Object.keys( this.props.currentAccount ).hasItems();
        if ( !hasAccount ) {
            return (
                <div className="col-lg-12">
                    <Alert color="danger">
              First you need to create an account or be added to one.
                    </Alert>
                </div>
            );
        }
        if ( this.state.cannotAccess || this.props.currentUser.isDefault ) {
            return (
                <div className="col-lg-12">
                    <Alert color="danger">
                        Sorry, you do not have access here.
                    </Alert>
                </div>
            );
        }
        return (
            <div>
                <PartySearch
                    currentAccount={ this.props.currentAccount }
                    auth={ this.props.auth }
                    match={ this.props.match } />
            </div>
        );
    }
}

const { array, func } = PropTypes;

PartiesView.defaultProps = {
};

PartiesView.propTypes = {
};

const mapStateToProps = state => ( {
    currentAccount: state.currentAccount,
    currentUser: state.currentUser,
    accounts: state.accounts
} );

const mapDispatchToProps = dispatch => ( {
    saveCalendarEvent: ( data ) => dispatch( calendarEventsOperations.saveCalendarEvent( data ) ),
    selectEvent: ( data ) => dispatch( calendarEventsOperations.selectEvent( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( PartiesView );
