import React from 'react';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import Button from 'reactstrap/lib/Button';

class FileAction extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            isDropDownOpen: false
        };
        this.toggle = this.toggle.bind( this );
    }

    toggle() {
        this.setState( prevState => ( {
            isDropDownOpen: !prevState.isDropDownOpen
        } ) );
    }

    render() {
        const { file, canCreate, onFileClick, onFileDeleteClick, asideToggle, onFileRename } = this.props;

        return (
            <Dropdown isOpen={ this.state.isDropDownOpen } toggle={ this.toggle } className="dropdownContainer">
                <DropdownToggle caret color="white" className="font-lg">
                </DropdownToggle>
                <DropdownMenu right style={ { marginTop: '-5px' } }>
                    <DropdownItem
                        className=""
                        id={ `download-${file.fileId}` }
                        onClick={ () => onFileClick( file ) }
                    >Download
                    </DropdownItem>
                    {file.isPDFConverted && (
                        <DropdownItem
                            className=""
                            id={ `download-${file.fileId}` }
                            style={ { marginRight: '10px', color: 'primary' } }
                            onClick={ () => onFileClick( file, true ) }
                            role="presentation">Download as PDF
                        </DropdownItem>
                    )
                    }
                    <DropdownItem
                        className=""
                        id={ `download-${file.fileId}` }
                        onClick={ () => { asideToggle( file ); } }>
                    Versions
                    </DropdownItem>
                    { canCreate && (
                        <React.Fragment>
                            <DropdownItem
                                className="i"
                                id={ `edit-${file.fileId}` }
                                style={ { marginRight: '10px' } }
                                onClick={ () => onFileRename( file ) }
                                role="presentation">Rename
                            </DropdownItem>
                            <DropdownItem
                                className="i"
                                id={ `delete-${file.fileId}` }
                                style={ { marginRight: '10px', color: 'red' } }
                                onClick={ () => onFileDeleteClick( file ) }
                                role="presentation">Delete
                            </DropdownItem>
                        </React.Fragment>

                    )}
                </DropdownMenu>

            </Dropdown>

        );
    }
}

export default FileAction;
