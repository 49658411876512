import types from './types';

const showMessage = ( text, type, options ) => ( {
    type: types.SHOW_MESSAGE,
    payload: {
        text,
        type,
        options,
        id: Math.floor( 1000 * Math.random( ) )
    }
} );

const deleteMessage = ( id ) => ( {
    type: types.DELETE_MESSAGE,
    payload: {
        id
    }
} );

export default {
    showMessage,
    deleteMessage
};
