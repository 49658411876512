import React from 'react';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import Button from 'reactstrap/lib/Button';

class CalendarEventAction extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            isDropDownOpen: false
        };
        this.toggle = this.toggle.bind( this );
    }

    toggle() {
        this.setState( prevState => ( {
            isDropDownOpen: !prevState.isDropDownOpen
        } ) );
    }

    render() {
        const { event, asideToggle } = this.props;

        return (
            <Dropdown isOpen={ this.state.isDropDownOpen } toggle={ this.toggle } className="dropdownContainer">
                <DropdownToggle caret color="white" className="font-lg">
                </DropdownToggle>
                <DropdownMenu right style={ { marginTop: '-5px' } }>
                    <DropdownItem
                        className=""
                        id={ `download-${event.id}` }
                        onClick={ () => { asideToggle( event ); } }>Versions
                    </DropdownItem>
                </DropdownMenu>

            </Dropdown>

        );
    }
}

export default CalendarEventAction;
