import React from 'react';

import Select from 'react-select';
import { isLocationValid } from './helpers';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={ groupStyles }>
        <span>{ data.label }</span>
        <span style={ groupBadgeStyles }>{ data.options.length }</span>
    </div>
);

function extractData( data ) {
    const catName = data.formData.split( '/' )[0];
    // const catName = `${data.formData.split( '/' )[0]}/${data.formData.split( '/' )[1]}`;
    const label = data.formData;
    // let label = '';
    // if ( ( data.formData.split( '/' ).length - 1 ) === 1 ) {
    //     label = data.formData.split( '/' )[1];
    // } else {
    //     label = data.formData.replace( `${catName}/`, '' );
    // }
    const currentLocationValid = isLocationValid( data.formData, data.schema.data );
    return { catName, label, currentLocationValid };
}

class GroupedSelector extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { ...props.formData, currentLocationValid: true };
        this.onChange = this.onChange.bind( this );
    }

    onChange( data ) {
        const currentLocationValid = isLocationValid( data.value, this.props.schema.data );
        this.setState( {
            location: data.value,
            value: data,
            currentLocationValid
        } );
        setTimeout( () => { this.props.onChange( data.value ); }, 300 );
    }

    UNSAFE_componentWillMount() {
        if ( typeof this.props.formData !== 'undefined' ) {
            const { catName, label, currentLocationValid } = extractData( this.props );
            this.setState( { value: { value: label, label, category: catName }, currentLocationValid } );
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {
        if ( typeof nextProps.formData !== 'undefined' ) {
            const { catName, label, currentLocationValid } = extractData( nextProps );
            this.setState( { value: { value: label, label, category: catName }, currentLocationValid } );
        }
    }

    render() {
        const colourStyles = {
            control: styles => ( { ...styles, backgroundColor: 'white' } ),
            option: ( styles, { data, isDisabled, isFocused, isSelected } ) => ( {
                ...styles,
                margin: '0 0 0 15px',
                cursor: isDisabled ? 'not-allowed' : 'default',
            } ),
        };
        return (
            <div>
                <label className="control-label" htmlFor={ this.props.idSchema.$id }>
                    { this.props.schema.title }
                    { this.props.required
                        && <span className="required">*</span>
                    }
                </label>
                { !this.state.currentLocationValid
                    && (
                        <div className="invalidLocation">
                        Document location changed!
                            <p>Old location: <i style={ { color: '#f37676' } }>{this.props.formData}</i></p>
                            <p>Please choose a new one </p>
                        </div>
                    )
                }
                <Select
                    // defaultValue={ colourOptions[1] }
                    options={ this.props.schema.data }
                    value={ this.state.value }
                    className="customReactSelect"
                    formatGroupLabel={ formatGroupLabel }
                    onChange={ this.onChange }
                    styles={ colourStyles }
                />
            </div>
        );
    }
}

export default GroupedSelector;
