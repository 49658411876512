import { combineReducers } from 'redux';
import types from './types';
import applicationTypes from '../application/types';
import { build } from '../../../utils/buildDataFromSubscription';

const initialState = null;
const initialTempDoc = {
    currentDocId: null,
    formData: {},
    parties: [],
    files: [],
    eventsToBeCreated: {},
    order: [],
    schema: {
        type: 'object',
        required: [],
        properties: {}
    },
    uiSchema: {
        options: {
            defaultOptions: { readonly: true }
        }
    }
};

const filesMetaReducer = ( state = [], action ) => {
    switch ( action.type ) {
        case applicationTypes.LOGOUT: return [];
        case applicationTypes.PARSE_SUBSCRIPTION:
            if ( action.payload.target === 'filesMeta' ) {
                const documents = build( action.payload.data );
                let newState = [ ...documents ];
                if ( action.payload.type === 'concat' && documents.hasItems() ) {
                    const newState2 = [ ...state ];
                    documents.map( doc => {
                        const position = newState2.findIndex( x => x.id === doc.id );
                        if ( position > -1 ) {
                            newState2.splice( position, 1, doc );
                        } else {
                            newState2.unshift( doc );
                        }
                    } );
                    newState = newState2;
                }
                if ( action.payload.type === 'delete' && documents.hasItems() && typeof action.payload.documentId !== 'undefined' ) {
                    const newState2 = [ ...state.except( 'documentId', action.payload.documentId ) ];
                    newState = [ ...newState2, ...documents ].sort( sortByUploaded );
                }
                return newState;
            }
            return state;
        default: return state;
    }
};

const filesMeta = combineReducers( {
    list: filesMetaReducer
} );

export default filesMeta;

function sortByUploaded( a, b ) {
    if ( a.uploaded < b.uploaded ) {
        return -1;
    }

    if ( a.uploaded > b.uploaded ) {
        return 1;
    }

    return 0;
}
