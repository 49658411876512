function getIdOfExistingMessage( messages, { text, type } ) {
    const checkMessage = element => element.text === text && element.type === type;

    const matchingMessages = messages.filter( checkMessage );
    if ( matchingMessages.length > 0 ) {
        return matchingMessages.first( ).id;
    }

    return null;
}

export default {
    getIdOfExistingMessage
};
