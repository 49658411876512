/* eslint-disable react/jsx-boolean-value */
import React, { Fragment, PureComponent } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';

import { connect } from 'react-redux';
import TableView from '../Table/Table';
import { activityOperations } from '../../redux/ducks/activity';

const columns = [
    { name: 'at', title: 'Time' },
    { name: 'activity', title: 'Activity' },
    { name: 'type', title: 'Type' },
    { name: 'name', title: 'Name' },
    { name: 'user', title: 'User' },

];

const columnWidths = [
    { columnName: 'at', width: '20%' },
    { columnName: 'user', width: '25%' },
    { columnName: 'type', width: '10%' },
    { columnName: 'name', width: '30%' },
    { columnName: 'activity', width: '10%' }
];

const iconStyle = {
    fontSize: '25px',
};

class ActivityList extends PureComponent {
    constructor( props ) {
        super( props );
        this.state = {
            history: [],
        };
        this._isMounted = false;
        this.loadDate = this.loadDate.bind( this );
        this.decorateEmail = this.decorateEmail.bind( this );
        this.decorateActivity = this.decorateActivity.bind( this );
        this.decorateType = this.decorateType.bind( this );
        this.styleRow = this.styleRow.bind( this );
        this.buildRow = this.buildRow.bind( this );
        this.rowClick = this.rowClick.bind( this );
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.setState( { history: nextProps.activity } );
    }

    loadDate( from, to ) {
        const { accountId } = this.props;
        const body = { accountId, activityType: 'ALL', from: parseInt( from ), to: parseInt( to ) };
        this.props.getActivity( body )
            .then( ( ) => {
                if ( this._isMounted ) {
                    this.setState( { loading: false } );
                }
            } )
            .catch( ( err ) => {
                if ( this._isMounted ) {
                    this.setState( { loading: false } );
                }
                console.error( err );
            } );
    }


    decorateEmail( email ) {
        return (
            <Fragment>
                <span>
                    <Gravatar
                        email={ email }
                        className="img-avatar"
                        style={ { width: '40px', height: '40px' } } />
                </span>
                <span style={ { marginLeft: '5px' } }> {email} </span>
            </Fragment>
        );
    }

    decorateActivity( activity ) {
        return (
            <Fragment>
                <span>
                    {activity === 'CREATE' ? <div style={ iconStyle } className="fa fa-plus-square-o green" /> : <div />}
                    {activity === 'EDIT' || activity === 'UPDATE' ? <div style={ iconStyle } className="fa fa-pencil-square-o blue" /> : <div />}
                    {activity === 'DELETE' ? <div style={ iconStyle } className="fa fa-trash-o text-warning" /> : <div />}
                </span>
            </Fragment>
        );
    }

    decorateType( type ) {
        return (
            <Fragment>
                <span>
                    {type === 'event' ? <div style={ iconStyle } className="fa fa-calendar-plus-o" /> : <div />}
                    {type === 'document' ? <div style={ iconStyle } className="fa fa-file-text-o" /> : <div />}
                    {type === 'party' ? <div style={ iconStyle } className="fa fa-user-o" /> : <div />}
                </span>
            </Fragment>
        );
    }

    styleRow( doc ) {
        const style = {};

        return style;
    }

    buildRow( docList = [] ) {
        return docList.map( doc => ( {
            id: doc.id,
            rowStyling: this.styleRow( doc ),
            name: doc.name,
            type: this.decorateType( doc.type ),
            at: moment( doc.at ).format( 'DD MMM YYYY hh:mm:ss A' ),
            user: this.decorateEmail( doc.userEmail ),
            activity: this.decorateActivity( doc.action ),
        } ) );
    }

    rowClick( row ) {

    }

    UNSAFE_componentWillMount() {
        this._isMounted = true;
        const { from, to } = this.props;
        this.loadDate( from, to );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { accountId, uid } = this.props;
        const { history } = this.state;

        if ( typeof ( accountId ) === 'undefined' || this.state.history === [] ) {
            return (
                <h3>Sorry, no activities for this account.</h3>
            );
        }

        const data = this.buildRow( history );

        return (
            <div
                className="customTable white-box"
                id="alertsList"
                style={ { margin: '15px 15px 15px 15px', width: '100%' } }>
                <TableView
                    rowsDOM={ data }
                    columns={ columns }
                    tableStyle={ { minHeight: 'auto' } }
                    columnWidths={ columnWidths }
                    // showFilter={ true }
                    // defaultFilters={ [ this.props.filters ] }
                />
            </div>
        );
    }
}

const { string } = PropTypes;

ActivityList.defaultProps = {
    from: null,
    to: null,
};

ActivityList.propTypes = {
    accountId: string.isRequired,
    uid: string.isRequired
};

const mapStateToProps = state => ( {
    accountId: state.currentAccount.id,
    activity: state.activity,
    uid: state.currentUser.uid
} );

const mapDispatchToProps = dispatch => ( {
    getActivity: ( data ) => dispatch( activityOperations.getActivity( data ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( ActivityList );
